import React, { useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { AvailableTemplatesQuery } from 'generated/graphql';

import TemplatePreview from 'components/creative/TemplatePreview';

interface PreviewIconProps {
  templateId: AvailableTemplatesQuery['getWorkspaceTemplates'][number]['template_id'] | undefined;
  templates: AvailableTemplatesQuery['getWorkspaceTemplates'] | undefined;
}

function PreviewIcon({ templateId, templates }: PreviewIconProps) {
  const [showPreview, setShowPreview] = useState(false);

  function closePreview() {
    setShowPreview(false);
  }

  function onPreviewClick() {
    setShowPreview(true);
  }

  const template =
    templates?.find?.((template) => template.template_id === templateId) ?? templates?.[0];

  if (!template) {
    return null;
  }

  const previewUrl = template.preview_url ?? null;

  return (
    <>
      <IconButton
        aria-label="show preview"
        onClick={onPreviewClick}
        edge="end"
        disabled={!previewUrl}
      >
        <Tooltip title="Preview Template" arrow placement="top">
          <VisibilityIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <TemplatePreview
        open={showPreview}
        type={template.creative_type}
        template_name={template.name}
        preview_url={previewUrl}
        onClose={closePreview}
      />
    </>
  );
}

export default PreviewIcon;
