import React from 'react';

import { CircularProgress } from '@material-ui/core';

export default function LoadingChartIndicator() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        marginLeft: 'auto',
        height: '70%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );
}
