import { Box, Typography } from '@material-ui/core';

import HyperPingIndicator from 'components/HyperpingIndicator';

import { APP_CONFIG } from 'lib/app/config';

const Footer = () => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 12
    }}
  >
    <Box>
      <Typography variant="caption">
        {`© Copyright ${new Date().getFullYear()} ${APP_CONFIG.SITE_TITLE}`}
      </Typography>
    </Box>
    <Box>
      <HyperPingIndicator variant="inline" />
    </Box>
  </Box>
);

export default Footer;
