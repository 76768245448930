import React, { useCallback, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import RefreshIcon from '@material-ui/icons/Refresh';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';
import MovieEditIcon from 'mdi-material-ui/MovieEditOutline';
import { useSnackbar } from 'notistack';

import { useRefreshCreativeStatusLazyQuery } from 'generated/graphql';

import LoadingBackdrop from 'components/LoadingBackdrop';
import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import { ShareLogicVariants } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useRocketiumContext from 'lib/hooks/useRocketiumContext';
import { track } from 'lib/utils/track';

import CreativePreviewDialog from '../CreativePreviewDialog';
import EditCreativeDrawer from '../EditCreativeDrawer';
import TagHolder, { Tag } from '../TagHolder';
import useVideoCreativeCardStyles from './_styles';

interface VideoCreativeCardProps {
  id: string;
  title: string;
  date: string;
  tags?: Tag[];
  src?: string;
  ready?: boolean;
  downloadUrl?: string;
  disableActionButtons?: boolean;
  draft?: boolean;
  rocketiumId?: string;
  listingId?: string;
  hasBeenPosted?: boolean;
  onRefetchRequired?: () => void;
}

const VideoCreativeCard: React.FC<VideoCreativeCardProps> = ({
  id,
  title,
  date,
  tags,
  onRefetchRequired,
  src,
  downloadUrl,
  ready = false,
  disableActionButtons = false,
  draft,
  rocketiumId,
  listingId
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const classes = useVideoCreativeCardStyles();
  const [shareOpen, setShareOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { ready: editorReady, rfsdk } = useRocketiumContext();
  const { enqueueSnackbar } = useSnackbar();

  const [refresh, { loading: loadingRefresh }] = useRefreshCreativeStatusLazyQuery({
    context: workspaceMemberContext
  });

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  let image = src;
  if (draft) {
    image = '/videos/draft.jpg';
  } else if (!ready) {
    image = '/videos/loading.jpg';
  }

  const handleRefreshPendingVideo = useCallback(() => {
    try {
      refresh({ variables: { id } });
    } catch (error) {
      enqueueSnackbar('Unable to get creative status', { variant: 'error' });
    }
  }, [id, refresh, enqueueSnackbar]);

  const handleClickCard = useCallback(() => {
    setPreviewOpen(true);
  }, [setPreviewOpen]);

  const handleOpenEditDialog = useCallback(() => {
    setEditOpen(true);
  }, [setEditOpen]);

  const handleCloseEdit = useCallback(() => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  }, [setEditOpen, onRefetchRequired]);

  const handleEditVideo = useCallback(() => {
    track('Video Edit', { id, rocketiumId });

    if (editorReady) {
      rfsdk('updateConfig', {
        preview: 'full'
      });
      rfsdk('openVideo', rocketiumId);
    }
  }, [rfsdk, id, rocketiumId, editorReady]);

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={image}>
            <TagHolder tags={tags} />
          </CardMedia>
          <CardContent>
            <Typography variant="overline">{date}</Typography>
            <Typography variant="h6">{title}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {ready && (
            <Tooltip title="Share Post">
              <IconButton
                aria-label="share post"
                onClick={handleOpenShareDrawer}
                disabled={!ready || disableActionButtons}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          {!draft && !ready && (
            <Tooltip title="Recheck Video">
              <IconButton
                onClick={handleRefreshPendingVideo}
                aria-label="Recheck Video"
                disabled={ready || disableActionButtons}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          {ready && downloadUrl && (
            <Tooltip title="Download Video">
              <IconButton
                component="a"
                href={downloadUrl}
                target="_blank"
                download={`${title.replace(' ', '-').replace(',', '-')}`}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          {draft && (
            <Tooltip title="Edit Video">
              <IconButton
                onClick={handleEditVideo}
                aria-label="Edit Video"
                disabled={!draft || !editorReady}
              >
                <MovieEditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip style={{ marginLeft: 'auto' }} title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDialog}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      {ready && !disableActionButtons && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CREATIVE}
          listingId={listingId}
          creativeId={id}
          disabled={disableActionButtons}
        />
      )}
      {ready && (
        <CreativePreviewDialog
          open={previewOpen}
          handleClose={() => {
            setPreviewOpen(false);
          }}
          title={title}
          src={downloadUrl}
        />
      )}
      <LoadingBackdrop open={loadingRefresh} />
      <EditCreativeDrawer
        creativeId={id}
        open={editOpen}
        onClose={handleCloseEdit}
        isVideoCreative
      />
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
    </>
  );
};

export default VideoCreativeCard;
