import { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button, Tab, Tabs } from '@material-ui/core';

import { useAutomationRulesCountQuery } from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import LoadingStatePage from 'components/LoadingStatePage';
import PageHeader from 'components/PageHeader';
import CogCheckIcon from 'components/icons/CogCheck';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

import AutomationRulesSchedule from './tabs/AutomationRulesSchedule';
import AutomationRulesTriggers from './tabs/AutomationRulesTriggers';

const hashes = ['#triggers', '#schedule'];

const AutomationPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const navigate = useNavigate();
  const location = useLocation();

  const { activeWorkspaceId, userId, isWorkspaceAnalyst, isWorkspaceCreator, isWorkspaceAgent } =
    useUserContext();
  const [platforms, platformsLoading] = usePlatforms();

  const { data, loading } = useAutomationRulesCountQuery({
    variables: {
      schedules_where: {
        workspace_id: { _eq: activeWorkspaceId },
        created_by_id: isWorkspaceAgent
          ? {
              _eq: userId
            }
          : undefined
      },
      triggers_where: {
        workspace_id: { _eq: activeWorkspaceId },
        created_by_id: isWorkspaceAgent
          ? {
              _eq: userId
            }
          : undefined
      }
    },
    context: workspaceMemberContext
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [showLoader, setShowLoader] = useLocalStorage('properti.automation.loader', 'true');

  useEffect(() => {
    if (!hashes.includes(location.hash)) {
      navigate('/automation#triggers', {
        replace: true
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    const hash = location.hash;
    const newTab = hashes.findIndex((el) => el === hash);

    if (newTab >= 0) {
      setCurrentTab(newTab);
    }
  }, [location, setCurrentTab]);

  const handleTabChange = (_e: any, newTab: number) => {
    if (currentTab !== newTab) {
      const hash = hashes[newTab];
      navigate(`/automation${hash}`);
    }
  };
  const count = (data?.triggers?.aggregate?.count ?? 0) + (data?.schedule?.aggregate?.count ?? 0);

  useEffect(() => {
    if (count > 0) {
      setShowLoader('false');
    }
  }, [count, setShowLoader]);

  // For smoothness when changing between tabs we check if we should show loader
  // We set show loader to false if we have data.
  if (loading && showLoader !== 'false') {
    return <LoadingStatePage />;
  }

  // Direct users to connect social platforms if they don't have any connected
  if (!platformsLoading && !Boolean(platforms?.length) && count === 0) {
    return (
      <EmptyStatePage
        title="Automations"
        text="Connect your social platform channels to continue"
        icon={<CogCheckIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/apps#channel"
            size="large"
            disabled={isWorkspaceAnalyst || isWorkspaceCreator}
            fullWidth
          >
            Connect apps
          </Button>
        }
      />
    );
  }

  if (!loading && count === 0) {
    return (
      <EmptyStatePage
        title="Automations"
        text="Pick your trigger or schedule, task and let us do the rest."
        icon={<CogCheckIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="create"
            size="large"
            disabled={isWorkspaceAnalyst || isWorkspaceCreator}
            fullWidth
          >
            Create Automation
          </Button>
        }
      />
    );
  }

  return (
    <div>
      <PageHeader
        title="Automations"
        subtitle="Pick your trigger or schedule, task and let us do the rest."
        rightComponent={
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="create"
            size="large"
            disabled={isWorkspaceAnalyst || isWorkspaceCreator}
            style={{ minWidth: 160 }}
          >
            Create Automation
          </Button>
        }
      />
      <Tabs textColor="secondary" value={currentTab} onChange={handleTabChange}>
        <Tab label="Triggers" />
        <Tab label="Schedule" />
      </Tabs>
      {currentTab === 0 && <AutomationRulesTriggers />}
      {currentTab === 1 && <AutomationRulesSchedule />}
    </div>
  );
};

export default AutomationPage;
