import React, { PropsWithChildren } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';

import { classNames } from './utils';

const useSmallChevronTextButtonStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    height: '24px',
    padding: '0 8px',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#111111',
    borderRadius: '2px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    userSelect: 'none'
  },
  chevron: {
    fontSize: '24px'
  },
  link: {
    textDecoration: 'none'
  }
}));

export default function SmallChevronTextButton({
  children,
  ...props
}: PropsWithChildren<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>) {
  const styles = useSmallChevronTextButtonStyles();
  return (
    <a {...props} className={classNames(props.className, styles.link)}>
      <div className={styles.button}>
        {children} <ChevronRight className={styles.chevron} />
      </div>
    </a>
  );
}
