import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

interface LinkedInAvatarProps extends AvatarProps {
  disabled?: boolean;
}

export default function LinkedInAvatar(props: LinkedInAvatarProps) {
  const { style, disabled, ...other } = props;

  const backgroundColor = disabled ? '#808080' : '#0077b5';

  return (
    <Avatar style={{ backgroundColor, ...style }} {...other}>
      <LinkedInIcon />
    </Avatar>
  );
}
