import { useCallback, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  useAutomationTriggersQuery,
  useDeleteAutomationTriggerMutation,
  useUpdateAutomationTriggerStatusMutation,
  Workspace_Automation_Available_Triggers_Enum,
  Workspace_Automation_Status_Enum
} from 'generated/graphql';

import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';
import { track } from 'lib/utils/track';

export default function AutomationRulesTriggers() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceCreator, isWorkspaceAgent } =
    useUserContext();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data, loading, refetch } = useAutomationTriggersQuery({
    variables: {
      where: {
        workspace_id: { _eq: activeWorkspaceId },
        created_by_id: isWorkspaceAgent
          ? {
              _eq: userId
            }
          : undefined
      },
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext,
    pollInterval: 60000
  });

  const [deleteTrigger] = useDeleteAutomationTriggerMutation({
    context: workspaceMemberContext
  });
  const [updateTriggerStatus] = useUpdateAutomationTriggerStatusMutation({
    context: workspaceMemberContext
  });

  const handleDeleteTrigger = useCallback(
    (id: string) => {
      track('Automation Trigger Deleted', { triggerId: id });
      deleteTrigger({ variables: { id } })
        .then(() => {
          enqueueSnackbar('Automation trigger deleted', { variant: 'success' });
          refetch();
        })
        .catch(() => {
          enqueueSnackbar('Unable to delete trigger', { variant: 'error' });
        });
    },
    [deleteTrigger, enqueueSnackbar, refetch]
  );

  const handleEditTrigger = useCallback(
    (id: string) => {
      navigate(`create/trigger?edit_id=${id}`, { state: { edit_id: id } });
    },
    [navigate]
  );

  const handleUpdateTriggerStatus = async (
    id: string,
    status: Workspace_Automation_Status_Enum
  ) => {
    track('Automation Trigger Status Updated', { triggerId: id, status });
    try {
      await updateTriggerStatus({ variables: { id, status } });
      enqueueSnackbar('Trigger status updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to update trigger status', { variant: 'error' });
    }
  };

  // https://github.com/mbrn/material-table/issues/1979
  const triggers = data?.triggers?.map((t) => ({ ...t })) ?? [];
  const count = data?.count.aggregate?.count ?? 0;

  return (
    <MaterialTable
      title="Automation Triggers"
      columns={[
        {
          title: 'Name',
          field: 'name'
        },
        {
          title: 'Trigger Event',
          field: 'triggerByTrigger.description',
          render: (rowData) => {
            if (rowData.trigger === Workspace_Automation_Available_Triggers_Enum.InsertListing) {
              const listingStatus: string[] = rowData.params?.listing_status ?? [];
              if (listingStatus.length) {
                return `${rowData.triggerByTrigger.description} (${listingStatus.join(', ')})`;
              }
            }

            return rowData.triggerByTrigger.description;
          }
        },
        {
          title: 'Action',
          field: 'actionByAction.description'
        },
        {
          title: 'Last Event',
          field: 'last_invocation',
          render: (data) => {
            if (!data.last_invocation) {
              return 'Never';
            }
            const correctedDate = moment(data.last_invocation);
            return correctedDate.fromNow();
          }
        },
        {
          title: 'Invocations',
          field: 'invocations_aggregate.aggregate.count',
          render: (data) => {
            return (
              <MuiLink component={Link} to={`triggers/${data.id}/invocations`}>
                {data?.invocations_aggregate?.aggregate?.count}
              </MuiLink>
            );
          }
        },
        {
          title: 'Status',
          field: 'name',
          render: (data) => {
            if (data.status === Workspace_Automation_Status_Enum.Paused) {
              return <StatusIndicator status={Status.PENDING} text="Paused" />;
            }
            return <StatusIndicator status={Status.ACTIVE} text="Active" pulse />;
          }
        }
      ]}
      data={triggers}
      totalCount={count ?? 0}
      isLoading={loading}
      actions={[
        (rowData) => ({
          icon: 'pause',
          tooltip: 'Pause trigger',
          onClick: () =>
            handleUpdateTriggerStatus(rowData.id, Workspace_Automation_Status_Enum.Paused),
          disabled: isWorkspaceAnalyst || isWorkspaceCreator,
          hidden: rowData.status !== Workspace_Automation_Status_Enum.Live
        }),
        (rowData) => ({
          icon: 'play_arrow',
          tooltip: 'Start trigger',
          onClick: () =>
            handleUpdateTriggerStatus(rowData.id, Workspace_Automation_Status_Enum.Live),
          disabled: isWorkspaceAnalyst || isWorkspaceCreator,
          hidden: rowData.status !== Workspace_Automation_Status_Enum.Paused
        }),
        (rowData) => ({
          icon: 'edit',
          tooltip: 'Edit Trigger',
          onClick: () => handleEditTrigger(rowData.id),
          disabled: isWorkspaceAnalyst || isWorkspaceCreator
        })
      ]}
      editable={{
        isDeleteHidden: () => Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator),
        isDeletable: () => !(Boolean(isWorkspaceAnalyst) || Boolean(isWorkspaceCreator)),
        onRowDelete: async (rowData) => {
          return handleDeleteTrigger(rowData.id);
        }
      }}
      options={{
        search: false,
        toolbar: false,
        draggable: false,
        showTitle: false,
        columnsButton: false,
        sorting: false,
        actionsColumnIndex: -1,
        pageSize: limit,
        pageSizeOptions: [5, 10, 20, 50]
      }}
      onRowsPerPageChange={(rowsPerPage) => setLimit(rowsPerPage)}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      page={page}
      components={{
        Container: TableContainer
      }}
    />
  );
}
