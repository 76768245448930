import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AdPreviewButton from '../AdPreviewButton';
import MobileFrameSkeleton from '../MobileFrameSkeleton';
import { GoogleAdsResponsivePreviewProps, TextProps } from './types';

const useMobile320x250ImageAdStyles = makeStyles({
  image: {
    width: '300px',
    height: '157px',
    objectFit: 'cover'
  },
  container: {
    height: '93px',
    padding: '8px 12px'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props: TextProps) => `${20 - props.title.length / 12.5}px`,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: '0 0 10px'
  },
  description: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: 1,
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '19px',
    margin: '0 0 5px'
  },
  brandName: {
    display: 'block',
    color: '#777777',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '19px',
    margin: '0 0 10px'
  },
  button: {
    fontSize: '36px',
    margin: '4px'
  }
});

export default function Mobile320x250ImageAd({
  imageUrl,
  title,
  description,
  brandName,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useMobile320x250ImageAdStyles({ title, description });

  return (
    <MobileFrameSkeleton>
      <img className={styles.image} src={imageUrl!} alt="" />
      <div className={styles.container}>
        <span className={styles.title}>{title}</span>
        <Box display="flex">
          <Box flex={1}>
            <span className={styles.description}>{description}</span>
            <span className={styles.brandName}>{brandName}</span>
          </Box>
          <AdPreviewButton
            className={styles.button}
            href={landingPageUrl}
            target="_blank"
            hidden={!showButton}
          />
        </Box>
      </div>
    </MobileFrameSkeleton>
  );
}
