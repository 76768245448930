import React from 'react';

import { usePostListingsQuery, useUpdatePostListingsMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import AssociatePropertyBaseDialog, { FormValues } from '../Base';

interface AssociatePropertyPostDialogProps {
  open: boolean;
  onClose: () => void;
  postId: string;
}

const AssociatePropertyPostDialog: React.FC<AssociatePropertyPostDialogProps> = ({
  open,
  onClose,
  postId
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data, loading, refetch } = usePostListingsQuery({
    variables: {
      post_id: postId
    },
    context: workspaceMemberContext
  });
  const [updateListings] = useUpdatePostListingsMutation({
    context: workspaceMemberContext
  });

  const handleSave = async (values: FormValues) => {
    await updateListings({
      variables: {
        post_id: postId,
        objects: values.property_ids?.map((id) => ({
          post_id: postId,
          listing_id: id
        }))
      }
    });
  };

  const initialValues = data?.listings?.map((l) => l.listing_id) ?? [];

  return (
    <AssociatePropertyBaseDialog
      open={open}
      onClose={onClose}
      loading={loading}
      initialValues={initialValues}
      onSave={handleSave}
      refetch={refetch}
    />
  );
};

export default AssociatePropertyPostDialog;
