import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import InstagramIcon from '@material-ui/icons/Instagram';

import styled from 'styled-components';

interface InstagramAvatarProps extends AvatarProps {
  disabled?: boolean;
}

export default function InstagramAvatar(props: InstagramAvatarProps) {
  const { style, disabled, ...other } = props;
  const AvatarComponent = disabled ? DisabledAvatar : StyledAvatar;

  return (
    <AvatarComponent style={style} {...other}>
      <InstagramIcon />
    </AvatarComponent>
  );
}

const StyledAvatar = styled(Avatar)`
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
`;

const DisabledAvatar = styled(Avatar)`
  background: #808080;
`;
