import { useMemo, useState } from 'react';

import { Bar } from 'react-chartjs-2';

import { NetworkStatus } from '@apollo/client';

import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  Tooltip,
  Link
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

import MaterialTable from '@material-table/core';

import { ChartOptions } from 'chart.js';
import PodiumBronzeIcon from 'mdi-material-ui/PodiumBronze';
import PodiumGoldIcon from 'mdi-material-ui/PodiumGold';
import PodiumSilverIcon from 'mdi-material-ui/PodiumSilver';
import moment from 'moment';
import numbro from 'numbro';

import { WorkspacePostsAnalyticsQuery, useWorkspacePostsAnalyticsQuery } from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';
import FacebookAvatar from 'components/avatars/Facebook';
import GoogleMyBusinessAvatar from 'components/avatars/GoogleMyBusiness';
import InstagramAvatar from 'components/avatars/Instagram';
import LinkedInAvatar from 'components/avatars/LinkedIn';
import YouTubeAvatar from 'components/avatars/YouTube';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import useUserContext from 'lib/hooks/useUserContext';

import EmptyChartMessage from 'pages/workspace/Dashboard/components/EmptyChartMessage';
import LoadingChartIndicator from 'pages/workspace/Dashboard/components/LoadingChartIndicator';

import PostTabs from '../components/PostTabs';

const options: ChartOptions<'bar'> = {
  plugins: {
    title: {
      display: false,
      text: 'Analytics by post date',
      font: {
        family: 'DM Sans, sans-serif',
        size: 12
      }
    }
  },
  responsive: true
};

export default function PostAnalyticsPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, workspace, isWorkspaceAdmin } = useUserContext();

  const hasHeadOffice = useMemo(
    () => Boolean(workspace?.head_offices?.length),
    [workspace?.head_offices?.length]
  );

  const [periodMonths, setPeriodMonths] = useState<number>(6);
  const [startDate, endDate] = useMemo(() => {
    return [
      moment().subtract(periodMonths, 'months').startOf('month').format('YYYY-MM-DD'),
      moment().endOf('month').format('YYYY-MM-DD')
    ];
  }, [periodMonths]);

  const { data, loading, networkStatus } = useWorkspacePostsAnalyticsQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      start_date: startDate,
      end_date: endDate,
      start_time: startDate,
      end_time: endDate
    },
    notifyOnNetworkStatusChange: true,
    context: workspaceMemberContext,
    skip: !activeWorkspaceId
  });

  const chartLabels = useMemo(() => {
    return data?.by_month?.map((d) => moment(d.month as string, 'YYYY-MM-DD').format('MMMM YYYY'));
  }, [data?.by_month]);

  const chartData = useMemo(() => {
    return {
      labels: chartLabels,
      datasets: [
        {
          label: 'Impressions',
          data: data?.by_month.map((d) => d.total_impressions),
          backgroundColor: '#17457A'
        },
        {
          label: 'Engagement',
          data: data?.by_month.map((d) => d.total_engagement),
          backgroundColor: '#F47D6F'
        },
        {
          label: 'Clicks',
          data: data?.by_month.map((d) => d.total_clicks),
          backgroundColor: '#f1b110'
        },
        {
          label: 'Video Views',
          data: data?.by_month.map((d) => d.total_video_views),
          backgroundColor: '#0aab52'
        }
      ]
    };
  }, [chartLabels, data?.by_month]);

  const popularPosts = useMemo(
    () => data?.popular_posts?.map((p, idx) => ({ index: idx + 1, ...p })) ?? [],
    [data?.popular_posts]
  );

  if (!workspace) {
    return <CircularProgress />;
  }

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading;

  return (
    <>
      <PageHeader title="Posts" />
      <PostTabs value={3} showHeadOfficeContentSchedule={hasHeadOffice && isWorkspaceAdmin} />
      <Paper style={{ padding: 4 }} elevation={1}>
        <Box
          style={{
            padding: '16px 0px 8px 16px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 30
          }}
        >
          <Typography variant="h6">Post analytics by month</Typography>
          <DateRangeSelect value={periodMonths} setValue={setPeriodMonths} />
        </Box>
        {isLoading ? (
          <LoadingChartIndicator />
        ) : data?.by_month.length ? (
          <Bar style={{ maxHeight: 600 }} data={chartData} options={options} />
        ) : (
          <EmptyChartMessage message="Not enough data available" />
        )}
      </Paper>
      <Paper style={{ marginTop: 12, padding: 4 }} elevation={1}>
        <Box
          style={{
            padding: '16px 0px 8px 16px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 30
          }}
        >
          <Typography variant="h6">Most viewed posts</Typography>
          <DateRangeSelect value={periodMonths} setValue={setPeriodMonths} />
        </Box>
        <MaterialTable
          data={popularPosts}
          isLoading={isLoading}
          columns={[
            {
              title: '',
              field: 'index',
              width: 10,
              render: RenderTrophyColumn
            },
            {
              title: 'Post Name',
              field: 'post.name',
              render: RenderNameColumn
            },
            {
              title: 'Platforms',
              field: 'post.args.platforms',
              render: RenderPlatformsColumn
            },
            {
              title: 'Post Date',
              field: 'post.scheduled_at',
              type: 'datetime',
              render: RenderPostDateColumn
            },
            {
              title: 'Total Impressions',
              field: 'post.overall_analytics.total_impressions',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.post?.overall_analytics?.total_impressions).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'Total Engagement',
              field: 'post.overall_analytics.total_engagement',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.post?.overall_analytics?.total_engagement).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'Total Clicks',
              field: 'post.overall_analytics.total_clicks',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.post?.overall_analytics?.total_clicks).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'Total Video Views',
              field: 'post.overall_analytics.total_video_views',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.post?.overall_analytics?.total_video_views).format({
                  thousandSeparated: true
                })
            }
          ]}
          options={{
            toolbar: false,
            search: false,
            filtering: false,
            sorting: false,
            paging: true,
            columnResizable: false,
            draggable: false,
            pageSize: 5,
            pageSizeOptions: [5, 10]
          }}
          components={{
            Container: TableContainer
          }}
        />
      </Paper>
    </>
  );
}

interface DateRangeSelectProps {
  value: number;
  setValue: (val: number) => void;
}

function DateRangeSelect({ value, setValue }: DateRangeSelectProps) {
  return (
    <Select
      value={value}
      onChange={(event) => setValue(event.target.value as number)}
      style={{ color: '#F47D6F' }}
      disableUnderline
    >
      <MenuItem value={0}>This Month</MenuItem>
      <MenuItem value={3}>Last 3 Months</MenuItem>
      <MenuItem value={6}>Last 6 Months</MenuItem>
      <MenuItem value={9}>Last 9 Months</MenuItem>
      <MenuItem value={12}>Last 12 Months</MenuItem>
      <MenuItem value={18}>Last 18 Months</MenuItem>
    </Select>
  );
}

function RenderTrophyColumn(
  rowData: { index: number } & WorkspacePostsAnalyticsQuery['popular_posts'][number]
) {
  if (rowData.index === 1) {
    return <PodiumGoldIcon titleAccess="1st" fontSize="large" htmlColor="#f0b905" />;
  } else if (rowData.index === 2) {
    return <PodiumSilverIcon titleAccess="2nd" fontSize="large" htmlColor="#c0c0c0" />;
  } else if (rowData.index === 3) {
    return <PodiumBronzeIcon titleAccess="3rd" fontSize="large" htmlColor="#cd7f32" />;
  }

  return `${numbro(rowData.index).format({ output: 'ordinal' })}`;
}

function RenderNameColumn(rowData: WorkspacePostsAnalyticsQuery['popular_posts'][number]) {
  if (rowData.post?.name) {
    return rowData.post?.name;
  } else if (rowData.post?.creative) {
    return rowData.post?.creative.title;
  } else if (rowData.post?.listing_id) {
    return 'Listing Post';
  }

  return 'Manual Post';
}

function RenderPlatformsColumn(rowData: WorkspacePostsAnalyticsQuery['popular_posts'][number]) {
  const isDraft = rowData.post?.draft;
  const hasFacebook = Boolean(rowData.post?.args?.facebook);
  const hasInstagram = Boolean(rowData.post?.args?.instagram);
  const hasLinkedIn = Boolean(rowData.post?.args?.linkedin);
  const hasLinkedInPersonal = Boolean(rowData.post?.args?.linkedin_personal);
  const hasYouTube = Boolean(rowData.post?.args?.youtube);
  const hasGoogleMyBusiness = Boolean(rowData.post?.args?.gmb);

  const creatorAvatarSrc = getFilePath(
    rowData?.post?.args?.linkedin_personal?.posting_from?.avatar_url ??
      rowData?.post?.created_by?.avatar_url,
    rowData?.post?.args?.linkedin_personal?.posting_from?.avatar_token ??
      rowData?.post?.created_by?.avatar_token
  );

  const fbPageId = rowData.post?.facebook_post_id?.includes('_')
    ? rowData.post?.facebook_post_id?.split('_')[0]
    : rowData.post?.args?.facebook?.posting_from?.id;

  const fbPostId = rowData.post?.facebook_post_id?.includes('_')
    ? rowData.post?.facebook_post_id?.split('_')[1]
    : rowData.post?.facebook_post_id;

  const isFacebookReady = Boolean(fbPostId);
  const isLinkedInReady = Boolean(rowData.post?.linkedin_post_id);
  const isYouTubeReady = Boolean(rowData.post?.youtube_post_id);
  const isLinkedInPersonalReady = Boolean(rowData.post?.linkedin_personal_post_id);
  const isInstagramReady = Boolean(rowData.post?.instagram_permalink);
  const isGoogleMyBusinessReady = Boolean(rowData.post?.google_my_business_permalink);

  // Permalinks
  const fbHref = isFacebookReady
    ? rowData.post?.facebook_permalink ?? `https://facebook.com/${fbPageId}/posts/${fbPostId}`
    : undefined;
  const igHref = rowData.post?.instagram_permalink ?? undefined;
  const liHref = isLinkedInReady
    ? `https://linkedin.com/feed/update/${rowData.post?.linkedin_post_id}`
    : undefined;
  const ytHref = isYouTubeReady ? `https://youtu.be/${rowData.post?.youtube_post_id}` : undefined;
  const lipHref = isLinkedInPersonalReady
    ? `https://linkedin.com/feed/update/${rowData.post?.linkedin_personal_post_id}`
    : undefined;
  const gmbHref = rowData.post?.google_my_business_permalink ?? undefined;

  // Disable avatars if draft or no href
  const fbDisabled = isDraft || !fbHref;
  const igDisabled = isDraft || !igHref;
  const liDisabled = isDraft || !liHref;
  const lipDisabled = isDraft || !lipHref;
  const ytDisabled = isDraft || !ytHref;
  const gmbDisabled = isDraft || !gmbHref;

  return (
    <AvatarGroup color="primary" max={5}>
      {hasFacebook && (
        <Tooltip
          title={`${isFacebookReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.facebook_page)?.facebook_page
              ?.display_name ?? 'Facebook'
          }`}
        >
          <Link style={{ border: 'none' }} href={fbHref} target="_blank">
            <FacebookAvatar disabled={fbDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasInstagram && (
        <Tooltip
          title={`${isInstagramReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.instagram_page)?.instagram_page
              ?.display_name ?? 'Instagram'
          }`}
        >
          <Link style={{ border: 'none' }} href={igHref} target="_blank">
            <InstagramAvatar disabled={igDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasLinkedIn && (
        <Tooltip
          title={`${isLinkedInReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.linkedin_page)?.linkedin_page
              ?.display_name ?? 'LinkedIn'
          }`}
        >
          <Link style={{ border: 'none' }} href={liHref} target="_blank">
            <LinkedInAvatar disabled={liDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasYouTube && (
        <Tooltip
          title={`${isYouTubeReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.youtube_page)?.youtube_page
              ?.display_name ?? 'Youtube'
          }`}
        >
          <Link style={{ border: 'none' }} href={ytHref} target="_blank">
            <YouTubeAvatar disabled={ytDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasGoogleMyBusiness && (
        <Tooltip
          title={`${isGoogleMyBusinessReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.gmb_page)?.gmb_page?.display_name ??
            'Google My Business'
          }`}
        >
          <Link style={{ border: 'none' }} href={gmbHref} target="_blank">
            <GoogleMyBusinessAvatar disabled={gmbDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasLinkedInPersonal && (
        <Tooltip
          title={`${isLinkedInPersonalReady ? 'Posted' : 'Posting'} on ${
            rowData.post?.posted_on?.find((page) => page.linkedin_personal_page)
              ?.linkedin_personal_page?.display_name ?? 'LinkedIn'
          }`}
        >
          <Link style={{ border: 'none' }} href={lipHref} target="_blank">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<Avatar src={creatorAvatarSrc} style={{ width: 20, height: 20 }} />}
            >
              <LinkedInAvatar disabled={lipDisabled} />
            </Badge>
          </Link>
        </Tooltip>
      )}
    </AvatarGroup>
  );
}

function RenderPostDateColumn(rowData: WorkspacePostsAnalyticsQuery['popular_posts'][number]) {
  if (rowData.post?.scheduled_at) {
    return moment(rowData.post?.scheduled_at).format('D MMM YYYY h:mm A');
  } else if (rowData.post?.posted) {
    return moment(rowData.post?.created_at).format('D MMM YYYY h:mm A');
  } else {
    return 'N/A';
  }
}
