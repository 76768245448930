import { useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';

import { useUrlMetadataQuery } from 'generated/graphql';

import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import { ShareLogicVariants } from 'components/types';

import { getFilePath } from 'lib/auth/hbp';

import EditCreativeDrawer from '../EditCreativeDrawer';
import ResponsiveCreativePreviewDialog from '../ResponsiveCreativePreviewDialog';
import TagHolder, { Tag } from '../TagHolder';
import useImageCreativeCardStyles from './_styles';

interface ManualCreativeCardProps {
  id: string;
  title: string;
  url?: string | null;
  date: string;
  tags?: Tag[];
  onRefetchRequired?: () => void;
  ready?: boolean;
  disableActionButtons?: boolean;
  attachments: any[];
}

export default function ManualCreativeCard({
  id,
  title,
  url,
  date,
  tags,
  ready,
  attachments,
  disableActionButtons,
  onRefetchRequired
}: ManualCreativeCardProps) {
  const classes = useImageCreativeCardStyles();
  const { data } = useUrlMetadataQuery({
    variables: {
      url: url as string
    },
    skip: !url || attachments?.length > 0
  });
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  const handleClickCard = () => {
    if (!attachments || attachments.length === 0) {
      handleOpenEditDrawer();
    } else {
      setPreviewOpen(true);
    }
  };

  const handleOpenEditDrawer = () => {
    setEditOpen(true);
  };

  const handleCloseEditDrawer = () => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const images = attachments?.map((attachment) => getFilePath(attachment.path)) ?? [];
  let mainImageUrl = images[0];

  if (!mainImageUrl && data?.urlMetadata?.image) {
    mainImageUrl = data.urlMetadata?.image;
  } else if (!mainImageUrl) {
    mainImageUrl = '/images/PropertiMasterLogo.png';
  }

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={mainImageUrl}>
            <TagHolder tags={tags} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">{date}</Typography>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
        <CardActions>
          <Tooltip title="Share Post">
            <IconButton
              aria-label="share post"
              onClick={handleOpenShareDrawer}
              disabled={disableActionButtons}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          <Tooltip style={{ marginLeft: 'auto' }} title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDrawer}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <EditCreativeDrawer creativeId={id} open={editOpen} onClose={handleCloseEditDrawer} />
      <ResponsiveCreativePreviewDialog
        id={id}
        open={previewOpen}
        title={title}
        onClose={handleClosePreview}
        imageUrls={images as string[]}
      />
      {!disableActionButtons && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CREATIVE}
          creativeId={id}
          disabled={disableActionButtons}
        />
      )}
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
    </>
  );
}
