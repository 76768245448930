import { useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters/csv';

import moment from 'moment';

import { useWorkspaceLeadsByFormQuery } from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

export default function LeadsByFormPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();
  const { id } = useParams();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const { data, loading } = useWorkspaceLeadsByFormQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      form_id: id!,
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext
  });
  const count = data?.count?.aggregate?.count ?? 0;

  // https://github.com/mbrn/material-table/issues/1979
  const leads = data?.leads?.map((l) => ({ ...l }));
  const form = data?.form;

  return (
    <div>
      <PageHeader
        title={`Leads from ${form?.name ?? '...'}`}
        subtitle="Incoming leads collected by Properti"
      />

      <MaterialTable
        title="Leads"
        columns={[
          {
            title: 'Name',
            field: 'name',
            editable: 'never',
            type: 'string'
          },
          {
            title: 'Created At',
            field: 'created_at',
            type: 'datetime',
            render: (data) => {
              return (
                <span>
                  {moment(data.created_at).format('D MMM YYYY')}
                  <br />
                  {moment(data.created_at).format('h:mm A')}
                </span>
              );
            },
            sorting: true,
            editable: 'never'
          },
          {
            title: 'Source',
            field: 'source',
            editable: 'never',
            render: (data) => data.lead_source.label ?? data.source
          },
          {
            title: 'Type',
            field: 'type.label',
            editable: 'never'
          },
          { title: 'Email', field: 'email', editable: 'never' },
          {
            title: 'Number',
            field: 'number'
          },
          {
            title: 'Message',
            field: 'message'
          },
          {
            title: 'Multiple Choice',
            field: 'multi_choice_questions',
            render: (data) => {
              if (!data.multi_choice_questions) {
                return '-';
              }
              const multiChoiceQuestions = data.multi_choice_questions ?? [];

              return multiChoiceQuestions.map((question: any, idx: number) => (
                <p key={idx}>
                  {question.question}: <strong>{question.response}</strong>
                </p>
              ));
            }
          },
          {
            title: 'Listing',
            field: 'listing_id',
            export: false,
            render: (data) => {
              if (!data.listing) {
                return '-';
              }
              const address = data?.listing?.location?.address?.sub_number
                ? `${data?.listing?.location?.address?.sub_number}/${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`
                : `${data?.listing?.location?.address?.street_number} ${data?.listing?.location?.address?.street}, ${data?.listing?.location?.address?.suburb}`;
              return (
                <MuiLink component={Link} to={`/properties/view/${data?.listing_id}`}>
                  {address}
                </MuiLink>
              );
            }
          }
        ]}
        data={leads ?? []}
        totalCount={count ?? 0}
        options={{
          search: false,
          draggable: false,
          showTitle: false,
          toolbar: true,
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) => ExportCsv(cols, datas, 'properti-leads')
            }
          ],
          exportAllData: false,
          actionsColumnIndex: -1,
          pageSize: limit
        }}
        isLoading={loading}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        page={page}
        components={{
          Container: TableContainer
        }}
      />
    </div>
  );
}
