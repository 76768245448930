import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import YouTubeIcon from '@material-ui/icons/YouTube';

interface YoutubeAvatarProps extends AvatarProps {
  disabled?: boolean;
}

export default function YouTubeAvatar(props: YoutubeAvatarProps) {
  const { style, disabled, ...other } = props;

  const backgroundColor = disabled ? '#808080' : '#FF0000';
  return (
    <Avatar style={{ backgroundColor, ...style }} {...other}>
      <YouTubeIcon />
    </Avatar>
  );
}
