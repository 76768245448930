import styled from 'styled-components';

const FormButtons = styled.div`
  margin-top: 16px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default FormButtons;
