import React from 'react';

import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { Button, Grid, Link as MuiLink, Paper, Typography } from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';

import { useLogger } from 'lib/LoggerContext';
import { APP_CONFIG } from 'lib/app/config';
import { auth } from 'lib/auth/hbp';

import useAuthStyles from '../_styles';

const schema = Yup.object()
  .shape({
    email: Yup.string().email().trim().required().label('Email address')
  })
  .required();

type FormSchema = Yup.InferType<typeof schema>;

const validate = makeValidate(schema);

const formFields: React.ReactNode[] = [
  <TextField style={{ height: 60 }} name="email" label="Email" type="email" autoComplete="email" />
];

const ForgotPasswordPage = () => {
  const classes = useAuthStyles();
  const logger = useLogger();

  const handleFormSubmit = async (values: FormSchema) => {
    try {
      await auth.changePasswordRequest(values.email);
      logger.info('Change password request', { meta: { email: values.email } });
    } catch (error: any) {
      let message = 'Unknown Error Occurred';
      if (error.isAxiosError) {
        message = error?.response?.data?.message ?? 'Unknown Error Occurred';
      }
      logger.error(message, {
        meta: {
          error
        }
      });
      return { [FORM_ERROR]: message };
    }
  };

  return (
    <div className={classes.content}>
      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 160 }}
        />
        <div className={classes.formWrapper}>
          <Form
            onSubmit={handleFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, submitSucceeded }) => {
              if (submitSucceeded) {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      style={{ maxWidth: 220 }}
                      src="/illustrations/mail-sent.svg"
                      alt="Email Sent"
                    />
                    <Typography variant="subtitle1" gutterBottom>
                      We've sent you an email containing reset instructions.
                    </Typography>
                    <MuiLink component={Link} color="secondary" to="/reset-password">
                      Enter your token here
                    </MuiLink>
                  </div>
                );
              }
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Forgot your password?
                  </Typography>
                  <Typography gutterBottom>
                    Fill in your email to start account recovery.
                  </Typography>
                  <Grid container spacing={1}>
                    {formFields.map((item, idx) => (
                      <Grid key={idx} item xs={12} sm={12}>
                        {item}
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={submitting}
                  >
                    {submitting ? 'Loading ...' : 'Reset Password'}
                  </Button>
                  {submitError && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography color="error" variant="subtitle2">
                          {submitError}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </form>
              );
            }}
          />
        </div>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Have your password?{' '}
              <MuiLink component={Link} color="secondary" to="/login">{`Sign In`}</MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ForgotPasswordPage;
