import React, { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

interface LeadTabsProps {
  currentTab: number;
}

export default function LeadTabs({ currentTab }: LeadTabsProps) {
  const [tab, setTab] = useState<number>(currentTab);

  useEffect(() => {
    setTab(currentTab);
  }, [setTab, currentTab]);

  const handleTabChange = (_e: any, newTab: number) => {
    setTab(newTab);
  };

  return (
    <Tabs textColor="secondary" value={tab} onChange={handleTabChange}>
      <Tab component={RouterLink} to="/leads" label="Leads" />
      <Tab component={RouterLink} to="/leads/forms" label="Leadgen Forms" />
      {/* <Tab component={RouterLink} to="/leads/autoresponder" label="Autoresponder" /> */}
    </Tabs>
  );
}
