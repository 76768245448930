import React from 'react';

import { Box } from '@material-ui/core';

import { Workspace_Creative_Types_Enum } from 'generated/graphql';

import { getFilePath } from 'lib/auth/hbp';

import GoogleAdsResponsivePreview from './GoogleAdsResponsivePreview';
import { useResponsiveContext } from './utils';

export interface SelectGoogleAdsPreviewProps {
  type: Workspace_Creative_Types_Enum;
  previewType: number;
}

export default function SelectGoogleAdsPreview({ type, previewType }: SelectGoogleAdsPreviewProps) {
  const { creative } = useResponsiveContext();

  if (!type) {
    return null;
  }

  switch (type) {
    case Workspace_Creative_Types_Enum.Image:
    case Workspace_Creative_Types_Enum.Manual: {
      const rawImagesUrls =
        type === Workspace_Creative_Types_Enum.Image
          ? creative?.images?.map((img) => img.image_url_jpg as string)
          : creative?.attachments?.map((att) => getFilePath(att.path, att.token) as string);

      const imageUrls: string[] = rawImagesUrls.filter(Boolean);

      return (
        <Box style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 560, gap: 4 }}>
          {imageUrls.map((imageUrl, idx) => (
            <Box key={idx} maxWidth={250} maxHeight={250}>
              <img
                src={imageUrl}
                alt=""
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Box>
          ))}
        </Box>
      );
    }
    case Workspace_Creative_Types_Enum.Responsive: {
      return <GoogleAdsResponsivePreview previewType={previewType} />;
    }
    default: {
      return <div>Could not generate preview.</div>;
    }
  }
}
