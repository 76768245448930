import { makeStyles, Theme } from '@material-ui/core/styles';

const useMainLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '@media print': {
      visibility: 'hidden'
    }
  },
  sidebar: {
    flexGrow: 1,
    flexBasis: 'auto',
    border: 'none'
  },
  content: {
    padding: theme.spacing(2.5),
    flexGrow: 1,
    flexBasis: 'auto'
  },
  printarea: {
    '@media print': {
      visibility: 'visible',
      position: 'fixed',
      left: 0,
      top: 0,
      overflow: 'visible'
    }
  },
  collapseButton: {
    color: ({ isAdminMode }: { isAdminMode: boolean }) =>
      isAdminMode ? theme.palette.secondary.main : theme.palette.primary.main
  },
  footer: {
    borderTop: 'none',
    padding: theme.spacing(2.5),
    flex: 'none'
  }
}));

export default useMainLayoutStyles;
