import { grey } from '@material-ui/core/colors';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontSize: 14,
  fontFamily: ['DM Sans', 'sans-serif'].join(','),
  fontWeightLight: 400,
  fontWeightRegular: 450,
  fontWeightMedium: 550,
  fontWeightBold: 700,
  h1: {
    fontSize: 34,
    fontWeight: 550,
    fontWeightBold: 800
  },
  h2: {
    fontSize: 30,
    fontWeight: 550
  },
  h3: {
    fontSize: 28,
    fontWeight: 550
  },
  h4: {
    fontSize: 24,
    fontWeight: 550
  },
  h5: {
    fontSize: 22,
    fontWeight: 550
  },
  h6: {
    fontSize: 18,
    fontWeight: 550
  },
  caption: {
    fontSize: 13,
    fontWeight: 500
  },
  overline: {
    color: grey['600'],
    fontSize: '0.75rem',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    fontWeight: 400
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: grey['800']
  }
};

export default typography;
