import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

import { CarouselImageSelectionType } from 'generated/graphql';

import { ItemPickerValue } from './types';

const schemaListings = Yup.object()
  .shape({
    property_ids: Yup.array().of(Yup.string()).min(0).max(20).optional().label('Properties')
  })
  .required();

const schemaItems = Yup.object().shape({
  items: Yup.array()
    .required()
    .test({
      message: 'At least two square must be selected',
      test: (values: ItemPickerValue[]) => {
        const smart = values.some(
          (value) => value.image.selection.type === CarouselImageSelectionType.Smart
        );

        const template = values.some(
          (value) => value.image.selection.type === CarouselImageSelectionType.Template
        );

        if (smart || template) return values.length > 1;

        const manual = values.filter(
          (value) => value.image.selection.type === CarouselImageSelectionType.Manual
        );

        return manual.length > 1;
      }
    })
    .test({
      message: 'A link and call to action must be provided',
      test: (values: ItemPickerValue[]) =>
        !values.some((value) => !value.photo_cta || !value.photo_link)
    })
});

const urlSchema =
  process.env.NODE_ENV === 'development'
    ? Yup.string().required('Link is required')
    : Yup.string().url('Link must be a valid url').required('Link is required');

const schemaDetails = Yup.object()
  .shape({
    creative_name: Yup.string()
      .required('Please enter a creative name')
      .trim()
      .label('Creative Name'),
    tags: Yup.array().of(Yup.string().trim().label('Tag')).min(0).label('Tags'),
    message: Yup.string().required('Message is required'),
    link: urlSchema,
    caption: urlSchema,
    order_optimised: Yup.boolean().required('This field is required'),
    end_card: Yup.boolean().required('This field is required')
  })
  .required();

const metadataSchema = Yup.object().shape({
  photo_name: Yup.string().max(35, 'The name should not be longer than 35 characters'),
  photo_link: urlSchema,
  photo_description: Yup.string().max(
    30,
    'The description should not be longer than 30 characters'
  ),
  photo_cta: Yup.string().required('A call to action is required')
});

export const validateDetails = makeValidate(schemaDetails);
export const validateItems = makeValidate(schemaItems);
export const validateListings = makeValidate(schemaListings);
export const validateMetadata = makeValidate(metadataSchema);
