import { SubscriptionPlan } from './types';

export const subscriptionPlans: SubscriptionPlan[] = [
  {
    id: 'pro',
    name: 'Pro',
    internal_only: false,
    setup_fee: {
      aud: 1100,
      nzd: 1200
    },
    monthly_price: {
      aud: 399,
      nzd: 419
    },
    features: [
      { text: '5 users' },
      { text: '10 social accounts' },
      { text: '50 monthly images' },
      { text: '20 monthly videos' },
      { text: '$1,000 one-time setup fee' },
      {
        text: '$250 Ad Credit rewards',
        helperText: 'When you spend $1,000 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'office',
    name: 'Office',
    internal_only: false,
    setup_fee: {
      aud: 1100,
      nzd: 1200
    },
    monthly_price: {
      aud: 629,
      nzd: 649
    },
    features: [
      { text: '15 users' },
      { text: '30 social accounts' },
      { text: '200 monthly images' },
      { text: '100 monthly videos' },
      { text: '$1,000 one-time setup fee' },
      {
        text: '$375 Ad Credit rewards',
        helperText: 'When you spend $1,500 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'office_plus',
    name: 'Office Plus',
    internal_only: false,
    setup_fee: {
      aud: 1100,
      nzd: 1200
    },
    monthly_price: {
      aud: 889,
      nzd: 1000
    },
    features: [
      { text: '100 users' },
      { text: '100 social accounts' },
      { text: '500 monthly images' },
      { text: '150 monthly videos' },
      { text: '$1,000 one-time setup fee' },
      {
        text: '$500 Ad Credit rewards',
        helperText: 'When you spend $2,000 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'pro_special',
    name: 'Pro - No Signup Fee',
    internal_only: true,
    setup_fee: {
      aud: null,
      nzd: null
    },
    monthly_price: {
      aud: 399,
      nzd: 419
    },
    features: [
      { text: '5 users' },
      { text: '10 social accounts' },
      { text: '50 monthly images' },
      { text: '20 monthly videos' },
      {
        text: '$250 Ad Credit rewards',
        helperText: 'When you spend $1,000 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'office_special',
    name: 'Office - No Signup Fee',
    internal_only: true,
    setup_fee: {
      aud: null,
      nzd: null
    },
    monthly_price: {
      aud: 629,
      nzd: 649
    },
    features: [
      { text: '15 users' },
      { text: '30 social accounts' },
      { text: '200 monthly images' },
      { text: '100 monthly videos' },
      {
        text: '$375 Ad Credit rewards',
        helperText: 'When you spend $1,500 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'office_plus_special',
    name: 'Office Plus - No Signup Fee',
    internal_only: true,
    setup_fee: {
      aud: null,
      nzd: null
    },
    monthly_price: {
      aud: 889,
      nzd: 1000
    },
    features: [
      { text: '100 users' },
      { text: '100 social accounts' },
      { text: '500 monthly images' },
      { text: '150 monthly videos' },
      {
        text: '$500 Ad Credit rewards',
        helperText: 'When you spend $2,000 on campaigns in the previous month'
      }
    ]
  },
  {
    id: 'custom',
    name: 'Custom',
    internal_only: false,
    setup_fee: {
      aud: null,
      nzd: null
    },
    features: [
      { text: '100+ users' },
      { text: '100+ social accounts' },
      { text: 'Custom minimum spend' },
      { text: 'Custom billing thresholds' },
      { text: 'Advanced ad spend rewards' },
      {
        text: 'Account management',
        helperText: 'Additional fee'
      }
    ]
  }
];

export function planFromPlanId(planId?: string) {
  if (!planId) {
    return null;
  }

  const plan = subscriptionPlans.find((p) => p.id === planId);
  return plan ?? null;
}
