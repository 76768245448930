import React from 'react';

import { Box, Fab, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Skeleton from '@material-ui/lab/Skeleton';

import { useUrlMetadataQuery } from 'generated/graphql';

interface AttachmentPreviewItem {
  title?: string | null;
  description?: string | null;
  url?: string | null;
  image?: string | null;
  logo?: string | null;
}

interface AttachmentPreviewCardProps {
  loading: boolean;
  onRemoveUrl: () => void;
  item?: AttachmentPreviewItem | null;
}

const AttachmentPreviewCard: React.FC<AttachmentPreviewCardProps> = ({
  item,
  loading,
  onRemoveUrl
}) => {
  const classes = useStyles();

  return (
    <Box width={220} marginRight={0.5} className={classes.container}>
      <Fab
        onClick={onRemoveUrl}
        className={classes.urlDeleteButton}
        aria-label="delete"
        size="small"
      >
        <DeleteIcon />
      </Fab>
      {loading || !item ? (
        <Skeleton variant="rect" width={220} height={120} />
      ) : (
        <img style={{ height: 120 }} alt={item?.title ?? 'Preview'} src={item?.image ?? '#'} />
      )}
      <Box>
        {loading || !item ? (
          <Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="60%" />
          </Box>
        ) : (
          <Box>
            <Typography variant="caption">
              <strong>{item?.title}</strong>
              <br />
            </Typography>
            <Typography variant="caption">{item?.description}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AttachmentPreviewCard;

interface AttachmentPreviewProps {
  url?: string | null;
  onRemoveUrl: () => void;
}

export const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ url, onRemoveUrl }) => {
  const { data, loading } = useUrlMetadataQuery({
    variables: {
      url: url as string
    },
    skip: !url
  });

  if (!url) {
    return null;
  }

  return (
    <AttachmentPreviewCard loading={loading} item={data?.urlMetadata} onRemoveUrl={onRemoveUrl} />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 10,
    textAlign: 'center',
    '&:hover $urlDeleteButton': {
      opacity: 1
    }
  },
  urlDeleteButton: {
    transition: '.5s ease',
    position: 'absolute',
    margin: 'auto',
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:focus': {
      opacity: 1
    }
  }
}));
