import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFormPaperStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 1),
      maxWidth: 900,
      margin: theme.spacing(1, 'auto')
    }
  })
);

export default useFormPaperStyles;
