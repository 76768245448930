import React from 'react';

import { Box, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MobileFrame } from '../MobileFrameSkeleton';
import SmallTextButton from '../SmallTextButton';
import { GoogleAdsResponsivePreviewProps, TextProps } from './types';

const useMobile320x568ImageAdEnhancedStyles = makeStyles({
  image: {
    width: '300px',
    height: '157px',
    objectFit: 'cover'
  },
  title: {
    display: 'block',
    color: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props: TextProps) => `${38 - props.description.length / 12.5}px`,
    fontWeight: 'bold',
    lineHeight: '54px',
    margin: (props: TextProps) => `0 0 ${32 - props.description.length / 5}px`,
    textAlign: 'center'
  },
  description: {
    display: 'block',
    color: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props: TextProps) => `${24 - props.description.length / 20}px`,
    fontWeight: 'normal',
    lineHeight: '30px',
    textAlign: 'center'
  },
  brandName: {
    display: 'block',
    color: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '19px',
    fontWeight: 'normal',
    lineHeight: '19px',
    margin: '0 0 32px',
    textAlign: 'center',
    letterSpacing: '-0.5%'
  }
});

export default function Mobile320x568ImageAdEnhanced({
  imageUrl,
  title,
  description,
  brandName,
  cta,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useMobile320x568ImageAdEnhancedStyles({ title, description });
  return (
    <MobileFrame>
      <Box mx={1.25} bgcolor="#2D323A">
        <img className={styles.image} src={imageUrl!} alt="" />
        <Box display="flex" flexDirection="column" px={1.25} height={376}>
          <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
          </Box>
          <Box>
            <span className={styles.brandName}>{brandName}</span>
            <Box
              display="grid"
              gridAutoFlow="column"
              gridAutoColumns="max-content"
              justifyContent="center"
              gridGap={30}
              pb={4}
            >
              <ThemeProvider
                theme={{ palette: { primary: { main: '#707070', contrastText: '#ffffff' } } }}
              >
                <SmallTextButton width="106px" height="32px">
                  Close
                </SmallTextButton>
              </ThemeProvider>
              <ThemeProvider
                theme={{ palette: { primary: { main: '#fefefe', contrastText: '#444444' } } }}
              >
                <SmallTextButton
                  width="106px"
                  height="32px"
                  href={landingPageUrl}
                  target="_blank"
                  hidden={!showButton}
                >
                  {cta}
                </SmallTextButton>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Box>
    </MobileFrame>
  );
}
