import React, { useEffect, useState } from 'react';

import { useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';

import arrayMutators from 'final-form-arrays';
import { DropzoneDialog } from 'material-ui-dropzone';
import { AlertCircle, CheckCircle } from 'mdi-material-ui';
import { makeValidate, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  ResponsiveImage,
  ResponsiveImageSelectionType,
  useCreateResponsiveCreativeMutation,
  useListingImagesQuery
} from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import { SelectedImageRatioMessage } from 'components/creative/template/SelectedImageRatioMessage';
import FinalFormWizard from 'components/forms/FinalFormWizard';
import FormPaper from 'components/forms/FormPaper';
import PropertySelect from 'components/forms/PropertySelect';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useStepperPage from 'lib/hooks/useStepperPage';
import useUserContext from 'lib/hooks/useUserContext';
import uploadFile from 'lib/utils/uploadFile';

import ImagePicker from './ImagePicker';
import ResponsiveAdDetails from './ResponsiveAdDetails';
import { decorator } from './decorator';
import { ImageCropManualSelection, ImagePickerValue } from './types';

// todo: Support full page editing
// export function EditResponsive() {
// const id = useQuery().get('id');
// const { data } = useExistingResponsiveCreativeQuery({
//   variables: {
//     id
//   },
//   context
// });
// const creative = data?.creative;
// const images =
//   creative?.responsive?.images.map((image) => {
//     const square = image.selections.find((selection) => selection.type === 'square');
//     const landscape = image.selections.find((selection) => selection.type === 'landscape');
//     return {
//       image: {
//         type: 'url',
//         src: image?.attachment
//           ? getFilePath(image.attachment.path, image.attachment.token)
//           : image.src!
//       },
//       selection: {
//         type: image.type,
//         landscape: landscape
//           ? {
//               enabled: landscape.enabled,
//               crop: landscape.rectangle
//             }
//           : void 0,
//         square: square
//           ? {
//               enabled: square.enabled,
//               crop: square.rectangle
//             }
//           : void 0
//       }
//     } as ImagePickerValue;
//   }) ?? [];
// }

const schema = Yup.object()
  .shape({
    property_ids: Yup.array().of(Yup.string()).min(0).max(20).optional().label('Properties')
  })
  .required();

const validate = makeValidate(schema);

const schemaImages = Yup.object().shape({
  images: Yup.array()
    .required()
    .test({
      message: 'At least one square and one landscape image must be selected',
      test: (values: ImagePickerValue[]) => {
        const smart = values.filter(
          (value) => value.selection.type === ResponsiveImageSelectionType.Smart
        );
        if (smart.length) return true;
        const manual = values
          .filter((value) => value.selection.type === ResponsiveImageSelectionType.Manual)
          .map((value) => value.selection as ImageCropManualSelection);
        const square = manual.filter((value) => value.square.enabled);
        const landscape = manual.filter((value) => value.landscape.enabled);
        return square.length > 0 && landscape.length > 0;
      }
    })
});

const validateImages = makeValidate(schemaImages);

export interface SelectImagesPageProps {
  listingsFieldName: string;
  existingImages?: ImagePickerValue[];
}

export function SelectImagesPage({
  listingsFieldName,
  existingImages = []
}: SelectImagesPageProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [dropzoneOpen, setDropzoneOpen] = useState<boolean>(false);
  const images = useField<ImagePickerValue[]>('images', { subscription: { value: true } });
  const [selection, setSelection] = useState<ImagePickerValue[]>(images.input.value);
  const listingIds = useField(listingsFieldName, { subscription: { value: true } });
  const { data } = useListingImagesQuery({
    variables: {
      ids: listingIds?.input.value ?? []
    },
    context: workspaceMemberContext
  });

  const imageSelection =
    images.input.value
      ?.map?.((value) => value.selection)
      .filter((value) => value.type !== 'none') ?? [];
  const squareImages = imageSelection.filter(
    (value) =>
      value.type === ResponsiveImageSelectionType.Smart ||
      (value.type === ResponsiveImageSelectionType.Manual && value.square?.enabled)
  );
  const landscapeImages = imageSelection.filter(
    (value) =>
      value.type === ResponsiveImageSelectionType.Smart ||
      (value.type === ResponsiveImageSelectionType.Manual && value.landscape?.enabled)
  );

  function handleSaveFile(files: File[]) {
    const sources: ImagePickerValue[] = files.map((file) => ({
      image: {
        src: file,
        type: 'upload'
      },
      selection: { type: 'none' }
    }));
    setSelection((selection) => sources.concat(selection));
    setDropzoneOpen(false);
  }

  function handleBrowseImages() {
    setDropzoneOpen(true);
  }

  function handleCloseDropzone() {
    setDropzoneOpen(false);
  }

  useEffect(() => {
    setSelection((selection) =>
      selection
        .filter((value) => value.image.type !== 'url')
        .concat(
          (data?.listings
            .map((listing) =>
              listing.images.map((image) => {
                if (image.image.url) {
                  return {
                    image: {
                      src: image.image.url,
                      type: 'url' as const
                    },
                    selection: { type: 'none' },
                    address: listing.location.address.full_address
                  };
                } else {
                  return {
                    image: {
                      src: {
                        content_type: image.image.content_type,
                        filename: image.image.filename,
                        path: image.image.path,
                        token: image.image.token
                      },
                      type: 'file'
                    },
                    selection: { type: 'none' },
                    address: listing.location.address.full_address
                  };
                }
              })
            )
            .flat(Infinity) as ImagePickerValue[]) ?? []
        )
    );
  }, [data]);

  return (
    <Grid container direction="column">
      <Grid container item alignItems="center">
        <Grid item xs>
          <Typography variant="body1">Select up to 5 images</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleBrowseImages}>
            Browse Images
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <ImagePicker
          fieldName="images"
          values={selection}
          maxImages={5}
          pinnedImages={existingImages}
        />
      </Grid>
      <Grid container item justifyContent="flex-end">
        <Typography variant="body2">{imageSelection.length}/5</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" gutterBottom={true}>
          Please check your images meet the following requirements.
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        <SelectedImageRatioMessage
          icon={
            squareImages.length ? (
              <CheckCircle style={{ color: '#43a047' }} />
            ) : (
              <AlertCircle color="error" />
            )
          }
          text={`${squareImages.length} square image${
            squareImages.length === 1 ? '' : 's'
          } selected`}
        />
        <SelectedImageRatioMessage
          icon={
            landscapeImages.length ? (
              <CheckCircle style={{ color: '#43a047' }} />
            ) : (
              <AlertCircle color="error" />
            )
          }
          text={`${landscapeImages.length} landscape image${
            landscapeImages.length === 1 ? '' : 's'
          } selected`}
        />
      </Grid>
      <DropzoneDialog
        open={dropzoneOpen}
        acceptedFiles={['image/*']}
        showPreviews={false}
        showPreviewsInDropzone={true}
        // @ts-ignore
        onSave={handleSaveFile}
        maxFileSize={5000000}
        onClose={handleCloseDropzone}
      />
    </Grid>
  );
}

export interface TextFieldArrayProps {
  name: string;
  label: string;
  maxLength: number;
}

export function TextFieldArray({ name, label, maxLength }: TextFieldArrayProps) {
  const {
    mutators: { push, pop }
  } = useForm();
  const field = useField(name, { subscription: { value: true } });
  const fieldCount = field.input.value.length;

  function add() {
    pop(name);
  }

  function remove() {
    push(name, '');
  }

  return (
    <>
      <FieldArray name={name}>
        {({ fields }) =>
          fields.map((name, index) => <TextField key={index} name={name} label={label} />)
        }
      </FieldArray>
      <ButtonGroup variant="contained" color="secondary" size="small">
        <Button onClick={add} disabled={fieldCount <= 1}>
          Remove last {label}
        </Button>
        <Button onClick={remove} disabled={fieldCount >= maxLength}>
          Add {label}
        </Button>
      </ButtonGroup>
    </>
  );
}

const urlSchema =
  process.env.NODE_ENV === 'development'
    ? Yup.string().required().label('Landing Page URL')
    : Yup.string().url().required().label('Landing Page URL');

const schemaDetails = Yup.object()
  .shape({
    creative_name: Yup.string().trim().label('Creative Name'),
    tags: Yup.array().of(Yup.string().trim().label('Tag')).min(0).label('Tags'),
    titles: Yup.array()
      .required()
      .min(1)
      .max(5)
      .of(Yup.string().min(5).max(30).trim().required().label('Title'))
      .label('Titles'),
    long_title: Yup.string().min(10).max(90).trim().required().label('Long Title'),
    descriptions: Yup.array()
      .required()
      .min(1)
      .max(5)
      .of(Yup.string().min(10).max(90).trim().required().label('Description'))
      .label('Descriptions'),
    cta: Yup.string().required().label('Call to Action'),
    youtube: Yup.array()
      .min(1)
      .max(5)
      .of(Yup.string().required().label('YouTube URL'))
      .label('YouTube URLs'),
    url: urlSchema
  })
  .required();

const validateDetails = makeValidate(schemaDetails);

interface FormValue {
  workspace_id: string;
  creative_name?: string;
  tags: string[];
  property_ids: string[];
  images: ImagePickerValue[];
  titles: string[];
  descriptions: string[];
  videos: string[];
  long_title: string;
  cta: string;
  url?: string;
}

export default function Responsive() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, handleNext, handleBack] = useStepperPage(0);
  const [createCreative] = useCreateResponsiveCreativeMutation();

  if (!activeWorkspaceId) {
    throw new Error('Invalid workspace');
  }

  const handleSubmit = async (value: FormValue) => {
    const images = value.images.filter((value) => value.selection.type !== 'none');

    const attachments = await Promise.all(
      images.map(async ({ image, selection }) => {
        if (image.type === 'upload') {
          return {
            type: image.type,
            attachment: await uploadFile(activeWorkspaceId, image.src),
            selection
          };
        } else if (image.type === 'file') {
          return {
            // file attachments are the same as uploads once the file file has been uploaded
            // revert to 'upload' type so backend knows what to do
            type: 'upload',
            attachment: image.src,
            selection
          };
        } else {
          return {
            type: image.type,
            src: image.src,
            selection
          };
        }
      })
    );

    try {
      await createCreative({
        variables: {
          creative: {
            workspace_id: activeWorkspaceId!,
            creative_name: value.creative_name ?? value.titles.filter(Boolean)[0],
            images: attachments as ResponsiveImage[],
            listings: value.property_ids,
            url: value.url,
            tags: value.tags,
            cta: value.cta,
            descriptions: value.descriptions.filter(Boolean),
            titles: value.titles.filter(Boolean),
            long_title: value.long_title,
            videos: value.videos.filter(Boolean)
          }
        },
        context: workspaceMemberContext
      });
      enqueueSnackbar('Responsive creative created', { variant: 'success' });
      navigate(`/creative`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to create responsive creative', { variant: 'error' });
    }
  };

  return (
    <div>
      <PageHeader title="Responsive Ad" subtitle="Select your property" />
      <FormPaper>
        <FinalFormWizard
          page={page}
          onNext={handleNext}
          onBack={handleBack}
          onSubmit={handleSubmit}
          initialValues={
            {
              workspace_id: activeWorkspaceId!,
              property_ids: [],
              images: [],
              titles: [''],
              descriptions: [''],
              videos: [''],
              cta: 'LEARN_MORE',
              url: '',
              creative_name: '',
              long_title: '',
              tags: ['responsive']
            } as FormValue
          }
          submitText="Create Responsive Creative"
          mutators={{ ...arrayMutators }}
          decorators={[decorator]}
        >
          <FinalFormWizard.Page validate={validate}>
            <PropertySelect name="property_ids" multiple={true} />
          </FinalFormWizard.Page>
          <FinalFormWizard.Page validate={validateImages}>
            <SelectImagesPage listingsFieldName="property_ids" />
          </FinalFormWizard.Page>
          <FinalFormWizard.Page validate={validateDetails}>
            <ResponsiveAdDetails />
          </FinalFormWizard.Page>
        </FinalFormWizard>
      </FormPaper>
    </div>
  );
}
