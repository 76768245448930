import { makeStyles, Theme } from '@material-ui/core/styles';

const useListItemLinkStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$selected, &$selected:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  selected: {},
  icon: {
    color: 'inherit'
  },
  submenu: {
    paddingLeft: theme.spacing(7)
  }
}));

export default useListItemLinkStyles;
