import { Theme, withStyles } from '@material-ui/core/styles';
import { default as MuiRating } from '@material-ui/lab/Rating';

const Rating = withStyles((theme: Theme) => ({
  iconFilled: {
    color: theme.palette.secondary.main
  }
}))(MuiRating);

export default Rating;
