import { useMemo, useState } from 'react';

import { Avatar, Button, TextField, Tooltip } from '@material-ui/core';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { Autocomplete } from '@material-ui/lab';

import MaterialTable from '@material-table/core';

import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  ReviewFieldsFragment,
  useDeleteCustomerReviewMutation,
  useListingsSlimQuery,
  useUpdateCustomerReviewMutation,
  useWorkspaceCustomerReviewsQuery,
  useWorkspaceTeamMembersQuery,
  Workspace_Listings_Bool_Exp,
  Workspace_Review_Source_Enum,
  Workspace_Review_Types_Enum,
  Workspace_Users_Bool_Exp
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import Link from 'components/Link';
import PageHeader from 'components/PageHeader';
import MuiRating from 'components/Rating';
import TableContainer from 'components/TableContainer';
import GoogleMyBusinessAvatar from 'components/avatars/GoogleMyBusiness';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { usePagination } from 'lib/hooks/useFiltersAndPagination';
import useUserContext from 'lib/hooks/useUserContext';

import AddCustomerReviewDrawer from './components/AddManualCustomerReviewDrawer';

export default function CustomerReviewsPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const { limit, setLimit, page, setPage, offset } = usePagination();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, refetch } = useWorkspaceCustomerReviewsQuery({
    variables: {
      where: {
        workspace_id: { _eq: activeWorkspaceId },
        _or: isWorkspaceAgent
          ? [
              {
                created_by_id: { _eq: userId }
              },
              { agent_user_id: { _eq: userId! } },
              { agent: { display_name: { _in: agentPermissions.allowed_agents } } },
              {
                listing: {
                  agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
                }
              }
            ]
          : undefined
      },
      limit: limit,
      offset: offset
    },
    context: workspaceMemberContext
  });
  const [deleteReview] = useDeleteCustomerReviewMutation({
    context: workspaceMemberContext
  });
  const [updateReview] = useUpdateCustomerReviewMutation({
    context: workspaceMemberContext
  });

  const handleOpenDrawer = () => setDrawerOpen(true);
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    refetch();
  };

  const handleDeleteReview = async (id: string) => {
    try {
      await deleteReview({ variables: { id } });
      enqueueSnackbar('Review Deleted', { variant: 'success' });
      refetch();
    } catch (error) {
      enqueueSnackbar('Unable to delete review', { variant: 'error' });
    }
  };

  const handleUpdateReview = async (review: Partial<ReviewFieldsFragment>) => {
    try {
      await updateReview({
        variables: {
          review_id: review.id!,
          set: {
            type: review.type,
            listing_id: review.listing_id ?? null,
            agent_user_id: review.agent_user_id ?? null
          }
        }
      });
      enqueueSnackbar('Review updated', { variant: 'success' });
      refetch();
    } catch (error: any) {
      enqueueSnackbar('Unable to update review', { variant: 'error' });
    }
  };

  const count = data?.reviews_aggregate?.aggregate?.count ?? 0;

  if (!loading && count === 0) {
    return (
      <>
        <EmptyStatePage
          title="Customer Reviews"
          icon={<RateReviewOutlinedIcon color="secondary" style={{ width: 64, height: 64 }} />}
          text="Collect and post reviews from your customers"
          button={
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleOpenDrawer}
              fullWidth
            >
              Add Review Manually
            </Button>
          }
        />
        <AddCustomerReviewDrawer open={drawerOpen} onClose={handleCloseDrawer} />
      </>
    );
  }

  // https://github.com/mbrn/material-table/issues/1979
  const reviews = data?.reviews?.map((r) => ({ ...r })) ?? [];

  return (
    <div>
      <PageHeader
        title="Customer Reviews"
        subtitle="Collect and post reviews from your customers"
        rightComponent={
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleOpenDrawer}
            style={{ minWidth: 160 }}
          >
            Add Review Manually
          </Button>
        }
      />

      <MaterialTable
        title="Reviews"
        columns={[
          {
            title: 'Source',
            field: 'source',
            render: (data) => {
              if (data?.source === Workspace_Review_Source_Enum.GoogleMyBusiness) {
                return (
                  <Avatar>
                    <GoogleMyBusinessAvatar />
                  </Avatar>
                );
              }

              return data.review_source.description ?? data.source;
            },
            editable: 'never'
          },
          {
            title: 'Customer Name',
            field: 'name',
            editable: 'never'
          },
          {
            title: 'Agent Name',
            field: 'agent_user_id',
            editComponent: (props) => {
              return <EditAgentSelect value={props.value} onChange={props.onChange} />;
            },
            render: (data) => {
              return data?.agent?.display_name ? (
                data.agent.display_name
              ) : (
                <span style={{ color: '#707683' }}>-</span>
              );
            },
            editable: 'onUpdate'
          },
          {
            title: 'Rating',
            field: 'rating',
            render: (rowData) => (
              <MuiRating value={rowData.rating} readOnly precision={0.5} size="large" />
            ),
            editable: 'never'
          },

          {
            title: 'Review',
            field: 'review',
            render: (data) => {
              if (!data?.title && !data?.review) {
                return <span style={{ color: '#707683' }}>-</span>;
              }

              if (data.title) {
                return (
                  <Tooltip title={data.review || ''} arrow>
                    <div>{data.title}</div>
                  </Tooltip>
                );
              }

              if (data.review && data.review.length > 45) {
                return (
                  <Tooltip title={data.review} arrow>
                    <div>{`${data.review.slice(0, 45)} ...`}</div>
                  </Tooltip>
                );
              }

              return <div>{data.review || <span style={{ color: '#707683' }}>-</span>}</div>;
            },
            editable: 'never'
          },

          {
            title: 'Type',
            field: 'type',
            lookup: {
              [Workspace_Review_Types_Enum.Buyer]: 'Buyer',
              [Workspace_Review_Types_Enum.Seller]: 'Seller',
              [Workspace_Review_Types_Enum.Landlord]: 'Landlord',
              [Workspace_Review_Types_Enum.Renter]: 'Tenant',
              [Workspace_Review_Types_Enum.Customer]: 'Customer'
            },
            editable: 'onUpdate'
          },

          {
            field: 'listing_id',
            title: 'Listing',
            render: (data) => {
              if (data.listing_id) {
                return (
                  <Link to={`/properties/view/${data.listing_id}`}>
                    {data.listing?.location.address.formatted_address}
                  </Link>
                );
              }

              return 'No listing';
            },
            editComponent: (props) => {
              return <EditListingSelect value={props.value} onChange={props.onChange} />;
            },
            editable: 'onUpdate'
          },
          {
            title: 'Created At',
            field: 'created_at',
            render: (data) => moment(data?.created_at).format('Do MMM YYYY'),
            editable: 'never'
          }
        ]}
        data={reviews}
        totalCount={count ?? 0}
        isLoading={loading}
        options={{
          search: false,
          toolbar: false,
          draggable: false,
          showTitle: false,
          columnsButton: false,
          sorting: true,
          actionsColumnIndex: -1,
          pageSize: limit
        }}
        editable={{
          isEditable: () => true,
          isEditHidden: () => isWorkspaceAnalyst ?? false,
          isDeletable: (rowData) => rowData.source === Workspace_Review_Source_Enum.Manual,
          isDeleteHidden: () => isWorkspaceAnalyst ?? false,
          onRowUpdate: (newData) => handleUpdateReview(newData),
          onRowDelete: (oldData) => handleDeleteReview(oldData.id)
        }}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        page={page}
        components={{
          Container: TableContainer
        }}
      />

      <AddCustomerReviewDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </div>
  );
}

interface EditSelectProps {
  value: string | null;
  onChange: (newValue: string | null) => void;
}

function EditAgentSelect({ value, onChange }: EditSelectProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const usersWhere: Workspace_Users_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    usersWhere._and!.push({
      _or: [
        {
          user: { display_name: { _in: agentPermissions.allowed_agents } }
        },
        {
          user_id: { _eq: userId! }
        }
      ]
    });
  }

  const { data, loading } = useWorkspaceTeamMembersQuery({
    variables: {
      where: usersWhere,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const options = useMemo(() => data?.workspace_users ?? [], [data?.workspace_users]);
  const selectOption = useMemo(() => options.find((o) => o.user_id === value), [options, value]);

  return (
    <Autocomplete
      value={selectOption}
      onChange={(_event, value) => onChange(value?.user_id ?? null)}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search agent ..." variant="outlined" />
      )}
      getOptionLabel={(option) => option?.user?.display_name ?? 'Unnamed Agent'}
      getOptionSelected={(option, value) => option?.user_id === value?.user_id}
      multiple={false}
      freeSolo={false}
      loading={loading}
      options={options}
    />
  );
}

function EditListingSelect({ value, onChange }: EditSelectProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const where: Workspace_Listings_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId }
      }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
    });
  }

  const { data, loading } = useListingsSlimQuery({
    variables: {
      limit: 2500,
      where: where
    },
    context: workspaceMemberContext
  });

  const options = useMemo(() => data?.listings ?? [], [data?.listings]);
  const selectOption = useMemo(() => options.find((o) => o.id === value), [options, value]);

  return (
    <Autocomplete
      value={selectOption}
      onChange={(_event, value) => onChange(value?.id ?? null)}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search listings ..." variant="outlined" />
      )}
      getOptionLabel={(listing) => {
        // Fallback to ID if we can't do anything else
        let addressPart = listing.unique_id ?? listing.id;

        if (listing.location.address.full_address) {
          addressPart = listing.location.address.full_address;
        } else if (listing.location.address.suburb && listing.location.address.street) {
          addressPart = `${listing.location.address.street} ${listing.location.address.suburb}`;
        }

        if (listing.type && listing.status) {
          return `${addressPart} (${listing.type} - ${listing.status})`;
        }

        return addressPart;
      }}
      getOptionSelected={(option, value) => option?.id === value?.id}
      multiple={false}
      freeSolo={false}
      loading={loading}
      options={options}
      fullWidth
    />
  );
}
