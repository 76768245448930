import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { FORM_ERROR } from 'final-form';
import qs from 'query-string';

import { useVerifyCanvaAppConfigurationMutation } from 'generated/graphql';

import CanvaIcon from 'components/icons/CanvaIcon';

import { meHasuraContext } from 'lib/HasuraRoleContext';
import { APP_CONFIG } from 'lib/app/config';
import useUserContext from 'lib/hooks/useUserContext';

import useAuthStyles from '../_styles';

export default function CanvaConnectionPopupPage() {
  const classes = useAuthStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const { info } = useUserContext();
  const [verifyCanva] = useVerifyCanvaAppConfigurationMutation({
    context: meHasuraContext
  });

  const { state, canva_user_token, nonce } = qs.parse(location.search);

  const handleConnect = async () => {
    if (!state || !canva_user_token || !nonce) {
      return {
        [FORM_ERROR]:
          'Missing connection parameters from Canva, please restart the connection process from within Canva'
      };
    }

    try {
      const resp = await verifyCanva({
        variables: {
          args: {
            state: state as string,
            canva_user_token: canva_user_token as string,
            nonce: nonce as string
          }
        }
      });

      const success = resp.data?.verifyCanvaAppConfiguration?.success;

      if (!success) {
        return {
          [FORM_ERROR]: 'Could not verify Canva connection, please try again'
        };
      }

      window.location.href = `https://www.canva.com/apps/configured?success=true&state=${state}`;
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Unknown Error Occurred';
      return { [FORM_ERROR]: message };
    }
  };

  const handleCancel = () => {
    window.location.href = `https://www.canva.com/apps/configured?success=false&state=${state}&error=canceled`;
  };

  const handleChangeAccount = () => {
    navigate('/login', {
      state: {
        from: location.pathname,
        search: location.search
      }
    });
  };

  return (
    <div className={classes.content}>
      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 140 }}
        />
        <div className={classes.formWrapper}>
          <Form
            onSubmit={handleConnect}
            render={({ handleSubmit, submitting, submitError }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography variant="h3" color="secondary" gutterBottom>
                  Connect Canva
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 16
                  }}
                >
                  <img
                    alt=""
                    src={APP_CONFIG.SITE_LOGO_URL_COLLAPSED}
                    style={{ width: 100, height: 100 }}
                  />
                  <SwapHorizIcon style={{ height: 60, width: 60 }} />
                  <CanvaIcon style={{ height: 100, width: 100 }} />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  disabled={submitting}
                >
                  {submitting ? 'Loading ...' : `Continue as ${info?.display_name ?? ''}`}
                </Button>
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  disabled={submitting}
                  onClick={handleChangeAccount}
                >
                  Change Account
                </Button>
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {submitError && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="error" variant="subtitle2">
                        {submitError}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </form>
            )}
          />
        </div>
      </Paper>
    </div>
  );
}
