import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

import MetaIcon from 'components/icons/MetaIcon';

interface MetaAvatarProps extends AvatarProps {
  disabled?: boolean;
}

export default function MetaAvatar(props: MetaAvatarProps) {
  const { style, disabled, ...other } = props;

  const backgroundColor = disabled ? '#808080' : '#1877F2';

  return (
    <Avatar style={{ backgroundColor, ...style }} {...other}>
      <MetaIcon />
    </Avatar>
  );
}
