// Hasura-Backend-Plus Config
import backend from './sdk';

const HASURA_BACKEND_PLUS_ENDPOINT = process.env.REACT_APP_HASURA_BACKEND_PLUS_ENDPOINT;
const CDN_ENDPOINT = process.env.REACT_APP_CDN_ENDPOINT;
const IS_PROD = process.env.NODE_ENV === 'production';

if (!HASURA_BACKEND_PLUS_ENDPOINT) {
  throw new Error('HASURA_BACKEND_PLUS_ENDPOINT Needs to be configured');
}

const config = {
  base_url: HASURA_BACKEND_PLUS_ENDPOINT,
  use_cookies: false
};

backend.initializeApp(config);

const auth = backend.auth();
const storage = backend.storage();

export { auth, storage };

export const getFilePath = (
  fileUrl?: string | null,
  token?: string | null,
  useCDN: boolean = true
) => {
  if (!fileUrl) {
    return undefined;
  }

  if (useCDN && IS_PROD && CDN_ENDPOINT) {
    if (fileUrl.startsWith('/')) {
      return `${CDN_ENDPOINT}${fileUrl}`;
    }
    return `${CDN_ENDPOINT}/${fileUrl}`;
  }

  if (token) {
    return `${HASURA_BACKEND_PLUS_ENDPOINT}/storage/o/${fileUrl}?token=${token}`;
  }

  return `${HASURA_BACKEND_PLUS_ENDPOINT}/storage/o/${fileUrl}`;
};
