import { useEffect, useState } from 'react';

import { CircularProgress, Link, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

import axios from 'axios';

interface HyperPingIndicatorProps {
  variant?: 'normal' | 'inline';
}

type HyperPingIndicators = 'incident' | 'maintenance' | 'outage' | 'down' | 'up';

function indicatorFontColor(indicator: HyperPingIndicators) {
  switch (indicator) {
    case 'up':
      return '#2BAC76';
    case 'incident':
    case 'outage':
      return '#FF9A00';
    case 'maintenance':
      return '#0070F3';
    case 'down':
      return '#E95858';
    default:
      return '#FFAF36';
  }
}

function indicatorText(indicator: HyperPingIndicators) {
  switch (indicator) {
    case 'up':
      return 'All systems operational';
    case 'incident':
    case 'outage':
      return 'Service degradation';
    case 'maintenance':
      return 'Scheduled maintenance under progress';
    case 'down':
      return 'System down';
    default:
      return 'Unknown system status';
  }
}

function IndicatorIcon({ indicator }: { indicator: HyperPingIndicators }) {
  switch (indicator) {
    case 'up':
      return <CheckCircleIcon fontSize="inherit" color="inherit" />;
    case 'incident':
    case 'outage':
      return <WarningIcon fontSize="inherit" color="inherit" />;
    case 'maintenance':
      return <ScheduleIcon fontSize="inherit" color="inherit" />;
    case 'down':
      return <ErrorIcon fontSize="inherit" color="inherit" />;
    default:
      return <WarningIcon fontSize="inherit" color="inherit" />;
  }
}

export default function HyperPingIndicator({ variant = 'normal' }: HyperPingIndicatorProps) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [indicator, setIndicator] = useState<HyperPingIndicators>('up');

  useEffect(() => {
    const fetchHealthData = async () => {
      try {
        const result = await axios.get<{
          indicator: HyperPingIndicators;
          uptime: string;
        }>(`https://status.properti.ai/status.json`);

        setIndicator(result.data.indicator);
      } catch (error: any) {
        setIndicator('down');
      } finally {
        setLoaded(true);
      }
    };
    fetchHealthData();
    const interval = setInterval(fetchHealthData, 120000);

    return () => {
      clearInterval(interval);
    };
  }, [setIndicator, setLoaded]);

  if (variant === 'inline') {
    if (!loaded) {
      return (
        <Link href="https://status.properti.ai" target="_blank">
          System Status
        </Link>
      );
    }

    return (
      <Link
        variant="caption"
        style={{
          color: indicatorFontColor(indicator)
        }}
        href="https://status.properti.ai"
        target="_blank"
      >
        <IndicatorIcon indicator={indicator} /> {indicatorText(indicator)}
      </Link>
    );
  }

  return (
    <div>
      {!loaded && (
        <div>
          <CircularProgress size={16} />
        </div>
      )}
      {loaded && (
        <Typography
          variant="h3"
          style={{
            color: indicatorFontColor(indicator),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 6
          }}
          gutterBottom
        >
          <IndicatorIcon indicator={indicator} /> {indicatorText(indicator)}
        </Typography>
      )}

      <Link href="https://status.properti.ai" target="_blank">
        View System Status Page
      </Link>
    </div>
  );
}
