import React from 'react';

import { Form } from 'react-final-form';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core';

import { Checkboxes, makeValidate } from 'mui-rff';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { WorkspacePostsQuery, useDeletePostFromPlatformMutation } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

const schema = Yup.object({
  selected_platforms: Yup.array().of(Yup.string().required()).required().label('Platforms')
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

interface DeletePostDialogProps {
  postData?: WorkspacePostsQuery['posts'][0];
  open: boolean;
  onClose: () => void;
}

export default function PostDeleteDialog({ open, onClose, postData }: DeletePostDialogProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, userId, isWorkspaceAgent, isWorkspaceAnalyst } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const { enqueueSnackbar } = useSnackbar();

  const [deletePostFromPlatform] = useDeletePostFromPlatformMutation({
    context: workspaceMemberContext
  });

  const linkedinUserId = postData?.args?.linkedin_personal?.posting_from?.id;
  const facebookPageId = postData?.args?.facebook?.posting_from?.id;
  const linkedinPageId = postData?.args?.linkedin?.posting_from?.id;
  const youtubePageId = postData?.args?.youtube?.posting_from?.id;
  const gmbPageId = postData?.args?.gmb?.posting_from?.id;

  const data = [
    {
      label: 'Facebook',
      value: 'facebook',
      disabled:
        !postData?.facebook_post_id ||
        postData?.deleted_platforms.includes('facebook') ||
        (isWorkspaceAgent && !agentPermissions.allowed_facebook_pages.includes(facebookPageId))
    },
    {
      label: 'Google My Business',
      value: 'gmb',
      disabled:
        !postData?.google_my_business_post_id ||
        postData?.deleted_platforms.includes('gmb') ||
        (isWorkspaceAgent && !agentPermissions.allowed_gmb_pages.includes(gmbPageId))
    },
    {
      label: 'Linkedin',
      value: 'linkedin',
      disabled:
        !postData?.linkedin_post_id ||
        postData?.deleted_platforms.includes('linkedin') ||
        (isWorkspaceAgent && !agentPermissions.allowed_linkedin_pages.includes(linkedinPageId))
    },
    {
      label: 'Linkedin Personal',
      value: 'linkedin_personal',
      disabled:
        !postData?.linkedin_personal_post_id ||
        postData?.deleted_platforms.includes('linkedin_personal') ||
        (isWorkspaceAgent && linkedinUserId !== userId)
    },
    {
      label: 'Youtube',
      value: 'youtube',
      disabled:
        !postData?.youtube_post_id ||
        postData?.deleted_platforms.includes('youtube') ||
        (isWorkspaceAgent && !agentPermissions.allowed_youtube_pages.includes(youtubePageId))
    },
    {
      label: 'Instagram',
      value: 'instagram',
      disabled: true
    }
  ];

  const handleDeletePostFromPlatform = async (formValues: FormSchema) => {
    try {
      const deleteResponse = await deletePostFromPlatform({
        variables: {
          args: {
            id: postData?.id!,
            workspace_id: activeWorkspaceId!,
            facebook_post_id: postData?.facebook_post_id,
            linkedin_post_id: postData?.linkedin_post_id,
            linkedin_personal_post_id: postData?.linkedin_personal_post_id,
            linkedin_personal_user_id: linkedinUserId,
            youtube_post_id: postData?.youtube_post_id,
            google_my_business_post_id: postData?.google_my_business_post_id,
            delete_platforms: formValues.selected_platforms
          }
        }
      });

      const data = deleteResponse.data?.deletePost;
      if (!data) {
        throw new Error('Unable to delete post');
      }

      const platformsDeleted = data.platforms_deleted;
      const platformsErrored = data.platforms_errored;

      if (!platformsDeleted.length) {
        throw new Error('No platforms deleted');
      }

      if (platformsDeleted) {
        enqueueSnackbar(`Post delete from ${platformsDeleted.join(', ')}`, { variant: 'success' });
      }

      if (platformsErrored.length) {
        enqueueSnackbar(`Unable to delete post from ${platformsErrored.join(', ')}`, {
          variant: 'warning'
        });
      }

      onClose();
    } catch (error) {
      enqueueSnackbar('Unable to delete Post', { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="campaign-template-dialog-title"
    >
      <DialogTitle id="campaign-template-dialog-title" onClose={onClose}>
        What platforms would you like to delete this post from?
      </DialogTitle>

      <DialogContent>
        <DialogContentText variant="caption" gutterBottom>
          Instagram posts cannot be deleted via Properti.
        </DialogContentText>
        <Form onSubmit={handleDeletePostFromPlatform} validate={validate}>
          {({ handleSubmit, submitting, submitError }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Box>
                  <Checkboxes name="selected_platforms" required={true} data={data} />
                </Box>

                {submitError && (
                  <Box mt={2}>
                    <Typography variant="caption" color="error">
                      {submitError}
                    </Typography>
                  </Box>
                )}

                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button disabled={submitting || isWorkspaceAnalyst} type="submit">
                    Delete
                  </Button>
                </Box>
              </form>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
}
