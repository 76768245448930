import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';

import { classNames } from './utils';

const useButtonStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    width: '1em',
    height: '1em',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: '100%',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))',
    userSelect: 'none'
  },
  chevron: {
    margin: 'auto',
    fontSize: '0.8em'
  },
  link: {
    textDecoration: 'none'
  }
}));

export default function AdPreviewButton(
  props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
) {
  const styles = useButtonStyles();

  return (
    <a {...props} className={classNames(props.className, styles.link)}>
      <div className={styles.button}>
        <ChevronRight className={styles.chevron} />
      </div>
    </a>
  );
}
