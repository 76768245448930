import { Workspace_Content_Type_Enum } from 'generated/graphql';

export const contentTypeColorMapping = {
  [Workspace_Content_Type_Enum.Listing]: '#3A6FA1',
  [Workspace_Content_Type_Enum.MarketData]: '#3CA988',
  [Workspace_Content_Type_Enum.AuthorityBuilding]: '#A37F4F',
  [Workspace_Content_Type_Enum.SocialProof]: '#7E4FA1',
  [Workspace_Content_Type_Enum.Personal]: '#A14F4F',
  [Workspace_Content_Type_Enum.NewsUpdate]: '#568C32',
  [Workspace_Content_Type_Enum.Other]: '#3FA1A6',
  [Workspace_Content_Type_Enum.AuctionClearanceRates]: '#E3935F',
  [Workspace_Content_Type_Enum.GenericPost]: '#3b3892'
};
