import { useMemo } from 'react';

import { Form } from 'react-final-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Grid,
  Link as MuiLink,
  Paper,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import * as Yup from 'yup';

import { useLogger } from 'lib/LoggerContext';
import { APP_CONFIG } from 'lib/app/config';
import { auth } from 'lib/auth/hbp';

import useAuthStyles from '../_styles';

const schema = Yup.object()
  .shape({
    token: Yup.string().trim().required(),
    new_password: Yup.string().required().label('New password')
  })
  .required();

type FormSchema = Yup.InferType<typeof schema>;

const validate = makeValidate(schema);

const ResetPasswordPage = () => {
  const classes = useAuthStyles();
  const location = useLocation();
  const logger = useLogger();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token, data } = qs.parse(location.search);

  const dataObj: { email?: string; token?: string } = useMemo(() => {
    if (data) {
      try {
        return JSON.parse(
          Buffer.from(decodeURI(Array.isArray(data) ? data[0] : data), 'base64').toString('ascii')
        );
      } catch {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        navigate('/');
      }
    }

    return {};
  }, [data, enqueueSnackbar, navigate]);

  const isNewUser = !!dataObj.email && !!dataObj.token;

  const formFields = useMemo(
    () => [
      <TextField
        style={{ height: 60 }}
        name="token"
        label="Reset Token"
        autoComplete="email"
        disabled={isNewUser}
      />,
      <TextField style={{ height: 60 }} name="new_password" label="New Password" type="password" />
    ],
    [isNewUser]
  );

  const handleFormSubmit = async (values: FormSchema) => {
    try {
      await auth.changePasswordChange(values.new_password, values.token);
      if (isNewUser) {
        navigate('/login?back_url=/profile');
        enqueueSnackbar('Account created successfully!', { variant: 'success' });
      }
    } catch (error: any) {
      let message = 'Unknown Error Occurred';
      if (error.isAxiosError) {
        message = error?.response?.data?.message ?? 'Unknown Error Occurred';
      }
      logger.warn(message, {
        meta: {
          response: error?.response?.data
        }
      });
      return { [FORM_ERROR]: message };
    }
  };

  return (
    <div className={classes.content}>
      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 160 }}
        />
        <div className={classes.formWrapper}>
          <Form
            initialValues={{
              token: dataObj.token ?? token
            }}
            onSubmit={handleFormSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, submitSucceeded }) => {
              if (submitSucceeded) {
                if (isNewUser) {
                  return <CircularProgress />;
                }
                return (
                  <div style={{ textAlign: 'center' }}>
                    <img style={{ maxWidth: 220 }} src="/illustrations/confirmation.svg" alt="" />
                    <Typography variant="subtitle1" gutterBottom>
                      You've successfully reset your password.{' '}
                      <MuiLink component={Link} color="secondary" to="/login">
                        Login using your new password
                      </MuiLink>
                    </Typography>
                  </div>
                );
              }
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Reset your password
                  </Typography>
                  <Typography gutterBottom>Enter your new password below.</Typography>

                  {isNewUser && (
                    // To allow password managers to correctly save username and
                    // password. Otherwise they try to save the token
                    <TextField
                      name="username"
                      style={{ display: 'none' }}
                      autoComplete="username"
                      value={dataObj.email}
                    />
                  )}
                  <Grid container spacing={1}>
                    {formFields.map((item, idx) => (
                      <Grid key={idx} item xs={12} sm={12}>
                        {item}
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={submitting}
                  >
                    {submitting ? 'Loading ...' : 'Reset Password'}
                  </Button>
                  {submitError && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography color="error" variant="subtitle2">
                          {submitError}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </form>
              );
            }}
          />
        </div>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Have your password?{' '}
              <MuiLink component={Link} color="secondary" to="/login">{`Login`}</MuiLink>
            </Typography>{' '}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ResetPasswordPage;
