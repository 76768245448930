import React from 'react';

import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import clsx from 'clsx';

import { useHideOnboardingMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useLogger } from 'lib/LoggerContext';
import useUserContext from 'lib/hooks/useUserContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1)
    },
    complete: {
      color: theme.palette.common.white,
      backgroundColor: '#27AE60'
    }
  })
);

export interface OnboardingItem {
  title: string;
  subtitle?: string;
  complete: boolean;
  actionUrl: string;
  hide?: boolean;
}

export interface OnboardingChecklistProps {
  title: string;
  items: OnboardingItem[];
  hide?: boolean;
  hideHideButton?: boolean;
}

const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({
  title,
  items,
  hide = false,
  hideHideButton = false
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const classes = useStyles();
  const logger = useLogger();

  const [hideOnboarding] = useHideOnboardingMutation({
    context: workspaceMemberContext
  });

  const handleHide = async () => {
    try {
      await hideOnboarding({
        variables: {
          hide_onboarding: true,
          workspace_id: activeWorkspaceId!
        }
      });
    } catch (error) {
      logger.error('Unable to hide workspace onboarding', { meta: { error } });
    }
  };

  if (hide) {
    return null;
  }

  return (
    <Paper className={classes.container}>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>

      <List>
        {items
          .filter((item) => !item.hide)
          .map((item, index) => (
            <ListItem
              key={index}
              component={item.actionUrl.includes('https:') ? 'a' : Link}
              target={item.actionUrl.includes('https:') ? '_blank' : undefined}
              to={item.actionUrl}
              href={item.actionUrl}
            >
              <ListItemAvatar>
                <Avatar className={clsx({ [classes.complete]: item.complete })}>
                  {item.complete ? <DoneIcon /> : index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.title} secondary={item.subtitle} />
              {!item.complete && (
                <ListItemSecondaryAction>
                  <IconButton
                    component={item.actionUrl.includes('https:') ? 'a' : Link}
                    target={item.actionUrl.includes('https:') ? '_blank' : undefined}
                    to={item.actionUrl}
                    href={item.actionUrl}
                    edge="end"
                    aria-label="complete task"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
      </List>

      {!hideHideButton && (
        <Button
          variant="text"
          color="primary"
          startIcon={<VisibilityOffIcon />}
          onClick={handleHide}
        >
          Don't show again
        </Button>
      )}
    </Paper>
  );
};

export default OnboardingChecklist;
