import { useEffect, useState } from 'react';

export enum ScriptStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  READY = 'ready'
}

export default function useScript(src?: string) {
  const [status, setStatus] = useState<ScriptStatus>(
    src ? ScriptStatus.LOADING : ScriptStatus.IDLE
  );

  useEffect(() => {
    if (!src) {
      setStatus(ScriptStatus.IDLE);
      return;
    }

    // Script may have been added by another instance of this hook
    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      let newScript = document.createElement('script');
      newScript.src = src;
      newScript.async = true;
      newScript.setAttribute('data-status', 'loading');

      document.body.appendChild(newScript);

      script = newScript;

      const setAttributeFromEvent = (event: any) => {
        script?.setAttribute(
          'data-status',
          event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR
        );
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Get existing script status
      setStatus(script.getAttribute('data-status') as ScriptStatus);
    }

    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR);
    };

    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    // Cleanup event listeners
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src, setStatus]);

  return status;
}
