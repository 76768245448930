import React from 'react';

import { Field } from 'react-final-form';

import CategoryCheckbox, { CategoryCheckboxProps } from 'components/forms/CategoryCheckbox';

interface FinalFormCategoryCheckboxProps {
  name: string;
  option: Exclude<CategoryCheckboxProps, 'checked'>;
  multiple?: boolean;
  disabled?: boolean;
}

const FinalFormCategoryCheckbox: React.FC<FinalFormCategoryCheckboxProps> = ({
  name,
  multiple = false,
  disabled = false,
  option
}) => {
  return (
    <Field
      name={name}
      type={multiple ? 'checkbox' : 'radio'}
      value={option.value}
      multiple={multiple}
      render={({ input }) => {
        const { onChange, checked, value } = input;
        const handleOnClick = () => {
          const type = multiple ? 'checkbox' : 'radio';
          onChange({
            target: {
              type,
              value: value,
              checked: !checked
            }
          });
          option.onClick?.();
        };

        return (
          <CategoryCheckbox
            {...option}
            checked={checked}
            readonly={disabled}
            onClick={handleOnClick}
          />
        );
      }}
    />
  );
};

export default FinalFormCategoryCheckbox;
