import React from 'react';

import { Typography } from '@material-ui/core';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

interface EmptyChartMessageProps {
  message?: string;
}

export default function EmptyChartMessage({
  message = 'No data available'
}: EmptyChartMessageProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        marginLeft: 'auto',
        height: '70%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="subtitle2">{message}</Typography>
      <TrendingUpOutlinedIcon color="disabled" fill="none" style={{ height: 64, width: 64 }} />
    </div>
  );
}
