import React, { useCallback, useMemo, useState } from 'react';

import { Box, Button, IconButton, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { AppointmentModel, EditingState, ViewState } from '@devexpress/dx-react-scheduler';
import {
  AppointmentForm,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DayView,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView
} from '@devexpress/dx-react-scheduler-material-ui';

import moment from 'moment';

import {
  Workspace_Automation_Rules_Bool_Exp,
  Workspace_Posts_Bool_Exp,
  useWorkspaceCalendarQuery,
  Workspace_Content_Type_Enum,
  useWorkspaceAgentMappingsQuery,
  Workspace_Post_Status_Enum
} from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import SchedulerTooltip from 'components/SchedulerTooltip';
import ShareDrawer from 'components/ShareDrawer';
import MultiFilterDropdown from 'components/multiFilterDropdown';
import { ShareLogicVariants } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { createFilter } from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { getPlatformLabel } from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';
import { contentTypeColorMapping } from 'lib/utils/contentTypeColorMappings';

import PostTabs from '../components/PostTabs';

const useStatusFilter = createFilter<Workspace_Posts_Bool_Exp>({ status: {} });
const useContentTypeFilter = createFilter<Workspace_Posts_Bool_Exp>({ content_type: {} });
const usePlatformFilter = createFilter<Workspace_Posts_Bool_Exp, string>({
  args: { _has_key: null }
});
const usePostedFromFilter = createFilter<Workspace_Posts_Bool_Exp>({ head_office_post: {} });

const resources = [
  {
    fieldName: 'appointmentColor',
    title: 'Content Type',
    instances: [
      { id: 'Draft', text: 'Draft', color: '#A9A9A9' },
      ...Object.entries(contentTypeColorMapping).map(([type, color]) => ({
        id: type,
        text: type,
        color: color
      }))
    ]
  }
];

const PostCalendar = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    workspace,
    activeWorkspaceId,
    isWorkspaceAgent,
    isWorkspaceAnalyst,
    isWorkspaceAdmin
  } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<any | null>(null);

  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const [fromDate, toDate] = useMemo(
    () => [moment(calendarDate).subtract(1, 'weeks'), moment(calendarDate).add(1, 'weeks')],
    [calendarDate]
  );

  const [draftPostId, setDraftPostId] = useState<string | null>(null);

  // Filters
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [contentTypeFilter, contentType, setContentType] = useContentTypeFilter();
  const [statusFilter, status, setStatus] = useStatusFilter();
  const [platformFilter, platform, setPlatform] = usePlatformFilter();
  const [, postedFrom, setPostedFrom] = usePostedFromFilter();

  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.posts.filter.agent`
  );

  const postsWhere: Workspace_Posts_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { scheduled_at: { _gte: fromDate.toISOString() } },
      { scheduled_at: { _lte: toDate.toISOString() } },
      { ...contentTypeFilter },
      { ...statusFilter },
      { ...platformFilter }
    ]
  };

  if (postedFrom === 'Head Office') {
    postsWhere._and!.push({
      head_office_post: { head_office_post_id: { _is_null: false } }
    });
  } else if (postedFrom === 'Workspace') {
    postsWhere._and!.push({
      _not: { head_office_post: { head_office_post_id: { _is_null: false } } }
    });
  }

  if (filterAgentName) {
    postsWhere._and!.push({
      _or: [
        {
          created_by: { display_name: { _eq: filterAgentName } }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _eq: filterAgentName } } }
            }
          }
        }
      ]
    });
  }

  const rulesWhere: Workspace_Automation_Rules_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    postsWhere._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId! }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        },
        {
          creative: {
            listings: {
              listing: {
                agents: {
                  user: {
                    display_name: { _in: agentPermissions.allowed_agents }
                  }
                }
              }
            }
          }
        }
      ]
    });

    rulesWhere._and!.push({
      created_by_id: { _eq: userId }
    });
  }

  const { data, loading, refetch } = useWorkspaceCalendarQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      posts_where: postsWhere,
      automation_rules_where: rulesWhere
    },
    context: workspaceMemberContext
  });

  const { data: agentsData } = useWorkspaceAgentMappingsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const agents =
    agentsData?.workspace_agent_mapping?.filter(
      (map) =>
        !isWorkspaceAgent ||
        agentPermissions.allowed_agents.includes(map.listing_agent_name.trim()) ||
        map.user_id === userId
    ) ?? [];

  const agentNameOptions = agents
    ?.filter(Boolean)
    .map((agent) => ({ value: agent.user?.display_name!, text: agent.user?.display_name! }));

  const facebookPageId = isWorkspaceAgent
    ? agentPermissions.allowed_facebook_pages[0]
    : data?.workspace?.apps?.facebook?.page_id;

  const posts = useMemo(
    () =>
      data?.posts.map((post) => ({
        title: post.name ?? post.message,
        id: post.id,
        created_by: post.created_by?.display_name ?? 'Properti Automation',
        startDate: post.scheduled_at ?? post.created_at,
        isFuture: post.scheduled_at ? moment(post.scheduled_at).isAfter(moment()) : false,
        posted: post.posted,
        draft: post.draft,
        contentType: post.content_type,
        appointmentColor: post.draft ? 'Draft' : post.content_type,
        type: 'post',
        status: post.status,
        metadata: {
          ...post,
          facebook_page_id: facebookPageId
        },
        refetch: refetch
      })) ?? [],
    [data?.posts, facebookPageId, refetch]
  );

  const automationRules = useMemo(
    () =>
      data?.automation_rules?.map((rule) => ({
        title: rule.name,
        created_by: rule.created_by?.display_name,
        id: rule.id,
        type: 'rule',
        startDate: rule.created_at,
        refetch: refetch
      })) ?? [],
    [data?.automation_rules, refetch]
  );

  // @ts-ignore endDate for posts weird
  const schedulerData: AppointmentModel[] = useMemo(
    () => [...posts, ...automationRules],
    [posts, automationRules]
  );

  const hasHeadOffice = useMemo(
    () => Boolean(workspace?.head_offices?.length),
    [workspace?.head_offices?.length]
  );

  const openShareDrawer = useCallback(() => {
    setShareDrawerOpen(true);
  }, [setShareDrawerOpen]);

  const handleCloseShareDrawer = useCallback(() => {
    setShareDrawerOpen(false);
    refetch();
  }, [setShareDrawerOpen, refetch]);

  const handleEditPost = useCallback(
    (postId: string) => {
      setDraftPostId(postId);
      setShareDrawerOpen(true);
    },
    [setDraftPostId, setShareDrawerOpen]
  );

  const FormOverlay = useCallback(() => {
    return (
      <ShareDrawer
        open={shareDrawerOpen}
        onClose={handleCloseShareDrawer}
        timestamp={selectedDateTime}
        title="New Social Post"
        variant={ShareLogicVariants.UPLOAD}
        disabled={isWorkspaceAnalyst}
        draftPostId={draftPostId}
      />
    );
  }, [shareDrawerOpen, handleCloseShareDrawer, selectedDateTime, isWorkspaceAnalyst, draftPostId]);

  const commitChanges = useCallback(({ added, changed, deleted }: any) => {
    // noop
  }, []);
  const onEditingAppointmentChange = useCallback(({ editingAppointment }: any) => {
    // noop
  }, []);

  const onAddedAppointmentChange = useCallback(
    (addedAppointment) => {
      const convertDateToInputFormat = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };

      const convertedDate = convertDateToInputFormat(new Date(addedAppointment.startDate));

      if (addedAppointment) {
        setSelectedDateTime(convertedDate);
        openShareDrawer();
      }
    },
    [setSelectedDateTime, openShareDrawer]
  );

  const ContentComponent = useCallback(
    (props: any) => {
      return <SchedulerTooltip {...props} handleEditPost={handleEditPost} />;
    },
    [handleEditPost]
  );

  return (
    <>
      <PageHeader
        title="Posts"
        rightComponent={
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={openShareDrawer}
            disabled={isWorkspaceAnalyst}
            style={{ minWidth: 160 }}
          >
            New Post
          </Button>
        }
      />
      <PostTabs value={1} showHeadOfficeContentSchedule={hasHeadOffice && isWorkspaceAdmin} />
      <Paper elevation={0}>
        <MultiFilterDropdown
          onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
          filtersOpen={filtersOpen}
          search={false}
          menuItems={[
            {
              name: 'Posted From',
              options: [
                { value: 'Head Office', text: 'Head Office Posts' },
                { value: 'Workspace', text: 'Workspace Posts' }
              ],
              value: postedFrom,
              setValue: setPostedFrom,
              hidden: !hasHeadOffice,
              hasAll: true
            },
            {
              name: 'Platform',
              options: [
                { value: 'facebook', text: getPlatformLabel('facebook') },
                { value: 'instagram', text: getPlatformLabel('instagram') },
                { value: 'linkedin', text: getPlatformLabel('linkedin') },
                { value: 'linkedin_personal', text: getPlatformLabel('linkedin_personal') },
                { value: 'youtube', text: getPlatformLabel('youtube') },
                { value: 'gmb', text: getPlatformLabel('gmb') }
              ],
              value: platform,
              setValue: setPlatform,
              hasAll: true
            },
            {
              name: 'Content Category',
              options: [
                {
                  value: Workspace_Content_Type_Enum.AuctionClearanceRates,
                  text: 'Auction Clearances'
                },
                {
                  value: Workspace_Content_Type_Enum.AuthorityBuilding,
                  text: 'Authority Building'
                },
                { value: Workspace_Content_Type_Enum.Listing, text: 'Listing' },
                { value: Workspace_Content_Type_Enum.MarketData, text: 'Market Data' },
                { value: Workspace_Content_Type_Enum.NewsUpdate, text: 'News Article' },
                {
                  value: Workspace_Content_Type_Enum.SocialProof,
                  text: 'Testimonials & Social Proof'
                },
                { value: Workspace_Content_Type_Enum.Personal, text: 'Personal' },
                { value: Workspace_Content_Type_Enum.GenericPost, text: 'Generic' },
                { value: Workspace_Content_Type_Enum.Other, text: 'Other' }
              ],
              value: contentType,
              setValue: setContentType,
              hasAll: true
            },
            {
              name: 'Status',
              options: [
                { value: Workspace_Post_Status_Enum.Draft, text: 'Draft' },
                { value: Workspace_Post_Status_Enum.Posted, text: 'Posted' },
                { value: Workspace_Post_Status_Enum.Posting, text: 'Posting' },
                { value: Workspace_Post_Status_Enum.Scheduled, text: 'Scheduled' },
                { value: Workspace_Post_Status_Enum.Error, text: 'Error' }
              ],
              value: status,
              setValue: setStatus,
              hasAll: true
            },
            {
              name: 'Agent',
              value: filterAgentName,
              setValue: setFilterAgentName,
              options: agentNameOptions,
              hasAll: true
            }
          ]}
        />
        <Scheduler data={schedulerData}>
          <ViewState
            defaultCurrentDate={moment().format('YYYY-MM-DD')}
            defaultCurrentViewName="WorkWeek"
            onCurrentDateChange={setCalendarDate}
          />
          <EditingState
            onCommitChanges={commitChanges}
            onEditingAppointmentChange={onEditingAppointmentChange}
            onAddedAppointmentChange={onAddedAppointmentChange}
          />
          <WeekView
            startDayHour={0}
            endDayHour={23}
            cellDuration={60}
            timeTableCellComponent={TimeTableCell}
          />
          <WeekView
            name="WorkWeek"
            displayName="Work Week"
            startDayHour={5}
            endDayHour={21}
            excludedDays={[0, 6]}
            cellDuration={60}
            timeTableCellComponent={TimeTableCell}
          />
          <DayView
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}
            timeTableCellComponent={TimeTableCell}
          />
          <Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <Appointments />
          <AppointmentTooltip
            headerComponent={HeaderComponent}
            contentComponent={ContentComponent}
          />
          <AppointmentForm overlayComponent={FormOverlay} />
          <Resources data={resources} />
        </Scheduler>
      </Paper>
    </>
  );
};

const HeaderComponent = ({ onHide }: any) => {
  return (
    <Box
      style={{
        position: 'absolute',
        right: 0,
        zIndex: 1
      }}
    >
      <IconButton onClick={onHide}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const TimeTableCell = (props: any) => (
  <DayView.TimeTableCell
    {...props}
    style={{
      ...props.style,
      cursor: 'pointer'
    }}
  />
);

const ToolbarWithLoading: React.FC<any> = ({ children, ...restProps }) => {
  return (
    <div>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <LinearProgress />
    </div>
  );
};

export default PostCalendar;
