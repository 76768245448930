import React from 'react';

import { Form } from 'react-final-form';

import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';

import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';

import DialogTitle from 'components/DialogTitle';

import { track } from 'lib/utils/track';

interface ShortenLinkModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (url: string) => Promise<any>;
}

const schema = Yup.object()
  .shape({
    url: Yup.string().url().required()
  })
  .required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

const ShortenLinkModal: React.FC<ShortenLinkModalProps> = ({ open, onClose, onSubmit }) => {
  const handleSubmit = async (values: FormSchema) => {
    track('Short Link Created', { ...values });
    return onSubmit(values.url);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Create a new short link</DialogTitle>
      <DialogContent>
        <Form
          validate={validate}
          onSubmit={handleSubmit}
          render={({ handleSubmit, submitting, submitError, submitSucceeded }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                {!submitSucceeded && (
                  <TextField
                    name="url"
                    type="url"
                    label="Destination Link"
                    placeholder="Paste your link here"
                  />
                )}

                {submitError && (
                  <Box>
                    <Typography variant="caption" color="error">
                      {submitError}
                    </Typography>
                  </Box>
                )}
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={submitting}>
                    {submitting ? 'Loading ...' : 'Create Short Link'}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ShortenLinkModal;
