import { useMemo, useState } from 'react';

export interface Paginated {
  page: number;
  limit: number;
  offset: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

export default function usePagination(
  initialLimit: number = 10,
  initialPage: number = 0
): Paginated {
  const [page, setPage] = useState<number>(initialPage);
  const [limit, setLimit] = useState<number>(initialLimit);

  const paginated = useMemo(() => {
    return {
      page,
      limit,
      offset: page * limit,
      setPage,
      setLimit
    };
  }, [limit, page]);

  return paginated;
}
