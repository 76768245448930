import React, { useState } from 'react';

// import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';

import { Workspace_Creative_Types_Enum } from 'generated/graphql';

import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import ResponsiveCreativePreviewDialog from 'components/creative/ResponsiveCreativePreviewDialog';

import { getFilePath } from 'lib/auth/hbp';

import { ShareLogicVariants } from '../../types';
import EditCreativeDrawer from '../EditCreativeDrawer';
import TagHolder, { Tag } from '../TagHolder';
import useImageCreativeCardStyles from './_styles';

interface ResponsiveCarouselCreativeCardProps {
  id: string;
  title: string;
  url?: string | null;
  date: string;
  tags?: Tag[];
  ready?: boolean;
  type: Workspace_Creative_Types_Enum.Responsive | Workspace_Creative_Types_Enum.Carousel;
  disableActionButtons?: boolean;
  attachments?: {
    path?: string | null;
    token?: string | null;
    src?: string | null;
    attachment?: any | null;
  }[];
  onRefetchRequired?: () => void;
}

export default function ResponsiveCarouselCreativeCard({
  id,
  title,
  date,
  tags,
  ready,
  attachments,
  disableActionButtons,
  type,
  onRefetchRequired
}: ResponsiveCarouselCreativeCardProps) {
  const classes = useImageCreativeCardStyles();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  // const navigate = useNavigate();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  const handleClickCard = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenEditDrawer = () => {
    setEditOpen(true);
  };

  const handleCloseEditDrawer = () => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  };

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const images =
    attachments?.map(({ src, attachment, path, token }) =>
      path ? getFilePath(path, token ?? undefined) : src ? src : getFilePath(attachment.path)
    ) ?? [];

  const pendingImage = '/videos/loading.jpg';
  const mainImage = images[0];
  const mediaImage = ready ? mainImage : pendingImage;

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={mediaImage}>
            <TagHolder tags={tags} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">{date}</Typography>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
        <CardActions>
          {type === Workspace_Creative_Types_Enum.Carousel && (
            <Tooltip title="Share Post">
              <IconButton
                aria-label="share post"
                onClick={handleOpenShareDrawer}
                disabled={disableActionButtons || !ready}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          <Tooltip style={{ marginLeft: 'auto' }} title="Edit Ad">
            <IconButton
              onClick={handleOpenEditDrawer}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <EditCreativeDrawer creativeId={id} open={editOpen} onClose={handleCloseEditDrawer} />
      <ResponsiveCreativePreviewDialog
        id={id}
        open={previewOpen}
        title={title}
        onClose={handleClosePreview}
        ignoredPlatforms={type === Workspace_Creative_Types_Enum.Carousel ? ['googleads'] : []}
      />
      {!disableActionButtons && type === Workspace_Creative_Types_Enum.Carousel && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CAROUSEL}
          creativeId={id}
          disabled={disableActionButtons}
        />
      )}
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
    </>
  );
}
