import { useContext, useEffect, useMemo, createContext, ReactNode } from 'react';

import {
  AccountInfoQuery,
  GetAvailableHeadOfficesQuery,
  WorkspaceByIdQuery,
  Workspace_Subscription_Model_Enum,
  useAccountInfoQuery,
  useGetAvailableHeadOfficesQuery,
  useWorkspaceByIdQuery
} from 'generated/graphql';

import { meHasuraContext, propertiStaffHasuraContext } from 'lib/HasuraRoleContext';

import { UserContext, UserContextType } from '.';

interface UserDataContextType extends UserContextType {
  info?: AccountInfoQuery['user'];
  workspace?: WorkspaceByIdQuery['workspace'];
  headOffices?: GetAvailableHeadOfficesQuery['head_offices'];
  stripeCustomerId?: string | null;
  subscription_model?: Workspace_Subscription_Model_Enum;
  loading: boolean;
}

export const UserDataContext = createContext<UserDataContextType>({} as any);

export function UserDataContextProvider({ children }: { children: ReactNode }) {
  const userContext = useContext(UserContext);

  const {
    data: userData,
    refetch: refetchAccountInfo,
    loading: loadingAccount
  } = useAccountInfoQuery({
    variables: {
      user_id: userContext.userId!
    },
    context: meHasuraContext,
    skip: !userContext.userId,
    fetchPolicy: 'cache-and-network'
  });

  const context = useMemo(
    () => (userContext.isAdminMode ? propertiStaffHasuraContext : meHasuraContext),
    [userContext.isAdminMode]
  );

  // Call workspace info
  const {
    data: workspaceData,
    refetch: refetchWorkspaceInfo,
    loading: loadingWorkspace
  } = useWorkspaceByIdQuery({
    variables: {
      id: userContext.activeWorkspaceId!
    },
    skip: !userContext.activeWorkspaceId,
    context: context,
    fetchPolicy: 'cache-and-network'
  });

  // Head Office Info
  const { data: headOfficeData, loading: loadingHeadOffices } = useGetAvailableHeadOfficesQuery({
    context: context,
    skip: !userContext.userId,
    fetchPolicy: 'cache-and-network'
  });

  const user = useMemo<AccountInfoQuery['user']>(() => userData?.user, [userData?.user]);
  const workspace = useMemo<WorkspaceByIdQuery['workspace']>(
    () => workspaceData?.workspace,
    [workspaceData?.workspace]
  );
  const headOffices = useMemo<GetAvailableHeadOfficesQuery['head_offices'] | undefined>(
    () => headOfficeData?.head_offices,
    [headOfficeData?.head_offices]
  );

  const stripeCustomerId = useMemo(
    () => workspace?.stripe_customer_id,
    [workspace?.stripe_customer_id]
  );
  const subscription_model = useMemo(
    () => workspace?.subscription_model,
    [workspace?.subscription_model]
  );

  useEffect(() => {
    if (refetchAccountInfo) {
      refetchAccountInfo();
    }

    if (refetchWorkspaceInfo) {
      refetchWorkspaceInfo();
    }
  }, [userContext.activeWorkspaceId, refetchAccountInfo, refetchWorkspaceInfo]);

  const loading = useMemo(
    () => loadingAccount || loadingWorkspace || loadingHeadOffices,
    [loadingAccount, loadingWorkspace, loadingHeadOffices]
  );

  const value = useMemo<UserDataContextType>(
    () => ({
      ...userContext,
      info: user,
      workspace: workspace,
      headOffices: headOffices,
      stripeCustomerId,
      subscription_model,
      loading
    }),
    [userContext, user, workspace, headOffices, stripeCustomerId, subscription_model, loading]
  );

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
}
