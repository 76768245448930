import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button, ButtonGroup, Menu, MenuItem, Tab, Tabs } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';

import { useSyncRssFeedMutation } from 'generated/graphql';

import PageHeader from 'components/PageHeader';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

import EnterpriseContentTab from './tabs/EnterpriseContent';
import RSSFeedTab from './tabs/RSSFeed';

const hashes = ['#rss', '#enterprise'];

export default function ContentFeedPage() {
  const { isWorkspaceAdmin, activeWorkspaceId } = useUserContext();
  const [currentTab, setCurrentTab] = useState(0);
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();

  const popupState = usePopupState({ variant: 'popover', popupId: 'rssFeedMenu' });

  const [syncRSSFeed] = useSyncRssFeedMutation({
    context: workspaceMemberContext
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;
    const tab = hashes.findIndex((el: string) => el === hash);

    if (tab >= 0) {
      setCurrentTab(tab);
    }
  }, [location, setCurrentTab]);

  const handleTabChange = useCallback(
    (_e: any, value: number) => {
      const hash = hashes[value];
      navigate(`/content${hash}`);
    },
    [navigate]
  );

  const handleSync = async () => {
    await syncRSSFeed({
      variables: { workspace_id: activeWorkspaceId! }
    });
    enqueueSnackbar('Sync initiated!', { variant: 'success' });
  };

  return (
    <div>
      <PageHeader
        title="Content Feeds"
        subtitle="Connect any website RSS feed to Properti. Content is updated every hour!"
        rightComponent={
          <>
            <ButtonGroup variant="contained" color="secondary" style={{ minWidth: 160 }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={!isWorkspaceAdmin}
                style={{ minWidth: 160 }}
                component={Link}
                to="/apps/rss"
              >
                Connect RSS Feed
              </Button>
              <Button size="small" {...bindTrigger(popupState)}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Menu
              {...bindMenu(popupState)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={handleSync}>Sync feed</MenuItem>
            </Menu>
          </>
        }
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}
      ></Box>
      <Tabs textColor="secondary" value={currentTab} onChange={handleTabChange}>
        <Tab label="RSS" />
        <Tab label="Enterprise Content" />
      </Tabs>
      {currentTab === 0 && <RSSFeedTab />}
      {currentTab === 1 && <EnterpriseContentTab />}
    </div>
  );
}
