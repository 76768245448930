import React, { useContext } from 'react';

import { MenuItem, Select } from '@material-ui/core';

import useLocalStorage from 'lib/hooks/useLocalStorage';

import { DatePresetOptions } from '../types';

const datePresetOptions = [
  { value: DatePresetOptions.LAST_7D, label: 'Last 7 Days' },
  { value: DatePresetOptions.LAST_28D, label: 'Last 28 Days' },
  { value: DatePresetOptions.LAST_90D, label: 'Last 90 Days' },
  { value: DatePresetOptions.THIS_MONTH, label: 'This Month' },
  { value: DatePresetOptions.LAST_MONTH, label: 'Last Month' },
  { value: DatePresetOptions.THIS_QUARTER, label: 'This Quarter' },
  { value: DatePresetOptions.LAST_QUARTER, label: 'Last Quarter' },
  { value: DatePresetOptions.THIS_YEAR, label: 'This Year' },
  { value: DatePresetOptions.LAST_YEAR, label: 'Last Year' }
];

const DATE_PRESET_KEY = 'properti_dashboard_date_preset';

type DashboardDatePresetContextType = {
  datePreset: DatePresetOptions;
  setDatePreset: React.Dispatch<string | undefined>;
};

export const DashboardDatePresetContext = React.createContext<DashboardDatePresetContextType>({
  datePreset: (localStorage.getItem(DATE_PRESET_KEY) ??
    DatePresetOptions.LAST_28D) as DatePresetOptions,
  setDatePreset: (_val?: string) => {}
});

export const DashboardDatePresetContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [datePreset, setDatePreset] = useLocalStorage(DATE_PRESET_KEY, DatePresetOptions.LAST_28D);

  return (
    <DashboardDatePresetContext.Provider
      value={{
        datePreset: datePreset as DatePresetOptions,
        setDatePreset: setDatePreset
      }}
    >
      {children}
    </DashboardDatePresetContext.Provider>
  );
};

export default function DatePresetSelect() {
  const { datePreset, setDatePreset } = useContext(DashboardDatePresetContext);

  return (
    <Select
      value={datePreset}
      onChange={(event) => setDatePreset(event.target.value as string)}
      style={{ color: '#F47D6F' }}
      disableUnderline
    >
      {datePresetOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value} dense>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}
