import { makeStyles, Theme } from '@material-ui/core/styles';

const useHeaderStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18
  },
  grow: {
    flexGrow: 1
  },
  adminSwitch: {
    color: ({ isAdminMode }: { isAdminMode: boolean }) =>
      isAdminMode ? theme.palette.primary.contrastText : 'unset'
  },
  avatar: {
    border: ({ isAdminMode }) =>
      isAdminMode ? `2px solid ${theme.palette.secondary.main}` : 'none'
  },
  avatarImg: {
    width: '40px',
    height: '40px'
  }
}));

export default useHeaderStyles;
