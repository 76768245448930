import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function PropertiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 11 11">
      <path d="M0.888916 10.0882V2.75765L5.47225 0L10.0556 2.75765V10.0882H0.888916Z" />
    </SvgIcon>
  );
}

export default PropertiIcon;
