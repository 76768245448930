import React from 'react';

import { Backdrop, BackdropProps, Typography } from '@material-ui/core';

import LoadingIndicator from 'components/LoadingIndicator';

import useBackdropStyles from './_styles';

type LoadingBackdropProps = BackdropProps & {
  title?: string;
};

const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({ title, ...backdropProps }) => {
  const classes = useBackdropStyles();

  return (
    <Backdrop className={classes.backdrop} {...backdropProps}>
      <Typography variant="h3" color="inherit" gutterBottom>
        {title}
      </Typography>
      <LoadingIndicator />
    </Backdrop>
  );
};

export default LoadingBackdrop;
