import React from 'react';

import { FormSpy } from 'react-final-form';

import { Divider, Typography } from '@material-ui/core';

import { Autocomplete, TextField, Checkboxes, CheckboxData } from 'mui-rff';

const oderOptimisedCheckboxData: CheckboxData = {
  label: 'Optimise the order?',
  value: 'order_optimised'
};
const endCardCheckboxData: CheckboxData = {
  label: 'Show end card on Facebook?',
  value: 'end_card'
};

export default function CarouselAdDetails() {
  return (
    <FormSpy subscription={{ values: true }}>
      {(props) => {
        if (props.values.items?.length > 5) {
          props.form.change('order_optimised', true);
        }
        return (
          <>
            <TextField name="creative_name" label="Creative Name" required />
            <Autocomplete
              name="tags"
              label="Creative Tags"
              placeholder="Start typing and press enter to select tags"
              multiple
              freeSolo
              options={[]}
              helperText="Start typing and press enter to select tags"
            />
            <Divider style={{ margin: '8px 0 16px' }} />
            <Typography variant="caption">Enter Ad Details</Typography>
            <TextField name="message" label="Message" required multiline minRows={6} />
            <TextField name="link" label="Link" required />
            <TextField name="caption" label="Caption" required />
            <Checkboxes
              name="order_optimised"
              data={oderOptimisedCheckboxData}
              disabled={props.values.items?.length > 5}
              helperText={
                props.values.items?.length > 5
                  ? 'Must be checked when more than 5 images are selected'
                  : undefined
              }
            />
            <Checkboxes name="end_card" data={endCardCheckboxData} />
          </>
        );
      }}
    </FormSpy>
  );
}
