import React from 'react';

import { useCampaignListingsQuery, useUpdateCampaignListingsMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import AssociatePropertyBaseDialog, { FormValues } from '../Base';

interface AssociatePropertyCampaignDialogProps {
  open: boolean;
  onClose: () => void;
  campaignId: string;
}

const AssociatePropertyCampaignDialog: React.FC<AssociatePropertyCampaignDialogProps> = ({
  open,
  onClose,
  campaignId
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data, loading, refetch } = useCampaignListingsQuery({
    variables: {
      campaign_id: campaignId
    },
    context: workspaceMemberContext
  });
  const [updateListings] = useUpdateCampaignListingsMutation({
    context: workspaceMemberContext
  });

  const handleSave = async (values: FormValues) => {
    await updateListings({
      variables: {
        campaign_id: campaignId,
        objects: values.property_ids?.map((id) => ({
          campaign_id: campaignId,
          listing_id: id
        }))
      }
    });
  };

  const initialValues = data?.listings?.map((l) => l.listing_id) ?? [];

  return (
    <AssociatePropertyBaseDialog
      open={open}
      onClose={onClose}
      loading={loading}
      initialValues={initialValues}
      onSave={handleSave}
      refetch={refetch}
    />
  );
};

export default AssociatePropertyCampaignDialog;
