import React from 'react';

import { Outlet } from 'react-router-dom';

import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';

import {
  getContent,
  getDrawerSidebar,
  getFixedScheme,
  getFooter,
  getHeader,
  getSidebarTrigger,
  Root
} from '@mui-treasury/layout';

import styled from 'styled-components';

import { useAdminModeContext } from 'lib/AdminModeContext';
import { PerfectScrollProvider } from 'lib/PerfectScrollbarContext';

import ErrorBoundary from '../ErrorBoundary';
import FooterComponent from './components/Footer';
import HeaderComponent from './components/Header';
import SidebarComponent from './components/Sidebar';
import useLayoutStyles from './styles';

const Header = getHeader(styled);
const Sidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

const scheme = getFixedScheme();

const Layout = () => {
  const { isAdminMode } = useAdminModeContext();
  const classes = useLayoutStyles({ isAdminMode });
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Root scheme={scheme} theme={theme}>
        {({ state: { sidebar } }) => (
          <>
            <Sidebar
              sidebarId="primarySidebar"
              PaperProps={{
                style: { boxShadow: 'rgba(0, 0, 0, 0.03) 4px 0px 6px', overflow: 'hidden' }
              }}
            >
              <div className={classes.sidebar}>
                <SidebarComponent collapsed={sidebar?.primarySidebar?.collapsed ?? false} />
              </div>
            </Sidebar>
            <PerfectScrollProvider>
              <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
                <Header>
                  <Toolbar
                    style={{
                      backgroundColor: isAdminMode ? theme.palette.primary.main : '#FFFFFF',
                      borderBottomWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderColor: '#EBEFF2',
                      borderStyle: 'solid'
                    }}
                  >
                    <SidebarTrigger sidebarId="primarySidebar" />
                    <HeaderComponent />
                  </Toolbar>
                </Header>
                <Content className={classes.content}>
                  <ErrorBoundary>
                    <Outlet />
                  </ErrorBoundary>
                </Content>
                <Footer className={classes.footer}>
                  <FooterComponent />
                </Footer>
              </div>
            </PerfectScrollProvider>
          </>
        )}
      </Root>
    </div>
  );
};

export default Layout;
