import React from 'react';

import { useForm, useFormState } from 'react-final-form';

import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useFacebookLeadFormPreviewQuery } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import LoadingIndicator from 'components/LoadingIndicator';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

const PreviewModal = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const form = useForm();
  const formState = useFormState({ subscription: { values: true } });

  const previewOpen = formState.values.preview_open;

  const facebookPageId = formState.values.facebook_page_id;
  const { data, loading: headerLoading } = useFacebookLeadFormPreviewQuery({
    variables: { page_id: facebookPageId },
    context: workspaceMemberContext,
    skip: !facebookPageId
  });

  const facebookPageName = data?.facebook_lead_form_preview.name ?? '';
  const facebookPicture = data?.facebook_lead_form_preview.picture?.data?.url ?? undefined;

  const handlePreviewClose = () => {
    form.change('preview_open', false);
  };

  const customerAddress = formState.values.fields.includes('STREET_ADDRESS');
  const customerMessage = formState.values.fields.includes('MESSAGE');
  const multiChoice: boolean = formState.values?.multi_choice ?? false;
  const multiChoiceQuestions = formState.values?.multi_choice_questions ?? [];

  const AddressHolder = () => {
    if (customerAddress === true) {
      return (
        <>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', color: 'black' }}> Street address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              style={{
                border: '0.5px solid grey',
                padding: '5px',
                marginRight: '40px',
                fontSize: '14px'
              }}
            >
              <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
            </Box>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  };

  const MessageHolder = () => {
    if (customerMessage) {
      return (
        <>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Enter any details or questions for us
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              style={{
                border: '0.5px solid grey',
                padding: '5px',
                marginRight: '40px',
                fontSize: '14px'
              }}
            >
              <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
            </Box>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  };

  const MultiChoiceHolder = () => {
    if (multiChoice && multiChoiceQuestions.length) {
      return (
        <>
          {multiChoiceQuestions.map((question: any) => (
            <>
              <Grid item xs={4}>
                <Typography style={{ fontSize: '14px', color: 'black' }}>
                  {question.label}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  endIcon={<ArrowDropDownIcon />}
                  disabled
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    border: '0.5px solid grey',
                    borderRadius: 0,
                    fontWeight: 'bold'
                  }}
                >
                  Select
                </Button>
              </Grid>
            </>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Dialog open={previewOpen} onClose={handlePreviewClose} fullWidth maxWidth="sm">
      <DialogTitle
        onClose={handlePreviewClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '0.5px solid black',
          width: '100%'
        }}
      >
        {headerLoading ? (
          <LoadingIndicator />
        ) : (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{ marginRight: '20px' }}>
              <img src={facebookPicture} alt="Facebook page profile" />
            </Box>
            <Box>{facebookPageName}</Box>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box style={{ width: '100%', margin: 'auto', borderBottom: '0.5px solid black' }}>
          <Box style={{ marginBottom: '30px', marginRight: '30px', marginLeft: '30px' }}>
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '70px' }}>
              <Typography variant="h4" style={{ fontSize: '18px' }}>
                {formState.values.context_title}
              </Typography>
            </Box>
            <>
              <Typography
                variant="h6"
                style={{ color: 'grey', marginTop: '30px', marginBottom: '30px', fontSize: '16px' }}
              >
                {formState.values.context_message}
              </Typography>
            </>
            <Box>
              <Typography
                variant="h6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  marginBottom: '30px',
                  fontSize: '16px'
                }}
              >
                Fill out your details below
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Full name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ border: '0.5px solid grey', padding: '5px', marginRight: '40px' }}>
                    <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ border: '0.5px solid grey', padding: '5px', marginRight: '40px' }}>
                    <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Phone number</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      endIcon={<ArrowDropDownIcon />}
                      disabled
                      style={{
                        width: '35%',
                        fontWeight: 'bold',
                        border: '0.5px solid grey',
                        borderRadius: 0,
                        color: 'black',
                        fontSize: '14px'
                      }}
                    >
                      AU +61
                    </Button>
                    <Box
                      style={{
                        border: '0.5px solid grey',
                        padding: '5px',
                        marginRight: '15px',
                        width: '55%'
                      }}
                    >
                      <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                    </Box>
                  </Box>
                </Grid>
                <AddressHolder />
                <MessageHolder />
                <MultiChoiceHolder />
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
          <Button
            disabled
            style={{
              backgroundColor: '#e4e4e4',
              borderRadius: 0,
              marginRight: '5px',
              color: 'black',
              border: '0.5px solid grey',
              height: '30px',
              fontWeight: 'bold'
            }}
          >
            Cancel
          </Button>
          <Button
            disabled
            style={{
              backgroundColor: '#3b5998',
              borderRadius: 0,
              color: 'white',
              border: '0.5px solid grey',
              height: '30px',
              fontWeight: 'bold'
            }}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;
