import React from 'react';

import { Form } from 'react-final-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import qs from 'query-string';
import * as Yup from 'yup';

import { useLogger } from 'lib/LoggerContext';
import { APP_CONFIG } from 'lib/app/config';
import { auth } from 'lib/auth/hbp';
import { planFromPlanId } from 'lib/subscription/plans';

import useAuthStyles from '../_styles';

const schema = Yup.object()
  .shape({
    first_name: Yup.string().trim().required().label('First name'),
    last_name: Yup.string().trim().required().label('Last name'),
    email: Yup.string().trim().email().required().label('Email address'),
    password: Yup.string().required().label('Password')
  })
  .required();

type FormSchema = Yup.InferType<typeof schema>;

const validate = makeValidate(schema);

const formFields: React.ReactNode[] = [
  <TextField style={{ height: 60 }} name="first_name" label="First Name" />,
  <TextField style={{ height: 60 }} name="last_name" label="Last Name" />,
  <TextField style={{ height: 60 }} name="email" label="Email" type="email" autoComplete="email" />,
  <TextField style={{ height: 60 }} name="password" label="Password" type="password" />
];

const RegisterPage = () => {
  const classes = useAuthStyles();
  const logger = useLogger();
  const location = useLocation();
  const navigate = useNavigate();

  const { email, plan: planId } = qs.parse(location.search);

  const plan = planFromPlanId(planId as unknown as string | undefined);

  const handleAttemptLogin = async (email: string, password: string) => {
    try {
      await auth.login(email, password);
      return true;
    } catch (error) {
      return false;
    }
  };

  const navigateAfterRegister = () => {
    // If we came from website plan page (query param) redirect to create workspace page
    let redirect = '/workspaces';
    if (plan) {
      redirect = `/workspaces/create?plan=${plan.id}`;
    }
    navigate(redirect);
  };

  const handleRegister = async (values: FormSchema) => {
    try {
      logger.info('User Registration', { meta: { email: values.email } });

      // Register
      await auth.register(values.email, values.password, {
        display_name: `${values.first_name} ${values.last_name}`
      });

      // Can auto sign-in
      await auth.login(values.email, values.password);

      navigateAfterRegister();
    } catch (error: any) {
      let message = 'Unknown Error Occurred';
      if (error.isAxiosError) {
        message = error?.response?.data?.message ?? 'Unknown Error Occurred';

        if (message.includes('Account already exists')) {
          const canLogin = await handleAttemptLogin(values.email, values.password);
          if (canLogin) {
            navigateAfterRegister();
          }
        }
      }
      return { [FORM_ERROR]: message };
    }
  };

  return (
    <div className={classes.content}>
      {plan && (
        <Paper elevation={1} className={classes.progressBox}>
          <Box width="100%" textAlign="center" py={2}>
            <Typography variant="subtitle1" gutterBottom>
              You have chosen the <strong>{plan.name} plan</strong>, just a few steps required to
              get started.
            </Typography>
          </Box>
          <Box width="100%">
            <Stepper activeStep={0} alternativeLabel>
              {['Create account', 'Create workspace', 'Review & confirm subscription'].map(
                (label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </Paper>
      )}

      <Paper elevation={1} className={classes.box}>
        <img
          className={classes.logo}
          src={APP_CONFIG.SITE_LOGO_URL_WIDE}
          alt=""
          style={{ width: 160 }}
        />
        <div className={classes.formWrapper}>
          <Form
            initialValues={{
              email: email
            }}
            onSubmit={handleRegister}
            validate={validate}
            render={({ handleSubmit, submitError, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Create your account
                  </Typography>
                  <Typography gutterBottom>
                    Dominate your Digital Patch - Real Estate Social Marketing.
                  </Typography>
                  <Grid container spacing={1}>
                    {formFields.map((item, idx) => {
                      const sm = idx < 2 ? 6 : 12;
                      return (
                        <Grid key={idx} item xs={12} sm={sm}>
                          {item}
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={submitting}
                  >
                    {submitting ? 'Loading ...' : "Let's get started"}
                  </Button>
                  <Typography className={classes.buttonCaption} variant="caption" gutterBottom>
                    By registering you agree to {APP_CONFIG.SITE_TITLE}'s{' '}
                    <MuiLink
                      href={APP_CONFIG.SITE_TERMS_URL}
                      color="secondary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </MuiLink>{' '}
                    and its{' '}
                    <MuiLink
                      href={APP_CONFIG.SITE_PRIVACY_URL}
                      color="secondary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </MuiLink>
                    .
                  </Typography>
                  {submitError && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography color="error" variant="subtitle2">
                          {submitError}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </form>
              );
            }}
          />
        </div>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Grid className={classes.extraItem} item xs>
            <Typography variant="body2" gutterBottom>
              Already have an account?{' '}
              <MuiLink component={Link} color="secondary" to="/login">
                Sign In
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default RegisterPage;
