import React from 'react';

import AssociatePropertyCampaignDialog from './Campaign';
import AssociatePropertyLinkDialog from './Link';
import AssociatePropertyPostDialog from './Post';

interface AssociatePropertyDialogBaseProps {
  open: boolean;
  onClose: () => void;
}

interface AssociatePropertyCampaignDialogProps extends AssociatePropertyDialogBaseProps {
  variant: 'campaign';
  campaignId: string;
  postId?: never;
  linkId?: never;
}

interface AssociatePropertyPostDialogProps extends AssociatePropertyDialogBaseProps {
  variant: 'post';
  campaignId?: never;
  postId: string;
  linkId?: never;
}

interface AssociatePropertyLinkDialogProps extends AssociatePropertyDialogBaseProps {
  variant: 'link';
  campaignId?: never;
  postId?: never;
  linkId: string;
}

export type AssociatePropertyDialogProps =
  | AssociatePropertyCampaignDialogProps
  | AssociatePropertyPostDialogProps
  | AssociatePropertyLinkDialogProps;

const AssociatePropertyDialog: React.FC<AssociatePropertyDialogProps> = ({
  variant,
  open,
  onClose,
  campaignId,
  postId,
  linkId
}) => {
  if (variant === 'campaign' && campaignId) {
    return (
      <AssociatePropertyCampaignDialog open={open} onClose={onClose} campaignId={campaignId} />
    );
  }

  if (variant === 'post' && postId) {
    return <AssociatePropertyPostDialog open={open} onClose={onClose} postId={postId} />;
  }

  if (variant === 'link' && linkId) {
    return <AssociatePropertyLinkDialog open={open} onClose={onClose} linkId={linkId} />;
  }

  return null;
};

export default AssociatePropertyDialog;
