import { useContext } from 'react';

import { RocketiumContext } from 'lib/RocketiumContext';

const useRocketiumContext = () => {
  const rc = useContext(RocketiumContext);
  const rfsdk = (window as any).rfsdk;

  return {
    ready: rc.ready,
    enabled: rc.enabled,
    rfsdk: rfsdk
  };
};

export default useRocketiumContext;
