import React from 'react';

import Chip from '@material-ui/core/Chip';
import useTagHolderStyles from './_styles';

export interface Tag {
  tag: string;
}

export interface TagHolderProps {
  tags?: Tag[];
}

export default function TagHolder({ tags }: TagHolderProps) {
  const classes = useTagHolderStyles();

  return (
    <div className={classes.tags}>
      <div className={classes.tagHolder}>
        {tags?.map((tag) => (
          <Chip key={tag.tag} label={tag.tag} size="small" color="primary" />
        ))}
      </div>
    </div>
  );
}
