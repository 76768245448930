import React, { Fragment, useCallback, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { useListingByIdQuery } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';

import ConfirmContext from './ListingImageSelectContext';

interface ListingImageSelectProviderProps {
  children: React.ReactNode;
}

interface Options {
  listingId: string;
  selectedImageId: string[];
}

interface Image {
  id: string;
  url: string;
}

const ListingImageSelectProvider: React.FC<ListingImageSelectProviderProps> = ({ children }) => {
  const [options, setOptions] = useState<Options>();
  const [resolveReject, setResolveReject] = useState<any>([]);
  const [resolve, reject] = resolveReject;

  const selectImages = useCallback((options: Options) => {
    return new Promise((resolve, reject) => {
      setOptions(options);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => {
    reject([]);
    setResolveReject([]);
  }, [reject, setResolveReject]);

  const handleConfirm = useCallback(
    (image: Image) => {
      resolve(image);
      handleClose();
    },
    [resolve, handleClose]
  );

  return (
    <Fragment>
      <ConfirmContext.Provider value={selectImages}>{children}</ConfirmContext.Provider>
      <ImageSelectDialog
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default ListingImageSelectProvider;

interface ImageSelectDialogProps {
  open: boolean;
  options?: Options;
  onClose: () => void;
  onConfirm: (image: Image) => void;
}

const ImageSelectDialog: React.FC<ImageSelectDialogProps> = ({
  open,
  options,
  onClose,
  onConfirm
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { data, loading } = useListingByIdQuery({
    variables: {
      id: options?.listingId!
    },
    context: workspaceMemberContext,
    skip: !options?.listingId
  });

  const images = data?.listing?.images?.filter((img) => !options?.selectedImageId.includes(img.id));

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>Select Listing Image</DialogTitle>
      {loading && <CircularProgress />}
      <Box
        p={1}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around'
        }}
      >
        <GridList cols={3} cellHeight={220}>
          {images?.map((image) => (
            <GridListTile key={image.id} cols={1}>
              <Button
                onClick={() =>
                  onConfirm({
                    id: image.id,
                    url: image.image.url ?? getFilePath(image.image.path, image.image.token)!
                  })
                }
              >
                <img
                  src={image.image.url ?? getFilePath(image.image.path, image.image.token)!}
                  style={{ maxHeight: 220 }}
                  alt=""
                />
              </Button>
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </Dialog>
  );
};
