import { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import MaterialTable from '@material-table/core';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  useDeleteCustomAudienceMutation,
  useWorkspaceAudiencesQuery,
  Workspace_Audience_Status_Enum,
  Workspace_Audiences_Bool_Exp
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import TooltipComponent from 'components/Tooltip';
import PresentationAudienceIcon from 'components/icons/PresentationAudience';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';
import { track } from 'lib/utils/track';

const AudiencesPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceCreator, isWorkspaceAgent } =
    useUserContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const where: Workspace_Audiences_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      created_by_id: { _eq: userId }
    });
  }

  const { data, loading, refetch } = useWorkspaceAudiencesQuery({
    variables: {
      where: where,
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext,
    skip: !activeWorkspaceId,
    pollInterval: 120000
  });
  const [deleteAudience] = useDeleteCustomAudienceMutation();

  const handleOnRowDelete = async (row: any) => {
    const id = row.id;
    try {
      track('Delete Audience', { ...row });
      const resp = await deleteAudience({
        variables: {
          id
        },
        context: workspaceMemberContext
      });

      if (!resp.data?.deleteCustomAudience?.deleted) {
        throw new Error('Failed to delete audience');
      } else {
        refetch();
        enqueueSnackbar('Audience deleted', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to delete audience', { variant: 'error' });
    }
  };

  const count = data?.aggregate?.aggregate?.count;

  if (!loading && count === 0) {
    return (
      <EmptyStatePage
        title="Audiences"
        icon={
          <PresentationAudienceIcon
            color="secondary"
            fill="none"
            style={{ height: 64, width: 64 }}
          />
        }
        text="Build custom audiences to use in your campaigns by segmenting you data sources."
        button={
          <Button
            component={Link}
            to="/audiences/create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceAnalyst}
            fullWidth
          >
            Create Audience
          </Button>
        }
      />
    );
  }

  // https://github.com/mbrn/material-table/issues/1979
  const audiences = data?.audiences?.map((a) => ({ ...a })) ?? [];

  const tooltipHeading = 'Unavailable';
  const tooltipText = 'Due to privacy controls, Facebook does not disclose custom audience sizes.';

  return (
    <div>
      <PageHeader
        title="Audiences"
        rightComponent={
          <Button
            component={Link}
            to="/audiences/create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceAnalyst || isWorkspaceCreator}
            style={{ minWidth: 160 }}
          >
            Create Audience
          </Button>
        }
      />

      <MaterialTable
        title="Audiences"
        columns={[
          { title: 'Audience Name', field: 'name', editable: 'never' },
          {
            title: 'Source',
            field: 'source',
            editable: 'never',
            render: (data) => {
              if (data.source === 'app') {
                if (data.app === 'agentbox') {
                  return 'Agentbox';
                }
                return data.app ?? 'App';
              } else if (data.source === 'customer-list') {
                return 'Customer List';
              } else if (data.source === 'website-traffic') {
                return 'Website Traffic';
              } else if (data.source === 'import') {
                return 'Imported';
              } else if (data.source === 'leads') {
                return 'Properti Leads';
              } else {
                return data.source;
              }
            }
          },
          {
            title: 'Status',
            field: 'status',
            editable: 'never',
            render: (data) => {
              if (data.status === Workspace_Audience_Status_Enum.Created) {
                return <StatusIndicator status={Status.ACTIVE} text="Created" />;
              } else if (data.status === Workspace_Audience_Status_Enum.Creating) {
                return <StatusIndicator status={Status.PENDING} text="Creating" />;
              } else if (data.status === Workspace_Audience_Status_Enum.Error) {
                return <StatusIndicator status={Status.ERROR} text="Error" />;
              }
              return data.status;
            }
          },
          {
            title: 'Created',
            field: 'created_at',
            render: (data) => moment(data?.created_at).fromNow(),
            editable: 'never'
          }
        ]}
        detailPanel={(rowData: any) => (
          <Box margin={1}>
            <Table size="small" aria-label="lookalikes">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1} />
                  <TableCell component="th">Name</TableCell>
                  <TableCell component="th">Type</TableCell>
                  <TableCell component="th">Audience Size</TableCell>
                  <TableCell component="th">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1} />
                  <TableCell component="th" scope="row">
                    {rowData?.facebook?.name}
                  </TableCell>
                  <TableCell>Custom</TableCell>
                  <TableCell>
                    {rowData.facebook?.info?.approximate_count_lower_bound === -1 ? (
                      <TooltipComponent heading={tooltipHeading} tooltip={tooltipText} />
                    ) : (
                      `${numbro(rowData.facebook?.info?.approximate_count_lower_bound).format({
                        thousandSeparated: true
                      })} to ${numbro(rowData.facebook?.info?.approximate_count_upper_bound).format(
                        {
                          thousandSeparated: true
                        }
                      )}`
                    )}
                  </TableCell>
                  <TableCell>
                    {rowData.facebook?.info?.delivery_status.code === 200 ? (
                      <StatusIndicator status={Status.ACTIVE} />
                    ) : (
                      rowData.facebook?.info?.delivery_status.description
                    )}
                  </TableCell>
                </TableRow>
                {rowData?.facebook?.lookalikes?.map((lookalike: any) => (
                  <TableRow key={lookalike.id}>
                    <TableCell colSpan={1} />
                    <TableCell component="th" scope="row">
                      {lookalike.name}
                    </TableCell>
                    <TableCell>Lookalike</TableCell>
                    <TableCell>
                      {numbro(lookalike.info?.approximate_count_lower_bound).format({
                        thousandSeparated: true
                      })}{' '}
                      to{' '}
                      {numbro(lookalike.info?.approximate_count_upper_bound).format({
                        thousandSeparated: true
                      })}
                    </TableCell>
                    <TableCell>
                      {lookalike.info?.delivery_status.code === 200 ? (
                        <StatusIndicator status={Status.ACTIVE} />
                      ) : (
                        lookalike.info?.delivery_status.description
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
        actions={[
          (rowData) => ({
            icon: BullhornIcon,
            tooltip: 'Create Campaign',
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            onClick: () => {
              navigate(`/campaigns/create`, {
                state: {
                  from_audience_id: rowData.id
                }
              });
            }
          }),
          (rowData) => ({
            icon: 'group_add',
            tooltip: 'Create Lookalike Audience',
            disabled: isWorkspaceAnalyst || isWorkspaceCreator,
            onClick: () => {
              navigate(`create/lookalike?id=${rowData.id}`);
            }
          })
          // (rowData) => ({
          //   icon: 'refresh',
          //   tooltip: 'Refresh Audience',
          //   onClick: () => {},
          //   disabled: rowData.source !== 'app',
          //   hidden: rowData.source !== 'app'
          // })
        ]}
        data={audiences}
        totalCount={count ?? 0}
        page={page}
        isLoading={loading}
        onRowClick={(_event, _rowData, togglePanel) => togglePanel!()}
        options={{
          search: false,
          toolbar: false,
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          pageSize: limit
        }}
        onRowsPerPageChange={(rowsPerPage) => setLimit(rowsPerPage)}
        onPageChange={(newPage) => setPage(newPage)}
        editable={{
          isDeletable: () => !isWorkspaceAnalyst && !isWorkspaceCreator,
          onRowDelete: handleOnRowDelete
        }}
        components={{
          Container: TableContainer
        }}
      />
    </div>
  );
};

export default AudiencesPage;
