import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

import FacebookLogo from 'mdi-material-ui/Facebook';

interface FacebookAvatarProps extends AvatarProps {
  disabled?: boolean;
}

export default function FacebookAvatar(props: FacebookAvatarProps) {
  const { style, disabled, ...other } = props;

  const backgroundColor = disabled ? '#808080' : '#1877F2';

  return (
    <Avatar style={{ backgroundColor, ...style }} {...other}>
      <FacebookLogo />
    </Avatar>
  );
}
