import React from 'react';

import Carousel from 'react-material-ui-carousel';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitle from 'components/DialogTitle';

interface ImageCreativePreviewDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  imageUrls: string[];
}

interface ImageCarouselProps extends Pick<ImageCreativePreviewDialogProps, 'imageUrls' | 'title'> {
  width?: number;
  minHeight?: number;
}

export function ImageCarousel({
  imageUrls,
  title,
  minHeight = 500,
  width = 720
}: ImageCarouselProps) {
  if (imageUrls.length === 0) {
    return null;
  }
  return (
    <Carousel animation="slide" navButtonsAlwaysVisible>
      {imageUrls.map((url) => (
        <div key={url} style={{ marginLeft: 'auto', marginRight: 'auto', width, minHeight }}>
          <img key={url} src={url} alt={title} width={width} />
        </div>
      ))}
    </Carousel>
  );
}

export default function ImageCreativePreviewDialog({
  open,
  title,
  onClose,
  imageUrls
}: ImageCreativePreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 720 }}>
          <ImageCarousel imageUrls={imageUrls} title={title} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
