import React from 'react';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitle from 'components/DialogTitle';

interface CreativePreviewDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  src?: string;
}

const CreativePreviewDialog: React.FC<CreativePreviewDialogProps> = ({
  open,
  handleClose,
  title,
  src
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%', overflowX: 'hidden' }}
        >
          {src && (
            <video id="createVideoPlayer" height={460} autoPlay={true} controls>
              <source src={src} type="video/mp4" />
            </video>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreativePreviewDialog;
