import React from 'react';

import Button from '@material-ui/core/Button';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import { Logger } from 'logdna';

import EmptyStatePage from 'components/EmptyStatePage';

import { LoggerContext } from 'lib/LoggerContext';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

interface ErrorBoundaryProps {
  /**
   * Overwrite the title for the EmptyStatePage
   */
  title?: string;
  /**
   * Overwrite the text for the EmptyStatePage
   */
  text?: string;
  /**
   * Overwrite the icon for the EmptyStatePage
   */
  icon?: React.ReactNode;
  /**
   * The button to pass to the EmptyStatePage
   */
  button?: React.ReactNode;
  /**
   * Render your own error page. Receives the title, text and icon props plus the Error
   */
  renderError?: (
    props: Required<
      Omit<ErrorBoundaryProps, 'renderError' | 'errorHandler' | 'onError'> & { error: Error }
    >
  ) => JSX.Element;

  /**
   * Called instead of the default error handler. Not called on ChunkLoadError
   */
  errorHandler?: (error: Error, errorInfo: React.ErrorInfo) => void;

  /**
   * Called anytime there is an error. Not called on ChunkLoadError
   */
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

// This can now be used to wrap individual components as well to prevent the whole app crashing, and improve error handling.
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: ErrorBoundaryState;
  static contextType = LoggerContext;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { errorHandler, onError } = this.props;
    console.error('==== START ERROR INFO =====');
    console.error(error);
    console.error(errorInfo);
    console.error('==== END ERROR INFO =====');

    if (error?.name !== 'ChunkLoadError') {
      if (onError) {
        onError(error, errorInfo);
      }
      if (errorHandler) {
        errorHandler(error, errorInfo);
      } else {
        const logger: Logger = this?.context?.logger;
        logger?.error('React Error', {
          meta: {
            location: window.location,
            name: error?.name,
            message: error?.message ?? 'Unknown React Error',
            errorInfo
          }
        });
      }
    }

    // if (error.name === 'ChunkLoadError') {
    //   window.location.reload();
    // }
  }

  render() {
    const { hasError, error } = this.state;
    let {
      icon = <SentimentVeryDissatisfiedIcon color="secondary" style={{ width: 64, height: 64 }} />,
      text = 'Your error has been logged with our tech team',
      title = 'Whoops, something went wrong!',
      button = (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window?.location?.reload()}
          size="large"
          fullWidth
        >
          Refresh
        </Button>
      )
    } = this.props;

    const { renderError, children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI

      if (error?.name === 'ChunkLoadError') {
        title = 'New update ready!';
        text = 'Wohoo, refresh your browser to see the latest version';
        icon = <NewReleasesIcon color="secondary" style={{ width: 64, height: 64 }} />;
      }

      if (renderError) {
        return renderError({ icon, title, text, error: error!, button });
      }

      return <EmptyStatePage title={title} text={text} icon={icon} button={button} />;
    }

    return children;
  }
}

export default ErrorBoundary;
