import React, { useEffect, useRef } from 'react';

import { Field, Form } from 'react-final-form';

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { Workspace_Creative_Types_Enum } from 'generated/graphql';

import FinalFormAutoSave from 'components/forms/FinalFormAutoSave';
import FinalFormCondition from 'components/forms/FinalFormCondition';
import FinalFormContentTypeSelect from 'components/forms/FinalFormContentTypeSelect';
import FinalFormPlatformButton from 'components/forms/FinalFormPlatformButton';
import FinalFormShareInput from 'components/forms/FinalFormShareInput';

import useListingImageSelect from 'lib/ListingContext/useListingImageSelect';
import { logger } from 'lib/LoggerContext';
import { getFilePath } from 'lib/auth/hbp';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';
import { track } from 'lib/utils/track';

import { ShareLogicVariants } from '../types';
import useShareLogic, { shareDrawerDecorator, FormSchema, validate } from './hook';

interface ShareDrawerBaseProps {
  open: boolean;
  onClose: () => void;
  timestamp?: string;
  title?: string;
  disabled?: boolean;
  draftPostId?: string | null;
}

export interface ShareDrawerCreativeProps extends ShareDrawerBaseProps {
  variant: ShareLogicVariants.CREATIVE | ShareLogicVariants.CAROUSEL;
  creativeId: string;
  listingId?: string;
  rssContentId?: never;
  realShortzId?: never;
}

export interface ShareDrawerUploadProps extends ShareDrawerBaseProps {
  variant: ShareLogicVariants.UPLOAD;
  creativeId?: never;
  listingId?: never;
  rssContentId?: never;
  realShortzId?: never;
}

export interface ShareDrawerListingProps extends ShareDrawerBaseProps {
  variant: ShareLogicVariants.LISTING;
  listingId?: string;
  creativeId?: never;
  rssContentId?: never;
  realShortzId?: never;
}

export interface ShareDrawerRSSContentProps extends ShareDrawerBaseProps {
  variant: ShareLogicVariants.CONTENT_RSS;
  listingId?: never;
  creativeId?: never;
  rssContentId: string;
  realShortzId?: never;
}
export interface ShareDrawerRealShortzProps extends ShareDrawerBaseProps {
  variant: ShareLogicVariants.REALSHORTZ;
  listingId?: never;
  creativeId?: never;
  rssContentId?: never;
  realShortzId: string;
}

export type ShareDrawerProps =
  | ShareDrawerCreativeProps
  | ShareDrawerUploadProps
  | ShareDrawerListingProps
  | ShareDrawerRSSContentProps
  | ShareDrawerRealShortzProps;

const ShareDrawer: React.FC<ShareDrawerProps> = ({
  open,
  onClose,
  timestamp,
  variant,
  creativeId,
  listingId,
  rssContentId,
  realShortzId,
  draftPostId,
  disabled = false,
  title = 'New Social Post'
}) => {
  const { info, activeWorkspaceId } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const draft = useRef<FormSchema>();
  const [availablePlatforms, platformsLoading, platformsMeta] = usePlatforms();
  const {
    initialValues,
    handleFormSubmit,
    handleSaveDraft,
    draftVariant,
    loading: logicLoading,
    savingDraft,
    creative
  } = useShareLogic({
    title: title,
    variant: variant,
    listingId: listingId,
    creativeId: creativeId,
    rssContentId: rssContentId,
    realShortzId: realShortzId,
    disabled: disabled,
    draftPostId: draftPostId
  });
  const selectImage = useListingImageSelect();

  const loading = platformsLoading || logicLoading;

  const shareVariant = draftVariant ?? variant;
  const shareListingId = initialValues?.listingId;
  const shareCreativeId = initialValues?.creativeId;

  useEffect(() => {
    if (open) {
      track('Share Drawer Open', {
        shareVariant
      });
    }
  }, [open, shareVariant]);

  if (disabled) {
    return null;
  }

  const handleSubmit = async (values: FormSchema) => {
    try {
      logger.debug('Submitting post', {
        meta: {
          ...values
        }
      });
    } catch (error) {
      console.warn('Unable to send logs');
    }

    try {
      await handleFormSubmit(values);

      const message = 'Post scheduled';
      enqueueSnackbar(message, { variant: 'success' });
      onClose();
    } catch (error: any) {
      logger.debug('Unable to submit post', error);
      logger.error('Unable to submit post', {
        meta: {
          ...values
        }
      });
      return {
        [FORM_ERROR]: error?.message ?? 'Unknown Error'
      };
    }
  };

  const creatorAvatarSrc = platformsMeta?.linkedinPersonal?.userIsConnected
    ? getFilePath(info?.avatar_url, info?.avatar_token)
    : undefined;

  let submit: any;
  const handleSubmitBtn = () => {
    if (submit) {
      submit();
    }
  };

  const handleSaveDraftBtn = async () => {
    try {
      await handleSaveDraft(draft.current as FormSchema);
      const message = 'Draft Saved';
      enqueueSnackbar(message, { variant: 'success' });
      onClose();
    } catch (error) {
      const message = 'Unable to save draft';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleAddImage = shareListingId
    ? async (currentSelectIds: string[]) => {
        if (!shareListingId) {
          return;
        }

        return selectImage({
          listingId: shareListingId,
          selectedImageId: currentSelectIds
        });
      }
    : undefined;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Container>
        <Top>
          <Typography variant="h5">{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Top>
        {loading && (
          <Middle>
            <CircularProgress />
          </Middle>
        )}
        {!loading && (
          <Form
            validate={validate}
            // @ts-ignore
            decorators={[shareDrawerDecorator]}
            initialValues={{
              defaults: initialValues.defaults,
              facebook: initialValues.facebook,
              linkedin: initialValues.linkedin,
              linkedin_personal: initialValues.linkedin_personal,
              youtube: initialValues.youtube,
              instagram: initialValues.instagram,
              gmb: initialValues.gmb,
              creative_id: shareCreativeId,
              listing_id: shareListingId,
              rss_content_item_id: rssContentId,
              schedule: 'schedule',
              schedule_timestamp: timestamp ?? initialValues.schedule_timestamp,
              platforms: initialValues.platforms,
              variant: shareVariant,
              post_name: initialValues.post_name,
              content_type: initialValues.content_type ?? 'Other'
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitting, submitError, hasValidationErrors }) => {
              submit = handleSubmit;
              if (submitting || savingDraft) {
                return (
                  <Middle>
                    <CircularProgress />
                  </Middle>
                );
              }

              return (
                <>
                  <Middle>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <FinalFormAutoSave valueRef={draft} />
                        <Field name="platforms" subscription={{ value: true }}>
                          {({ input: { value } }) => {
                            // ap -> Active Platform. The currently active platform.
                            return value?.map((ap: string) => {
                              const hideAttachments = ['carousel'].includes(shareVariant);
                              const hideUrl =
                                ['creative', 'carousel'].includes(shareVariant) ||
                                ['youtube', 'instagram'].includes(ap);

                              const hideAIEnhancer = [
                                ShareLogicVariants.CAROUSEL,
                                ShareLogicVariants.REALSHORTZ
                              ].includes(shareVariant);

                              const hideShortLink =
                                ['instagram'].includes(ap) || ['carousel'].includes(shareVariant);

                              let filesLimit: number | undefined = undefined;
                              if (['youtube', 'gmb'].includes(ap)) {
                                filesLimit = 1;
                              }

                              let acceptedFiles: string[] | undefined = [
                                'image/*',
                                'image/heic',
                                'video/*'
                              ];
                              if (ap === 'youtube') {
                                acceptedFiles = ['video/*'];
                              } else if (ap === 'linkedin_personal') {
                                acceptedFiles = ['image/*', 'image/heic', 'video/mp4'];
                              } else if (ap === 'instagram') {
                                acceptedFiles = [
                                  'image/jpg',
                                  'image/jpeg',
                                  'image/png',
                                  'image/heic',
                                  'video/mov',
                                  'video/mp4'
                                ];
                              } else if (ap === 'gmb') {
                                acceptedFiles = ['image/*', 'image/heic'];
                              }

                              const showPostingFromOption =
                                ap === 'linkedin_personal' ||
                                (ap === 'facebook' &&
                                  Boolean(platformsMeta?.facebook?.allowedPages)) ||
                                (ap === 'instagram' &&
                                  Boolean(platformsMeta?.instagram?.allowedPages)) ||
                                (ap === 'linkedin' &&
                                  Boolean(platformsMeta?.linkedin?.allowedPages)) ||
                                (ap === 'gmb' && Boolean(platformsMeta?.gmb?.allowedLocations));

                              const postingFromOptions =
                                ap === 'linkedin_personal'
                                  ? platformsMeta?.linkedinPersonal?.users ?? []
                                  : ap === 'facebook'
                                  ? platformsMeta?.facebook?.allowedPages
                                  : ap === 'linkedin'
                                  ? platformsMeta?.linkedin?.allowedPages
                                  : ap === 'instagram'
                                  ? platformsMeta?.instagram?.allowedPages
                                  : ap === 'gmb'
                                  ? platformsMeta?.gmb?.allowedLocations
                                  : undefined;

                              return (
                                <Box key={ap}>
                                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Field name={ap} subscription={{ value: true }}>
                                      {({ input: { value } }) => {
                                        const postingFrom = value?.posting_from;
                                        const postingFromAvatarSrc = getFilePath(
                                          postingFrom?.avatar_url,
                                          postingFrom?.avatar_token
                                        );

                                        return (
                                          <FinalFormPlatformButton
                                            platform={ap}
                                            creatorAvatarUrl={
                                              postingFromAvatarSrc ?? creatorAvatarSrc
                                            }
                                            showRemoveButton
                                          />
                                        );
                                      }}
                                    </Field>

                                    <div style={{ flexGrow: 1 }}>
                                      <FinalFormShareInput
                                        workspaceId={activeWorkspaceId!}
                                        baseFileUploadPath={`workspace/${activeWorkspaceId}/posts/uploads`}
                                        name={ap}
                                        platform={ap as any}
                                        hideAttachments={hideAttachments}
                                        disableAttachments={
                                          shareVariant === ShareLogicVariants.CAROUSEL
                                        }
                                        hideUrl={hideUrl}
                                        hideShortLink={hideShortLink}
                                        hideAIEnhancer={hideAIEnhancer}
                                        filesLimit={filesLimit}
                                        uppyRestrictions={{
                                          maxNumberOfFiles: filesLimit,
                                          allowedFileTypes: acceptedFiles
                                        }}
                                        uppyNote="Image max size 30MB. Video max size 100MB."
                                        maxMessageLength={2000}
                                        onAddImage={handleAddImage}
                                        rows={10}
                                        listingId={listingId}
                                        showPostingFromOption={showPostingFromOption}
                                        postingFromOptions={postingFromOptions}
                                      />
                                    </div>
                                  </div>
                                </Box>
                              );
                            });
                          }}
                        </Field>
                      </div>
                      <div>
                        <FinalFormCondition when="platforms" isEmpty>
                          <Typography variant="subtitle1">Where would you like to post?</Typography>
                        </FinalFormCondition>
                        <FinalFormCondition when="platforms" isNotEmpty>
                          <Typography variant="subtitle1">Post to another platform?</Typography>
                        </FinalFormCondition>
                        {availablePlatforms?.map((ap) => {
                          if (
                            [ShareLogicVariants.LISTING, ShareLogicVariants.CONTENT_RSS].includes(
                              shareVariant
                            ) &&
                            ap === 'youtube'
                          ) {
                            return undefined;
                          } else if (
                            [
                              Workspace_Creative_Types_Enum.Image,
                              Workspace_Creative_Types_Enum.Manual
                            ].includes(creative?.type!) &&
                            ap === 'youtube'
                          ) {
                            return undefined;
                          } else if (
                            ([
                              Workspace_Creative_Types_Enum.Video,
                              Workspace_Creative_Types_Enum.ManualVideo
                            ].includes(creative?.type!) ||
                              [ShareLogicVariants.REALSHORTZ].includes(shareVariant)) &&
                            ['gmb'].includes(ap)
                          ) {
                            return undefined;
                          } else if (
                            creative?.type === Workspace_Creative_Types_Enum.Carousel &&
                            !['facebook', 'instagram'].includes(ap)
                          ) {
                            return undefined;
                          }

                          return (
                            <FinalFormCondition key={ap} when="platforms" excludes={ap}>
                              <FinalFormPlatformButton
                                platform={ap}
                                creatorAvatarUrl={creatorAvatarSrc}
                              />
                            </FinalFormCondition>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          paddingTop: 8,
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box width={400}>
                          <FinalFormContentTypeSelect />
                        </Box>
                      </div>
                      <div style={{ paddingTop: 8 }}>
                        <Typography variant="subtitle1">
                          When would you like to schedule this post?
                        </Typography>
                        <ScheduleHolder>
                          <Box width={400}>
                            <TextField
                              name="schedule_timestamp"
                              label="Schedule your post"
                              type="datetime-local"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Box>
                        </ScheduleHolder>
                      </div>
                      <div style={{ paddingTop: 8 }}>
                        <Typography variant="subtitle1">
                          Would you like to name this post?
                        </Typography>
                        <ScheduleHolder>
                          <Box width={400}>
                            <TextField
                              name="post_name"
                              label="Post name"
                              placeholder="Give your post a rememberable name?"
                              helperText="Use this name to help track and find posts"
                              autoComplete="off"
                            />
                          </Box>
                        </ScheduleHolder>
                      </div>
                      {submitError && (
                        <Typography variant="caption" color="error">
                          {submitError}
                        </Typography>
                      )}
                    </form>
                  </Middle>
                  <Bottom>
                    <ButtonGroup>
                      <Button
                        variant="text"
                        size="large"
                        onClick={handleSaveDraftBtn}
                        disabled={savingDraft || submitting}
                      >
                        Save Draft
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSubmitBtn}
                        disabled={savingDraft || submitting || hasValidationErrors}
                      >
                        Schedule Post
                      </Button>
                    </ButtonGroup>
                  </Bottom>
                </>
              );
            }}
          />
        )}
      </Container>
    </Drawer>
  );
};

export default ShareDrawer;

const Container = styled.div`
  min-width: 560px;
  width: 680px;
  max-width: 680px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Middle = styled.div`
  padding: 40px 16px 8px 19px;
  text-align: center;
  flex-grow: 1;
  background-color: #f5f6f8;
  overflow-y: auto;
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
`;

const ScheduleHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
`;
