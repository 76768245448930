import React from 'react';

import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import DialogTitle from 'components/DialogTitle';

interface EnterpriseFeedActivationProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export default function EnterpriseFeedActivation({
  open,
  onClose,
  onAccept
}: EnterpriseFeedActivationProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle title="Enhance your posting with Properti Content Feed" onClose={onClose}>
        Enhance your posting with Properti Content Feed
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText>
          Stay ahead with the latest Australian real estate news and trends with the Properti
          Content Feed. It's a perfect solution for those times when listings are low, and you still
          want to engage your audience through your socials with relevant and up-to-date content.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onAccept}>
          Add Feed
        </Button>
        <Button onClick={onClose}>Maybe Later</Button>
      </DialogActions>
    </Dialog>
  );
}
