import React from 'react';

import Paper from '@material-ui/core/Paper';

export default function TableContainer(props: any) {
  return <Paper elevation={0} {...props} />;
}

export function FlatTableContainer(props: any) {
  return <div {...props} />;
}
