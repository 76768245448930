import React, { PropsWithChildren } from 'react';

import { Box } from '@material-ui/core';

import styled from 'styled-components';

export const MobileFrame = styled.div((props: { backgroundColor?: string }) => ({
  width: '320px',
  height: '568px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
  backgroundColor: props.backgroundColor ?? '#ffffff'
}));

export const Bar = styled.div(
  (props: { backgroundColor?: string; height?: string; last?: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.backgroundColor ?? '#f5f5f5',
    height: props.height ?? '7px',
    width: props.last ? '75%' : '100%'
  })
);

export default function MobileFrameSkeleton({ children }: PropsWithChildren<{}>) {
  return (
    <MobileFrame>
      <Bar height="32px">
        <Box width={120}>
          <Bar height="9px" backgroundColor="#e5e5e5" />
        </Box>
      </Bar>
      <Box px={1.25}>
        <Box display="grid" mt={2} mb={2.5} gridGap={8}>
          <Bar />
          <Bar />
          <Bar />
          <Bar last />
        </Box>
        <div>{children}</div>
        <Box display="grid" mt={2} mb={2.5} gridGap={8}>
          <Bar />
          <Bar />
          <Bar />
          <Bar last />
        </Box>
        <Box display="grid" mt={2} mb={2.5} gridGap={8}>
          <Bar />
          <Bar last />
        </Box>
      </Box>
    </MobileFrame>
  );
}
