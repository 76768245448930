import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import SmallTextButton from '../SmallTextButton';
import { GoogleAdsResponsivePreviewProps } from './types';

const useDesktop480x120NativeAdStyles = makeStyles({
  host: {
    display: 'flex',
    width: '480px',
    height: '120px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.12)'
  },
  image: {
    width: '229px',
    height: '120px',
    objectFit: 'cover'
  },
  container: {
    flex: 1,
    padding: '4px 12px'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '20px'
  },
  adContainer: {
    display: 'flex',
    alignItems: 'baseline',
    alignContent: 'center',
    height: '38px',
    paddingTop: '4px'
  },
  ad: {
    fontFamily: 'Roboto, sans-serif',
    width: '17px',
    height: '11px',
    fontSize: '9px',
    lineHeight: '12px',
    fontWeight: 500,
    letterSpacing: '0.01em',
    position: 'relative',
    top: '-1px',
    color: '#ffffff',
    backgroundColor: '#008000',
    borderRadius: '2px',
    textAlign: 'center'
  },
  description: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '17px',
    marginLeft: '4px'
  },
  brandName: {
    display: 'block',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '19px',
    margin: '2px 0 10px',
    color: '#B5B5B5'
  },
  button: {
    margin: '12px 0 0'
  }
});

export default function Desktop480x120NativeAd({
  title,
  description,
  brandName,
  cta,
  imageUrl,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useDesktop480x120NativeAdStyles();

  return (
    <div className={styles.host}>
      <img className={styles.image} src={imageUrl!} alt="" />
      <div className={styles.container}>
        <span className={styles.title}>{title}</span>
        <div className={styles.adContainer}>
          <div className={styles.ad}>Ad</div>
          <span className={styles.description}>{description}</span>
        </div>
        <span className={styles.brandName}>{brandName}</span>
        <SmallTextButton
          className={styles.button}
          href={landingPageUrl}
          target="_blank"
          hidden={!showButton}
        >
          {cta}
        </SmallTextButton>
      </div>
    </div>
  );
}
