import React from 'react';

import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useFacebookLeadFormPreviewQuery, useSavedLeadGenFormQuery } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import LoadingIndicator from 'components/LoadingIndicator';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

interface LeadGenListPreviewModalDialogProps {
  open: boolean;
  onClose: (id?: string) => void;
  formId: any;
}

const LeadGenListPreviewDialog = ({
  open,
  onClose,
  formId
}: LeadGenListPreviewModalDialogProps) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data } = useSavedLeadGenFormQuery({
    variables: {
      id: formId
    },
    context: workspaceMemberContext,
    skip: !formId
  });

  const facebookConfig = data?.workspace_lead_forms_by_pk?.facebook_config;
  const facebookPageId = facebookConfig?.facebookPageId;

  const { data: previewData, loading: headerLoading } = useFacebookLeadFormPreviewQuery({
    variables: { page_id: facebookPageId },
    context: workspaceMemberContext,
    skip: !facebookPageId
  });

  const facebookHeaderData = previewData?.facebook_lead_form_preview;

  const fields = facebookConfig?.fields;
  const contextTitle = facebookConfig?.contextTitle ?? 'Request information';
  const contextMessage = facebookConfig?.contextMessage;
  const facebookPageName = facebookHeaderData?.name ?? '';
  const facebookPicture = facebookHeaderData?.picture?.data?.url ?? undefined;

  const customerAddress = fields?.includes('STREET_ADDRESS');
  const customerMessage = fields?.includes('MESSAGE');
  const multiChoice: boolean = facebookConfig?.multiChoice ?? false;
  const multiChoiceQuestions = facebookConfig?.multiChoiceQuestions;

  const AddressHolder = () => {
    if (customerAddress === true) {
      return (
        <>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', color: 'black' }}> Street address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              style={{
                border: '0.5px solid grey',
                padding: '5px',
                marginRight: '40px',
                fontSize: '14px'
              }}
            >
              <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
            </Box>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  };

  const MessageHolder = () => {
    if (customerMessage) {
      return (
        <>
          <Grid item xs={4}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Enter any details or questions for us
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              style={{
                border: '0.5px solid grey',
                padding: '5px',
                marginRight: '40px',
                fontSize: '14px'
              }}
            >
              <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
            </Box>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  };

  const MultiChoiceHolder = () => {
    if (multiChoice && multiChoiceQuestions.length) {
      return (
        <>
          {multiChoiceQuestions.map((question: any) => (
            <>
              <Grid item xs={4}>
                <Typography style={{ fontSize: '14px', color: 'black' }}>
                  {question.label}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  endIcon={<ArrowDropDownIcon />}
                  disabled
                  style={{
                    fontSize: '14px',
                    color: 'black',
                    border: '0.5px solid grey',
                    borderRadius: 0,
                    fontWeight: 'bold'
                  }}
                >
                  Select
                </Button>
              </Grid>
            </>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        onClose={handleClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '0.5px solid black',
          width: '100%'
        }}
      >
        {headerLoading ? (
          <LoadingIndicator />
        ) : (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{ marginRight: '20px' }}>
              <img src={facebookPicture} alt="Facebook page profile" />
            </Box>
            <Box>{facebookPageName}</Box>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box style={{ width: '100%', margin: 'auto', borderBottom: '0.5px solid black' }}>
          <Box style={{ marginBottom: '30px', marginRight: '30px', marginLeft: '30px' }}>
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '70px' }}>
              <Typography variant="h4" style={{ fontSize: '18px' }}>
                {contextTitle}
              </Typography>
            </Box>
            <>
              <Typography
                variant="h6"
                style={{ color: 'grey', marginTop: '30px', marginBottom: '30px', fontSize: '16px' }}
              >
                {contextMessage}
              </Typography>
            </>
            <Box>
              <Typography
                variant="h6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  marginBottom: '30px',
                  fontSize: '16px'
                }}
              >
                Fill out your details below
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Full name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ border: '0.5px solid grey', padding: '5px', marginRight: '40px' }}>
                    <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ border: '0.5px solid grey', padding: '5px', marginRight: '40px' }}>
                    <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '14px', color: 'black' }}>Phone number</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      endIcon={<ArrowDropDownIcon />}
                      disabled
                      style={{
                        width: '35%',
                        fontWeight: 'bold',
                        border: '0.5px solid grey',
                        borderRadius: 0,
                        color: 'black',
                        fontSize: '14px'
                      }}
                    >
                      AU +61
                    </Button>
                    <Box
                      style={{
                        border: '0.5px solid grey',
                        padding: '5px',
                        marginRight: '15px',
                        width: '55%'
                      }}
                    >
                      <Typography style={{ fontSize: '14px' }}>Enter your answer here.</Typography>
                    </Box>
                  </Box>
                </Grid>
                <AddressHolder />
                <MessageHolder />
                <MultiChoiceHolder />
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
          <Button
            disabled
            style={{
              backgroundColor: '#e4e4e4',
              borderRadius: 0,
              marginRight: '5px',
              color: 'black',
              border: '0.5px solid grey',
              height: '30px',
              fontWeight: 'bold'
            }}
          >
            Cancel
          </Button>
          <Button
            disabled
            style={{
              backgroundColor: '#3b5998',
              borderRadius: 0,
              color: 'white',
              border: '0.5px solid grey',
              height: '30px',
              fontWeight: 'bold'
            }}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LeadGenListPreviewDialog;
