import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

import GoogleAdsLogo from 'mdi-material-ui/GoogleAds';

export default function GoogleAvatar(props: AvatarProps) {
  const { style, ...other } = props;

  return (
    <Avatar style={{ backgroundColor: '#4285F4', ...style }} {...other}>
      <GoogleAdsLogo />
    </Avatar>
  );
}
