import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useSnackbar } from 'notistack';

import { useSetAdminActiveWorkspaceMutation } from 'generated/graphql';

import LoadingBackdrop from 'components/LoadingBackdrop';

import { propertiStaffHasuraContext } from 'lib/HasuraRoleContext';
import { logger } from 'lib/LoggerContext';
import useBool from 'lib/hooks/useBool';
import useUserContext from 'lib/hooks/useUserContext';

import { auth } from '../auth/hbp';

type AdminModeContextType = {
  isAdminMode: boolean;
  isAdminModeAvailable: boolean;
  setAdminMode: (value: boolean) => void;
};

export const ADMIN_MODE_SESSION_STORAGE_KEY = 'properti-admin-mode';
const defaultContext: AdminModeContextType = {} as AdminModeContextType;

const AdminModeContext = createContext(defaultContext);

function getAdminModeAvailable() {
  const allowedRoles = auth.getClaim('x-hasura-allowed-roles');
  return allowedRoles?.includes('properti-staff');
}

function hydrateAdminMode(isAdminModeAvailable: boolean, activeAdminWorkspaceId: string | null) {
  if (!isAdminModeAvailable) {
    return false;
  }

  if (activeAdminWorkspaceId) {
    return true;
  }

  return false;
}

function AdminModeContextProvider({ children }: { children: ReactNode }) {
  const [adminModeAvailable, setAdminModeAvailable] = useState<boolean>(getAdminModeAvailable);
  const { activeAdminWorkspaceId, activeWorkspaceId, userId } = useUserContext();

  const [isAdminMode] = useState<boolean>(() =>
    hydrateAdminMode(adminModeAvailable, activeAdminWorkspaceId)
  );
  const [backdropLoading, setBackdropLoading, setFinished] = useBool();
  const [backdropTitle, setBackdropTitle] = useState(
    `${isAdminMode ? 'Leaving' : 'Entering'} Admin Mode`
  );
  const { enqueueSnackbar } = useSnackbar();
  const [setAdminActiveWorkspace] = useSetAdminActiveWorkspaceMutation();

  useEffect(() => {
    auth.onAuthStateChanged((state: boolean) => {
      if (!state) {
        setAdminModeAvailable(false);
      }

      setAdminModeAvailable(getAdminModeAvailable);
    });
  }, [setAdminModeAvailable]);

  useEffect(() => {
    if (window.sessionStorage) {
      if (isAdminMode) {
        window.sessionStorage.setItem(ADMIN_MODE_SESSION_STORAGE_KEY, 'true');
      } else {
        window.sessionStorage.removeItem(ADMIN_MODE_SESSION_STORAGE_KEY);
      }
    }
  }, [isAdminMode]);

  const setAdminMode = useCallback(
    async (value: boolean) => {
      if (!adminModeAvailable && value) {
        return;
      }

      setBackdropTitle(`${value ? 'Entering' : 'Leaving'} Admin Mode`);
      setBackdropLoading();

      try {
        const resp = await setAdminActiveWorkspace({
          variables: {
            user_id: userId!,
            // Set the active_admin_workspace_id to the active_workspace_id when admin mode is enabled. Reset to null when disabled.
            workspace_id: value ? activeWorkspaceId : null
          },
          context: propertiStaffHasuraContext
        });

        if (resp.errors) {
          throw resp.errors[0];
        }

        await new Promise((resolve) => setTimeout(resolve, 2500));
        window.location.reload();
      } catch (error: any) {
        logger.error(error?.message ?? `Error switching ${value ? 'to' : 'from'} admin mode`, {
          meta: {
            error
          }
        });
        enqueueSnackbar(`Unable to switch ${value ? 'to' : 'from'} admin mode`, {
          variant: 'error'
        });
        setFinished();
      }
    },
    [
      activeWorkspaceId,
      adminModeAvailable,
      enqueueSnackbar,
      setAdminActiveWorkspace,
      setBackdropTitle,
      setBackdropLoading,
      setFinished,
      userId
    ]
  );

  return (
    <AdminModeContext.Provider
      value={{
        isAdminMode: isAdminMode,
        isAdminModeAvailable: adminModeAvailable,
        setAdminMode
      }}
    >
      <>
        {children}
        <LoadingBackdrop title={backdropTitle} open={backdropLoading} />
      </>
    </AdminModeContext.Provider>
  );
}

const useAdminModeContext = () => useContext(AdminModeContext);

export { AdminModeContextProvider, useAdminModeContext };
