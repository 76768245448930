import { createContext, useContext } from 'react';

import { GoogleAdsPreviewContextProps } from './google/types';

export function classNames(...classes: (string | null | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export const GoogleAdsPreviewContext = createContext<GoogleAdsPreviewContextProps | null>(null);

export function useResponsiveContext() {
  const context = useContext(GoogleAdsPreviewContext);
  if (context === null) {
    throw new Error('No valid provided for ResponsivePreviewContext');
  }
  return context;
}

export function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] as const;
  }
  throw new Error('Invalid hex value');
}

export function calculateContrastColor([r, g, b]: readonly [number, number, number]) {
  // calculate contrast of color (standard grayscale algorithmic formula)
  const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
  return contrast >= 128 ? '#000000' : '#ffffff';
}
