import { AttachmentEnum } from '../types';

interface Image {
  id: string;
  image: {
    id?: string | null;
    url?: string | null;
    path?: string | null;
    token?: string | null;
    content_type?: string | null;
    filename?: string | null;
    source?: string | null;
  };
}

export function listingAttachment({ id, image }: Image) {
  return image.source === 'upload'
    ? {
        type: AttachmentEnum.UPLOADED_FILE,
        uploaded_file: {
          key: image.path!,
          token: image.token!,
          contenttype: image.content_type!,
          filename: image.filename!
        }
      }
    : {
        type: AttachmentEnum.LISTING_IMAGE,
        image: {
          id,
          url: image.url!
        }
      };
}
