import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { AnyBoolExp } from 'generated/filterTypes';

import { Context, setContext } from './context';
import { buildSetter, LogicalOperatorOption, Setter } from './utils';

export type FilterBySearchOptions = {
  debounceTime?: number;
  customReplacer?: (str: string) => string;
  initialSearch?: string;
};

type HookReturn<T extends AnyBoolExp> = [
  T | undefined,
  string,
  React.Dispatch<React.SetStateAction<string>>
];

export interface UseFilterBySearchHook<T extends AnyBoolExp> {
  (options?: FilterBySearchOptions): HookReturn<T>;
}

export default function createFilterBySearch<T extends AnyBoolExp>(
  boolExp: T | T[],
  options?: FilterBySearchOptions & LogicalOperatorOption
): UseFilterBySearchHook<T> & Context {
  const {
    customReplacer,
    debounceTime = 500,
    initialSearch = '',
    logicalOperator = '_or'
  } = options ?? {};

  const emptyReturn = Array.isArray(boolExp) ? ({ [logicalOperator]: undefined } as T) : undefined;

  let setter: Setter<T>;

  if (Array.isArray(boolExp)) {
    setter = buildSetter(boolExp, '_ilike', logicalOperator);
  } else {
    setter = buildSetter(boolExp, '_ilike');
  }

  let CONTEXT_KEY: null | Object = null;

  const hook = function useFilterBySearch(this: any, options?: FilterBySearchOptions) {
    const [search, __setSearch] = useState<string>(options?.initialSearch ?? initialSearch);
    const [debouncedSearch] = useDebounce(search, options?.debounceTime ?? debounceTime);
    const optionsCustomReplacer = useRef(options?.customReplacer);
    const [filter, setFilter] = useState<T | undefined>(emptyReturn);

    useEffect(() => {
      return () => {
        // By setting CONTEXT_KEY to null, the WeakMap automagically clears the key/value pair
        CONTEXT_KEY = null;
      };
    }, []);

    useEffect(() => {
      const customReplace = optionsCustomReplacer.current ?? customReplacer;

      const searchTerm = `%${customReplace ? customReplace(debouncedSearch) : debouncedSearch}%`;

      const filter = setter(searchTerm);

      const hasSearchTerm = debouncedSearch && debouncedSearch.length > 0;

      setFilter(hasSearchTerm ? filter : emptyReturn);
    }, [debouncedSearch]);

    const setSearch = useCallback<React.Dispatch<React.SetStateAction<string>>>((state) => {
      if (typeof state === 'function') {
        __setSearch((prev) => {
          const newValue = (state as (prev: string) => string)(prev);

          setContext(CONTEXT_KEY ?? (CONTEXT_KEY = {}), newValue);

          return newValue;
        });
      } else {
        setContext(CONTEXT_KEY ?? (CONTEXT_KEY = {}), state);
        __setSearch(state);
      }
    }, []);

    const filteredSearch = useMemo<HookReturn<T>>(() => {
      return [filter, search, setSearch];
    }, [filter, search, setSearch]);

    return filteredSearch;
  };

  hook.___getContextKey = () => CONTEXT_KEY;

  return hook;
}
