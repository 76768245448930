import createDecorator from 'final-form-calculate';

import { getHasuraContextFromSessionStorage } from 'lib/HasuraRoleContext';
import { buildApolloClient } from 'lib/graphql/apollo';
import { ListingResponsiveAdDefaultsQuery } from 'lib/graphql/queries/workspaces/listings';

export const decorator = createDecorator({
  field: 'property_ids',
  updates: async (value: string[], _field: string, allValues?: any, _prevValues?: object) => {
    if (!value?.length) {
      return;
    }

    const client = buildApolloClient();
    const workspaceMemberContext = getHasuraContextFromSessionStorage();
    const resp = await client
      .query({
        query: ListingResponsiveAdDefaultsQuery,
        variables: {
          args: {
            workspace_id: allValues?.workspace_id,
            listing_ids: value
          }
        },
        context: workspaceMemberContext
      })
      .catch((error) => {
        console.debug(error);
      });

    if (!resp) {
      return;
    }

    return resp.data?.getResponsiveAdDefaults;
  }
});
