import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Link,
  Theme,
  useMediaQuery
} from '@material-ui/core';

import DialogTitle from 'components/DialogTitle';

interface InstallRealShortzModalProps {
  open: boolean;
  onClose: () => void;
}

export default function InstallRealShortzModal({ open, onClose }: InstallRealShortzModalProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: '#651CFF77' } }}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle onClose={onClose}>
        <img src="/realshortz/RealShortzLogo.png" height={32} alt="Install RealShortz" />
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText>
          The ultimate real estate video creation app for busy agents! Now, you can create stunning
          branded vertical videos right from your phone in just minutes.
        </DialogContentText>
        <DialogContentText>
          Scan the QR code below on your phone or go to{' '}
          <Link href="https://realshortz.com" target="_blank">
            realshortz.com
          </Link>{' '}
          to install RealShortz
        </DialogContentText>
        <img
          src="/realshortz/RealShortzQRCode.png"
          height={isMobile ? 250 : 500}
          width={isMobile ? 250 : 500}
          alt="QR Code"
        />
      </DialogContent>
    </Dialog>
  );
}
