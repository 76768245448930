import { useCallback, useState } from 'react';

import { Column } from '@material-table/core';

export interface UseHiddenColumnsProps {
  hiddenByDefault?: string[];
}

export function useHiddenColumns<T extends object = any>({
  hiddenByDefault = []
}: UseHiddenColumnsProps) {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(hiddenByDefault);

  const handleOnChangeColumnHidden = useCallback(
    (column: Column<T>, hidden: boolean) => {
      if (!column.field || typeof column.field !== 'string') {
        return;
      }

      if (hidden) {
        setHiddenColumns((cols) => [...cols, column.field as string]);
      } else {
        setHiddenColumns((cols) => cols.filter((col) => col !== column.field));
      }
    },
    [setHiddenColumns]
  );

  return {
    hiddenColumns,
    handleOnChangeColumnHidden
  };
}
