import { useCallback, useMemo, useRef, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  IconButton,
  Link as MuiLink,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HelpOutlineIcon from '@material-ui/icons/ErrorOutline';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import MaterialTable from '@material-table/core';

import { useConfirm } from 'material-ui-confirm';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  useDeleteDraftCampaignMutation,
  useWorkspaceCampaignsQuery,
  Workspace_Campaigns_Bool_Exp,
  Workspace_Campaign_Status_Enum,
  useSetCampaignStatusMutation,
  CampaignStatus,
  Workspace_Content_Type_Enum,
  Workspace_Campaigns_Order_By,
  Order_By,
  Workspace_Billing_Model_Enum,
  useStripeCustomActiveSubscriptionQuery
} from 'generated/graphql';

import AssociatePropertyDialog from 'components/AssociatePropertyDialog';
import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import GoogleAvatar from 'components/avatars/Google';
import MetaAvatar from 'components/avatars/Meta';
import CampaignReportExportDialog from 'components/campaigns/export/CampaignReportExportDialog';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import WaitingPaymentTooltip from 'components/campaigns/tooltips/WaitingPaymentTooltip';
import PresentationAudienceIcon from 'components/icons/PresentationAudience';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useCloneCampaign from 'lib/hooks/useCloneCampaign';
import {
  createFilter,
  createFilterBySearch,
  usePagination,
  subscribeAll,
  createUseFilterContextHook,
  createNotifier,
  createOrderBy
} from 'lib/hooks/useFiltersAndPagination';
import useUserContext from 'lib/hooks/useUserContext';
import { capitaliseFirstLetter } from 'lib/utils/capitaliseFirstLetter';
import { track } from 'lib/utils/track';

import CampaignTabs from './CampaignTabs';

const useSearchFilter = createFilterBySearch<Workspace_Campaigns_Bool_Exp>([{ name: {} }], {
  customReplacer: (str) => str.replace(/\s+/, '%')
});

enum CampaignEffectiveStatus {
  Error = 'error',
  Paused = 'paused',
  Pending = 'pending',
  Active = 'active',
  Deleted = 'deleted',
  Archived = 'archived',
  Finished = 'finished'
}

type StatusFilters =
  | 'active'
  | 'draft'
  | 'scheduled'
  | 'finished'
  | 'error'
  | 'archived'
  | 'awaiting_approval'
  | undefined;

type AdditionalFilter =
  | 'active_month'
  | 'active_week'
  | 'expired_month'
  | 'expired_week'
  | 'almost_expired'
  | undefined;

const useStatusFilter = createFilter<Workspace_Campaigns_Bool_Exp, StatusFilters>(
  {},
  {
    customizeFilter: (_, state) => {
      if (!state) {
        return {};
      }

      switch (state) {
        case 'draft':
        case 'error':
        case 'archived':
        case 'awaiting_approval':
        case 'scheduled': {
          return { status: { _eq: state as Workspace_Campaign_Status_Enum } };
        }
        case 'finished': {
          return {
            _or: [
              { status: { _eq: Workspace_Campaign_Status_Enum.Created } },
              { status: { _eq: Workspace_Campaign_Status_Enum.Done } }
            ]
          };
        }
        case 'active': {
          return {
            _or: [
              { status: { _eq: Workspace_Campaign_Status_Enum.Created } },
              { status: { _eq: Workspace_Campaign_Status_Enum.Creating } }
            ]
          };
        }
        default: {
          // Should not reach here
          if (process.env.NODE_ENV !== 'production') {
            throw Error('Unhandled switch statement case');
          }
          return {};
        }
      }
    }
  }
);

const usePlatformFilter = createFilter<Workspace_Campaigns_Bool_Exp, string>({
  ad_platforms: { _has_key: null }
});

const useDateRangeFilter = createFilter<Workspace_Campaigns_Bool_Exp, DateRange>(
  {},
  {
    customizeFilter: (_, state, ctx) => {
      const { endDate, startDate } = state ?? {};
      const [filter, status] = ctx as [AdditionalFilter, StatusFilters];

      const isoStart = moment(startDate).startOf('day').toISOString();
      const isoEnd = moment(endDate).endOf('day').toISOString();

      if (filter === 'active_month' || filter === 'active_week') {
        // To display active this month the end date has greater than the
        // beginning of the month. Likewise, for active this week, the end date
        // needs to be greater then the beginning of this week.
        const period = filter === 'active_month' ? 'month' : 'week';
        const startOf = moment().startOf(period).startOf('day').toISOString();

        return { start_date: { _gte: isoStart }, end_date: { _gt: startOf } };
      }

      if (filter === 'expired_month' || filter === 'expired_week') {
        // To display expired this month/week the end date needs to be less
        // than or equal to the end of the month/week but also greater than or
        // equal to the start of the month/week
        const period = filter === 'expired_month' ? 'month' : 'week';
        const startOf = moment().startOf(period).startOf('day').toISOString();

        return { start_date: { _gte: isoStart }, end_date: { _lte: isoEnd, _gte: startOf } };
      }

      if (filter === 'almost_expired') {
        // To display almost expired campaigns the campaign has to be currently
        // active and have an end_date greater than or equal to today, but less
        // than or equal to one week from today
        const isoDate = moment().endOf('day').toISOString();

        return { start_date: { _gte: isoStart }, end_date: { _lte: endDate, _gte: isoDate } };
      }

      let dateFilter = {} as Workspace_Campaigns_Bool_Exp;

      if (status === 'active') {
        // If the status is `active` the end_date needs to be greater than or equal to today
        const isoDate = moment().toISOString();

        dateFilter.end_date = { _gte: isoDate };
      } else if (status === 'finished') {
        // If the status is `finished` the end date needs to be less than today
        const isoDate = moment().toISOString();

        dateFilter.end_date = { _lte: isoDate };
      }

      if (endDate) {
        if (dateFilter.end_date) {
          if (dateFilter.end_date._lte) {
            dateFilter._and = [{ end_date: dateFilter.end_date }, { end_date: { _lte: isoEnd } }];
            delete dateFilter.end_date;
          } else {
            dateFilter.end_date._lte = isoEnd;
          }
        } else {
          dateFilter.end_date = { _lte: isoEnd };
        }
      }

      if (startDate) {
        dateFilter.start_date = { _gte: isoStart };
      }

      return dateFilter;
    }
  }
);

const useBudgetFilter = createFilter<Workspace_Campaigns_Bool_Exp, number>(
  {},
  {
    customizeFilter: (_, state) => {
      if (state === undefined) {
        return {};
      }

      const budgets = [0, 25, 50, 75, 100];

      const index = budgets.indexOf(state);

      if (index === -1) {
        return {};
      }

      if (index === 0) {
        return { budget_usage: { _eq: 0 } };
      } else if (state === 100) {
        return { budget_usage: { _gt: 76 } };
      }

      return {
        budget_usage: { _gt: budgets[index - 1], _lt: state }
      };
    }
  }
);

const useFilterContext = createUseFilterContextHook<AdditionalFilter>();
const useContentTypeFilter = createFilter<Workspace_Campaigns_Bool_Exp>({ content_type: {} });

const useOrderBy = createOrderBy<Workspace_Campaigns_Order_By>(
  [{ created_at: Order_By.Desc }],
  [
    'name',
    'id',
    'budget_usage',
    // types don't support 3rd level sorting, but code does
    'campaign_analytics_aggregate.sum.impressions' as any,
    'start_date',
    'end_date',
    'status'
  ],
  {
    orderByOverwrites: {
      budget_usage: {
        asc: Order_By.AscNullsFirst,
        desc: Order_By.DescNullsLast
      },
      // types don't support 3rd level sorting, but code does
      ['campaign_analytics_aggregate.sum.impressions' as any]: {
        asc: Order_By.AscNullsLast,
        desc: Order_By.DescNullsLast
      }
    }
  }
);

const [, withNotify] = createNotifier(useDateRangeFilter, useStatusFilter);

subscribeAll(useFilterContext, useDateRangeFilter, useStatusFilter);

const CampaignsPage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceCreator,
    isWorkspaceAnalyst,
    isWorkspaceAgent,
    isWorkspaceAdmin,
    workspace
  } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const billingModel = useMemo(() => workspace?.billing_model, [workspace?.billing_model]);

  const [searchFilter, search, setSearch] = useSearchFilter();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isQuickFilterEnabled = useRef(false);
  const filterContext = useFilterContext();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const popupState = usePopupState({ variant: 'popover', popupId: 'campaignMenu' });
  const [createFromTemplateModalOpen, setCreateFromTemplateModalOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const [statusFilter, status, setStatus] = useStatusFilter();
  const [platformFilter, platform, setPlatform] = usePlatformFilter();
  const [dateRangeFilter, dateRange, setDateRange] = useDateRangeFilter();
  const [contentTypeFilter, contentType, setContentType] = useContentTypeFilter();

  const [budgetFilter, budget, setBudget] = useBudgetFilter();
  const { limit, offset, page, setLimit, setPage } = usePagination();
  const [orderBy, handleOnOrderChange] = useOrderBy();

  const [associateCampaignId, setAssociateCampaignId] = useState<string>();
  const [associateOpen, setAssociateOpen] = useState<boolean>(false);
  const [setCampaignStatus] = useSetCampaignStatusMutation({
    context: workspaceMemberContext
  });

  const filters = {
    _and: [
      { ...searchFilter },
      { ...statusFilter },
      { ...platformFilter },
      { ...dateRangeFilter },
      { ...budgetFilter },
      { ...contentTypeFilter }
    ]
  };

  const { data, loading, refetch } = useWorkspaceCampaignsQuery({
    variables: {
      where: {
        workspace_id: { _eq: activeWorkspaceId! },
        ...filters,
        _or: isWorkspaceAgent
          ? [
              {
                created_by_id: { _eq: userId! }
              },
              {
                listings: {
                  listing: {
                    agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
                  }
                }
              }
            ]
          : undefined
      },
      limit: limit,
      offset: offset,
      order_by: orderBy
    },
    context: workspaceMemberContext,
    skip: !activeWorkspaceId
  });

  const { data: stripePaymentMethodsData, loading: stripePaymentMethodsLoading } =
    useStripeCustomActiveSubscriptionQuery({
      variables: {
        customerId: workspace?.stripe_customer_id!
      },
      context: workspaceMemberContext,
      skip: !workspace?.stripe_customer_id
    });
  const stripeCustomerStatus = stripePaymentMethodsData?.stripe_customer_status;
  const hasDefaultSource = useMemo(
    () => stripeCustomerStatus?.has_default_source ?? false,
    [stripeCustomerStatus]
  );

  const handleCloneCampaign = useCloneCampaign(refetch);

  const [deleteDraft] = useDeleteDraftCampaignMutation({ context: workspaceMemberContext });

  const handleDeleteDraft = async (row: any) => {
    try {
      await confirm({
        title: 'Delete campaign',
        description: 'Are you sure you want to delete this campaign'
      });
    } catch (error: any) {
      // noop
      return;
    }

    try {
      const resp = await deleteDraft({
        variables: {
          campaign_id: row.id
        }
      });
      if (resp.errors) {
        throw resp.errors[0];
      } else if (!resp.data?.delete_workspace_campaigns_by_pk?.id) {
        throw new Error('Unable to delete draft');
      } else {
        enqueueSnackbar('Draft deleted', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Unable to delete draft', { variant: 'error' });
    } finally {
      refetch();
    }
  };

  const handleOpenCreateFromTemplateModal = () => {
    popupState.close();
    setCreateFromTemplateModalOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setCreateFromTemplateModalOpen(false);
  };

  const count = data?.campaigns_aggregate?.aggregate?.count;

  const handleOpenAssociateProperty = useCallback(
    (campaignId: string) => {
      setAssociateCampaignId(campaignId);
      setAssociateOpen(true);
    },
    [setAssociateCampaignId, setAssociateOpen]
  );

  const handleCloseAssociateProperty = () => {
    setAssociateOpen(false);
  };

  const handleOpenExportDialog = () => {
    popupState.close();
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  const handlePaidInvoice = async () => {
    await refetch();
  };

  const handleSetCampaignStatus = async (status: CampaignStatus, campaignId: string) => {
    track('Update Campaign Status', { status, campaignId });
    try {
      const resp = await setCampaignStatus({
        variables: {
          args: {
            campaign_id: campaignId,
            status: status
          }
        }
      });

      if (!resp.data?.setCampaignStatus?.campaign_id) {
        throw new Error('Unable to set status');
      }
      enqueueSnackbar('Campaign status updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to set status', { variant: 'error' });
    } finally {
      refetch();
    }
  };

  // Direct users to add payment method if advertising workspace
  // and no payment method has been added to workspace
  if (
    billingModel === Workspace_Billing_Model_Enum.Advertising &&
    !hasDefaultSource &&
    !stripePaymentMethodsLoading
  ) {
    return (
      <EmptyStatePage
        title="Add default payment method"
        text="To create campaigns, you need to add a valid default payment method"
        icon={<CreditCardIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            component={Link}
            to="/billing"
            variant="contained"
            color="secondary"
            size="large"
            disabled={!isWorkspaceAdmin}
            fullWidth
          >
            Go to billing
          </Button>
        }
      />
    );
  }

  if (
    !loading &&
    !count &&
    !search &&
    !status &&
    !platform &&
    !dateRange &&
    !budgetFilter &&
    !contentTypeFilter
  ) {
    return (
      <EmptyStatePage
        title="Campaigns"
        icon={
          <PresentationAudienceIcon
            color="secondary"
            fill="none"
            style={{ height: 64, width: 64 }}
          />
        }
        text="Set your objectives, creative and budget to launch campaigns that convert."
        button={
          <Button
            component={Link}
            to="/campaigns/create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceCreator || isWorkspaceAnalyst}
            fullWidth
          >
            Create Campaign
          </Button>
        }
      />
    );
  }

  // https://github.com/mbrn/material-table/issues/1979
  const campaigns = data?.campaigns?.map((c) => ({ ...c })) ?? [];

  const isEditingDisabled = isWorkspaceCreator || isWorkspaceAnalyst;

  return (
    <>
      <div>
        <PageHeader
          title="Campaigns"
          rightComponent={
            <>
              <ButtonGroup
                variant="contained"
                color="secondary"
                disabled={isEditingDisabled}
                style={{ minWidth: 160 }}
              >
                <Button component={Link} to="create" size="large">
                  Create Campaign
                </Button>
                <Button size="small" {...bindTrigger(popupState)}>
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Menu
                {...bindMenu(popupState)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem component={Link} to="create" disabled={isEditingDisabled}>
                  Create blank campaign
                </MenuItem>
                <MenuItem onClick={handleOpenCreateFromTemplateModal} disabled={isEditingDisabled}>
                  Create from template
                </MenuItem>
                <MenuItem onClick={handleOpenExportDialog}>Export full CSV report</MenuItem>
              </Menu>
            </>
          }
        />
        <CampaignTabs value={0} userId={userId!} />
        <MaterialTable
          title="Campaigns"
          columns={[
            {
              title: 'Campaign Name',
              field: 'name',
              editable: 'never',
              sorting: true,
              render: (data) => {
                if (['draft', 'scheduled'].includes(data.status)) {
                  if (isEditingDisabled) {
                    return data.name;
                  }
                }

                // No link if status is creating or error
                if (['error', 'creating'].includes(data.status)) {
                  return data.name;
                }

                return (
                  <MuiLink component={Link} to={`edit/${data.id}`}>
                    {data.name}
                  </MuiLink>
                );
              }
            },
            {
              title: 'Platform',
              field: 'ad_platforms',
              sorting: false,
              render: (data) => {
                const hasFacebook = data.ad_platforms?.includes('facebook');
                const hasGoogle = data.ad_platforms?.includes('googleads');
                return (
                  <AvatarGroup color="primary" max={5}>
                    {hasFacebook && <MetaAvatar />}
                    {hasGoogle && <GoogleAvatar />}
                  </AvatarGroup>
                );
              }
            },
            {
              title: 'Amount Spent',
              field: 'spend_cap',
              editable: 'never',
              sorting: true,
              render: (data) => {
                // Spend is return in cents
                const spend = data.campaign_analytics_aggregate?.aggregate?.sum?.spend ?? 0;

                // Cap is stored in dollars
                const cap = data.spend_cap ?? 0;
                return (
                  <span>
                    {['draft', 'scheduled', 'error'].includes(data.status) ? (
                      <span style={{ color: '#707683' }}>--.--</span>
                    ) : (
                      <strong>
                        {numbro(spend).formatCurrency({ thousandSeparated: true, mantissa: 2 })}
                      </strong>
                    )}
                    {' / '}
                    {cap > 0 ? (
                      numbro(cap).formatCurrency({ thousandSeparated: true, mantissa: 2 })
                    ) : (
                      <span style={{ color: '#707683' }}>--.--</span>
                    )}
                  </span>
                );
              }
            },
            {
              title: 'Impressions',
              field: 'analytics.aggregated.impressions',
              editable: 'never',
              sorting: true,
              render: (data) => {
                if (['draft', 'scheduled', 'error'].includes(data.status)) {
                  return <span style={{ color: '#707683' }}>--</span>;
                }
                const impressions =
                  data.campaign_analytics_aggregate?.aggregate?.sum?.impressions ?? 0;

                return numbro(impressions).format({
                  thousandSeparated: true
                });
              }
            },
            {
              title: 'Start Date',
              field: 'start_date',
              sorting: true,

              render: (data) =>
                data?.start_date ? moment(data?.start_date).format('Do MMM YYYY') : 'Not set',
              editable: 'never'
            },
            {
              title: 'End Date',
              field: 'end_date',
              sorting: true,

              render: (data) =>
                data?.end_date ? moment(data?.end_date).format('Do MMM YYYY') : 'Ongoing',
              editable: 'never'
            },
            {
              title: 'Status',
              field: 'status',
              editable: 'never',
              sorting: true,

              render: (data) => {
                if (data.status === 'draft') {
                  return <StatusIndicator status={data.status} text="Draft" />;
                } else if (data.status === 'awaiting_approval') {
                  return <StatusIndicator status="draft" text="Awaiting approval" />;
                } else if (data.status === Workspace_Campaign_Status_Enum.Scheduled) {
                  return <StatusIndicator status={Status.PENDING} text="scheduled" />;
                } else if (data.status === Workspace_Campaign_Status_Enum.WaitingPayment) {
                  const stripeInvoiceIds = (data.spend_usage_records
                    ?.map((sur) => sur.stripe_invoice_id)
                    ?.filter(Boolean) ?? []) as string[];

                  return (
                    <WaitingPaymentTooltip
                      key={data.id}
                      stripeInvoiceIds={stripeInvoiceIds}
                      hideRetryButton={isEditingDisabled}
                      onInvoicePaid={handlePaidInvoice}
                    />
                  );
                } else if (data.status === Workspace_Campaign_Status_Enum.Error) {
                  const errors = data.errors.length
                    ? data.errors?.map((err) => err.message)
                    : [
                        'Unknown campaign error, please retry your request. If the problem persists, contact support'
                      ];

                  const tooltipEl = (
                    <span>
                      {errors.map((err, idx) => (
                        <p key={idx}>{err}</p>
                      ))}
                    </span>
                  );
                  return (
                    <>
                      <StatusIndicator status={Status.ERROR} text="Error" />
                      <Tooltip title={tooltipEl} enterDelay={200} leaveDelay={250}>
                        <IconButton size="small">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  );
                }

                // Aggregated statuses
                const status = data?.statuses?.aggregated?.effective_status ?? 'error';
                return <StatusIndicator status={status} text={capitaliseFirstLetter(status)} />;
              }
            }
          ]}
          data={campaigns}
          totalCount={count ?? 0}
          isLoading={loading}
          actions={[
            (rowData) => ({
              icon: 'edit',
              tooltip: 'Finish editing',
              onClick: () => {
                if (rowData.status === Workspace_Campaign_Status_Enum.AwaitingApproval) {
                  navigate(`simple-campaign-approval/${rowData.id}`);
                } else {
                  navigate(`create`, {
                    state: {
                      draft_campaign_id: rowData.id
                    }
                  });
                }
              },
              disabled: isEditingDisabled,
              hidden: !['draft', 'awaiting_approval', 'scheduled'].includes(rowData.status)
            }),
            (rowData) => ({
              icon: 'file_copy',
              tooltip: 'Clone Campaign',
              disabled: isEditingDisabled,
              onClick: () => {
                handleCloneCampaign(rowData.id);
              }
            }),
            (rowData) => ({
              icon: 'home_work',
              tooltip: 'Associate Property',
              disabled: isEditingDisabled,
              onClick: () => {
                handleOpenAssociateProperty(rowData.id);
              }
            }),
            (rowData) => ({
              icon: 'play_arrow',
              tooltip: 'Resume Campaign',
              onClick: () => {
                handleSetCampaignStatus(CampaignStatus.Active, rowData.id);
              },
              hidden:
                rowData.statuses?.aggregated?.effective_status !== CampaignEffectiveStatus.Paused,
              disabled:
                rowData.statuses?.aggregated.effective_status !== CampaignEffectiveStatus.Paused ||
                isEditingDisabled
            }),
            (rowData) => ({
              icon: 'pause',
              tooltip: 'Pause Campaign',
              onClick: () => {
                handleSetCampaignStatus(CampaignStatus.Paused, rowData.id);
              },
              hidden:
                rowData.statuses?.aggregated?.effective_status !== CampaignEffectiveStatus.Active,
              disabled:
                rowData.statuses?.aggregated.effective_status === CampaignEffectiveStatus.Paused ||
                rowData.statuses?.aggregated.effective_status ===
                  CampaignEffectiveStatus.Archived ||
                rowData.statuses?.aggregated.effective_status === CampaignEffectiveStatus.Deleted ||
                rowData.statuses?.aggregated.effective_status === CampaignEffectiveStatus.Error ||
                rowData.statuses?.aggregated.effective_status ===
                  CampaignEffectiveStatus.Finished ||
                rowData.statuses?.aggregated.effective_status === CampaignEffectiveStatus.Pending ||
                rowData.statuses?.aggregated.effective_status ===
                  Workspace_Campaign_Status_Enum.Draft ||
                rowData.statuses?.aggregated.effective_status ===
                  Workspace_Campaign_Status_Enum.AwaitingApproval ||
                isEditingDisabled
            }),
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete Campaign',
              onClick: () => handleDeleteDraft(rowData),
              hidden: !['draft', 'awaiting_approval', 'scheduled', 'error'].includes(
                rowData.status
              ),
              disabled:
                !['draft', 'awaiting_approval', 'scheduled', 'error'].includes(rowData.status) ||
                isEditingDisabled
            })
          ]}
          options={{
            search: true,
            toolbar: true,
            draggable: false,
            showTitle: false,
            columnsButton: true,
            emptyRowsWhenPaging: false,
            sorting: true,
            actionsColumnIndex: -1,
            pageSize: limit,
            pageSizeOptions: [5, 10, 20, 50, 100, 200],
            debounceInterval: 1250
          }}
          onRowsPerPageChange={setLimit}
          onPageChange={setPage}
          onSearchChange={setSearch}
          onOrderChange={handleOnOrderChange}
          page={page}
          components={{
            Container: TableContainer,
            Toolbar: (props) => (
              <Toolbar
                {...props}
                searchValue={search}
                setSearch={setSearch}
                filtersOpen={filtersOpen}
                onToggleFiltersOpen={() => {
                  setFiltersOpen(!filtersOpen);
                }}
                menuItems={[
                  {
                    name: 'Platform',
                    options: [
                      { value: 'facebook', text: 'Meta' },
                      { value: 'googleads', text: 'Google Ads' }
                    ],
                    value: platform,
                    setValue: setPlatform,
                    hasAll: true
                  },
                  {
                    name: 'Status',
                    options: [
                      { value: Workspace_Campaign_Status_Enum.Draft, text: 'Draft' },
                      {
                        value: Workspace_Campaign_Status_Enum.AwaitingApproval,
                        text: 'Awaiting Approval'
                      },
                      { value: 'active', text: 'Active' },
                      { value: Workspace_Campaign_Status_Enum.Scheduled, text: 'Scheduled' },
                      { value: 'finished', text: 'Finished' },
                      { value: Workspace_Campaign_Status_Enum.Archived, text: 'Archived' },
                      { value: Workspace_Campaign_Status_Enum.Error, text: 'Error' }
                    ],
                    value: status,
                    setValue: (val) => withNotify(() => setStatus(val as StatusFilters)),
                    hasAll: true
                  },
                  {
                    name: 'Content Category',
                    options: [
                      {
                        value: Workspace_Content_Type_Enum.AuctionClearanceRates,
                        text: 'Auction Clearances'
                      },
                      {
                        value: Workspace_Content_Type_Enum.AuthorityBuilding,
                        text: 'Authority Building'
                      },
                      { value: Workspace_Content_Type_Enum.Listing, text: 'Listing' },
                      { value: Workspace_Content_Type_Enum.MarketData, text: 'Market Data' },
                      { value: Workspace_Content_Type_Enum.NewsUpdate, text: 'News Article' },
                      {
                        value: Workspace_Content_Type_Enum.SocialProof,
                        text: 'Testimonials & Social Proof'
                      },
                      { value: Workspace_Content_Type_Enum.Personal, text: 'Personal' },
                      { value: Workspace_Content_Type_Enum.GenericPost, text: 'Generic' },
                      { value: Workspace_Content_Type_Enum.Other, text: 'Other' }
                    ],
                    value: contentType,
                    setValue: setContentType,
                    hasAll: true
                  },
                  {
                    name: 'Budget Spent',
                    options: [
                      { value: 0, text: '0%' },
                      { value: 25, text: '1% - 25%' },
                      { value: 50, text: '26% - 50%' },
                      { value: 75, text: '51% - 75%' },
                      { value: 100, text: '76% - 100%' }
                    ],
                    value: budget,
                    setValue: setBudget,
                    hasAll: true
                  }
                ]}
                quickFilter={{
                  options: [
                    { value: 'active_month', text: 'Campaigns active this month' },
                    { value: 'expired_month', text: 'Campaigns expired this month' },
                    { value: 'active_week', text: 'Campaigns active this week' },
                    { value: 'expired_week', text: 'Campaigns expired this week' },
                    { value: 'low_budget', text: 'Campaigns with low budget' },
                    { value: 'almost_expired', text: 'Campaigns almost expired' }
                  ],
                  onInvalidate: () => {
                    if (!isQuickFilterEnabled.current) {
                      return;
                    }

                    withNotify(() => {
                      setStatus(undefined);
                      setBudget(undefined);
                      filterContext(undefined);
                    });

                    isQuickFilterEnabled.current = false;
                  },
                  onChange: (val) => {
                    isQuickFilterEnabled.current = true;
                    const anyStartDate = new Date(0); // Any start date

                    switch (val) {
                      case 'active_month':
                      case 'expired_month': {
                        const endOfMonth = moment().endOf('month').endOf('day').toDate();
                        const status = val === 'active_month' ? 'active' : 'finished';
                        setStatus(status);
                        setDateRange({ startDate: anyStartDate, endDate: endOfMonth });
                        filterContext(val);

                        break;
                      }
                      case 'active_week':
                      case 'expired_week': {
                        const endOfWeek = moment().endOf('week').endOf('day').toDate();
                        const status = val === 'active_week' ? 'active' : 'finished';
                        setStatus(status);
                        setDateRange({ startDate: anyStartDate, endDate: endOfWeek });
                        filterContext(val);
                        break;
                      }
                      case 'low_budget': {
                        setStatus('active');
                        setBudget(100);
                        break;
                      }
                      case 'almost_expired': {
                        const endDate = moment().add(1, 'week').endOf('day').toDate();
                        setStatus('active');
                        setDateRange({ startDate: anyStartDate, endDate });
                        filterContext('almost_expired');
                        break;
                      }
                      default: {
                        if (process.env.NODE_ENV !== 'production') {
                          throw Error('Unhandled switch statement case');
                        }
                        // Noop
                      }
                    }
                  }
                }}
                dateRange={dateRange}
                setDateRange={(range) => withNotify(() => setDateRange(range))}
              />
            )
          }}
        />
      </div>
      <CampaignFromListingOrCreativeDialog
        open={createFromTemplateModalOpen}
        onClose={handleCloseCreateFromTemplateModal}
      />
      <AssociatePropertyDialog
        variant="campaign"
        open={associateOpen}
        onClose={handleCloseAssociateProperty}
        campaignId={associateCampaignId as string}
      />
      <CampaignReportExportDialog open={exportDialogOpen} onClose={handleCloseExportDialog} />
    </>
  );
};

export default CampaignsPage;
