import cuid from 'cuid';

import { storage } from 'lib/auth/hbp';

export interface FileAttachment {
  path: string;
  filename: string;
  token: string;
  content_type: string;
}

export default async function uploadFile(
  activeWorkspaceId: string,
  file: File
): Promise<FileAttachment> {
  const slug = cuid.slug();
  const name = file.name
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z.]/g, '')
    .trim();
  const filename = `${slug}_${name}`;
  const path = `/workspace/${activeWorkspaceId}/posts/uploads/${filename}`;
  const resp = await storage.put(path, file);
  const {
    key,
    Metadata: { token },
    ContentType
  } = resp;

  return {
    path: key,
    filename: filename,
    token: token,
    content_type: ContentType
  };
}
