import React, { useState } from 'react';

import { Field, Form } from 'react-final-form';
import ReactCrop from 'react-image-crop';

import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxProps,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { AlertCircle } from 'mdi-material-ui';
import styled from 'styled-components';

import ImagePickerCrop from 'components/creative/template/ImagePickerCrop';
import { SelectedImageRatioMessage } from 'components/creative/template/SelectedImageRatioMessage';
import FinalFormCategoryCheckbox from 'components/forms/FinalFormCategoryCheckbox';
import FinalFormCondition from 'components/forms/FinalFormCondition';

import { ImageCropSelection } from './types';
import {
  meetsResponsiveImageRequirements,
  RESPONSIVE_IMAGE_REQUIREMENTS,
  ResponsiveImageSpec
} from './utils';

interface ResponsiveCreativeDrawerProps {
  src: string;
  data: HTMLImageElement;
  open: boolean;
  onClose: () => void;
  onSaved: (selection: ImageCropSelection) => void;
  value: ImageCropSelection;
}

function calculateMinWidth(data: HTMLImageElement, spec: ResponsiveImageSpec) {
  const { width } = data;
  const { minWidth } = spec;

  return (100 * minWidth) / width;
}

function calculateMinHeight(data: HTMLImageElement, spec: ResponsiveImageSpec) {
  const { height } = data;
  const { minHeight } = spec;

  return (100 * minHeight) / height;
}

function RatioNotSupported(props: { image: string; message: string }) {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <img
          src={props.image}
          width="100%"
          alt=""
          style={{ filter: 'grayscale(100%) brightness(50%)' }}
        />
      </Grid>
      <Grid item>
        <SelectedImageRatioMessage icon={<AlertCircle color="error" />} text={props.message} />
      </Grid>
    </Grid>
  );
}

export interface FinalFormImageRatioProps {
  name: string;
  label: string;
  src: string;
  ratio: any;
}

interface RatioFieldValue {
  enabled: boolean;
  crop: ReactCrop.PercentCrop;
}

export function FinalFormImageRatio({ src, name, label, ratio }: FinalFormImageRatioProps) {
  return (
    <Field<RatioFieldValue>
      name={name}
      render={({ input: { name, value, checked, onChange } }) => {
        function handleToggle(event: any, checked: boolean) {
          onChange({
            ...value,
            enabled: checked
          });
        }
        function handleSelect(crop: ReactCrop.Crop) {
          onChange({
            ...value,
            crop
          });
        }
        return (
          <>
            <RatioCheckbox
              name={name}
              label={label}
              value={value}
              checked={value?.enabled ?? false}
              onChange={handleToggle}
              disabled={!ratio.enabled}
            />
            {ratio.enabled ? (
              <ImagePickerCrop
                crop={value?.crop}
                image={src}
                onSelect={handleSelect}
                aspect={ratio.aspect}
                minWidth={ratio.minWidth}
                minHeight={ratio.minHeight}
              />
            ) : (
              <RatioNotSupported image={src} message="Image too small for this ratio" />
            )}
          </>
        );
      }}
    />
  );
}

const defaultCrop: {
  enabled: boolean;
} = {
  enabled: false
};

function getSaveText(type: ImageCropSelection['type'], selectedRatios: number) {
  if (type === 'none') {
    return `Don't use image`;
  }

  if (type === 'smart') {
    return `Use smart crop`;
  }

  return selectedRatios
    ? `Select ${selectedRatios} Ratio${selectedRatios === 1 ? '' : 's'}`
    : `Don't use image`;
}

const ResponsiveCreativeDrawer: React.FC<ResponsiveCreativeDrawerProps> = ({
  open,
  onClose,
  src,
  data,
  value,
  onSaved
}) => {
  const [square, landscape] = RESPONSIVE_IMAGE_REQUIREMENTS;
  const ratios = {
    square: {
      enabled: meetsResponsiveImageRequirements(data, ['square']),
      aspect: square.minWidth / square.minHeight,
      minWidth: calculateMinWidth(data, square),
      minHeight: calculateMinHeight(data, square)
    },
    landscape: {
      enabled: meetsResponsiveImageRequirements(data, ['landscape']),
      aspect: landscape.minWidth / landscape.minHeight,
      minWidth: calculateMinWidth(data, landscape),
      minHeight: calculateMinHeight(data, landscape)
    }
  };
  const smartCropEnabled = ratios.square.enabled && ratios.landscape.enabled;
  const [selectedRatios, setSelectedRatios] = useState(
    value.type === 'manual' ? +value.square?.enabled + +value.landscape?.enabled : -1
  );
  const [saveText, setSaveText] = useState(() => getSaveText(value.type, selectedRatios));

  value =
    !smartCropEnabled && value.type === 'none'
      ? ({ type: 'manual', square: defaultCrop, landscape: defaultCrop } as ImageCropSelection)
      : value;

  if (data.width < square.minWidth) {
    ratios.square.enabled = false;
  }

  function handleSave() {
    if (selectedRatios === 0 && value.type === 'manual') {
      onSaved({ type: 'none' });
    } else {
      onSaved(value);
    }
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleSave}
      elevation={2}
      ModalProps={{
        disableEnforceFocus: true
      }}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Container>
        <Top>
          <div>
            <Typography variant="h5" gutterBottom>
              Crop Image
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Top>
        <Middle>
          <Form
            onSubmit={handleSave}
            initialValues={{
              square: defaultCrop,
              landscape: defaultCrop,
              ...value
            }}
            render={({ handleSubmit, values: formValues }) => {
              value = formValues as ImageCropSelection;

              const selectedRatios =
                value.type === 'manual' ? +value.square?.enabled + +value.landscape?.enabled : -1;

              setSelectedRatios(selectedRatios);

              setSaveText(getSaveText(value.type, selectedRatios));

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <FinalFormCategoryCheckbox
                        name="type"
                        option={{
                          title: 'Smart Crop',
                          description: "We'll automatically select the best crop for your image.",
                          value: 'smart',
                          readonly: !smartCropEnabled
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FinalFormCategoryCheckbox
                        name="type"
                        option={{
                          title: 'Manual Crop',
                          description: 'Select and crop images manually.',
                          value: 'manual'
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FinalFormCondition when="type" equals="manual">
                    <Divider style={{ margin: '32px 0' }} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FinalFormImageRatio
                          name="landscape"
                          label="Select Landscape 1.91:1"
                          ratio={ratios.landscape}
                          src={src}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FinalFormImageRatio
                          name="square"
                          label="Select Square 1:1"
                          ratio={ratios.square}
                          src={src}
                        />
                      </Grid>
                    </Grid>
                  </FinalFormCondition>
                </form>
              );
            }}
          />
        </Middle>
        <Bottom>
          <ButtonGroup>
            <Button variant="contained" color="secondary" size="large" onClick={handleSave}>
              {saveText}
            </Button>
          </ButtonGroup>
        </Bottom>
      </Container>
    </Drawer>
  );
};

export default ResponsiveCreativeDrawer;

const Container = styled.div`
  min-width: 560px;
  width: 900px;
  max-width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Middle = styled.div`
  padding: 40px 16px 8px 19px;
  flex-grow: 1;
  background-color: #f5f6f8;
  overflow-y: auto;
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
`;

const CheckboxBox = styled.div`
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
`;

interface RatioCheckboxProps {
  label: string;
}

const RatioCheckbox = (props: RatioCheckboxProps & CheckboxProps) => {
  return (
    <CheckboxBox>
      <FormControlLabel
        style={{ width: '100%', padding: '16px' }}
        disabled={props.disabled}
        control={<Checkbox color="secondary" {...props} />}
        label={props.label}
      />
    </CheckboxBox>
  );
};
