import { useCallback, useState } from 'react';

type GoToNextPageFunction = () => void;
type GoToPrevPageFunction = () => void;

type UseStepperPage = [
  number,
  GoToNextPageFunction,
  GoToPrevPageFunction,
  React.Dispatch<React.SetStateAction<number>>
];

export default function useStepperPage(defaultValue: number = 0): UseStepperPage {
  const [page, setPage] = useState<number>(defaultValue);

  const goToNextPage: GoToNextPageFunction = useCallback(() => {
    setPage((page) => page + 1);
  }, []);

  const goToPrevPage: GoToPrevPageFunction = useCallback(() => {
    setPage((page) => Math.max(0, page - 1));
  }, []);

  return [page, goToNextPage, goToPrevPage, setPage];
}
