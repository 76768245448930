import React from 'react';

import { Field, FieldProps } from 'react-final-form';

import { RatingProps as MuiRatingProps } from '@material-ui/lab/Rating';

import MuiRating from 'components/Rating';

export interface RatingProps extends Partial<Omit<MuiRatingProps, 'onChange'>> {
  name: string;
  required?: boolean;
  fieldProps?: Partial<FieldProps<any, any>>;
}

export default function Rating(props: RatingProps) {
  const { name, disabled, required, fieldProps, ...restRating } = props;

  const handleOnChange = (onChange: (value: number | number[]) => void) => {
    return (_event: React.ChangeEvent<{}>, value: number | null) => {
      onChange(value ?? 0);
    };
  };

  return (
    <div>
      <Field
        name={name}
        render={({ input: { name, value, onChange, ...restInput } }) => (
          <MuiRating
            value={value}
            onChange={handleOnChange(onChange)}
            {...restRating}
            {...restInput}
          />
        )}
        {...fieldProps}
      />
    </div>
  );
}
