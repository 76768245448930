import { createTheme } from '@material-ui/core/styles';

import overrides from './overrides';
import props from './props';
import typography from './typography';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#17457A',
      light: '#F6F9FD',
      dark: '#2B59AD',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#F37D6F',
      light: '#ffae9d',
      dark: '#bc4e44',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#B00020',
      light: '#E57373',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#070000'
    },
    background: {
      default: '#F5F6F8'
    }
  },
  shadows: [
    'none',
    '0px 6px 18px rgba(0, 0, 0, 0.06)',
    '0px 6px 18px rgba(0, 0, 0, 0.08)',
    '0px 6px 18px rgba(0, 0, 0, 0.10)',
    '0px 6px 18px rgba(0, 0, 0, 0.12)',
    '0px 6px 18px rgba(0, 0, 0, 0.14)',
    '0px 6px 18px rgba(0, 0, 0, 0.16)',
    '0px 6px 18px rgba(0, 0, 0, 0.18)',
    '0px 6px 18px rgba(0, 0, 0, 0.20)',
    '0px 6px 18px rgba(0, 0, 0, 0.22)',
    '0px 6px 18px rgba(0, 0, 0, 0.24)',
    '0px 6px 18px rgba(0, 0, 0, 0.26)',
    '0px 6px 18px rgba(0, 0, 0, 0.28)',
    '0px 6px 18px rgba(0, 0, 0, 0.30)',
    '0px 6px 18px rgba(0, 0, 0, 0.32)',
    '0px 6px 18px rgba(0, 0, 0, 0.34)',
    '0px 6px 18px rgba(0, 0, 0, 0.36)',
    '0px 6px 18px rgba(0, 0, 0, 0.38)',
    '0px 6px 18px rgba(0, 0, 0, 0.40)',
    '0px 6px 18px rgba(0, 0, 0, 0.42)',
    '0px 6px 18px rgba(0, 0, 0, 0.44)',
    '0px 6px 18px rgba(0, 0, 0, 0.46)',
    '0px 6px 18px rgba(0, 0, 0, 0.48)',
    '0px 6px 18px rgba(0, 0, 0, 0.50)',
    '0px 6px 18px rgba(0, 0, 0, 0.52)'
  ],
  typography,
  overrides,
  props
});

export default materialTheme;
