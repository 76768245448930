export async function createFileFromUrl(url: string, name: string, contentType = 'image/jpeg') {
  const resp = await fetch(url);
  const data = await resp.blob();
  const metadata = {
    type: resp.headers.get('content-type') || contentType
  };

  const file = new File([data], name, metadata);

  return file;
}
