import React, { useCallback, useMemo } from 'react';

import { Grid, GridProps } from '@material-ui/core';

export type StackedItemProps = Omit<GridProps, 'item' | 'container'> & {
  containerProps?: Omit<GridProps, 'item' | 'container'>;
  itemProps?: Omit<GridProps, 'item' | 'container'>;
};
/**
 * Wraps all the children in a MUI `<Grid item/>` all wrapped up in a root
 * `<Grid container/>`
 */
export default function StackedItems({
  children,
  itemProps,
  containerProps,
  ...props
}: StackedItemProps) {
  const { childProps, rootProps, bothProps } = useMemo(() => {
    return Object.entries(props).reduce(
      (sortedProps, [key, value]) => {
        if (
          [
            'alignContent',
            'alignItems',
            'direction',
            'justifyContent',
            'justify',
            'spacing',
            'wrap'
          ].includes(key)
        ) {
          sortedProps.rootProps[key as keyof StackedItemProps] = value;
        } else if (['xs', 'xl', 'sm', 'md', 'lg'].includes(key)) {
          sortedProps.childProps[key as keyof StackedItemProps] = value;
        } else {
          sortedProps.bothProps[key as keyof StackedItemProps] = value;
        }

        return sortedProps;
      },
      {
        childProps: {} as StackedItemProps,
        rootProps: {} as StackedItemProps,
        bothProps: {} as StackedItemProps
      }
    );
  }, [props]);

  const childrenArray = Array.isArray(children) ? children : [children];

  const isGridItem = useCallback(
    (child: React.ReactNode) =>
      React.isValidElement(child) && child.type === Grid && child.props.item,
    []
  );

  const getComponent = useCallback(
    (child: React.ReactNode, index: React.Key) => {
      // If child is a <Grid item> return it without wrapping it
      if (isGridItem(child)) {
        return child;
      }
      // https://reactjs.org/docs/jsx-in-depth.html#booleans-null-and-undefined-are-ignored
      else if (child === null || child === undefined || typeof child === 'boolean') {
        return child; // React doesn't render this so it shouldn't be wrapped in a Grid
      }

      return (
        <Grid item {...childProps} {...bothProps} {...itemProps} key={index}>
          {child}
        </Grid>
      );
    },
    [bothProps, childProps, isGridItem, itemProps]
  );

  return (
    <Grid container {...rootProps} {...bothProps} {...containerProps}>
      {childrenArray.map((child, index) => {
        if (Array.isArray(child) && child.some((item) => isGridItem(item))) {
          return child.map((child, idx) => getComponent(child, `${index}-${idx}`));
        }

        return getComponent(child, index);
      })}
    </Grid>
  );
}
