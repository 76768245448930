import { makeStyles, Theme } from '@material-ui/core/styles';

const useBackdropStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    backgroundColor: 'rgba(51, 51, 51, 0.8)'
  }
}));

export default useBackdropStyles;
