import React from 'react';

import { FormSpy } from 'react-final-form';

const AutoSave: React.FC<any> = (props) => {
  props.valueRef.current = props.values;
  return null;
};

interface FinalFormAutoSaveProps {
  valueRef: any;
}

const FinalFormAutoSave: React.FC<FinalFormAutoSaveProps> = (props) => {
  return <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />;
};

export default FinalFormAutoSave;
