import React, { useRef } from 'react';

import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Fab, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import clsx from 'clsx';
import VideoIcon from 'mdi-material-ui/VideoBox';

import { buildThumbnailUrl } from 'lib/Cloudinary';

interface ShareAttachmentPreviewProps {
  src?: string;
  id: string;
  index: number;
  onDelete: (index: number) => void;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
  disabled?: boolean;
  showVideoIcon?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    minWidth: 140,
    margin: 4,
    maxWidth: 140,
    position: 'relative',
    zIndex: 10,
    textAlign: 'center',
    '&:hover $deleteButton': {
      opacity: 1
    }
  },
  containerEnabled: {
    '&:hover $image': {
      opacity: 0.4,
      cursor: 'move'
    }
  },
  containerDisabled: {
    '&:hover $image': {
      opacity: 0.4,
      cursor: 'not-allowed'
    }
  },
  image: {
    width: '100%',
    height: '100%',
    color: theme.palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: theme.shape.borderRadius,
    zIndex: 5,
    opacity: 1
  },
  deleteButton: {
    transition: '.5s ease',
    position: 'absolute',
    margin: 'auto',
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // transform: 'translateY(-50%)',
    // width: 30,
    // height: 30
    '&:focus': {
      opacity: 1
    }
  },
  videoIcon: {
    position: 'absolute',
    opacity: 1,
    top: 4,
    right: 4
  }
}));

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ShareAttachmentPreview: React.FC<ShareAttachmentPreviewProps> = ({
  src,
  index,
  onDelete,
  moveItem,
  disabled = false,
  showVideoIcon = false
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'attachment',
      id: index,
      index: index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: 'attachment',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !moveItem || disabled) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;
      const hoverClientX = (clientOffset as any).x - hoverBoundingRect.left;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverClientX > hoverMiddleX) {
        return;
      }

      // Move Card
      moveItem(dragIndex, hoverIndex);

      // Avoid expensive index searches
      item.index = hoverIndex;
    }
  });

  const handleDelete = () => {
    onDelete(index);
  };

  const preview = src ? (
    <img
      src={buildThumbnailUrl(src, 400)}
      role="presentation"
      alt="Preview"
      className={classes.image}
    />
  ) : (
    <VideoIcon className={classes.image} />
  );

  const opacity = isDragging && moveItem ? 0.25 : 1;
  drag(drop(ref));

  return (
    <Grid
      ref={ref}
      style={{ opacity }}
      className={clsx([
        classes.imageContainer,
        { [classes.containerEnabled]: !disabled },
        { [classes.containerDisabled]: disabled }
      ])}
      item
    >
      {preview}
      {showVideoIcon && (
        <span className={classes.videoIcon}>
          <VideoIcon />
        </span>
      )}

      <Fab
        onClick={handleDelete}
        aria-label="delete"
        className={classes.deleteButton}
        size="small"
        disabled={disabled}
      >
        <DeleteIcon />
      </Fab>
    </Grid>
  );
};

export default ShareAttachmentPreview;
