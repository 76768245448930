// App Config

// Properti Settings
const SITE_TITLE = 'Properti';
const SITE_SLUG = 'properti';
const SITE_LOGO_URL_COLLAPSED = '/images/PropertiMasterLogo.png';
const SITE_LOGO_URL_WIDE = '/images/properti_logo_white_bg.svg';
const SITE_TERMS_URL = 'https://www.properti.ai/terms';
const SITE_PRIVACY_URL = 'https://www.properti.ai/privacy';

const APP_CONFIG = {
  SITE_TITLE,
  SITE_SLUG,
  SITE_LOGO_URL_COLLAPSED,
  SITE_LOGO_URL_WIDE,
  SITE_TERMS_URL,
  SITE_PRIVACY_URL
};

export { APP_CONFIG };
