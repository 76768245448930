import Uppy, { BasePlugin, DefaultPluginOptions, UppyFile } from '@uppy/core';

import Compressor from 'compressorjs';

// PLUGIN DETAILS
const PLUGIN_NAME = 'Image Compressor';

// IGNORE FILE SOURCES
const IGNORE_SOURCES = ['Url', 'Canva'];

const FILE_EXT_REGEX = /\.[^/.]+$/;

interface UppyImageCompressorOptions extends DefaultPluginOptions {
  quality?: number;
  type?: 'jpeg' | 'png' | 'auto';
}

interface UppyImageCompressorOpts extends DefaultPluginOptions {
  quality: number;
  type: 'jpeg' | 'png' | 'auto';
}

function extensionTypeByType(
  file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
  type: UppyImageCompressorOpts['type']
) {
  if (type === 'auto') {
    return { extension: file.extension, type: file.type };
  }

  return { extension: type, type: `image/${type}` };
}

export class UppyImageCompressorPlugin extends BasePlugin {
  id: string;
  type: string;
  opts: UppyImageCompressorOpts;

  constructor(uppy: Uppy, opts: UppyImageCompressorOptions = {}) {
    super(uppy, opts);
    this.id = opts.id || 'ImageCompressor';
    this.type = 'modifier';

    const defaultOptions = {
      quality: 0.9,
      type: 'jpeg'
    };

    this.opts = Object.assign({}, defaultOptions, opts);

    // we use those internally in `this.compress`, so they should not be overridden
    delete this.opts.success;
    delete this.opts.error;

    this.prepareUpload = this.prepareUpload.bind(this);
    this.compress = this.compress.bind(this);
  }

  setOptions(newOpts: UppyImageCompressorOpts) {
    super.setOptions(newOpts);
  }

  compress(blob: Blob): Promise<Blob> {
    return new Promise((resolve, reject) => {
      new Compressor(
        blob,
        Object.assign({}, this.opts, {
          success: (result: Blob) => {
            return resolve(result);
          },
          error: (err: any) => {
            return reject(err);
          }
        })
      );
    });
  }

  async void(): Promise<void> {
    return;
  }

  async prepareUpload(fileIds: string[]) {
    const promises = fileIds.map((fileId) => {
      const file = this.uppy.getFile(fileId);
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: 'Compressing Image'
      });

      if (!file || !file.type || !file.size || !file.source) {
        return this.void();
      }

      // Don't edit images imported from Url
      if (IGNORE_SOURCES.includes(file.source)) {
        return this.void();
      }

      if (file.type.split('/')[0] !== 'image') {
        return this.void();
      }

      return this.compress(file.data)
        .then((compressedBlob) => {
          this.uppy.log(
            `[${PLUGIN_NAME}]: Image ${file.id} size before/after compression: ${file.data.size} / ${compressedBlob.size}`
          );

          const { extension, type } = extensionTypeByType(file, this.opts.type);

          // Update filename/path/extension to be jpeg or png
          this.uppy.setFileState(fileId, {
            data: compressedBlob,
            extension: extension,
            type: type,
            name: file.name.replace(FILE_EXT_REGEX, `.${extension}`),
            meta: {
              ...file.meta,
              filename: (file.meta.filename as string).replace(FILE_EXT_REGEX, `.${extension}`),
              name: file.meta.name.replace(FILE_EXT_REGEX, `.${extension}`),
              path: (file.meta.path as string).replace(FILE_EXT_REGEX, `.${extension}`),
              type: type
            }
          });
        })
        .catch((err) => {
          console.error(err);
          this.uppy.log(`[${PLUGIN_NAME}]: Failed to compress ${file.id}:`, 'warning');
          this.uppy.log(err, 'warning');
        });
    });

    const emitPreprocessCompleteForAll = () => {
      fileIds.forEach((fileId) => {
        const file = this.uppy.getFile(fileId);
        this.uppy.emit('preprocess-complete', file);
      });
    };

    // Why emit `preprocess-complete` for all files at once, instead of
    // above when each is processed?
    // Because it leads to StatusBar showing a weird “upload 6 files” button,
    // while waiting for all the files to complete pre-processing.
    await Promise.all(promises);
    return emitPreprocessCompleteForAll();
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}
