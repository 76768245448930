import React from 'react';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';

export interface WorkspaceTypeIconProps {
  type: 'realestate' | 'ecommerce' | 'saas' | 'marketplace' | 'headoffice' | 'other';
  iconProps?: SvgIconProps;
}

const WorkspaceTypeIcon = ({ type, iconProps }: WorkspaceTypeIconProps) => {
  let Icon = StoreIcon;

  switch (type) {
    case 'ecommerce': {
      Icon = StorefrontIcon;
      break;
    }
    case 'saas': {
      Icon = BusinessCenterIcon;
      break;
    }

    case 'realestate': {
      Icon = BusinessIcon;
      break;
    }

    case 'marketplace': {
      Icon = ShopTwoIcon;
      break;
    }

    case 'headoffice': {
      Icon = AccountTreeIcon;
      break;
    }

    default: {
      Icon = StoreIcon;
    }
  }

  return <Icon {...iconProps} />;
};

export default WorkspaceTypeIcon;
