import React from 'react';

import { Grid } from '@material-ui/core';

export interface SelectedImageRatioMessageProps {
  icon: JSX.Element;
  text: string;
}

export function SelectedImageRatioMessage({ icon, text }: SelectedImageRatioMessageProps) {
  return (
    <Grid container item spacing={2} alignItems="center">
      <Grid item style={{ display: 'flex' }}>
        {icon}
      </Grid>
      <Grid item xs>
        {text}
      </Grid>
    </Grid>
  );
}
