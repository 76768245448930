import React from 'react';

import { Box, Button } from '@material-ui/core';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';

import EmptyStatePage from 'components/EmptyStatePage';

const text =
  'This looks like someone is trying to steal your account. Please exit this website and navigate to https://www.properti.ai to continue safely to your account';

const UnsecureProxyPage = () => {
  return (
    <Box>
      <EmptyStatePage
        title="WARNING: Unsecured page"
        text={text}
        icon={<NoEncryptionIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href="https://www.properti.ai"
            target="_parent"
            size="large"
            fullWidth
          >
            Go to Properti
          </Button>
        }
      />
    </Box>
  );
};

export default UnsecureProxyPage;
