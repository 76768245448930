import { useEffect, useState, useCallback } from 'react';

import { Button } from '@material-ui/core';
import { Avatar, Badge, Box, Link, Tooltip } from '@material-ui/core';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import ImpressionsIcon from '@material-ui/icons/VisibilityOutlined';
import { AvatarGroup } from '@material-ui/lab';

import MaterialTable from '@material-table/core';

import _ from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  Order_By,
  Workspace_Posts_Order_By,
  useWorkspaceAgentMappingsQuery,
  useWorkspacePostsCountQuery
} from 'generated/graphql';
import {
  useDeletePostMutation,
  usePostByIdQuery,
  useSavePostDraftMutation,
  useWorkspacePostsQuery,
  Workspace_Posts_Bool_Exp,
  Workspace_Post_Status_Enum,
  CreatePostInput,
  WorkspacePostsQuery,
  Workspace_Content_Type_Enum
} from 'generated/graphql';

import AssociatePropertyDialog from 'components/AssociatePropertyDialog';
import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import PostDeleteDialog from 'components/PostDeleteDialog';
import PostInsightsModal from 'components/PostInsightsModal';
import ShareDrawer from 'components/ShareDrawer';
import StatusIndicator, { Status } from 'components/StatusIndicator';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import TooltipComponent from 'components/Tooltip';
import FacebookAvatar from 'components/avatars/Facebook';
import GoogleMyBusinessAvatar from 'components/avatars/GoogleMyBusiness';
import InstagramAvatar from 'components/avatars/Instagram';
import LinkedInAvatar from 'components/avatars/LinkedIn';
import YouTubeAvatar from 'components/avatars/YouTube';
import CampaignFromPostDialog from 'components/campaigns/templates/CampaignFromPostDialog';
import CalendarAddIcon from 'components/icons/CalendarAdd';
import { ShareLogicVariants } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import {
  createFilter,
  createFilterBySearch,
  usePagination,
  createOrderBy,
  useHiddenColumns
} from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import usePlatforms, { getPlatformLabel } from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

import PostTabs from './components/PostTabs';

const useSearchFilter = createFilterBySearch<Workspace_Posts_Bool_Exp>([
  { name: {} },
  { creative: { title: {} } }
]);

const useStatusFilter = createFilter<Workspace_Posts_Bool_Exp>({ status: {} });

const useContentTypeFilter = createFilter<Workspace_Posts_Bool_Exp>({ content_type: {} });

const usePlatformFilter = createFilter<Workspace_Posts_Bool_Exp, string>({
  args: { _has_key: null }
});

const usePostedFromFilter = createFilter<Workspace_Posts_Bool_Exp>({ head_office_post: {} });

const useOrderBy = createOrderBy<Workspace_Posts_Order_By>(
  [
    {
      created_at: Order_By.Desc
    }
  ],
  [
    'name',
    'id',
    'id', // args.facebook.posting_from.display_name,
    'id', // args.instagram.posting_from.display_name,
    'id', // args.linkedin.posting_from.display_name,
    'id', // args.linkedin_personal.posting_from.display_name,
    'id', // args.gmb.posting_from.display_name,
    'overall_analytics.total_impressions',
    'status',
    'created_at',
    'scheduled_at'
  ],
  {
    orderByOverwrites: {
      'overall_analytics.total_impressions': {
        asc: Order_By.AscNullsLast,
        desc: Order_By.DescNullsLast
      }
    }
  }
);

export default function PostsPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    workspace,
    activeWorkspaceId,
    isWorkspaceAnalyst,
    isWorkspaceAgent,
    isWorkspaceAdmin
  } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [searchFilter, search, setSearch] = useSearchFilter();
  const [statusFilter, status, setStatus] = useStatusFilter();
  const [platformFilter, platform, setPlatform] = usePlatformFilter();
  const [contentTypeFilter, contentType, setContentType] = useContentTypeFilter();
  const [, postedFrom, setPostedFrom] = usePostedFromFilter();
  const { limit, offset, page, setLimit, setPage } = usePagination();
  const { hiddenColumns, handleOnChangeColumnHidden } = useHiddenColumns({
    hiddenByDefault: [
      'args.facebook.posting_from.display_name',
      'args.instagram.posting_from.display_name',
      'args.linkedin.posting_from.display_name',
      'args.linkedin_personal.posting_from.display_name',
      'args.gmb.posting_from.display_name'
    ]
  });

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [, , meta] = usePlatforms();

  const [insightsPostId, setInsightsPostId] = useState<string>();
  const [insightsOpen, setInsightsOpen] = useState<boolean>(false);
  const [associatePostId, setAssociatePostId] = useState<string>();
  const [associateOpen, setAssociateOpen] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [boostPostId, setBoostPostId] = useState<string>();
  const [boostModelOpen, setBoostModelOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletePostData, setDeletePostData] = useState<WorkspacePostsQuery['posts'][0]>();
  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.posts.filter.agent`
  );

  const [orderBy, handleOnOrderChange] = useOrderBy();

  const where: Workspace_Posts_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { ...searchFilter },
      { ...statusFilter },
      { ...platformFilter },
      { ...contentTypeFilter }
    ]
  };

  if (postedFrom === 'Head Office') {
    where._and!.push({
      head_office_post: { head_office_post_id: { _is_null: false } }
    });
  } else if (postedFrom === 'Workspace') {
    where._and!.push({
      _not: { head_office_post: { head_office_post_id: { _is_null: false } } }
    });
  }

  if (filterAgentName) {
    where._and!.push({
      _or: [
        {
          created_by: { display_name: { _eq: filterAgentName } }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _eq: filterAgentName } } }
            }
          }
        }
      ]
    });
  }

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        }
      ]
    });
  }

  const { data, loading, refetch } = useWorkspacePostsQuery({
    variables: {
      where: where,
      limit: limit,
      offset: offset,
      order_by: orderBy
    },
    pollInterval: 100000,
    context: workspaceMemberContext
  });

  const { data: agentsData } = useWorkspaceAgentMappingsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const agents =
    agentsData?.workspace_agent_mapping?.filter(
      (map) =>
        !isWorkspaceAgent ||
        agentPermissions.allowed_agents.includes(map.listing_agent_name.trim()) ||
        map.user_id === userId
    ) ?? [];

  const agentNameOptions = agents
    ?.filter(Boolean)
    .map((agent) => ({ value: agent.user?.display_name!, text: agent.user?.display_name! }));

  const { refetch: fetchPostById } = usePostByIdQuery({
    context: workspaceMemberContext,
    skip: true
  });
  const [deletePost] = useDeletePostMutation({
    context: workspaceMemberContext
  });
  const [clonePost] = useSavePostDraftMutation({
    context: workspaceMemberContext
  });

  // https://github.com/mbrn/material-table/issues/1979
  const posts = data?.posts?.map((p) => ({ ...p }));
  const hasHeadOffice = Boolean(workspace?.head_offices?.length);

  const handleOpenPostInsightsModal = useCallback(
    (postId: string) => {
      setInsightsPostId(postId);
      setInsightsOpen(true);
    },
    [setInsightsPostId, setInsightsOpen]
  );

  const handleOpenAssociateProperty = useCallback(
    (postId: string) => {
      setAssociatePostId(postId);
      setAssociateOpen(true);
    },
    [setAssociatePostId, setAssociateOpen]
  );

  const handleClosePostInsightsModal = () => {
    setInsightsOpen(false);
  };

  const handleCloseAssociateProperty = () => {
    setAssociateOpen(false);
  };

  const handleDeletePost = async (id: string) => {
    try {
      await deletePost({
        variables: {
          post_id: id
        }
      });
      enqueueSnackbar('Post deleted', { variant: 'success' });
      refetch();
    } catch (error) {
      enqueueSnackbar('Unable to delete Post', { variant: 'error' });
    }
  };

  const handleConfirmDeletePost = (id: string) => {
    confirm({
      title: `Delete Post`,
      description: 'Are you sure you want to delete this post? '
    }).then(() => handleDeletePost(id));
  };

  const handleConfirmDeletePostFromPlatform = (data: WorkspacePostsQuery['posts'][0]) => {
    setDeletePostData(data);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    refetch();
  };

  const handleClonePost = async (id: string) => {
    try {
      const resp = await fetchPostById({ id });
      const post = resp?.data?.workspace_posts_by_pk;

      if (!post) {
        throw new Error('Unable to find post to clone');
      }

      const scheduleAtTimestamp = moment(post.scheduled_at).isBefore(moment())
        ? moment()
        : post.scheduled_at;

      // Ensure agents don't clone posts with non-allowed pages
      let cloneArgs: CreatePostInput = _.cloneDeep(post.args);
      if (isWorkspaceAgent) {
        // FACEBOOK
        if (cloneArgs.facebook) {
          const defaultAgentFacebookPageId = agentPermissions.allowed_facebook_pages[0];
          const defaultAgentFacebookPage = meta.facebook?.allowedPages?.find(
            (page) => page.id === defaultAgentFacebookPageId
          );
          if (!defaultAgentFacebookPageId || !defaultAgentFacebookPage) {
            delete cloneArgs.facebook;
          } else {
            cloneArgs.facebook.posting_from = defaultAgentFacebookPage;
          }
        }

        // INSTAGRAM
        if (cloneArgs.instagram) {
          const defaultAgentInstagramPageId = agentPermissions.allowed_instagram_pages[0];
          const defaultAgentInstagramPage = meta.instagram?.allowedPages?.find(
            (page) => page.id === defaultAgentInstagramPageId
          );
          if (!defaultAgentInstagramPageId || !defaultAgentInstagramPage) {
            delete cloneArgs.instagram;
          } else {
            cloneArgs.instagram.posting_from = defaultAgentInstagramPage;
          }
        }

        // LINKEDIN
        if (cloneArgs.linkedin) {
          const defaultAgentLinkedinPageId = agentPermissions.allowed_linkedin_pages[0];
          const defaultAgentLinkedinPage = meta.linkedin?.allowedPages?.find(
            (page) => page.id === defaultAgentLinkedinPageId
          );
          if (!defaultAgentLinkedinPageId || !defaultAgentLinkedinPage) {
            delete cloneArgs.linkedin;
          } else {
            cloneArgs.linkedin.posting_from = defaultAgentLinkedinPage;
          }
        }

        // LINKEDIN PERSONAL
        if (cloneArgs.linkedin_personal) {
          const defaultAgentLinkedinPersonalPage = meta.linkedinPersonal?.users?.find(
            (page) => page.id === userId
          );
          if (!userId || !defaultAgentLinkedinPersonalPage) {
            delete cloneArgs.linkedin_personal;
          } else {
            cloneArgs.linkedin_personal.posting_from = defaultAgentLinkedinPersonalPage;
          }
        }

        // YOUTUBE
        if (cloneArgs.youtube) {
          const defaultAgentYoutubePageId = agentPermissions.allowed_youtube_pages[0];
          const defaultAgentYouTubePage = meta.youtube?.allowedChannels?.find(
            (page) => page.id === defaultAgentYoutubePageId
          );
          if (!defaultAgentYoutubePageId || !defaultAgentYouTubePage) {
            delete cloneArgs.youtube;
          } else {
            cloneArgs.youtube.posting_from = defaultAgentYouTubePage;
          }
        }

        // GOOGLE MY BUSINESS
        if (cloneArgs.gmb) {
          const defaultAgentGoogleMyBusinessPageId = agentPermissions.allowed_gmb_pages[0];
          const defaultAgentGoogleMyBusinessPage = meta.gmb?.allowedLocations?.find(
            (page) => page.id === defaultAgentGoogleMyBusinessPageId
          );
          if (!defaultAgentGoogleMyBusinessPageId || !defaultAgentGoogleMyBusinessPage) {
            delete cloneArgs.gmb;
          } else {
            cloneArgs.gmb.posting_from = defaultAgentGoogleMyBusinessPage;
          }
        }
      }

      await clonePost({
        variables: {
          workspace_id: post.workspace_id,
          scheduled_at: scheduleAtTimestamp,
          message: post.message,
          args: cloneArgs,
          listing_id: post.listing_id,
          creative_id: post.creative_id,
          name: `${post.name ?? 'Manual Post'} Clone`
        }
      });

      await refetch();
      enqueueSnackbar('Post cloned!', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to clone Post', { variant: 'error' });
    }
  };

  const handleOpenCreateFromTemplateModal = (postId: string) => {
    setBoostPostId(postId);
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const {
    data: postCount,
    loading: postCountLoading,
    refetch: refetchPostCount
  } = useWorkspacePostsCountQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const [draftPostId, setDraftPostId] = useState<string | null>(null);
  const [refetchCount, setRefetchCount] = useState<number>(0);

  const handleOpenShareDrawer = () => {
    setDraftPostId(null);
    setShareDrawerOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareDrawerOpen(false);
    refetchPostCount();
    setRefetchCount(refetchCount + 1);
  };

  const handleEditPost = (postId: string) => {
    setDraftPostId(postId);
    setShareDrawerOpen(true);
  };

  useEffect(() => {
    if (refetchCount) {
      refetch();
    }
  }, [refetchCount, refetch]);

  if (
    !postCountLoading &&
    postCount?.workspace_posts_aggregate?.aggregate?.count === 0 &&
    postCount?.workspace_automation_rules_aggregate?.aggregate?.count === 0
  ) {
    return (
      <>
        <EmptyStatePage
          title="Posts"
          text="In this section you can view, edit or schedule your upcoming posts."
          icon={<CalendarAddIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
          button={
            <Button
              onClick={handleOpenShareDrawer}
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst}
              fullWidth
            >
              Create a Post
            </Button>
          }
        />
        <ShareDrawer
          open={shareDrawerOpen}
          onClose={handleCloseShareDrawer}
          title="New Social Post"
          variant={ShareLogicVariants.UPLOAD}
          disabled={isWorkspaceAnalyst}
        />
      </>
    );
  }

  return (
    <>
      <div>
        <PageHeader
          title="Posts"
          rightComponent={
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleOpenShareDrawer}
              disabled={isWorkspaceAnalyst}
              style={{ minWidth: 160 }}
            >
              New Post
            </Button>
          }
        />
        <ShareDrawer
          open={shareDrawerOpen}
          onClose={handleCloseShareDrawer}
          title="New Social Post"
          variant={ShareLogicVariants.UPLOAD}
          disabled={isWorkspaceAnalyst}
          draftPostId={draftPostId}
        />
      </div>
      <PostTabs value={0} showHeadOfficeContentSchedule={hasHeadOffice && isWorkspaceAdmin} />
      <MaterialTable
        title="Posts"
        columns={[
          {
            title: 'Creative',
            field: 'name',
            sorting: true,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('name'),
            render: RenderNameColumn
          },
          {
            title: 'Platforms',
            field: 'args',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args'),
            render: RenderPlatformsColumn
          },
          {
            title: 'Facebook Page',
            field: 'args.facebook.posting_from.display_name',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args.facebook.posting_from.display_name')
          },
          {
            title: 'Instagram Account',
            field: 'args.instagram.posting_from.display_name',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args.instagram.posting_from.display_name')
          },
          {
            title: 'LinkedIn Organisation',
            field: 'args.linkedin.posting_from.display_name',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args.linkedin.posting_from.display_name')
          },
          {
            title: 'LinkedIn Personal Profile',
            field: 'args.linkedin_personal.posting_from.display_name',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args.linkedin_personal.posting_from.display_name')
          },
          {
            title: 'Google My Business Location Channel',
            field: 'args.gmb.posting_from.display_name',
            sorting: false,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('args.gmb.posting_from.display_name')
          },
          {
            title: 'Insights',
            field: 'overall_analytics.total_impressions',
            sorting: true,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('overall_analytics.total_impressions'),
            render: RenderInsightsColumn
          },
          {
            title: 'Status',
            field: 'status',
            sorting: true,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('status'),
            render: RenderStatusColumn
          },
          {
            title: 'Date Created',
            field: 'created_at',
            sorting: true,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('created_at'),
            render: RenderDateCreatedColumn
          },
          {
            title: 'Post Date',
            field: 'scheduled_at',
            sorting: true,
            hiddenByColumnsButton: true,
            hidden: hiddenColumns.includes('scheduled_at'),
            render: RenderPostDateColumn
          }
        ]}
        data={posts ?? []}
        totalCount={data?.aggregate.aggregate?.count ?? undefined}
        isLoading={loading}
        options={{
          search: true,
          toolbar: true,
          draggable: false,
          showTitle: false,
          columnsButton: true,
          sorting: true,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 25, 50, 100]
        }}
        actions={[
          (data) => ({
            icon: 'assessment',
            tooltip: 'View Post Insights',
            onClick: () => {
              handleOpenPostInsightsModal(data.id);
            },
            disabled: !data.posted || !Boolean(data.overall_analytics),
            hidden: data.draft || !data.posted
          }),
          (data) => {
            const isPosting =
              data.status === Workspace_Post_Status_Enum.Posted ||
              data.status === Workspace_Post_Status_Enum.Queued ||
              data.status === Workspace_Post_Status_Enum.Posting ||
              (data.status === Workspace_Post_Status_Enum.Scheduled &&
                data.scheduled_at &&
                moment(data.scheduled_at).isSameOrBefore(moment().add(1, 'minutes')));
            return {
              icon: 'edit',
              tooltip: isPosting
                ? 'Posts currently being processed cannot be modified'
                : 'Edit Post',
              onClick: () => {
                handleEditPost(data.id);
              },
              disabled: isWorkspaceAnalyst || isPosting,
              hidden: !data.draft && data.posted
            };
          },
          (data) => ({
            icon: 'file_copy',
            tooltip: 'Clone post',
            onClick: () => {
              handleClonePost(data.id);
            },
            disabled: isWorkspaceAnalyst
          }),
          (data) => ({
            icon: 'home_work',
            tooltip: 'Associate Property',
            onClick: () => {
              handleOpenAssociateProperty(data.id);
            },
            disabled: isWorkspaceAnalyst
          }),
          (data) => ({
            icon: BullhornIcon,
            tooltip: 'Boost Post',
            onClick: () => {
              handleOpenCreateFromTemplateModal(data.id);
            },
            disabled:
              isWorkspaceAnalyst ||
              !data.facebook_post_id ||
              data.deleted_platforms.includes('facebook')
          }),
          (data) => {
            const isPosting =
              data.status === Workspace_Post_Status_Enum.Posting ||
              data.status === Workspace_Post_Status_Enum.Queued ||
              (data.status === Workspace_Post_Status_Enum.Scheduled &&
                data.scheduled_at &&
                moment(data.scheduled_at).isSameOrBefore(moment().add(1, 'minutes')));

            return {
              icon: 'delete',
              tooltip: isPosting ? 'Posts currently being processed cannot be modified' : 'Discard',
              onClick: () => {
                if (
                  [Workspace_Post_Status_Enum.Posted, Workspace_Post_Status_Enum.Deleted].includes(
                    data.status
                  )
                ) {
                  handleConfirmDeletePostFromPlatform(data);
                } else {
                  handleConfirmDeletePost(data.id);
                }
              },
              disabled: isWorkspaceAnalyst || isPosting
            };
          }
        ]}
        onRowsPerPageChange={setLimit}
        onPageChange={setPage}
        onOrderChange={handleOnOrderChange}
        onChangeColumnHidden={handleOnChangeColumnHidden}
        page={page}
        components={{
          Container: TableContainer,
          Toolbar: (props: any) => (
            <Toolbar
              {...props}
              setSearch={setSearch}
              searchValue={search}
              onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
              filtersOpen={filtersOpen}
              menuItems={[
                {
                  name: 'Posted From',
                  options: [
                    { value: 'Head Office', text: 'Head Office Posts' },
                    { value: 'Workspace', text: 'Workspace Posts' }
                  ],
                  value: postedFrom,
                  setValue: setPostedFrom,
                  hasAll: true,
                  hidden: !hasHeadOffice
                },
                {
                  name: 'Platform',
                  options: [
                    { value: 'facebook', text: getPlatformLabel('facebook') },
                    { value: 'instagram', text: getPlatformLabel('instagram') },
                    { value: 'linkedin', text: getPlatformLabel('linkedin') },
                    { value: 'linkedin_personal', text: getPlatformLabel('linkedin_personal') },
                    { value: 'youtube', text: getPlatformLabel('youtube') },
                    { value: 'gmb', text: getPlatformLabel('gmb') }
                  ],
                  value: platform,
                  setValue: setPlatform,
                  hasAll: true
                },
                {
                  name: 'Status',
                  options: [
                    { value: Workspace_Post_Status_Enum.Draft, text: 'Draft' },
                    { value: Workspace_Post_Status_Enum.Posted, text: 'Posted' },
                    { value: Workspace_Post_Status_Enum.Posting, text: 'Posting' },
                    { value: Workspace_Post_Status_Enum.Scheduled, text: 'Scheduled' },
                    { value: Workspace_Post_Status_Enum.Error, text: 'Error' }
                  ],
                  value: status,
                  setValue: setStatus,
                  hasAll: true
                },
                {
                  name: 'Content Category',
                  options: [
                    {
                      value: Workspace_Content_Type_Enum.AuctionClearanceRates,
                      text: 'Auction Clearances'
                    },
                    {
                      value: Workspace_Content_Type_Enum.AuthorityBuilding,
                      text: 'Authority Building'
                    },
                    { value: Workspace_Content_Type_Enum.Listing, text: 'Listing' },
                    { value: Workspace_Content_Type_Enum.MarketData, text: 'Market Data' },
                    { value: Workspace_Content_Type_Enum.NewsUpdate, text: 'News Article' },
                    {
                      value: Workspace_Content_Type_Enum.SocialProof,
                      text: 'Testimonials & Social Proof'
                    },
                    { value: Workspace_Content_Type_Enum.Personal, text: 'Personal' },
                    { value: Workspace_Content_Type_Enum.GenericPost, text: 'Generic' },
                    { value: Workspace_Content_Type_Enum.Other, text: 'Other' }
                  ],
                  value: contentType,
                  setValue: setContentType,
                  hasAll: true
                },
                {
                  name: 'Agent',
                  value: filterAgentName,
                  setValue: setFilterAgentName,
                  options: agentNameOptions,
                  hasAll: true
                }
              ]}
            />
          )
        }}
      />
      <PostInsightsModal
        postId={insightsPostId}
        open={insightsOpen}
        onClose={handleClosePostInsightsModal}
      />
      <AssociatePropertyDialog
        variant="post"
        open={associateOpen}
        onClose={handleCloseAssociateProperty}
        postId={associatePostId as string}
      />
      <CampaignFromPostDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        postId={boostPostId}
      />
      <PostDeleteDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        postData={deletePostData}
      />
    </>
  );
}

function RenderNameColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  if (rowData.name) {
    return rowData.name;
  } else if (rowData.creative) {
    return rowData.creative.title;
  } else if (rowData.listing_id) {
    return 'Listing Post';
  }

  return 'Manual Post';
}

function RenderPlatformsColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  const isDraft = rowData.draft;
  const hasFacebook = Boolean(rowData.args?.facebook);
  const hasInstagram = Boolean(rowData.args?.instagram);
  const hasLinkedIn = Boolean(rowData.args?.linkedin);
  const hasLinkedInPersonal = Boolean(rowData.args?.linkedin_personal);
  const hasYouTube = Boolean(rowData.args?.youtube);
  const hasGoogleMyBusiness = Boolean(rowData.args?.gmb);

  const creatorAvatarSrc = getFilePath(
    rowData?.args?.linkedin_personal?.posting_from?.avatar_url ?? rowData?.created_by?.avatar_url,
    rowData?.args?.linkedin_personal?.posting_from?.avatar_token ??
      rowData?.created_by?.avatar_token
  );

  const fbPageId = rowData.facebook_post_id?.includes('_')
    ? rowData.facebook_post_id?.split('_')[0]
    : rowData.args?.facebook?.posting_from?.id;

  const fbPostId = rowData.facebook_post_id?.includes('_')
    ? rowData.facebook_post_id?.split('_')[1]
    : rowData.facebook_post_id;

  const isFacebookReady = Boolean(fbPostId);
  const isLinkedInReady = Boolean(rowData.linkedin_post_id);
  const isYouTubeReady = Boolean(rowData.youtube_post_id);
  const isLinkedInPersonalReady = Boolean(rowData.linkedin_personal_post_id);
  const isInstagramReady = Boolean(rowData.instagram_permalink);
  const isGoogleMyBusinessReady = Boolean(rowData.google_my_business_permalink);

  // Permalinks
  const fbHref = isFacebookReady
    ? rowData.facebook_permalink ?? `https://facebook.com/${fbPageId}/posts/${fbPostId}`
    : undefined;
  const igHref = rowData.instagram_permalink ?? undefined;
  const liHref = isLinkedInReady
    ? `https://linkedin.com/feed/update/${rowData.linkedin_post_id}`
    : undefined;
  const ytHref = isYouTubeReady ? `https://youtu.be/${rowData.youtube_post_id}` : undefined;
  const lipHref = isLinkedInPersonalReady
    ? `https://linkedin.com/feed/update/${rowData.linkedin_personal_post_id}`
    : undefined;
  const gmbHref = rowData.google_my_business_permalink ?? undefined;

  // Disable avatars if draft or no href
  const fbDisabled = isDraft || !fbHref;
  const igDisabled = isDraft || !igHref;
  const liDisabled = isDraft || !liHref;
  const lipDisabled = isDraft || !lipHref;
  const ytDisabled = isDraft || !ytHref;
  const gmbDisabled = isDraft || !gmbHref;

  return (
    <AvatarGroup color="primary" max={5}>
      {hasFacebook && (
        <Tooltip
          title={`${isFacebookReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.facebook_page)?.facebook_page?.display_name ??
            'Facebook'
          }`}
        >
          <Link style={{ border: 'none' }} href={fbHref} target="_blank">
            <FacebookAvatar disabled={fbDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasInstagram && (
        <Tooltip
          title={`${isInstagramReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.instagram_page)?.instagram_page?.display_name ??
            'Instagram'
          }`}
        >
          <Link style={{ border: 'none' }} href={igHref} target="_blank">
            <InstagramAvatar disabled={igDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasLinkedIn && (
        <Tooltip
          title={`${isLinkedInReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.linkedin_page)?.linkedin_page?.display_name ??
            'LinkedIn'
          }`}
        >
          <Link style={{ border: 'none' }} href={liHref} target="_blank">
            <LinkedInAvatar disabled={liDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasYouTube && (
        <Tooltip
          title={`${isYouTubeReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.youtube_page)?.youtube_page?.display_name ??
            'Youtube'
          }`}
        >
          <Link style={{ border: 'none' }} href={ytHref} target="_blank">
            <YouTubeAvatar disabled={ytDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasGoogleMyBusiness && (
        <Tooltip
          title={`${isGoogleMyBusinessReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.gmb_page)?.gmb_page?.display_name ??
            'Google My Business'
          }`}
        >
          <Link style={{ border: 'none' }} href={gmbHref} target="_blank">
            <GoogleMyBusinessAvatar disabled={gmbDisabled} />
          </Link>
        </Tooltip>
      )}
      {hasLinkedInPersonal && (
        <Tooltip
          title={`${isLinkedInPersonalReady ? 'Posted' : 'Posting'} on ${
            rowData.posted_on?.find((page) => page.linkedin_personal_page)?.linkedin_personal_page
              ?.display_name ?? 'LinkedIn'
          }`}
        >
          <Link style={{ border: 'none' }} href={lipHref} target="_blank">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<Avatar src={creatorAvatarSrc} style={{ width: 20, height: 20 }} />}
            >
              <LinkedInAvatar disabled={lipDisabled} />
            </Badge>
          </Link>
        </Tooltip>
      )}
    </AvatarGroup>
  );
}

function RenderInsightsColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  if (!rowData.posted || !rowData.overall_analytics) {
    return '-';
  }

  return (
    <span style={{ display: 'flex', justifyContent: 'flex-start', gap: 16 }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <ImpressionsIcon fontSize="small" />
        {rowData.overall_analytics?.total_impressions ?? '-'}
      </span>
      <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <ThumbsUpDownIcon fontSize="small" />
        {rowData.overall_analytics?.total_engagement ?? '-'}
      </span>
    </span>
  );
}

function RenderStatusColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  const tooltipHeading = null;
  const tooltipText = 'This post has been deleted from some or all platforms';

  if (rowData.status === Workspace_Post_Status_Enum.Posted) {
    return <StatusIndicator status={Status.ACTIVE} text="Posted" />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Draft) {
    return <StatusIndicator status={Status.DRAFT} text="Draft" />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Posting) {
    return <StatusIndicator status={Status.PENDING} text="Posting" pulse />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Scheduled) {
    return <StatusIndicator status={Status.PENDING} text="Scheduled" />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Queued) {
    return <StatusIndicator status={Status.PENDING} text="Queued" />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Error) {
    return <StatusIndicator status={Status.ERROR} text="Error" />;
  } else if (rowData.status === Workspace_Post_Status_Enum.Deleted) {
    return (
      <>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <StatusIndicator status={Status.DELETED} text="Posted" />
          <TooltipComponent heading={tooltipHeading} tooltip={tooltipText} />
        </Box>
      </>
    );
  }

  return <StatusIndicator status={Status.DRAFT} text="Draft" />;
}

function RenderDateCreatedColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  return moment(rowData.created_at).format('D MMM YYYY');
}

function RenderPostDateColumn(rowData: WorkspacePostsQuery['posts'][number]) {
  if (rowData.scheduled_at) {
    return moment(rowData.scheduled_at).format('D MMM YYYY h:mm A');
  } else if (rowData.posted) {
    return moment(rowData.created_at).format('D MMM YYYY h:mm A');
  } else {
    return 'N/A';
  }
}
