import React from 'react';

import { Form } from 'react-final-form';

import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';

import DialogTitle from 'components/DialogTitle';
import PropertySelect from 'components/forms/PropertySelect';

interface AssociatePropertyBaseDialogProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onSave: (values: FormValues) => Promise<void>;
  refetch: () => Promise<any>;
  initialValues: string[];
}

export interface FormValues {
  property_ids: string[];
}

const AssociatePropertyBaseDialog: React.FC<AssociatePropertyBaseDialogProps> = ({
  open,
  onClose,
  loading,
  onSave,
  refetch,
  initialValues
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = async (values: FormValues) => {
    try {
      await onSave(values);
      enqueueSnackbar('Associated Properties Saved', { variant: 'success' });
      refetch();
      onClose();
    } catch (error) {
      return { [FORM_ERROR]: error.message ?? 'Unable to save properties' };
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>Associate Properties</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleSave}
          initialValues={{
            property_ids: initialValues
          }}
          render={({ handleSubmit, submitError, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <PropertySelect name="property_ids" multiple={true} />
                {submitError && (
                  <Typography variant="caption" color="error">
                    Error saving
                  </Typography>
                )}
                <div style={{ float: 'right' }}>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={submitting}>
                    {submitting ? 'Saving ...' : 'Save'}
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AssociatePropertyBaseDialog;
