// Track using segment
export const track = (event: string, data?: any) => {
  const analytics = (window as any).analytics;
  if (analytics) {
    try {
      if (data) {
        analytics.track(event, data);
      } else {
        analytics.track(event);
      }
    } catch (error) {
      // noop
    }
  }
};

// Track Page Events
export const trackPage = (page: string) => {
  const analytics = (window as any).analytics;
  if (analytics) {
    try {
      analytics.page(page);
    } catch (error) {
      // noop
    }
  }
};
