import React from 'react';

import { Link } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import useUserContext from 'lib/hooks/useUserContext';

type CampaignTabsProps = {
  value: number;
  agentName?: string | null;
  userId: string;
};

function getTabs(value: number, userId: string, agentName?: string, subscriptionModel?: string) {
  let tabs = [
    { label: 'Workspace', to: '/campaigns' },
    { label: 'View By Agent', to: '/campaigns/agents' },
    { label: 'Calendar', to: '/campaigns/calendar' },
    { label: agentName ?? 'This Agent', to: `/campaigns/agents/${userId}` },
    { label: 'Monthly Spend', to: '/campaigns/reports/monthly-spend' },
    { label: 'Status & Budget', to: '/campaigns/reports/status-budget' }
  ];

  if (subscriptionModel === 'basic') {
    tabs = tabs.filter((tab) => tab.label === 'Workspace' || tab.label === 'Calendar');
  }

  return tabs.map((tab, idx) => {
    const props = value === idx ? {} : { component: Link, to: tab.to };
    return <Tab label={tab.label} {...props} key={idx} />;
  });
}

// This is so we can follow DRY principles
export default function CampaignTabs({ userId, value, agentName }: CampaignTabsProps) {
  const { subscription_model, loading } = useUserContext();

  if (loading) {
    return null;
  }

  return (
    <Tabs textColor="secondary" value={value}>
      {getTabs(value, userId, agentName ?? undefined, subscription_model)}
    </Tabs>
  );
}
