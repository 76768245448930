import type { Maybe } from "./graphql";
export type BoolOrComparisonExp = AnyComparisonExp | AnyBoolExp;
export type AnyComparisonExp = Record<ComparisonOperators, unknown>;
export type AnyBoolExp = {
    [property: string]: any;
} & {
    _and?: Maybe<AnyBoolExp[]>;
    _or?: Maybe<AnyBoolExp[]>;
    _not?: Maybe<AnyBoolExp>;
};
export const comparisonOperatorTerms = ["_eq", "_gt", "_gte", "_in", "_is_null", "_lt", "_lte", "_neq", "_nin", "_ilike", "_iregex", "_like", "_nilike", "_niregex", "_nlike", "_nregex", "_nsimilar", "_regex", "_similar", "_cast", "_st_d_within", "_st_intersects", "_st_3d_d_within", "_st_3d_intersects", "_st_contains", "_st_crosses", "_st_equals", "_st_overlaps", "_st_touches", "_st_within", "_contained_in", "_contains", "_has_key", "_has_keys_all", "_has_keys_any"] as const;
export type ComparisonOperators = (typeof comparisonOperatorTerms)[number];
export function isComparisonExp(obj: BoolOrComparisonExp): obj is AnyComparisonExp {
    return Object.keys(obj).every(key => comparisonOperatorTerms.includes(key as keyof BoolOrComparisonExp));
}
export function isBoolExp(obj: BoolOrComparisonExp): obj is AnyBoolExp {
    return !isComparisonExp(obj);
}
