import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'emoji-mart/css/emoji-mart.css';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarKey, SnackbarProvider } from 'notistack';

import ActiveSubscriptionOutlet from 'components/ActiveSubscriptionOutlet';
import Layout from 'components/Layout';
import LoadingIndicator from 'components/LoadingIndicator';

import { AdminModeContextProvider } from 'lib/AdminModeContext';
import { CanvaContextProvider } from 'lib/CanvaContext';
import { HasuraRoleContextProvider } from 'lib/HasuraRoleContext';
import { HeadOfficeModeContextProvider } from 'lib/HeadOfficeModeContext';
import ListingImageSelectProvider from 'lib/ListingContext/ListingImageSelectProvider';
import { LoggerContextProvider } from 'lib/LoggerContext';
import { RocketiumContextProvider } from 'lib/RocketiumContext';
import { UserContext, UserContextProvider } from 'lib/UserContext';
import { UserDataContextProvider } from 'lib/UserContext/UserDataContext';
import { buildApolloClient } from 'lib/graphql/apollo';

import AdminOutlet from 'pages/admin/AdminOutlet';
import CanvaConnectionPopupPage from 'pages/auth/apps/canva';
import ForgotPasswordPage from 'pages/auth/forgot';
import LoginPage from 'pages/auth/login';
import RegisterPage from 'pages/auth/register';
import ResetPasswordPage from 'pages/auth/reset';
import NotFoundPage from 'pages/errors/404';
import UnsecureProxyPage from 'pages/errors/407';
import AudiencesPage from 'pages/workspace/Audiences';
import AutomationPage from 'pages/workspace/Automation';
import CampaignsPage from 'pages/workspace/Campaigns';
import CampaignsCalendarPage from 'pages/workspace/Campaigns/pages/CampaignsCalendarPage';
import ContentFeedPage from 'pages/workspace/Content';
import CreativePage from 'pages/workspace/Creatives';
import CustomerReviewsPage from 'pages/workspace/CustomerReviews';
import DashboardPage from 'pages/workspace/Dashboard';
import LeadsPage from 'pages/workspace/Leads';
import LeadAutoResponderPage from 'pages/workspace/Leads/autoresponder';
import LeadsByFormPage from 'pages/workspace/Leads/formleads';
import LeadGenFormsPage from 'pages/workspace/Leads/forms';
import LinksPage from 'pages/workspace/Links';
import PostsPage from 'pages/workspace/Posts';
import PostsAnalyticsPage from 'pages/workspace/Posts/tabs/PostAnalytics';
import PostCalendar from 'pages/workspace/Posts/tabs/PostCalendar';
import PostsReportPage from 'pages/workspace/Posts/tabs/PostsReport';
import PropertiesPage from 'pages/workspace/Properties';
import DraftVideosPage from 'pages/workspace/RealShortz/tabs/draftVideos';
import Callback from 'pages/workspace/Store/Callback';
import WelcomePage from 'pages/workspace/Welcome';

import theme from './theme';

// Head Office Router
const HeadOfficeRouter = lazy(() => import('pages/headoffice/Router'));

// Other Pages lazy load these ones
const ProfilePage = lazy(() => import('pages/account/Profile'));
const UserProfile = lazy(() => import('pages/workspace/Team/Upsert'));
const MyRealShortzPage = lazy(() => import('pages/account/RealShortz'));
const ListWorkspacesPage = lazy(() => import('pages/account/Workspaces'));
const ListHeadOfficesPage = lazy(() => import('pages/account/HeadOffices'));

const CreateWorkspacePage = lazy(() => import('pages/workspace/Create'));
const SettingsPage = lazy(() => import('pages/workspace/Settings'));
const BillingPage = lazy(() => import('pages/workspace/Billing'));
const BillingSetupPage = lazy(() => import('pages/workspace/Billing/Setup'));
const BillingChooseSubscriptionPage = lazy(() => import('pages/workspace/Billing/Subscription'));
const BrandingPage = lazy(() => import('pages/workspace/Branding'));
const CampaignTemplatePage = lazy(() => import('pages/workspace/Campaigns/pages/templates'));
const CampaignsSimpleViewPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsSimpleViewPage')
);
const CreativesCreatePage = lazy(() => import('pages/workspace/Creatives/Create'));
const TeamPage = lazy(() => import('pages/workspace/Team'));
const TeamInvitesPage = lazy(() => import('pages/workspace/Team/Invites'));
const TeamFallbackInfoPage = lazy(() => import('pages/workspace/Team/FallbackInfo'));
const TeamMappingsPage = lazy(() => import('pages/workspace/Team/Mappings'));
const AppsPage = lazy(() => import('pages/workspace/Apps'));
const AppsAppPage = lazy(() => import('pages/workspace/Apps/[app]'));
const AudiencesCreatePage = lazy(() => import('pages/workspace/Audiences/Create'));
const AudiencesCreateSourcePage = lazy(() => import('pages/workspace/Audiences/Create/[source]'));
const CreativesCreateTemplatesPage = lazy(
  () => import('pages/workspace/Creatives/Create/[template]')
);
const CreativesEditTemplatesPage = lazy(() => import('pages/workspace/Creatives/Edit/[template]'));
const RealShortzPage = lazy(() => import('pages/workspace/RealShortz/tabs/finalisedVideos'));
const PostsContentSchedulePage = lazy(
  () => import('pages/workspace/Posts/tabs/PostsContentSchedule')
);

// Properti Admin
const CustomTemplates = lazy(() => import('pages/admin/CustomTemplates'));
const CreateTemplate = lazy(() => import('pages/admin/CustomTemplates/create'));
const EditTemplate = lazy(() => import('pages/admin/CustomTemplates/edit'));
const BrandPacksPage = lazy(() => import('pages/admin/BrandPacks'));
const EditBrandPackPage = lazy(() => import('pages/admin/BrandPacks/edit/EditBrandPackPage'));

const CampaignsViewCampaignPage = lazy(() => import('pages/workspace/Campaigns/View/[id]'));
const CampaignsCreatePage = lazy(() => import('pages/workspace/Campaigns/Create'));
const CampaignsEditPage = lazy(() => import('pages/workspace/Campaigns/Edit'));
const SimpleCampaignApprovalPage = lazy(
  () => import('pages/workspace/Campaigns/SimpleCampaignApproval')
);
const CampaignsAgentSelectPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsAgentSelectPage')
);
const CampaignsAgentViewPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsAgentViewPage')
);
const CampaignSpendReportPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignSpendReportPage')
);

const CampaignBudgetReportPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsBudgetReportPage')
);
const JoinWorkspacePage = lazy(() => import('pages/workspace/Team/Join'));
const SupportPage = lazy(() => import('pages/support'));
const CreativeViewPage = lazy(() => import('pages/workspace/Creatives/View'));
const CreateAutomationPage = lazy(() => import('pages/workspace/Automation/Create'));
const CreateAutomationRulePage = lazy(() => import('pages/workspace/Automation/Create/Schedule'));
const CreateAutomationTriggerPage = lazy(() => import('pages/workspace/Automation/Create/Trigger'));
const AutomationTriggersInvocationsPage = lazy(
  () => import('pages/workspace/Automation/Triggers/Invocations')
);
const AutomationScheduleInvocationsPage = lazy(
  () => import('pages/workspace/Automation/Schedules/Invocations')
);
const ImportHeadOfficeAutomationPage = lazy(
  () => import('pages/workspace/Automation/Create/HeadOffice')
);

const ViewPropertyPage = lazy(() => import('pages/workspace/Properties/View'));
const LinkedInProfileOAuthCallback = lazy(
  () => import('pages/account/Profile/Apps/linkedin/callback')
);

const NotificationsPage = lazy(() => import('pages/workspace/Notifications'));
const SupportLoginPage = lazy(() => import('pages/auth/support-login'));

const CreateListingPage = lazy(() => import('pages/workspace/Properties/Create'));
const EditListingPage = lazy(() => import('pages/workspace/Properties/Edit'));

// Store
const StoreOutlet = lazy(() => import('pages/workspace/Store/StoreOutlet'));
const Store = lazy(() => import('pages/workspace/Store'));
const PurchaseHistory = lazy(() => import('pages/workspace/Store/History'));
const CreativePurchase = lazy(() => import('pages/workspace/Store/Purchase/Creative'));

const NON_WORKSPACE_ROUTES = [
  '/welcome',
  '/workspaces',
  '/workspaces/create',
  '/profile',
  '/profile/',
  '/join',
  '/support',
  '/headoffices',
  '/head-office',
  '/head-office/',
  '/me/realshortz'
];

const VALID_HEAD_OFFICE_MODE_ROUTES = [
  '/profile',
  '/profiles/',
  '/support',
  '/headoffices',
  '/head-office',
  '/head-office/',
  '/me/realshortz',
  '/login/oauth/canva'
];

function Fallback() {
  const [showHelpMessage, setShowHelpMessage] = useState<boolean>(false);

  // Show link to login page if stuck on Fallback loader
  useEffect(() => {
    setTimeout(() => setShowHelpMessage(true), 6000);
  }, [setShowHelpMessage]);

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingIndicator />
      {showHelpMessage && (
        <div>
          <a href="/login">Having issues? Click here to try re-logging in</a>
        </div>
      )}
    </div>
  );
}

function VariousProviders({ children }: { children: React.ReactNode }) {
  return (
    <UserDataContextProvider>
      <AdminModeContextProvider>
        <HeadOfficeModeContextProvider>
          <HasuraRoleContextProvider>
            <ListingImageSelectProvider>
              <RocketiumContextProvider>
                <CanvaContextProvider>{children}</CanvaContextProvider>
              </RocketiumContextProvider>
            </ListingImageSelectProvider>
          </HasuraRoleContextProvider>
        </HeadOfficeModeContextProvider>
      </AdminModeContextProvider>
    </UserDataContextProvider>
  );
}

const AuthenticatedRoutes = () => {
  const { signedIn, activeWorkspaceId, authStatusReported, isHeadOfficeMode } =
    useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Hacky solution for apple devices is we force them back to
  // the login page after ~5 seconds of nothing happening
  const [forceReLogin, setForceReLogin] = useState<boolean>(false);

  useEffect(() => {
    if (authStatusReported && !signedIn) {
      return navigate('/login', {
        state: {
          from: location.pathname,
          search: location.search
        }
      });
    }

    // Navigate to workspaces choice if one is not active
    if (authStatusReported && signedIn && (!activeWorkspaceId || activeWorkspaceId === 'null')) {
      const matches = NON_WORKSPACE_ROUTES.findIndex((route) =>
        route.startsWith(location.pathname)
      );
      if (matches === -1) {
        navigate('/workspaces', {
          replace: true
        });
      }
    }
  }, [navigate, authStatusReported, signedIn, activeWorkspaceId, location]);

  // Navigate to head office dashboard if in headoffice mode
  useEffect(() => {
    if (signedIn && isHeadOfficeMode) {
      const matches = VALID_HEAD_OFFICE_MODE_ROUTES.some(
        (route) => route.startsWith(location.pathname) || location.pathname.startsWith(route)
      );

      if (!matches) {
        navigate('/head-office/dashboard', {
          replace: true
        });
      }
    }
  }, [isHeadOfficeMode, signedIn, location, navigate]);

  // Set force login timer
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (!authStatusReported) {
      timeout = setTimeout(() => setForceReLogin(true), 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [setForceReLogin, authStatusReported]);

  // Navigate to login page if forced after timeout
  // This occurs on apple devices when directly navigating to the empty pathname or `/`
  useEffect(() => {
    if (forceReLogin && !authStatusReported) {
      navigate('/login', {
        state: {
          from: location.pathname,
          search: location.search
        }
      });
    }
  }, [forceReLogin, authStatusReported, navigate, location.pathname, location.search]);

  if (!authStatusReported) {
    return <Fallback />;
  }

  if (signedIn) {
    const apolloClient = buildApolloClient();

    return (
      <ApolloProvider client={apolloClient}>
        <VariousProviders>
          <Routes>
            {/* Special paths for full screen interactions. i.e. workspace, campaign creation  */}
            <Route path="/workspaces/create" element={<CreateWorkspacePage />} />
            <Route path="/campaigns/create" element={<ActiveSubscriptionOutlet />}>
              <Route path="/campaigns/create" element={<CampaignsCreatePage />} />
            </Route>
            <Route
              path="/campaigns/simple-campaign-approval/:id"
              element={<SimpleCampaignApprovalPage />}
            />
            <Route path="/automation/create/trigger" element={<CreateAutomationTriggerPage />} />
            <Route path="/automation/create/schedule" element={<CreateAutomationRulePage />} />

            {/* Billing Setup Page */}
            <Route path="/billing/setup" element={<BillingSetupPage />} />
            <Route path="/billing/subscription" element={<BillingChooseSubscriptionPage />} />

            {/* App Connection Pages */}
            <Route path="login/oauth/canva" element={<CanvaConnectionPopupPage />} />

            {/* Normal paths with Layout component */}
            <Route path="/" element={<Layout />}>
              <Route path="welcome" element={<WelcomePage />} />

              {/* <Route path="/" element={<DashboardPage />} /> */}
              <Route path="/" element={<ActiveSubscriptionOutlet />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
              </Route>
              <Route path="home/*" element={<Navigate to="/dashboard" replace />} />

              <Route path="posts" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<PostsPage />} />
                <Route path="calendar" element={<PostCalendar />} />
                <Route path="report" element={<PostsReportPage />} />
                <Route path="analytics" element={<PostsAnalyticsPage />} />
                <Route path="head-office-schedule" element={<PostsContentSchedulePage />} />
              </Route>

              <Route path="campaigns" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<CampaignsPage />} />
                <Route path="agents" element={<CampaignsAgentSelectPage />} />
                <Route path="agents/:userId" element={<CampaignsAgentViewPage />} />
                <Route path="view/:id" element={<CampaignsViewCampaignPage />} />
                <Route path="edit/:id" element={<CampaignsEditPage />} />
                <Route path="reports">
                  <Route path="monthly-spend" element={<CampaignSpendReportPage />} />
                  <Route path="status-budget" element={<CampaignBudgetReportPage />} />
                </Route>
                <Route path="templates" element={<CampaignTemplatePage />} />
                <Route path="simple" element={<CampaignsSimpleViewPage />} />
                <Route path="calendar" element={<CampaignsCalendarPage />} />
              </Route>

              <Route path="leads" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<LeadsPage />} />
                <Route path="forms" element={<LeadGenFormsPage />} />
                <Route path="forms/:id/leads" element={<LeadsByFormPage />} />
                <Route path="autoresponder" element={<LeadAutoResponderPage />} />
              </Route>

              <Route path="audiences" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<AudiencesPage />} />
                <Route path="create" element={<Outlet />}>
                  <Route path="" element={<AudiencesCreatePage />} />
                  <Route path="*" element={<AudiencesCreateSourcePage />} />
                </Route>
              </Route>

              <Route path="creative" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<CreativePage />} />
                <Route path="create" element={<Outlet />}>
                  <Route path="" element={<CreativesCreatePage />} />
                  <Route path="*" element={<CreativesCreateTemplatesPage />} />
                </Route>
                <Route path="edit" element={<Outlet />}>
                  <Route path="*" element={<CreativesEditTemplatesPage />} />
                </Route>
                <Route path="view/:id" element={<CreativeViewPage />} />
              </Route>

              <Route path="realshortz" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<RealShortzPage />} />
                <Route path="draftVideos" element={<DraftVideosPage />} />
              </Route>

              <Route path="content" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<ContentFeedPage />} />
              </Route>

              <Route path="automation" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<AutomationPage />} />
                <Route path="create" element={<CreateAutomationPage />} />
                <Route path="create/head-office" element={<ImportHeadOfficeAutomationPage />} />
                <Route path="triggers" element={<Outlet />}>
                  <Route path=":id/invocations" element={<AutomationTriggersInvocationsPage />} />
                </Route>
                <Route path="schedules" element={<Outlet />}>
                  <Route path=":id/invocations" element={<AutomationScheduleInvocationsPage />} />
                </Route>
              </Route>

              <Route path="properties" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<PropertiesPage />} />
                <Route path="listing/create" element={<CreateListingPage />} />
                <Route path="listing/edit/:id" element={<EditListingPage />} />
                <Route path="view/:id" element={<ViewPropertyPage />} />
              </Route>

              <Route path="links" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<LinksPage />} />
              </Route>

              <Route path="reviews" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<CustomerReviewsPage />} />
              </Route>

              <Route path="store" element={<StoreOutlet />}>
                <Route path="" element={<Store />} />
                <Route path="history" element={<PurchaseHistory />} />
                <Route path="callback" element={<Callback />} />
                <Route path="purchase">
                  <Route path="" element={<Navigate to="/store" replace />} />
                  <Route path="creative" element={<CreativePurchase />} />
                </Route>
              </Route>

              <Route path="workspaces" element={<ListWorkspacesPage />} />
              <Route path="headoffices" element={<ListHeadOfficesPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="billing" element={<BillingPage />} />
              <Route path="branding" element={<BrandingPage />} />
              <Route path="apps" element={<Outlet />}>
                <Route path="" element={<AppsPage />} />
                <Route path="*" element={<AppsAppPage />} />
              </Route>

              {/* Head Office Routes */}
              <Route path="head-office/*" element={<HeadOfficeRouter />} />

              {/* Admin Routes */}
              <Route path="admin" element={<AdminOutlet />}>
                <Route path="custom-templates">
                  <Route path="" element={<CustomTemplates />} />
                  <Route path="add" element={<CreateTemplate />} />
                  <Route path="edit/:id" element={<EditTemplate />} />
                </Route>
                <Route path="brand-packs">
                  <Route path="" element={<BrandPacksPage />} />
                  <Route path="edit/:id" element={<EditBrandPackPage />} />
                </Route>
              </Route>
              <Route path="notifications" element={<NotificationsPage />} />

              <Route path="team" element={<Outlet />}>
                <Route path="" element={<TeamPage />} />
                <Route path="invites" element={<TeamInvitesPage />} />
                <Route path="mappings" element={<TeamMappingsPage />} />
                <Route path="info" element={<TeamFallbackInfoPage />} />
              </Route>

              {/* Adding team members */}
              <Route path="edit-account/:user_id" element={<UserProfile />} />
              <Route path="create-account" element={<UserProfile />} />

              <Route path="join" element={<JoinWorkspacePage />} />
              <Route path="profile" element={<Outlet />}>
                <Route path="" element={<ProfilePage />} />
                <Route path="apps" element={<Outlet />}>
                  <Route path="linkedin" element={<Outlet />}>
                    <Route path="callback" element={<LinkedInProfileOAuthCallback />} />
                  </Route>
                </Route>
              </Route>

              <Route path="me" element={<Outlet />}>
                <Route path="realshortz" element={<MyRealShortzPage />} />
              </Route>

              <Route path="*" element={<NotFoundPage />} />

              <Route path="support" element={<SupportPage />} />
            </Route>
          </Routes>
        </VariousProviders>
      </ApolloProvider>
    );
  }

  // Fallback
  return <Fallback />;
};

const PropertiRoutes = () => {
  // Disable anyone being able to embed our app in an iFrame
  if (window.self !== window.top) {
    return <UnsecureProxyPage />;
  }

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="login-as-support" element={<SupportLoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="*" element={<AuthenticatedRoutes />} />
    </Routes>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const App = () => {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContextProvider>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <LoggerContextProvider>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                ref={notistackRef}
                action={(key) => (
                  <IconButton color="inherit" onClick={onClickDismiss(key)}>
                    <CloseIcon color="inherit" />
                  </IconButton>
                )}
              >
                <ConfirmProvider defaultOptions={{ confirmationButtonProps: { autoFocus: true } }}>
                  <DndProvider backend={HTML5Backend}>
                    <Suspense fallback={<Fallback />}>
                      <PropertiRoutes />
                    </Suspense>
                  </DndProvider>
                </ConfirmProvider>
              </SnackbarProvider>
            </LoggerContextProvider>
          </BrowserRouter>
        </Elements>
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default App;
