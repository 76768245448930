import moment from 'moment';

import { ConnectedAppsQuery, useConnectedAppsQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import { AgentPermissions, useAgentPermissions } from './useAgentPermissions';
import useUserContext from './useUserContext';

type Platform = 'facebook' | 'youtube' | 'linkedin' | 'linkedin_personal' | 'instagram' | 'gmb';

export interface FacebookMeta {
  page_id: string;
  ad_account_id?: string | null;
  allowedPages?: PostingFrom[];
}

export interface InstagramMeta {
  page_id: string;
  allowedPages?: PostingFrom[];
}

export interface PostingFrom {
  id: string;
  display_name: string;
  name?: string | null;
  access_token?: string | null;
  avatar_url?: string | null;
  avatar_token?: string | null;
}

export interface LinkedInPersonalMeta {
  users: PostingFrom[];
  userIsConnected: boolean;
}

export interface LinkedInMeta {
  allowedPages?: PostingFrom[];
}

export interface GoogleMyBusinessMeta {
  account_id: string;
  allowedLocations?: PostingFrom[];
}

export interface YouTubeMeta {
  channel_id: string;
  allowedChannels: PostingFrom[];
}

export interface PlatformMetaHolder {
  facebook?: FacebookMeta;
  instagram?: InstagramMeta;
  linkedinPersonal?: LinkedInPersonalMeta;
  linkedin?: LinkedInMeta;
  gmb?: GoogleMyBusinessMeta;
  youtube?: YouTubeMeta;
}

export type usePlatformsResponse = [Platform[] | undefined, boolean, PlatformMetaHolder];

/**
 * Build platforms metadata for workspace Agent users.
 *
 * @param userId current userID
 * @param workspace current workspace data
 * @param agentPermissions agent allowed asset permissions for current user
 * @returns
 */
function buildPlatformsForAgents(
  userId: string,
  workspace: ConnectedAppsQuery['workspace'],
  agentPermissions: AgentPermissions
): usePlatformsResponse {
  const workspaceApps = workspace?.apps;
  const now = moment();
  const platforms: Platform[] = [];

  // FACEBOOK
  let facebook: FacebookMeta | undefined = undefined;
  if (
    workspaceApps?.facebook?.allowed_pages?.length &&
    agentPermissions.allowed_facebook_pages?.length
  ) {
    const agentAllowedPageIds = agentPermissions.allowed_facebook_pages;
    const facebookAllowedPages = workspaceApps?.facebook?.allowed_pages?.filter((page: any) =>
      agentAllowedPageIds.includes(page.id)
    );

    if (facebookAllowedPages.length) {
      platforms.push('facebook');

      facebook = {
        page_id: facebookAllowedPages[0].id,
        allowedPages: facebookAllowedPages
      };
    }
  }

  // INSTAGRAM
  let instagram: InstagramMeta | undefined = undefined;
  if (
    workspaceApps?.facebook?.allowed_instagram_pages?.length &&
    agentPermissions.allowed_instagram_pages?.length
  ) {
    const agentAllowedPageIds = agentPermissions.allowed_instagram_pages;
    const instagramAllowedPages = workspaceApps?.facebook?.allowed_instagram_pages?.filter(
      (page: any) => agentAllowedPageIds.includes(page.id)
    );

    if (instagramAllowedPages.length) {
      platforms.push('instagram');

      instagram = {
        page_id: instagramAllowedPages[0].id,
        allowedPages: instagramAllowedPages
      };
    }
  }

  // LINKEDIN
  let linkedin: LinkedInMeta | undefined = undefined;
  if (
    workspaceApps?.linkedin?.allowed_pages?.length &&
    agentPermissions.allowed_linkedin_pages?.length
  ) {
    const agentAllowedPageIds = agentPermissions.allowed_linkedin_pages;
    const linkedinAllowedPages = workspaceApps?.linkedin?.allowed_pages?.filter((page: any) =>
      agentAllowedPageIds.includes(page.id)
    );

    // Check refresh token expiry
    const expiresAt = moment(workspaceApps?.linkedin?.refresh_token_expires_at);

    if (linkedinAllowedPages.length && expiresAt.isValid() && expiresAt.isAfter(now)) {
      platforms.push('linkedin');

      linkedin = {
        allowedPages: linkedinAllowedPages
      };
    }
  }

  // LINKEDIN PERSONAL
  let linkedinPersonal: LinkedInPersonalMeta | undefined = undefined;
  const linkedInPersonalUsers: PostingFrom[] =
    workspace?.users
      ?.filter(
        (user) =>
          agentPermissions.allowed_agents.includes(user.user.display_name?.trim()!) ||
          user.user.id === userId
      )
      .map((user) => ({
        id: user.user.id,
        display_name: user.user.display_name ?? user.user.id,
        avatar_url: user.user.avatar_url,
        avatar_token: user.user.avatar_token
      })) ?? [];

  if (linkedInPersonalUsers.length) {
    const userLinkedInPersonalIndex = linkedInPersonalUsers?.findIndex(
      (user) => user.id === userId
    );
    const userIsConnected = userLinkedInPersonalIndex !== -1;
    const orderedPersonalUsers: PostingFrom[] = userIsConnected
      ? [
          linkedInPersonalUsers[userLinkedInPersonalIndex],
          ...linkedInPersonalUsers.filter((user) => user.id !== userId)
        ]
      : linkedInPersonalUsers;

    linkedinPersonal = {
      users: orderedPersonalUsers,
      userIsConnected: userIsConnected
    };
    platforms.push('linkedin_personal');
  }

  // GOOGLE MY BUSINESS
  let gmb: GoogleMyBusinessMeta | undefined = undefined;
  if (
    workspaceApps?.google_my_business?.allowed_locations?.length &&
    agentPermissions.allowed_gmb_pages?.length
  ) {
    const agentAllowedPageIds = agentPermissions.allowed_gmb_pages;
    const gmbAllowedLocations = workspaceApps?.google_my_business?.allowed_locations?.filter(
      (page: any) => agentAllowedPageIds.includes(page.id)
    );

    if (gmbAllowedLocations.length) {
      platforms.push('gmb');

      gmb = {
        account_id: gmbAllowedLocations[0].id,
        allowedLocations: gmbAllowedLocations
      };
    }
  }

  // YOUTUBE
  let youtube: YouTubeMeta | undefined = undefined;
  if (workspaceApps?.youtube?.channel_id && agentPermissions.allowed_youtube_pages?.length) {
    const agentAllowedChannels = agentPermissions.allowed_youtube_pages;
    if (agentAllowedChannels.includes(workspaceApps?.youtube?.channel_id)) {
      platforms.push('youtube');
      youtube = {
        channel_id: workspaceApps?.youtube?.channel_id!,
        allowedChannels: [
          {
            id: workspaceApps?.youtube?.channel_id!!,
            display_name: 'Workspace YouTube Channel'
          }
        ]
      };
    }
  }

  const meta: PlatformMetaHolder = {
    facebook,
    instagram,
    linkedin,
    linkedinPersonal,
    gmb,
    youtube
  };

  return [platforms, false, meta];
}

/**
 * Build platforms data for Non-Agent workspace users
 * @param userId current userId
 * @param workspace  workspace data
 * @returns
 */
function buildPlatforms(
  userId: string,
  workspace: ConnectedAppsQuery['workspace']
): usePlatformsResponse {
  const workspaceApps = workspace?.apps;
  const now = moment();
  const platforms: Platform[] = [];

  if (workspaceApps?.facebook?.id) {
    platforms.push('facebook');
  }

  if (workspaceApps?.facebook?.allowed_instagram_pages?.length) {
    platforms.push('instagram');
  }

  if (workspaceApps?.youtube?.id) {
    platforms.push('youtube');
  }

  if (workspaceApps?.linkedin?.id) {
    const expiresAt = moment(workspaceApps?.linkedin?.refresh_token_expires_at);
    if (expiresAt.isValid() && expiresAt.isAfter(now)) {
      platforms.push('linkedin');
    }
  }
  if (
    workspaceApps?.google_my_business?.account_id &&
    workspaceApps?.google_my_business?.allowed_locations.length
  ) {
    platforms.push('gmb');
  }

  // Facebook
  const facebookAllowedPages =
    workspaceApps?.facebook?.allowed_pages && workspaceApps?.facebook?.allowed_pages.length > 0
      ? workspaceApps?.facebook?.allowed_pages
      : undefined;

  const facebook: FacebookMeta | undefined = workspaceApps?.facebook?.page_id
    ? {
        page_id: workspaceApps?.facebook?.page_id,
        ad_account_id: workspaceApps?.facebook?.ad_account_id,
        allowedPages: facebookAllowedPages
      }
    : undefined;

  // Instagram
  const instagramAllowedPages =
    workspaceApps?.facebook?.allowed_instagram_pages &&
    workspaceApps?.facebook?.allowed_instagram_pages.length > 0
      ? workspaceApps?.facebook?.allowed_instagram_pages
      : undefined;

  const instagram = instagramAllowedPages
    ? {
        page_id: instagramAllowedPages[0].id,
        allowedPages: instagramAllowedPages
      }
    : undefined;

  // LinkedIn
  const linkedinAllowedPages =
    workspaceApps?.linkedin?.allowed_pages && workspaceApps?.linkedin?.allowed_pages.length > 0
      ? workspaceApps?.linkedin?.allowed_pages
      : undefined;

  const linkedin = linkedinAllowedPages
    ? {
        allowedPages: linkedinAllowedPages
      }
    : undefined;

  // LinkedIn Personal
  const linkedInPersonalUsers: PostingFrom[] =
    workspace?.users?.map((user) => ({
      id: user.user.id,
      display_name: user.user.display_name ?? user.user.id,
      avatar_url: user.user.avatar_url,
      avatar_token: user.user.avatar_token
    })) ?? [];

  if (linkedInPersonalUsers.length > 0) {
    platforms.push('linkedin_personal');
  }

  const userLinkedInPersonalIndex = linkedInPersonalUsers?.findIndex((user) => user.id === userId);
  const userIsConnected = userLinkedInPersonalIndex !== -1;

  const orderedPersonalUsers: PostingFrom[] = userIsConnected
    ? [
        linkedInPersonalUsers[userLinkedInPersonalIndex],
        ...linkedInPersonalUsers.filter((user) => user.id !== userId)
      ]
    : linkedInPersonalUsers;

  const linkedinPersonal: LinkedInPersonalMeta = {
    users: orderedPersonalUsers,
    userIsConnected: userIsConnected
  };

  // Google My Business
  const gmbAllowedLocations = workspaceApps?.google_my_business?.allowed_locations.length
    ? workspaceApps?.google_my_business?.allowed_locations
    : undefined;

  const gmb: GoogleMyBusinessMeta | undefined =
    workspaceApps?.google_my_business?.account_id && gmbAllowedLocations
      ? {
          account_id: workspaceApps?.google_my_business?.account_id,
          allowedLocations: gmbAllowedLocations
        }
      : undefined;

  // YouTube
  const youtube: YouTubeMeta | undefined = workspaceApps?.youtube?.channel_id
    ? {
        channel_id: workspaceApps?.youtube?.channel_id!,
        allowedChannels: [
          { id: workspaceApps?.youtube?.channel_id!, display_name: 'Workspace YouTube Channel' }
        ]
      }
    : undefined;

  const meta: PlatformMetaHolder = {
    facebook,
    instagram,
    linkedin,
    linkedinPersonal,
    gmb,
    youtube
  };

  return [platforms, false, meta];
}

// Hook to return available platforms to post to
export default function usePlatforms(): usePlatformsResponse {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAgent } = useUserContext();

  const { data, loading } = useConnectedAppsQuery({
    variables: {
      user_id: userId!,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext,
    skip: !userId || !activeWorkspaceId
  });

  const agentPermissions = useAgentPermissions();

  if (!userId || !activeWorkspaceId) {
    return [[], false, {}];
  }

  if (loading) {
    return [[], true, {}];
  }

  if (isWorkspaceAgent) {
    return buildPlatformsForAgents(userId, data?.workspace, agentPermissions);
  } else {
    return buildPlatforms(userId, data?.workspace);
  }
}

export function getPlatformLabel(platform: Platform): string {
  switch (platform) {
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'linkedin':
      return 'LinkedIn';
    case 'linkedin_personal':
      return 'LinkedIn (personal)';
    case 'gmb':
      return 'Google My Business';
    case 'youtube':
      return 'YouTube';
    default:
      return platform;
  }
}
