import React, { useCallback, useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ShareIcon from '@material-ui/icons/ShareOutlined';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import CommentEditIcon from 'mdi-material-ui/CommentEditOutline';

import ShareDrawer from 'components/ShareDrawer';
import CampaignFromListingOrCreativeDialog from 'components/campaigns/templates/CampaignFromListingOrCreativeDialog';
import { ShareLogicVariants } from 'components/types';

import CreativePreviewDialog from '../CreativePreviewDialog';
import EditCreativeDrawer from '../EditCreativeDrawer';
import TagHolder, { Tag } from '../TagHolder';
import useManualVideoCreativeCardStyles from './_styles';

interface ManualVideoCreativeCardProps {
  id: string;
  title: string;
  date: string;
  tags?: Tag[];
  videoUrl?: string | null;
  thumbnailUrl?: string | null;
  ready?: boolean;
  disableActionButtons?: boolean;
  onRefetchRequired?: () => void;
}

const ManualVideoCreativeCard: React.FC<ManualVideoCreativeCardProps> = ({
  id,
  title,
  date,
  tags,
  videoUrl,
  thumbnailUrl,
  ready = false,
  disableActionButtons = false,
  onRefetchRequired
}) => {
  const classes = useManualVideoCreativeCardStyles();
  const [shareOpen, setShareOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [boostModelOpen, setBoostModelOpen] = useState(false);

  let image = thumbnailUrl;
  if (!ready) {
    image = '/videos/loading.jpg';
  }

  const handleClickCard = useCallback(() => {
    setPreviewOpen(true);
  }, [setPreviewOpen]);

  const handleOpenEditDialog = useCallback(() => {
    setEditOpen(true);
  }, [setEditOpen]);

  const handleCloseEdit = useCallback(() => {
    setEditOpen(false);
    if (onRefetchRequired) {
      onRefetchRequired();
    }
  }, [setEditOpen, onRefetchRequired]);

  const handleOpenShareDrawer = () => {
    setShareOpen(true);
  };

  const handleCloseShareDrawer = () => {
    setShareOpen(false);
  };

  const handleOpenCreateFromTemplateModal = () => {
    setBoostModelOpen(true);
  };

  const handleCloseCreateFromTemplateModal = () => {
    setBoostModelOpen(false);
  };

  return (
    <>
      <Card className={classes.root} elevation={2}>
        <CardActionArea disabled={!ready} onClick={handleClickCard}>
          <CardMedia className={classes.media} title={title} image={image ?? undefined}>
            <TagHolder tags={tags} />
          </CardMedia>
          <CardContent>
            <Typography variant="overline">{date}</Typography>
            <Typography variant="h6">{title}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {ready && (
            <Tooltip title="Share Post">
              <IconButton
                aria-label="share post"
                onClick={handleOpenShareDrawer}
                disabled={!ready || disableActionButtons}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Boost Creative">
            <IconButton
              aria-label="boost creative"
              onClick={handleOpenCreateFromTemplateModal}
              disabled={disableActionButtons || !ready}
            >
              <BullhornIcon />
            </IconButton>
          </Tooltip>
          {ready && videoUrl && (
            <Tooltip title="Download Video">
              <IconButton
                component="a"
                href={videoUrl}
                target="_blank"
                download={`${title.replace(' ', '-').replace(',', '-')}`}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip style={{ marginLeft: 'auto' }} title="Edit Ad Copy">
            <IconButton
              onClick={handleOpenEditDialog}
              aria-label="Edit Ad Copy"
              disabled={disableActionButtons}
            >
              <CommentEditIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      {ready && !disableActionButtons && (
        <ShareDrawer
          open={shareOpen}
          onClose={handleCloseShareDrawer}
          title={title}
          variant={ShareLogicVariants.CREATIVE}
          creativeId={id}
          disabled={disableActionButtons}
        />
      )}
      {ready && (
        <CreativePreviewDialog
          open={previewOpen}
          handleClose={() => {
            setPreviewOpen(false);
          }}
          title={title}
          src={videoUrl ?? undefined}
        />
      )}
      <EditCreativeDrawer
        creativeId={id}
        open={editOpen}
        onClose={handleCloseEdit}
        isVideoCreative
      />
      <CampaignFromListingOrCreativeDialog
        open={boostModelOpen}
        onClose={handleCloseCreateFromTemplateModal}
        creativeId={id}
      />
    </>
  );
};

export default ManualVideoCreativeCard;
