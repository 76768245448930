import { useContext } from 'react';

import { UserDataContext } from 'lib/UserContext/UserDataContext';

export function useUserContext() {
  const userData = useContext(UserDataContext);
  return userData;
}

export default useUserContext;
