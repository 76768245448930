import React, { PropsWithChildren, useMemo } from 'react';

import { useField, useForm } from 'react-final-form';

import { FormControl, FormGroup, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ItemGrouping from 'components/ItemGrouping';

import { ImagePickerItem } from './ImagePickerItem';
import { ImageCropSelection, ImagePickerValue } from './types';

interface ImagePickerProps {
  fieldName: string;
  values: ImagePickerValue[];
  maxImages: number;
  pinnedImages?: ImagePickerValue[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#e5e5e5',
    borderRadius: '3px',
    padding: theme.spacing(2, 1.5)
  },
  scrollContainer: {
    maxHeight: '432px',
    minHeight: '144px',
    paddingBottom: '16px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  legend: {
    color: '#424242',
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '0 4px'
  }
}));

function uniqueImages(value: ImagePickerValue, index: number, arr: ImagePickerValue[]) {
  return arr.findIndex((val) => val.image.src === value.image.src) === index;
}

export default function ImagePicker({
  fieldName,
  values,
  maxImages,
  pinnedImages = []
}: PropsWithChildren<ImagePickerProps>) {
  const classes = useStyles();
  const {
    mutators: { push, remove, update }
  } = useForm();
  const { input } = useField(fieldName, { subscription: { value: true } });
  const disabled = input.value.length >= maxImages;

  const images: ImagePickerValue[] = useMemo(() => {
    const mapped: ImagePickerValue[] = pinnedImages.map((image) => ({
      ...image,
      selection: { type: 'none' }
    }));
    return input.value.concat(...mapped, ...values).filter(uniqueImages);

    // TODO better fix with react hook missing dependencies
    // eslint-disable-next-line
  }, [values]);

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend" className={classes.legend}>
        Images
      </FormLabel>
      <FormGroup className={classes.scrollContainer}>
        <ItemGrouping
          values={images}
          groupBy={(image) => {
            if (image.address) {
              return image.address;
            }

            if (image.image.type === 'upload') {
              return 'Uploads';
            }

            return 'Unknown listings';
          }}
        >
          {({ value }) => {
            const imageSrc = value.image.src;
            const fieldArrayIndex = input.value.findIndex((val: any) => val.image.src === imageSrc);
            function handleChange(selection: ImageCropSelection) {
              const fieldValue = {
                image: value.image,
                selection,
                address: value.address
              };
              if (fieldArrayIndex > -1) {
                if (selection.type === 'none') {
                  remove(fieldName, fieldArrayIndex);
                } else {
                  update(fieldName, fieldArrayIndex, fieldValue);
                }
              } else if (selection.type !== 'none') {
                push(fieldName, fieldValue);
              }
            }
            return (
              <ImagePickerItem
                image={value.image}
                value={value.selection}
                disabled={disabled && fieldArrayIndex === -1}
                onChange={handleChange}
              />
            );
          }}
        </ItemGrouping>
      </FormGroup>
    </FormControl>
  );
}
