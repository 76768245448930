import React from 'react';

import { Field } from 'react-final-form';

interface BaseProps {
  // Field name of condition
  when: string;

  // React Children
  children: React.ReactNode;
}

interface EqualsProps extends BaseProps {
  // Condition to be met
  equals?: string | number | boolean;
  includes?: never;
  excludes?: never;
  within?: never;
  notWithin?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}

interface NotEqualsProps extends BaseProps {
  notEquals?: string | number | boolean;
  equals?: never;
  includes?: never;
  excludes?: never;
  within?: never;
  notWithin?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}

interface IncludesProps extends BaseProps {
  // Value must include this
  includes?: string | number | boolean;
  excludes?: never;
  equals?: never;
  within?: never;
  notWithin?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}
interface ExcludesProps extends BaseProps {
  // Value must include this
  excludes?: string | number | boolean;
  includes?: never;
  equals?: never;
  within?: never;
  notWithin?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}
interface IsEmptyProps extends BaseProps {
  // Value must include this
  excludes?: never;
  includes?: never;
  equals?: never;
  within?: never;
  notWithin?: never;
  isEmpty: true;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}

interface IsNotEmptyProps extends BaseProps {
  // Value must include this
  excludes?: never;
  includes?: never;
  equals?: never;
  within?: never;
  notWithin?: never;
  isEmpty?: never;
  isNotEmpty: true;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}

interface InProps extends BaseProps {
  // Must be contained within this list
  within?: string[];
  notWithin?: never;
  equals?: never;
  includes?: never;
  excludes?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}
interface NotInProps extends BaseProps {
  // Must be contained within this list
  notWithin?: string[];
  within?: never;
  equals?: never;
  includes?: never;
  excludes?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  gte?: never;
  lte?: never;
  isDefined?: never;
}

interface GTEProps extends BaseProps {
  // Must be greater than or equal to this value
  gte: number;
  lte?: never;
  within?: never;
  notWithin?: never;
  equals?: never;
  includes?: never;
  excludes?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  isDefined?: never;
}

interface LTEProps extends BaseProps {
  // Must be less than or equal to this value
  lte: number;
  gte?: never;
  within?: never;
  notWithin?: never;
  equals?: never;
  includes?: never;
  excludes?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  isDefined?: never;
}

interface DefinedProps extends BaseProps {
  // Must be greater than or equal to this value
  gte?: never;
  lte?: never;
  within?: never;
  notWithin?: never;
  equals?: never;
  includes?: never;
  excludes?: never;
  isEmpty?: never;
  isNotEmpty?: never;
  notEquals?: never;
  isDefined: true;
}

export type FinalFormConditionProps =
  | IsEmptyProps
  | IsNotEmptyProps
  | EqualsProps
  | IncludesProps
  | ExcludesProps
  | InProps
  | NotInProps
  | NotEqualsProps
  | GTEProps
  | LTEProps
  | DefinedProps;

const FinalFormCondition: React.FC<FinalFormConditionProps> = ({
  when,
  equals,
  notEquals,
  includes,
  excludes,
  isEmpty,
  isNotEmpty,
  within,
  notWithin,
  gte,
  lte,
  isDefined,
  children
}) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        if (equals && value === equals) {
          return children;
        }
        if (isDefined && Boolean(value)) {
          return children;
        } else if (notEquals && value !== notEquals) {
          return children;
        } else if (includes && value?.includes?.(includes)) {
          return children;
        } else if (excludes && !value?.includes?.(excludes)) {
          return children;
        } else if (isEmpty && (value?.length === 0 || !value)) {
          return children;
        } else if (isNotEmpty && value?.length && value.length > 0) {
          return children;
        } else if (within?.includes?.(value)) {
          return children;
        } else if (notWithin && value && !notWithin.includes(value)) {
          return children;
        } else if (gte && value >= gte) {
          return children;
        } else if (lte && value <= lte) {
          return children;
        }
        return null;
      }}
    </Field>
  );
};

export default FinalFormCondition;
