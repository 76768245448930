import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy from '@uppy/core';

import cuid from 'cuid';
import { v4 as uuid } from 'uuid';

import { Attachment, AttachmentEnum, FileAttachment } from 'components/types';

export async function uploadFiles(uploaderId: string, isHeadOffice: boolean = false) {
  const uppy = new Uppy();

  uppy.use(AwsS3Multipart, {
    limit: 5,
    companionUrl: process.env.REACT_APP_UPPY_COMPANION_ENDPOINT,
    companionHeaders: {
      'Access-Control-Allow-Origin': '*'
    }
  });

  function addFile(file: File) {
    const slug = cuid.slug();
    const token = uuid();

    const name = file.name
      .replace(/\s+/g, '_')
      .replace(/[^a-zA-Z.0-9]/g, '')
      .trim();
    const filename = `${slug}_${name}`;
    const path = isHeadOffice
      ? `headoffice/${uploaderId}/posts/${filename}`
      : `workspace/${uploaderId}/uploads/${filename}`;
    uppy.addFile({
      name: filename,
      type: file.type,
      data: file,
      meta: {
        path,
        filename,
        token
      }
    });

    return {
      id: filename,
      filename: filename,
      key: path,
      token: token,
      contenttype: file.type
    };
  }

  async function upload() {
    const resp = await uppy.upload();
    const result = [] as FileAttachment[];
    if (resp.failed.length) {
      console.error(resp.failed);
      throw new Error('Some files failed to upload');
    }
    for (const file of resp.successful) {
      result.push({
        key: file.meta.path as string,
        token: file.meta.token as string,
        filename: file.meta.filename as string,
        contenttype: file.type as string
      });
    }
    return result;
  }

  return {
    addFile,
    upload
  };
}

export interface FileOp {
  file: File;
  inputs: {
    type: AttachmentEnum.FILE;
    file: FileAttachment;
  }[];
}

export async function processFileOps(uploaderId: string, fileOps: FileOp[], isHeadOffice: boolean) {
  const { addFile, upload } = await uploadFiles(uploaderId, isHeadOffice);
  const ids = new Map();
  for (const { file, inputs } of fileOps) {
    const { id } = addFile(file);
    ids.set(id, inputs);
  }
  const results = await upload();
  for (const attachment of results!) {
    const inputs = ids.get(attachment.filename);
    for (const input of inputs) {
      input.file = attachment;
    }
  }
}

function getFileKey(a: File) {
  return a.name + a.size + a.type + a.lastModified;
}

export async function uploadAttachments(
  uploaderId: string, // Either workspace or head office id
  allAttachments: Attachment[][] = [],
  isHeadOffice: boolean = false
): Promise<any[][]> {
  const fileOps = new Map<string, FileOp>();
  const results: any[][] = [];

  for (const attachments of allAttachments) {
    const inputs: any[] = [];

    results.push(inputs);

    if (!attachments) {
      continue;
    }

    for (const attachment of attachments) {
      const { type, file, image, video, entry, uploaded_file } = attachment;
      if (type === AttachmentEnum.FILE && file) {
        const fileKey = getFileKey(file);
        const input = {
          type: type
        } as any;
        inputs.push(input);

        if (fileOps.has(fileKey)) {
          fileOps.get(fileKey)!.inputs.push(input);
        } else {
          fileOps.set(fileKey, {
            file,
            inputs: [input]
          });
        }
      } else if (type === AttachmentEnum.UPLOADED_FILE && uploaded_file) {
        inputs.push({
          type: 'FILE',
          file: uploaded_file
        });
      } else if (
        (type === AttachmentEnum.LISTING_IMAGE || type === AttachmentEnum.CREATIVE_IMAGE) &&
        image
      ) {
        inputs.push({
          type: type,
          image: {
            id: image.id,
            url: image.url
          }
        });
      } else if (type === AttachmentEnum.CREATIVE_VIDEO && video) {
        inputs.push({ type, video });
      } else if (type === AttachmentEnum.CREATIVE_FILE && entry) {
        inputs.push({ type, entry });
      } else if (type === AttachmentEnum.CAROUSEL_ITEM && entry) {
        inputs.push({ type, entry });
      } else if (type === AttachmentEnum.REALSHORTZ_VIDEO && video) {
        inputs.push({ type, video });
      }
    }
  }

  if (fileOps.size) {
    await processFileOps(uploaderId, Array.from(fileOps.values()), isHeadOffice);
  }

  return results;
}
