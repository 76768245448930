const context = new WeakMap();

export interface Context {
  ___getContextKey: () => Object | null;
  ___subscribe?: (hooks: Context | Context[]) => void;
  ___evaluate?: () => void;
  ___enableDefer?: () => void;
}

export function setContext(key: Object, value: unknown) {
  context.set(key, value);
}

function getContextForKey(key: Object | undefined | null) {
  if (!key) {
    return undefined;
  }
  return context.get(key);
}

export function getContext(hook: Context[]) {
  return hook.map((hook) => getContextForKey(hook.___getContextKey()));
}

export function subscribe(hook: Context, subscribers: Context | Context[]) {
  hook.___subscribe?.(subscribers);
}

export function subscribeAll(...hooks: Context[]) {
  hooks.forEach((hook) =>
    subscribe(
      hook,
      hooks.filter((h) => h !== hook)
    )
  );
}

/**
 * Call all of the `customizeFilter` functions for all of the hooks that have been passed to the `createNotifier` function
 */
type Notify = () => void;

/**
 * Defers all of the `customizeFilter` functions for every hook that has been
 * passed in to the `createNotifier` function until after `fn` has run and then
 * will automatically call `notify`.
 *
 * This is useful when calling when changing the state of filters that are have
 * also been passed into the `createNotifier` function. This allows the
 * `customizeFilter` function to run once for every hook rather than multiple
 * times
 */
type WithNotify = (fn: () => void) => void;

export function createNotifier(...hooks: Context[]): [Notify, WithNotify] {
  let isNotifying = false;

  function enableDefer() {
    hooks.forEach((hook) => hook.___enableDefer?.());
  }

  function evaluate() {
    hooks.forEach((hook) => hook.___evaluate?.());
  }

  function notify() {
    if (isNotifying) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('Calling `notify()` inside of `withNotify()` is a noop');
      }
      return;
    }
    evaluate();
  }

  function withNotify(fn: () => void) {
    isNotifying = true;
    enableDefer();
    fn();
    evaluate();
    isNotifying = false;
  }

  return [notify, withNotify];
}
