import React, { useState } from 'react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export interface ImagePickerCropProps {
  crop?: ReactCrop.PercentCrop;
  image: string;
  aspect: number;
  minWidth: number;
  minHeight: number;
  onSelect: (selection: ReactCrop.PercentCrop) => void;
}

function calculateCrop(
  percent: ReactCrop.PercentCrop,
  minWidth: number,
  minHeight: number,
  previous: ReactCrop.PercentCrop
) {
  const width = Math.max(percent.width ?? minWidth, minWidth);
  const height = Math.max(percent.height ?? minHeight, minHeight);
  const resize = (percent.width ?? minWidth) < width || (percent.height ?? minHeight) < height;

  return {
    x: resize
      ? previous.x ?? 50 - minHeight / 2
      : Math.min(Math.max(percent.x ?? 50 - minWidth / 2, 0), 100 - minWidth),
    y: resize
      ? previous.y ?? 50 - minHeight / 2
      : Math.min(Math.max(percent.y ?? 50 - minHeight / 2, 0), 100 - minHeight),
    width: Math.max(percent.width ?? minWidth, minWidth),
    height: Math.max(percent.height ?? minHeight, minHeight)
  };
}

const ImagePickerCrop = React.forwardRef<any, ImagePickerCropProps>(
  (
    { crop: initialCrop = {}, image, onSelect, aspect, minWidth, minHeight }: ImagePickerCropProps,
    ref
  ) => {
    const [crop, setCrop] = useState<ReactCrop.PercentCrop>({
      aspect,
      width: minWidth,
      height: minHeight,
      x: 50 - minWidth / 2,
      y: 50 - minHeight / 2,
      unit: '%',
      ...initialCrop
    });

    function handleChange(croppedArea: ReactCrop.Crop, percent: ReactCrop.PercentCrop) {
      setCrop({
        ...percent,
        ...calculateCrop(percent, minWidth, minHeight, crop)
      });
    }

    function handleComplete(croppedArea: ReactCrop.Crop, percent: ReactCrop.PercentCrop) {
      onSelect(calculateCrop(percent, minWidth, minHeight, crop));
    }

    return (
      <div ref={ref}>
        <ReactCrop
          src={image}
          crop={crop}
          ruleOfThirds={true}
          onChange={handleChange}
          onComplete={handleComplete}
          keepSelection={true}
        />
      </div>
    );
  }
);

export default ImagePickerCrop;
