import React, { useCallback, useMemo, useState } from 'react';

import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  FormControl,
  Typography,
  Checkbox,
  Menu,
  Badge,
  TextField,
  InputAdornment,
  makeStyles,
  SvgIconTypeMap
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import ClearIcon from '@material-ui/icons/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FilterIcon from '@material-ui/icons/FilterList';
import StarsIcon from '@material-ui/icons/Stars';

import { capitalize } from 'lodash';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { DateRangePicker, DateRange } from 'materialui-daterange-picker';
import moment from 'moment';

import StackedItems from 'components/StackedItems';
import SearchInput from 'components/forms/SearchInput';

function setIfDefined<T>(value: T, fn?: (value: T) => void) {
  if (fn) {
    fn(value);
  }
}

const useStyles = makeStyles({
  dateRange: {
    //@ts-ignore
    zIndex: '10 !important'
  }
});

type Primitive = string | number;

type Option = { text: string; value: Primitive } | Primitive;

type Filter = { text: string; value: string } | string;

type MenuItemType = {
  name: string;
  options: Option[];
  value: Primitive;
  setValue: (str?: Primitive) => void;
  hasAll?: boolean;
};

type QuickFilter = {
  options: Filter[];
  onChange: (str?: string) => void;
  /**
   * Called before a filter's `setState`. Allows you to clean up any quick
   * filter states after a quick filter is invalidated by the user
   */
  onInvalidate?: () => void;
};

export type ToolbarProps = {
  columnsButton: boolean;
  search: boolean;
  searchValue?: string;
  setSearch?: (val: string) => void;
  showTitle: boolean;
  title?: string;
  onToggleFiltersOpen?: () => void;
  filtersOpen?: boolean;
  menuItems: MenuItemType[];
  quickFilter?: QuickFilter;
  dateRange?: DateRange;
  setDateRange?: (date: DateRange | undefined) => void;
  actions?: {
    icon: string | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    disabled: boolean;
    hidden: boolean;
    isFreeAction: boolean;
    position: 'row' | 'toolbar';
    tooltip: string;
    onClick: () => void;
  }[];
  exportMenu?: {
    label: string;
    exportFunc: (cols: any[], datas: any[]) => void;
  }[];
  exportAllData: boolean;
  columns: any[];
  data: any[];
  [prop: string]: any;
};

function FilterItem({
  name,
  options,
  setValue,
  value,
  hasAll,
  onQuickFilterInvalidate
}: MenuItemType & { onQuickFilterInvalidate?: QuickFilter['onInvalidate'] }) {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      onQuickFilterInvalidate?.();
      setValue(event.target.value as Primitive | undefined);
    },
    [onQuickFilterInvalidate, setValue]
  );

  const isSelected = (optionValue: Primitive | Primitive[]) => {
    if (Array.isArray(value)) {
      // Allows for a value-based comparison, enabling the correct menu item selection to be identified when 'value' is an array.
      return JSON.stringify(value) === JSON.stringify(optionValue);
    }
    return value === optionValue;
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>{name}</InputLabel>
      <Select
        variant="standard"
        autoWidth
        value={value}
        onChange={onChangeHandler}
        renderValue={(selected) => {
          // Find the option text corresponding to the selected value
          const selectedOption = options.find((option) => {
            if (typeof option === 'object' && 'value' in option) {
              return isSelected(option.value);
            }
            return false;
          });
          return selectedOption && typeof selectedOption === 'object' ? selectedOption.text : '';
        }}
      >
        {hasAll && (
          <MenuItem value={undefined}>
            <em>All</em>
          </MenuItem>
        )}
        {options.map((option, idx) => {
          if (typeof option === 'string' || typeof option === 'number') {
            return (
              <MenuItem value={option} key={`${option}-${idx}`}>
                {typeof option === 'string'
                  ? option
                      .split('_')
                      .map((text) => capitalize(text))
                      .join(' ')
                  : option}
              </MenuItem>
            );
          }

          return (
            <MenuItem value={option.value} key={`${option.value}-${idx}`}>
              {option.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

// Number.MAX_SAFE_INTEGER is not max safe date int
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
const MAX_SAFE_DATE_INT = 8640000000000000;

function calculateDateRangeValue(dateRange?: DateRange) {
  if (!!dateRange) {
    const anyStartDate = new Date(0);
    const anyEndDate = new Date(MAX_SAFE_DATE_INT);

    const startDate =
      anyStartDate.valueOf() === dateRange.startDate?.valueOf()
        ? 'Any Date'
        : moment(dateRange.startDate).format('DD/MM/YYYY');

    const endDate =
      anyEndDate.valueOf() === dateRange.endDate?.valueOf()
        ? 'Any Date'
        : moment(dateRange.endDate).format('DD/MM/YYYY');

    return `${startDate} - ${endDate}`;
  }

  return 'No Date Range';
}

export default function Toolbar({
  columnsButton,
  search,
  searchValue,
  setSearch,
  showTitle,
  title,
  onToggleFiltersOpen,
  filtersOpen,
  menuItems,
  quickFilter,
  dateRange,
  setDateRange,
  actions,
  exportMenu,
  ...props
}: ToolbarProps) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'toggleColumnsMenu' });
  const quickFilterPopupState = usePopupState({
    variant: 'popover',
    popupId: 'toggleQuickFilterMenu'
  });
  const exportPopupState = usePopupState({
    variant: 'popover',
    popupId: 'exportMenu'
  });
  const [openDateRange, setOpenDateRange] = useState(false);
  const classes = useStyles();

  const toggleDateRange = () => setOpenDateRange((prev) => !prev);

  const handleChangeSearch = (event: any) => {
    setIfDefined(event.target.value, setSearch);
  };

  const handleClickSearch = () => {};

  const handleClearSearch = () => {
    setIfDefined('', setSearch);
  };

  const clearActiveFilters = () => menuItems?.forEach((item) => item.setValue(undefined));

  const handleQuickFilterClick = (value: string) => {
    quickFilterPopupState.close();
    quickFilter?.onInvalidate?.();
    clearActiveFilters();
    setDateRange?.(undefined);
    handleClearSearch();
    quickFilter?.onChange(value);
  };

  const __setDateRange = useCallback(
    (range: DateRange | undefined) => {
      quickFilter?.onInvalidate?.();
      setDateRange?.(range);
    },
    [quickFilter, setDateRange]
  );

  const activeFilters = useMemo(() => {
    return menuItems?.reduce((num, item) => {
      if (item.value !== undefined) {
        num++;
      }

      return num;
    }, 0);
  }, [menuItems]);

  const handleToggleFilter = () => {
    setIfDefined(undefined, onToggleFiltersOpen);
  };

  const freeActions = useMemo(() => {
    return actions?.filter((action) => action.position === 'toolbar' && !action.hidden) ?? [];
  }, [actions]);

  const dateRangeValue = calculateDateRangeValue(dateRange);

  return (
    <>
      <StackedItems direction="column">
        <Grid item style={{ width: '100%' }}>
          <StackedItems containerProps={{ style: { padding: 8 } }}>
            <Grid item style={{ flexGrow: 1 }}>
              {showTitle && (
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {title}
                </Typography>
              )}
            </Grid>

            {!!setDateRange && (
              <TextField
                value={dateRangeValue}
                style={{ paddingRight: '16px', minWidth: dateRange ? '335px' : '260px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleDateRange}>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: dateRange ? (
                    <InputAdornment position="start">
                      <IconButton onClick={() => __setDateRange(undefined)}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined
                }}
                disabled
                variant="standard"
              />
            )}

            {!!search && (
              <SearchInput
                variant="standard"
                onClickClear={handleClearSearch}
                onClickSearch={handleClickSearch}
                placeholder="Search"
                value={searchValue}
                onChange={handleChangeSearch}
                autoFocus
              />
            )}

            {menuItems?.length > 0 && (
              <Badge
                badgeContent={activeFilters}
                invisible={filtersOpen || activeFilters === 0}
                color="primary"
                overlap="circular"
              >
                <Tooltip
                  title={
                    activeFilters === 0
                      ? 'Custom Filters'
                      : `${activeFilters} Active Custom Filter(s)`
                  }
                >
                  <IconButton onClick={handleToggleFilter}>
                    <FilterIcon />
                  </IconButton>
                </Tooltip>
              </Badge>
            )}
            {!!quickFilter && (
              <Tooltip title="Quick filters">
                <IconButton {...bindTrigger(quickFilterPopupState)}>
                  <StarsIcon />
                </IconButton>
              </Tooltip>
            )}
            {freeActions.map((action, idx) => (
              <Tooltip key={idx} title={action.tooltip}>
                <IconButton onClick={action.onClick} disabled={action.disabled}>
                  {<action.icon />}
                </IconButton>
              </Tooltip>
            ))}
            {columnsButton && (
              <Tooltip title="Show Columns">
                <IconButton {...bindTrigger(popupState)}>
                  <props.icons.ViewColumn />
                </IconButton>
              </Tooltip>
            )}
            {Boolean(exportMenu?.length) && (
              <Tooltip title="Export Data">
                <IconButton {...bindTrigger(exportPopupState)}>
                  <props.icons.Export />
                </IconButton>
              </Tooltip>
            )}
          </StackedItems>
        </Grid>
        {filtersOpen && menuItems?.length > 0 && (
          <StackedItems
            containerProps={{ style: { paddingTop: 4, paddingRight: 8 } }}
            justifyContent="flex-end"
            alignItems="flex-end"
            wrap="nowrap"
          >
            <Grid item xs>
              <StackedItems spacing={1} justifyContent="flex-end">
                {menuItems?.map((item) =>
                  !item.hasAll && item.options.length === 0 ? null : (
                    <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={item.name}>
                      <FilterItem {...item} onQuickFilterInvalidate={quickFilter?.onInvalidate} />
                    </Grid>
                  )
                )}
              </StackedItems>
            </Grid>
            <Tooltip title={activeFilters === 0 ? 'No active filters' : 'Clear active filters'}>
              <div>
                <IconButton disabled={activeFilters === 0} onClick={clearActiveFilters}>
                  <ClearIcon />
                </IconButton>
              </div>
            </Tooltip>
          </StackedItems>
        )}
      </StackedItems>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          disabled
          style={{
            opacity: 1,
            fontWeight: 600,
            fontSize: 12
          }}
        >
          Add/Remove Columns
        </MenuItem>
        {props.columns.map((col: any) => {
          if (!col.hidden || col.hiddenByColumnsButton) {
            return (
              <li key={col.tableData.id}>
                <MenuItem
                  component="label"
                  htmlFor={`column-toggle-${col.tableData.id}`}
                  disabled={col.removable === false}
                >
                  <Checkbox
                    checked={!col.hidden}
                    id={`column-toggle-${col.tableData.id}`}
                    onChange={() => props.onColumnsChanged(col, !col.hidden)}
                  />
                  <span>{col.title}</span>
                </MenuItem>
              </li>
            );
          }
          return null;
        })}
      </Menu>
      <Menu {...bindMenu(quickFilterPopupState)}>
        {quickFilter?.options.map((option) => {
          if (typeof option === 'string') {
            return (
              <MenuItem key={option} onClick={() => handleQuickFilterClick(option)}>
                {option
                  .split('_')
                  .map((text) => capitalize(text))
                  .join(' ')}
              </MenuItem>
            );
          }

          return (
            <MenuItem key={option.value} onClick={() => handleQuickFilterClick(option.value)}>
              {option.text}
            </MenuItem>
          );
        })}
      </Menu>
      <Menu {...bindMenu(exportPopupState)}>
        {exportMenu?.map((exp: any, idx: number) => (
          <MenuItem
            key={idx}
            onClick={() => {
              exp.exportFunc(props.columns, props.data);
              exportPopupState.close();
            }}
          >
            {exp.label}
          </MenuItem>
        ))}
      </Menu>

      <DateRangePicker
        open={openDateRange}
        initialDateRange={dateRange}
        toggle={toggleDateRange}
        onChange={__setDateRange}
        closeOnClickOutside
        wrapperClassName={classes.dateRange}
      />
    </>
  );
}
