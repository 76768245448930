import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type TooltipType = {
  heading: any;
  tooltip: string;
  link?: string;
  target?: string;
};

const TooltipComponent = ({ heading, tooltip, link, target }: TooltipType) => {
  const tooltipHeading = heading;
  const tooltipText = tooltip;
  const iconLink = link ?? '';
  return (
    <>
      <span>{tooltipHeading}</span>
      <span>
        <Tooltip enterDelay={200} leaveDelay={250} title={tooltipText}>
          <IconButton size="small" href={iconLink} target={target}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </span>
    </>
  );
};

export default TooltipComponent;
