import React from 'react';

import { Form } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Link as MuiLink,
  MenuItem,
  Typography
} from '@material-ui/core';

import { FORM_ERROR } from 'final-form';
import { Autocomplete, makeValidate, Select } from 'mui-rff';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';
import * as Yup from 'yup';

import {
  useCampaignTemplatesQuery,
  useCreateCampaignFromTemplateMutation,
  Workspace_Posts_Bool_Exp,
  usePostsAutocompleteQuery,
  Workspace_Campaign_Templates_Bool_Exp
} from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

const schema = Yup.object({
  template_key: Yup.string().required().label('Template Key'),
  post_id: Yup.string().optional().nullable().label('Post'),
  publish: Yup.boolean().defined().label('Publish campaign')
}).required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

interface CampaignFromPostDialogProps {
  postId?: string;
  open: boolean;
  onClose: () => void;
}

export default function CampaignFromPostDialog({
  open,
  onClose,
  postId
}: CampaignFromPostDialogProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, userId, isWorkspaceAgent } = useUserContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createCampaign] = useCreateCampaignFromTemplateMutation({
    context: workspaceMemberContext
  });

  const where: Workspace_Campaign_Templates_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId }
      }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          available_to_agents: { _eq: true }
        }
      ]
    });
  }

  const { data: templateData, loading: templateLoading } = useCampaignTemplatesQuery({
    variables: {
      where: where
    },
    context: workspaceMemberContext
  });

  const isLoading = templateLoading;

  const handleSubmit = async (formValues: FormSchema) => {
    try {
      const publish = formValues.publish ?? false;

      if (publish) {
        // Check if post is selected
        if (!formValues.post_id) {
          return {
            [FORM_ERROR]: 'Post is required for quick publishing campaigns'
          };
        }
      }

      const resp = await createCampaign({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            post_id: formValues.post_id,
            template_key: formValues.template_key,
            publish: publish
          }
        }
      });

      const campaignId = resp?.data?.createCampaignFromTemplate?.campaign_id;
      if (!campaignId) {
        throw new Error('Unable to create campaign');
      }

      if (publish && resp.data?.createCampaignFromTemplate?.was_published) {
        navigate('/campaigns');
        onClose();
      } else if (publish) {
        enqueueSnackbar('We were unable to confirm settings to publish, please complete manually', {
          variant: 'warning'
        });
        navigate(`/campaigns/edit/${campaignId}`);
      } else {
        navigate(`/campaigns/edit/${campaignId}`);
      }
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message ?? 'Unable to create campaign'
      };
    }
  };

  const validTemplates = templateData?.templates?.filter(
    (template) => template.ad_platforms.length === 1 && template.ad_platforms.includes('facebook')
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="campaign-template-dialog-title"
    >
      <DialogTitle id="campaign-template-dialog-title" onClose={onClose}>
        Boost your post
      </DialogTitle>

      <DialogContent>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={{
              publish: false,
              post_id: postId
            }}
          >
            {({ handleSubmit, form, submitting, submitError, values }) => {
              const chosenTemplate = templateData?.templates?.find(
                (template) => template.template_key === values.template_key
              );

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Box>
                    <Typography variant="subtitle1">Select your campaign template</Typography>
                    <Select name="template_key" label="Campaign template">
                      {validTemplates?.map((template) => (
                        <MenuItem value={template.template_key}>
                          {template.template_key
                            .replace('-', ' ')
                            .replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase())}
                        </MenuItem>
                      ))}
                    </Select>
                    <MuiLink component={Link} to="/templates/campaigns">
                      Don't see any campaign templates? Click here to set them up
                    </MuiLink>
                  </Box>

                  <Box mt={1}>
                    <PostAutocomplete name="post_id" label="Select your Facebook post" />
                  </Box>

                  <Box my={1}>
                    {chosenTemplate && (
                      <Typography variant="caption">
                        Your campaign will run for <strong>{chosenTemplate?.duration} days</strong>{' '}
                        with a{' '}
                        <strong>
                          {numbro(chosenTemplate.spend_cap ?? 0).formatCurrency({
                            thousandSeparated: true
                          })}{' '}
                          total budget
                        </strong>
                        .
                      </Typography>
                    )}
                  </Box>

                  {submitError && (
                    <Box mt={2}>
                      <Typography variant="caption" color="error">
                        {submitError}
                      </Typography>
                    </Box>
                  )}

                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <ButtonGroup variant="contained" color="secondary" disabled={submitting}>
                      <Button
                        startIcon={
                          submitting ? <CircularProgress size={16} color="inherit" /> : undefined
                        }
                        type="submit"
                        onClick={() => {
                          form.change('publish', false);
                        }}
                      >
                        Edit Campaign
                      </Button>
                      <Button
                        startIcon={
                          submitting ? <CircularProgress size={16} color="inherit" /> : undefined
                        }
                        type="submit"
                        onClick={() => {
                          form.change('publish', true);
                        }}
                      >
                        Publish Campaign
                      </Button>
                    </ButtonGroup>
                  </Box>
                </form>
              );
            }}
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

// Post modal auto complete function
interface PostAutocompleteProps {
  name: string;
  label?: string;
}

function PostAutocomplete({ name, label }: PostAutocompleteProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const where: Workspace_Posts_Bool_Exp = {
    _and: [
      {
        workspace_id: { _eq: activeWorkspaceId }
      },
      {
        facebook_post_id: { _is_null: false }
      },
      {
        posted_on: { facebook_page: { facebook_page_id: { _is_null: false } } }
      }
    ]
  };

  const { data, loading } = usePostsAutocompleteQuery({
    variables: {
      where: where
    },
    context: workspaceMemberContext
  });

  const options = data?.posts ?? [];

  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      loading={loading}
      getOptionValue={(post: any) => post.id}
      getOptionLabel={(post: any) => post.name ?? 'Manual Post'}
    />
  );
}
