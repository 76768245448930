import { useGetWorkspaceAgentAssignedAssetsQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import useUserContext from './useUserContext';

export interface AgentPermissions {
  allowed_agents: string[];
  allowed_facebook_pages: string[];
  allowed_facebook_pages_leads: string[];
  allowed_instagram_pages: string[];
  allowed_linkedin_pages: string[];
  allowed_gmb_pages: string[];
  allowed_youtube_pages: string[];
}

export function useAgentPermissions(): AgentPermissions {
  const { userId, activeWorkspaceId, isWorkspaceAgent } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data } = useGetWorkspaceAgentAssignedAssetsQuery({
    variables: {
      user_id: userId!,
      workspace_id: activeWorkspaceId!
    },
    skip: !userId || !activeWorkspaceId || !isWorkspaceAgent,
    context: workspaceMemberContext
  });

  const currentPermissions = data?.workspace_agent_assets[0];

  return {
    allowed_agents: currentPermissions?.allowed_agents ?? [],
    allowed_facebook_pages: currentPermissions?.allowed_facebook_pages ?? [],
    allowed_facebook_pages_leads: currentPermissions?.allowed_facebook_pages_leads ?? [],
    allowed_instagram_pages: currentPermissions?.allowed_instagram_pages ?? [],
    allowed_linkedin_pages: currentPermissions?.allowed_linkedin_pages ?? [],
    allowed_gmb_pages: currentPermissions?.allowed_gmb_pages ?? [],
    allowed_youtube_pages: currentPermissions?.allowed_youtube_pages ?? []
  };
}
