import React from 'react';

import { Link } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

type RealShortzProps = {
  value: number;
};

function getTabs(value: number) {
  return [
    { label: 'Finalised Videos', to: '/realshortz' },
    { label: 'Draft Videos', to: '/realshortz/draftVideos' }
  ].map((tab, idx) => {
    const props = value === idx ? {} : { component: Link, to: tab.to };

    return <Tab label={tab.label} {...props} key={idx} />;
  });
}

export default function RealShortzTabs({ value }: RealShortzProps) {
  return (
    <Tabs textColor="secondary" value={value}>
      {getTabs(value ?? undefined)}
    </Tabs>
  );
}
