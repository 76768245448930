// Material helpers
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// Component styles
const useStatusIndicatorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      borderRadius: '50%',
      flexGrow: 0,
      flexShrink: 0,
      margin: theme.spacing(0, 1)
    },
    small: {
      height: theme.spacing(0.9),
      width: theme.spacing(0.9)
    },
    medium: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5)
    },
    large: {
      height: theme.spacing(2),
      width: theme.spacing(2)
    },
    draft: {
      backgroundColor: theme.palette.grey[400]
    },
    active: {
      backgroundColor: theme.palette.success.main
    },
    ready: {
      backgroundColor: theme.palette.success.main
    },
    in_progress: {
      backgroundColor: theme.palette.success.main
    },
    error: {
      backgroundColor: theme.palette.error.main
    },
    active_with_issues: {
      backgroundColor: theme.palette.error.main
    },
    paused_with_issues: {
      backgroundColor: theme.palette.error.main
    },
    with_issues: {
      backgroundColor: theme.palette.error.main
    },
    paused: {
      backgroundColor: theme.palette.warning.main
    },
    finished: {
      backgroundColor: theme.palette.primary.main
    },
    archived: {
      backgroundColor: theme.palette.grey[400]
    },
    deleted: {
      backgroundColor: theme.palette.grey[400]
    },
    created: {
      backgroundColor: theme.palette.success.main
    },
    creating: {
      backgroundColor: theme.palette.success.main
    },
    done: {
      backgroundColor: theme.palette.primary.main
    },
    pending: {
      backgroundColor: theme.palette.primary.main
    },
    '@keyframes pulseDot': {
      '0%': {
        transform: 'scale(.75)'
      },
      '50%': {
        transform: 'scale(1.2)'
      },
      '100%': {
        transform: 'scale(.75)'
      }
    },
    pulse: {
      animationName: '$pulseDot',
      animationDuration: '1.2s',
      animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      animationIterationCount: 'infinite'
    }
  })
);

export default useStatusIndicatorStyles;
