import React, { useState } from 'react';

import { Button, ButtonGroup } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import {
  CreativeByPkQuery,
  useDeleteVideoThumbnailMutation,
  useUpsertVideoThumbnailMutation
} from 'generated/graphql';

import UppyUploader, { DEFAULT_MAX_IMAGE_SIZE } from 'components/Uppy/Uploader';
import { FileAttachment } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { getFilePath } from 'lib/auth/hbp';
import useUserContext from 'lib/hooks/useUserContext';

interface CreativeVideoThumbnailEditorProps {
  creative: NonNullable<CreativeByPkQuery['creative']>;
  onRefreshRequired: () => void | Promise<void>;
}

export default function CreativeVideoThumbnailEditor({
  creative,
  onRefreshRequired
}: CreativeVideoThumbnailEditorProps) {
  const { activeWorkspaceId } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [uppyOpen, setUppyOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [upsertThumbnail] = useUpsertVideoThumbnailMutation({ context: workspaceMemberContext });
  const [deleteThumbnail] = useDeleteVideoThumbnailMutation({ context: workspaceMemberContext });

  const currentThumbnail =
    getFilePath(creative.video_thumbnail?.s3_path ?? creative.video?.thumbnail_url) ??
    creative.cloudinary_video?.thumbnail_url;

  const hasUploadedThumbnail = Boolean(creative.video_thumbnail?.s3_path);

  const handleOpenUppy = () => {
    setUppyOpen(true);
  };

  const handleCloseUppy = () => {
    setUppyOpen(false);
  };

  const handleSaveThumbnail = async (files: FileAttachment[]) => {
    const file = files[0];

    if (!file) {
      return handleCloseUppy();
    }

    try {
      const { key, token } = file;
      await upsertThumbnail({
        variables: {
          thumbnail: {
            creative_id: creative.id,
            s3_path: key,
            s3_token: token
          }
        }
      });
      enqueueSnackbar('Thumbnail Updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to upload thumbnail', { variant: 'error' });
    } finally {
      handleCloseUppy();
      await onRefreshRequired();
    }
  };

  const handleDeleteThumbnail = async () => {
    try {
      await deleteThumbnail({
        variables: {
          video_thumbnail_id: creative.video_thumbnail?.id!
        }
      });
    } catch (error) {
      enqueueSnackbar('Unable to clear thumbnail', { variant: 'error' });
    } finally {
      await onRefreshRequired();
    }
  };

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: 4 }}>
        {currentThumbnail && (
          <div style={{ display: 'flex', placeContent: 'center' }}>
            <img
              alt={'Current video thumbnail'}
              src={currentThumbnail!}
              style={{ width: 300, height: 300, objectFit: 'contain' }}
            />
          </div>
        )}
        <ButtonGroup variant="contained" color="primary">
          <Button onClick={handleOpenUppy}>Update Thumbnail</Button>
          <Button onClick={handleDeleteThumbnail} disabled={!hasUploadedThumbnail}>
            Clear Uploaded Thumbnail
          </Button>
        </ButtonGroup>
      </div>
      <UppyUploader
        baseFileUploadPath={`workspace/${activeWorkspaceId!}/creatives/${creative.id!}`}
        workspaceId={activeWorkspaceId!}
        onUppyCompleteSuccess={handleSaveThumbnail}
        uppyRestrictions={{
          maxFileSize: DEFAULT_MAX_IMAGE_SIZE,
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png']
        }}
        uppyDashboardProps={{
          open: uppyOpen,
          closeModalOnClickOutside: true,
          onRequestClose: handleCloseUppy
        }}
        uppyImageCompressionOptions={{
          type: 'jpeg'
        }}
      />
    </>
  );
}
