import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const usePreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    iframe: {
      border: 'none'
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    }
  })
);
