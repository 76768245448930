import { makeStyles, Theme } from '@material-ui/core/styles';

const useManualVideoCreativeCardStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    minHeight: 360,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  tagHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.25)
    }
  },
  media: {
    position: 'relative',
    color: 'white',
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain'
  },
  tags: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
  }
}));

export default useManualVideoCreativeCardStyles;
