import React from 'react';

import { Button, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoopRoundedIcon from '@material-ui/icons/LoopRounded';

import { useSnackbar } from 'notistack';

import { useCampaignInvoiceInfoQuery, useRetryInvoicePaymentMutation } from 'generated/graphql';

import StatusIndicator, { Status } from 'components/StatusIndicator';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

interface WaitingPaymentTooltipProps {
  stripeInvoiceIds: string[];
  hideRetryButton?: boolean;
  onInvoicePaid?: () => void;
}

export default function WaitingPaymentTooltip({
  stripeInvoiceIds,
  hideRetryButton,
  onInvoicePaid
}: WaitingPaymentTooltipProps) {
  const { workspaceMemberContext, headOfficeUserContext } = useHasuraRoleContext();

  const { enqueueSnackbar } = useSnackbar();

  const [retryInvoicePayment, { loading }] = useRetryInvoicePaymentMutation({
    context: workspaceMemberContext
  });

  const { data: invoiceData, refetch } = useCampaignInvoiceInfoQuery({
    variables: {
      stripeInvoiceIds: stripeInvoiceIds
    },
    context: headOfficeUserContext ?? workspaceMemberContext,
    skip: !stripeInvoiceIds.length
  });

  const handleRetryPayment = async (invoiceId: string) => {
    try {
      const { data } = await retryInvoicePayment({ variables: { invoiceId } });
      if (data?.retryInvoicePayment?.success) {
        await refetch();

        if (onInvoicePaid) {
          onInvoicePaid();
        }

        enqueueSnackbar('Invoice paid', { variant: 'success' });
      } else {
        throw new Error('Invoice still unpaid');
      }
    } catch (error: any) {
      enqueueSnackbar('Unable to pay invoice', { variant: 'error' });
    }
  };

  const invoices = invoiceData?.stripe_invoices ?? [];
  const invoiceHostedUrls = invoices.map((invoices) => invoices.hosted_invoice_url!);
  const unpaidInvoiceIds = invoices
    .filter((invoice) => !invoice.paid)
    .map((invoice) => invoice.id!);
  const unpaidInvoiceId = [...unpaidInvoiceIds].pop();

  return (
    <>
      <StatusIndicator status={Status.ERROR} text="Waiting Payment" />
      <Tooltip
        title={"Please pay your campaign's invoice to enable"}
        enterDelay={200}
        leaveDelay={250}
      >
        <Button
          component="a"
          href={[...invoiceHostedUrls].pop() ?? '#'}
          target="_blank"
          size="small"
          startIcon={<HelpOutlineIcon />}
        >
          View invoice
        </Button>
      </Tooltip>
      {unpaidInvoiceId && !hideRetryButton && (
        <Button
          onClick={() => handleRetryPayment(unpaidInvoiceId)}
          startIcon={<LoopRoundedIcon />}
          size="small"
        >
          {loading ? 'Paying...' : 'Retry Payment'}
        </Button>
      )}
    </>
  );
}
