import gql from 'graphql-tag';

gql`
  query WorkspaceRSSContentItems(
    $where: workspace_rss_content_items_bool_exp!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    items: workspace_rss_content_items(
      where: $where
      limit: $limit
      offset: $offset
      order_by: [{ created_at: desc }]
    ) {
      id
      created_at
      updated_at
      title
      description
      image
      link
      rss_item_id
      author
      channel
      rss_feed {
        id
        name
      }
      posts(
        where: { status: { _in: [posted, scheduled, posting] } }
        order_by: [{ scheduled_at: desc }]
      ) {
        id
        scheduled_at
        posted
      }
    }
    items_aggregate: workspace_rss_content_items_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const RssContentItemByIdQuery = gql`
  query WorkspaceRssContentItem($id: uuid!) {
    item: workspace_rss_content_items_by_pk(id: $id) {
      id
      created_at
      updated_at
      title
      description
      image
      link
      author
      channel
      rss_item_id
    }
  }
`;
