import React from 'react';

import { Field } from 'react-final-form';

import ShareInput, { ShareInputProps } from 'components/ShareInput';

interface FinalFormShareInputProps
  extends Omit<ShareInputProps, 'values' & 'onChange' & 'errors' & 'dirty'> {
  name: string;
}

const FinalFormShareInput: React.FC<FinalFormShareInputProps> = ({ name, ...shareInputProps }) => {
  return (
    <Field
      name={name}
      render={(props) => {
        return (
          <ShareInput
            values={props.input.value}
            errors={props.meta.error}
            dirty={props.meta.dirty || props.meta.submitFailed || props.meta.submitError}
            onChange={props.input.onChange}
            {...shareInputProps}
          />
        );
      }}
    />
  );
};

export default FinalFormShareInput;
