// @ts-nocheck
import axios, { AxiosInstance } from 'axios';

import JWTMemory from './JWTMemory';
import * as types from './types';

export default class Storage {
  private http_client: AxiosInstance;
  private JWTMemory: JWTMemory;
  private use_cookies: boolean;

  constructor(config: types.StorageConfig, JWTMemory: JWTMemory) {
    this.JWTMemory = JWTMemory;
    this.use_cookies = config.use_cookies;

    this.http_client = axios.create({
      baseURL: config.base_url,
      timeout: 120 * 1000, // milliseconds
      withCredentials: this.use_cookies
    });
  }

  private generateAuthorizationHeader(): null | types.Headers {
    if (this.use_cookies) return null;

    const jwt_token = this.JWTMemory.getJWT();

    if (jwt_token) {
      return {
        Authorization: `Bearer ${jwt_token}`
      };
    } else {
      return null;
    }
  }

  async put(
    path: string,
    file: File,
    metadata: object | null = null,
    onUploadProgress: any | undefined = undefined
  ) {
    let form_data = new FormData();
    form_data.append('file', file);

    // todo: handle metadata
    if (metadata !== null) {
      console.warn('Metadata is not yet handled in this NHOST JS SDK.');
    }

    const upload_res = await this.http_client.post(`/storage/o${path}`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.generateAuthorizationHeader()
      },
      onUploadProgress
    });

    return upload_res.data;
  }

  async putViaPresignedURL(
    path: string,
    file: File,
    metadata: object | null = null,
    onUploadProgress: any | undefined = undefined
  ): Promise<any> {
    // todo: handle metadata
    if (metadata !== null) {
      console.warn('Metadata is not yet handled in this NHOST JS SDK.');
    }

    const presignedResp = await this.http_client.post(`/storage/presign${path}`, undefined, {
      headers: {
        ...this.generateAuthorizationHeader()
      }
    });
    const { url } = presignedResp.data;

    let form_data = new FormData();
    form_data.append('file', file);

    // Upload to S3 Bucket Directly
    await this.http_client.put(url, file, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      onUploadProgress
    });

    // Fetch Object Metadata
    const data = await this.getMetadata(path);
    return data;
  }

  async delete(path: string) {
    const upload_res = await this.http_client.delete(`storage/o${path}`, {
      headers: {
        ...this.generateAuthorizationHeader()
      }
    });
    return upload_res.data;
  }

  async getPresignedURL(path: string) {
    const presignedResp = await this.http_client.get(`/storage/presign${path}`, {
      headers: {
        ...this.generateAuthorizationHeader()
      }
    });
    const { url } = presignedResp.data;

    return url;
  }

  async getMetadata(path: string): Promise<object> {
    const res = await this.http_client.get(`storage/m${path}`, {
      headers: {
        ...this.generateAuthorizationHeader()
      }
    });
    return res.data;
  }
}
