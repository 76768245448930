export type Maybe<T> = T | null;

export enum AttachmentEnum {
  LISTING_IMAGE = 'LISTING_IMAGE',
  CREATIVE_IMAGE = 'CREATIVE_IMAGE',
  CREATIVE_VIDEO = 'CREATIVE_VIDEO',
  CREATIVE_FILE = 'CREATIVE_FILE',
  CAROUSEL_ITEM = 'CAROUSEL_ITEM',
  FILE = 'FILE',
  UPLOADED_FILE = 'UPLOADED_FILE',
  REALSHORTZ_VIDEO = 'REALSHORTZ_VIDEO'
}

export interface FileAttachment {
  key: string;
  filename: string;
  token: string;
  contenttype: string;
  thumbnail_path?: string;
}

export interface Image {
  id: string;
  url: string;
}

export interface CreativeVideo {
  id: string;
  thumbnail_url?: string;
}

export interface CreativeFile {
  id: string;
  path: string;
  token: string;
  priority?: number;
  content_type: string;
  filename: string;
}

export interface Attachment {
  type: AttachmentEnum;
  file?: File;
  image?: Image;
  video?: CreativeVideo;
  entry?: CreativeFile;
  uploaded_file?: FileAttachment;
}

export interface UploadAttachment {
  type: AttachmentEnum;
  file?: FileAttachment;
  image?: Image;
  video?: CreativeVideo;
  entry?: CreativeFile;
}

export interface Facebook {
  attachments?: Attachment[];
  title?: string;
  message?: string;
}

type Platform = 'youtube' | 'linkedin' | 'facebook' | 'linkedin_personal' | 'instagram' | 'gmb';

export type Platforms = Array<Platform>;

export enum ShareLogicVariants {
  LISTING = 'listing',
  CREATIVE = 'creative',
  UPLOAD = 'upload',
  CONTENT_RSS = 'content_rss',
  CAROUSEL = 'carousel',
  REALSHORTZ = 'realshortz'
}
