import React, { useMemo } from 'react';

import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import _, { ValueIteratee } from 'lodash';

export interface ImageGroupingProps<T> {
  values: Array<T>;
  groupBy: ValueIteratee<T>;
  children: (props: { value: T; index: number }) => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  groupHeader: {
    top: -10,
    position: 'sticky',
    zIndex: 10,
    backgroundColor: theme.palette.common.white
  }
}));

export default function ItemGrouping<T>({ values, groupBy, children }: ImageGroupingProps<T>) {
  const classes = useStyles();
  const groupedValues = useMemo(() => {
    return _.groupBy(values, groupBy);
  }, [groupBy, values]);

  return (
    <Grid container direction="column" spacing={3}>
      {React.Children.toArray(
        Object.entries(groupedValues).map(([title, arr]) => (
          <Grid item>
            <Grid container direction="column" spacing={3}>
              <Grid item className={classes.groupHeader}>
                <Typography variant="h6">{title}</Typography>
                <Divider />
              </Grid>
              <Grid item>
                <Grid spacing={2} container>
                  {React.Children.toArray(
                    arr.map((value, index) => <Grid item>{children({ value, index })}</Grid>)
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
    </Grid>
  );
}
