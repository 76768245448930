import React from 'react';

import { Field } from 'react-final-form';

import { Avatar, Badge, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import FacebookAvatar from 'components/avatars/Facebook';
import GoogleMyBusinessAvatar from 'components/avatars/GoogleMyBusiness';
import InstagramAvatar from 'components/avatars/Instagram';
import LinkedInAvatar from 'components/avatars/LinkedIn';
import YouTubeAvatar from 'components/avatars/YouTube';

export interface FinalFormPlatformButtonProps {
  platform: string;
  showRemoveButton?: boolean;
  creatorAvatarUrl?: string;
}

const FinalFormPlatformButton: React.FC<FinalFormPlatformButtonProps> = ({
  platform,
  creatorAvatarUrl,
  showRemoveButton = false
}) => {
  let icon: React.ReactNode = undefined;
  let tooltip: string = '';
  switch (platform) {
    case 'facebook': {
      icon = <FacebookAvatar />;
      tooltip = 'Facebook';
      break;
    }
    case 'instagram': {
      icon = <InstagramAvatar />;
      tooltip = 'Instagram';
      break;
    }
    case 'linkedin': {
      icon = <LinkedInAvatar />;
      tooltip = 'LinkedIn';
      break;
    }
    case 'linkedin_personal': {
      icon = (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<Avatar src={creatorAvatarUrl} style={{ width: 20, height: 20 }} />}
        >
          <LinkedInAvatar />
        </Badge>
      );
      tooltip = 'LinkedIn Personal Feed';
      break;
    }
    case 'youtube': {
      icon = <YouTubeAvatar />;
      tooltip = 'YouTube';
      break;
    }
    case 'gmb': {
      icon = <GoogleMyBusinessAvatar />;
      tooltip = 'Google My Business';
      break;
    }
  }

  return (
    <Field
      name="platforms"
      type="checkbox"
      multiple={true}
      value={platform}
      render={({ input: { onChange, checked, value } }) => {
        const handleChange = () => {
          onChange({
            target: {
              type: 'checkbox',
              value: value,
              checked: !checked
            }
          });
        };

        return (
          <Tooltip title={tooltip}>
            <IconButton onClick={handleChange} style={{ padding: 8 }}>
              {showRemoveButton ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    <CloseIcon
                      style={{
                        backgroundColor: '#192A3E',
                        color: '#FFFFFF',
                        borderRadius: 10,
                        width: 15,
                        height: 15
                      }}
                    />
                  }
                >
                  {icon}
                </Badge>
              ) : (
                icon
              )}
            </IconButton>
          </Tooltip>
        );
      }}
    />
  );
};

export default FinalFormPlatformButton;
