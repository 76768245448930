import { ComponentsProps } from '@material-ui/core/styles/props';

const props: ComponentsProps = {
  MuiTextField: {
    margin: 'normal',
    variant: 'outlined'
  },
  MuiFormControl: {
    margin: 'normal',
    variant: 'outlined'
  },
  MuiButton: {
    disableElevation: true
  },
  MuiButtonGroup: {
    disableElevation: true
  }
};

export default props;
