import React, { useEffect, useState } from 'react';

import ReactPlayer from 'react-player';

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import DialogTitle from 'components/DialogTitle';

import useRealShortzVDraftideoCardStyles from './_styles';

interface DraftVideoCardProps {
  id: string;
  title: string;
  subtitle: string;
  thumbnailUrl: string;
  cloudinaryPlaybackUrl: string;
  disableActionButtons?: boolean;
  isUploading: boolean;
  sections?: any;
}

const DraftVideoCard: React.FC<DraftVideoCardProps> = ({
  id,
  title,
  subtitle,
  thumbnailUrl,
  cloudinaryPlaybackUrl,
  disableActionButtons = false,
  isUploading,
  sections
}) => {
  const classes = useRealShortzVDraftideoCardStyles();
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeVideo, setActiveVideo] = useState(cloudinaryPlaybackUrl);

  useEffect(() => {
    const image = new Image();
    image.src = thumbnailUrl;
    image.onload = () => setImageLoaded(true);
  }, [thumbnailUrl]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={classes.root} elevation={2}>
      <CardActionArea disabled={isUploading} onClick={handleClickOpen}>
        <CardMedia
          className={classes.media}
          image={imageLoaded ? thumbnailUrl : '/videos/LoadingVideo.jpg'}
          title={title}
        />
      </CardActionArea>
      <CardContent>
        <Typography variant="overline">{subtitle}</Typography>
        <Typography variant="h6">{title}</Typography>

        {isUploading && (
          <Typography variant="caption" color="error">
            This RealShortz video has not finished uploading yet. Have your agent open the video via
            the RealShortz app to continue upload.
          </Typography>
        )}
      </CardContent>
      <CardActions>
        {/* TODO: Add Canva linking */}
        {/* <Tooltip title="Share Post">
          <IconButton
            aria-label="Edit in Canva"
            disabled={disableActionButtons}
          >
            <ShareIcon />
          </IconButton>
        </Tooltip> */}
      </CardActions>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { maxWidth: 'none', width: 'auto', margin: '0px' } }}
      >
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <DialogContent>
          {(isUploading || !cloudinaryPlaybackUrl) && (
            <Typography variant="caption" color="error">
              This RealShortz video has not finished uploading yet. Have your agent open the video
              via the RealShortz app to continue upload.
            </Typography>
          )}
          <div style={{ width: '100%', height: 600, textAlign: 'center' }}>
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={activeVideo}
              controls
              playing
              style={{ display: 'inline-block' }}
            />
          </div>
        </DialogContent>
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}>
          {sections.map((section: any) => (
            <img
              key={section.id}
              src={section.sync.cloudinary_thumbnail_url}
              alt={section.room_type}
              style={{ cursor: 'pointer', width: 100, height: 'auto', padding: '10px' }}
              onClick={() => setActiveVideo(section.sync.cloudinary_playback_url)}
            />
          ))}
        </div>
        <DialogActions>
          {/* TODO: Add Canva linking */}
          {/* <Button startIcon={<OpenInNew />} target="_blank">
            Edit in Canva
          </Button> */}
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DraftVideoCard;
