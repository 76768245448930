import React from 'react';

import { Paper, PaperProps } from '@material-ui/core';

import clsx from 'clsx';

import useFormPaperStyles from './_styles';

type FormPaperProps = {
  children: React.ReactNode;
} & PaperProps;

const FormPaper = React.forwardRef(
  ({ children, className, ...otherProps }: FormPaperProps, ref) => {
    const classes = useFormPaperStyles();

    return (
      <Paper className={clsx(classes.paper, className)} elevation={1} ref={ref} {...otherProps}>
        {children}
      </Paper>
    );
  }
);

export default FormPaper;
