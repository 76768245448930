import React, { useContext } from 'react';

import { Bar } from 'react-chartjs-2';

import { MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { ChartDataset } from 'chart.js';
import 'chartjs-adapter-moment';
import numbro from 'numbro';

import { useSpendChartQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useLocalStorage from 'lib/hooks/useLocalStorage';

import { DateResolution } from '../types';
import { getStartDate, getEndDate, getDataResolution } from '../utils';
import DatePresetSelect, { DashboardDatePresetContext } from './DatePresetSelect';
import EmptyChartMessage from './EmptyChartMessage';
import LoadingChartIndicator from './LoadingChartIndicator';

interface SpendBoxProps {
  workspaceId: string;
}

interface ChartData {
  date: string;
  impressions: number;
  spend: number;
  clicks: number;
}

enum DataChoice {
  SpendImpressions = 'SpendImpressions',
  SpendClicks = 'SpendClicks'
}

function mapCampaignData(data: any): ChartData {
  return {
    date: data.date,
    impressions: data.impressions,
    spend: data.spend,
    clicks: data.clicks
  };
}

function mapBenchmarkingData(data: any): ChartData {
  return {
    date: data.date,
    impressions: 0,
    clicks: 0,
    spend: data.top_20_percent
  };
}

function getYAxisKey(dataChoice: DataChoice): string {
  switch (dataChoice) {
    case DataChoice.SpendImpressions:
      return 'impressions';
    case DataChoice.SpendClicks:
      return 'clicks';
    default:
      return 'impressions';
  }
}

function getLabel(dataChoice: DataChoice): string {
  switch (dataChoice) {
    case DataChoice.SpendImpressions:
      return 'Impressions';
    case DataChoice.SpendClicks:
      return 'Clicks';
    default:
      return 'Impressions';
  }
}

export default function SpendBox({ workspaceId }: SpendBoxProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { datePreset } = useContext(DashboardDatePresetContext);
  const [dataChoice, setDataChoice] = useLocalStorage(
    'properti_dashboard_data',
    DataChoice.SpendImpressions
  );

  const startDate = getStartDate(datePreset).format('YYYY-MM-DD');
  const endDate = getEndDate(datePreset).format('YYYY-MM-DD');
  const resolution = getDataResolution(datePreset);

  const { data, loading } = useSpendChartQuery({
    variables: {
      workspace_id: workspaceId,
      start_date: startDate,
      end_date: endDate,
      skip_daily: resolution !== DateResolution.DAILY,
      skip_weekly: resolution !== DateResolution.WEEKLY,
      skip_monthly: resolution !== DateResolution.MONTHLY
    },
    context: workspaceMemberContext
  });

  // Handle data resolution
  let campaignData: ChartData[] = data?.workspace_analytics_workspaces?.map(mapCampaignData) ?? [];
  if (resolution === DateResolution.WEEKLY) {
    campaignData = data?.workspace_analytics_workspaces_weekly?.map(mapCampaignData) ?? [];
  } else if (resolution === DateResolution.MONTHLY) {
    campaignData = data?.workspace_analytics_workspaces_monthly?.map(mapCampaignData) ?? [];
  }

  let benchmarkData: ChartData[] =
    data?.benchmarking_workspaces_spend?.map(mapBenchmarkingData) ?? [];
  if (resolution === DateResolution.WEEKLY) {
    benchmarkData = data?.benchmarking_workspaces_spend_weekly?.map(mapBenchmarkingData) ?? [];
  } else if (resolution === DateResolution.MONTHLY) {
    benchmarkData = data?.benchmarking_workspaces_spend_monthly?.map(mapBenchmarkingData) ?? [];
  }

  const datasets: ChartDataset<'bar', ChartData[]>[] = [
    {
      type: 'line' as any,
      label: getLabel(dataChoice as DataChoice),
      backgroundColor: '#F47D6F',
      borderColor: '#F47D6F',
      // @ts-ignore
      pointRadius: 5,
      borderWidth: 2,
      fill: false,
      data: campaignData,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: getYAxisKey(dataChoice as DataChoice)
      },
      yAxisID: 'line'
    },
    {
      type: 'bar',
      label: 'Spend',
      backgroundColor: '#90A0B7',
      data: campaignData,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'spend'
      },
      yAxisID: 'bar'
    }
  ];

  const showBenchmarkingData = benchmarkData.length > 5;
  if (showBenchmarkingData) {
    datasets.push({
      type: 'bar',
      label: 'Benchmark Spend',
      backgroundColor: '#C2CFE0',
      data: benchmarkData,
      yAxisID: 'bar',
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'spend'
      }
    });
  }

  return (
    <Paper>
      <div
        style={{
          padding: '16px 0px 8px 16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 30
        }}
      >
        <Typography variant="h6">Summary</Typography>
        <DatePresetSelect />
        <Select
          value={dataChoice}
          onChange={(event) => setDataChoice(event.target.value as DataChoice)}
          style={{ color: '#F47D6F' }}
          disableUnderline
        >
          <MenuItem value={DataChoice.SpendImpressions}>Spend / Impressions</MenuItem>
          <MenuItem value={DataChoice.SpendClicks}>Spend / Clicks</MenuItem>
        </Select>
      </div>

      {loading ? (
        <LoadingChartIndicator />
      ) : !campaignData.length ? (
        <EmptyChartMessage />
      ) : (
        <div
          style={{
            position: 'relative',
            maxHeight: 500,
            maxWidth: 1200,
            paddingTop: 16,
            paddingBottom: 16,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Bar
            data={{
              datasets: datasets
            }}
            options={{
              parsing: {
                key: 'value'
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  align: 'start',
                  labels: {
                    boxWidth: 8,
                    padding: 40,
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    pointStyle: 'circle',
                    usePointStyle: true
                  }
                }
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: resolution,
                    round: resolution,
                    stepSize: 1,
                    displayFormats: {
                      day: 'Do MMM',
                      week: 'Do MMM',
                      month: 'MMM YYYY'
                    },
                    tooltipFormat: 'Do MMM YYYY'
                  }
                },
                bar: {
                  display: 'auto',
                  type: 'linear',
                  position: 'left',
                  title: {
                    display: true,
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    text: 'Spend',
                    align: 'end',
                    color: '#696C80'
                  },
                  ticks: {
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    callback: (value) =>
                      numbro(value).formatCurrency({
                        thousandSeparated: true,
                        spaceSeparated: false,
                        mantissa: 0
                      })
                  }
                },
                line: {
                  display: 'auto',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    }
                  },
                  title: {
                    display: true,
                    text: getLabel(dataChoice as DataChoice),
                    align: 'end',
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    }
                  }
                }
              },
              backgroundColor: 'rbga(0, 0, 0, 0)',
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 3
            }}
          />
        </div>
      )}
    </Paper>
  );
}
