import { makeStyles } from '@material-ui/core/styles';

const useAuthStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#E5E5E5'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  formWrapper: {
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(3, 6)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 12)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 4)
    }
  },
  progressBox: {
    width: '100%',
    maxWidth: 680,
    height: 230,
    padding: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  box: {
    width: '100%',
    maxWidth: 680,
    height: 680,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100vh',
      padding: theme.spacing(1)
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  logo: {
    display: 'block',
    margin: theme.spacing(3, 'auto')
  },
  extraItem: {
    margin: theme.spacing(2, 0, 1, 0)
  },
  buttonCaption: {}
}));

export default useAuthStyles;
