import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function PresentationAudienceIcon(props: SvgIconProps) {
  const theme = useTheme();

  let defaultStroke = '#000000';
  if (props.color) {
    // @ts-ignore
    defaultStroke = theme.palette[props.color]?.main;
  }

  const stroke = props.stroke ?? defaultStroke;

  return (
    <SvgIcon viewBox="0 0 64 64" stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.6771 16C34.7272 16 38.0104 12.7167 38.0104 8.66665C38.0104 4.61656 34.7272 1.33331 30.6771 1.33331C26.627 1.33331 23.3438 4.61656 23.3438 8.66665C23.3438 12.7167 26.627 16 30.6771 16Z"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6526 25.432C39.1002 23.487 37.9311 21.7739 36.3212 20.5505C34.7113 19.3272 32.7476 18.6597 30.7257 18.6486C28.7037 18.6375 26.7328 19.2833 25.1095 20.4888C23.4862 21.6944 22.2984 23.3945 21.7246 25.3334"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6768 30.6667H42.6768"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9011 38.536L21.3438 30.6667H40.0104L39.4478 38.616"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6667 36.7253C30.5348 36.7253 30.4059 36.7644 30.2963 36.8376C30.1867 36.9109 30.1012 37.015 30.0507 37.1368C30.0003 37.2586 29.9871 37.3927 30.0128 37.522C30.0385 37.6513 30.102 37.7701 30.1953 37.8633C30.2885 37.9566 30.4073 38.0201 30.5366 38.0458C30.6659 38.0715 30.8 38.0583 30.9218 38.0079C31.0436 37.9574 31.1477 37.872 31.221 37.7623C31.2942 37.6527 31.3333 37.5238 31.3333 37.3919C31.3333 37.2151 31.2631 37.0456 31.1381 36.9205C31.013 36.7955 30.8435 36.7253 30.6667 36.7253Z"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2403 55.2C35.7013 55.2 38.507 52.3943 38.507 48.9333C38.507 45.4723 35.7013 42.6667 32.2403 42.6667C28.7793 42.6667 25.9736 45.4723 25.9736 48.9333C25.9736 52.3943 28.7793 55.2 32.2403 55.2Z"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5274 55.2C15.9884 55.2 18.7941 52.3943 18.7941 48.9333C18.7941 45.4723 15.9884 42.6667 12.5274 42.6667C9.06642 42.6667 6.26074 45.4723 6.26074 48.9333C6.26074 52.3943 9.06642 55.2 12.5274 55.2Z"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5157 55.2C54.9767 55.2 57.7823 52.3943 57.7823 48.9333C57.7823 45.4723 54.9767 42.6667 51.5157 42.6667C48.0547 42.6667 45.249 45.4723 45.249 48.9333C45.249 52.3943 48.0547 55.2 51.5157 55.2Z"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.3333 62.6666C60.1629 61.2145 58.682 60.0431 56.9996 59.2383C55.3171 58.4334 53.4757 58.0157 51.6107 58.0157C49.7456 58.0157 47.9042 58.4334 46.2218 59.2383C44.5393 60.0431 43.0585 61.2145 41.888 62.6666C40.6768 61.2203 39.1644 60.0557 37.4566 59.2543C35.7487 58.4529 33.8865 58.0339 32 58.0266"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 62.6666C3.83745 61.2145 5.31828 60.0431 7.00075 59.2383C8.68322 58.4334 10.5246 58.0157 12.3897 58.0157C14.2547 58.0157 16.0961 58.4334 17.7786 59.2383C19.461 60.0431 20.9419 61.2145 22.1123 62.6666C23.3236 61.2203 24.8359 60.0557 26.5438 59.2543C28.2516 58.4529 30.1138 58.0339 32.0003 58.0266"
        stroke="#F47D6F"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
