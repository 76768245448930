import { Line } from 'react-chartjs-2';

import { Paper, Typography } from '@material-ui/core';

import moment from 'moment';

import { useWorkspacePostFrequencyQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import LoadingChartIndicator from './LoadingChartIndicator';

interface OrganicPostBoxProps {
  workspaceId: string;
}

export default function OrganicPostBox({ workspaceId }: OrganicPostBoxProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const startOfThisWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const endOfThisWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

  const startOfLastWeek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
  const endOfLastWeek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');

  const { data, loading } = useWorkspacePostFrequencyQuery({
    variables: {
      period_where: {
        _and: [
          { workspace_id: { _eq: workspaceId! } },
          { date: { _gte: startOfThisWeek } },
          { date: { _lte: endOfThisWeek } }
        ]
      },
      comparison_where: {
        _and: [
          { workspace_id: { _eq: workspaceId! } },
          { date: { _gte: startOfLastWeek } },
          { date: { _lte: endOfLastWeek } }
        ]
      }
    },
    context: workspaceMemberContext
  });

  const thisWeekData: number[] = data?.period?.map((p) => p.count!) ?? [];
  const lastWeekData: number[] = data?.comparison?.map((p) => p.count!) ?? [];

  return (
    <Paper style={{ height: '100%', minHeight: 200 }}>
      <div
        style={{
          padding: '16px 0px 8px 16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 30
        }}
      >
        <Typography variant="h6">Organic Posts</Typography>
      </div>
      {loading ? (
        <LoadingChartIndicator />
      ) : (
        <div
          style={{
            position: 'relative',
            maxHeight: 400,
            maxWidth: 400,
            paddingTop: 4,
            paddingBottom: 16,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Line
            data={{
              labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              datasets: [
                {
                  type: 'line',
                  label: 'This Week',
                  backgroundColor: '#309062',
                  borderColor: '#309062',
                  pointRadius: 10,
                  borderWidth: 0,
                  fill: false,
                  data: thisWeekData
                },
                {
                  type: 'line',
                  label: 'Last Week',
                  backgroundColor: '#C2CFE0',
                  borderColor: '#C2CFE0',
                  pointRadius: 10,
                  borderWidth: 0,
                  fill: false,
                  data: lastWeekData
                }
              ]
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: 'top',
                  align: 'start',
                  labels: {
                    boxWidth: 8,
                    padding: 8,
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    color: '#696C80',
                    pointStyle: 'circle',
                    usePointStyle: true
                  }
                }
              },
              scales: {
                y: {
                  display: false,
                  type: 'linear',
                  position: 'left',
                  min: 0,
                  suggestedMax: 5
                },
                x: {
                  display: true,
                  type: 'category',
                  position: 'bottom',
                  ticks: {
                    display: true,
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    }
                  }
                }
              },
              backgroundColor: 'rbga(0, 0, 0, 0)',
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 2
            }}
          />
        </div>
      )}
    </Paper>
  );
}
