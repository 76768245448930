import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import AdPreviewButton from '../AdPreviewButton';
import { GoogleAdsResponsivePreviewProps } from './types';

const useDesktop350x250ImageAdStyles = makeStyles({
  host: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '350px',
    height: '250px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.12)'
  },
  image: {
    display: 'block',
    width: '100%',
    height: '157px',
    objectFit: 'cover'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '19px',
    fontWeight: 700,
    lineHeight: '20px',
    margin: '10px 10px 0'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1,
    margin: '14px 18px'
  },
  logo: {
    display: 'block',
    maxWidth: '32px',
    maxHeight: '32px'
  },
  logoImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  description: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    flex: 1,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '19px',
    marginLeft: '18px',
    marginRight: '9px',
    maxHeight: '2.714285714em'
  },
  button: {
    fontSize: '36px'
  }
});

export default function Desktop350x250ImageAd({
  imageUrl,
  title,
  description,
  logoUrl,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useDesktop350x250ImageAdStyles({ title, description });

  return (
    <div className={styles.host}>
      <img className={styles.image} src={imageUrl!} alt="" />
      <span className={styles.title}>{title}</span>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img className={styles.logoImage} src={logoUrl!} alt="" />
        </div>
        <span className={styles.description}>{description}</span>
        <AdPreviewButton
          className={styles.button}
          href={landingPageUrl}
          target="_blank"
          hidden={!showButton}
        />
      </div>
    </div>
  );
}
