import Grid from '@material-ui/core/Grid';

import {
  useStripeCustomActiveSubscriptionQuery,
  useWorkspaceOnboardingQuery
} from 'generated/graphql';

import OnboardingChecklist from 'components/OnboardingChecklist';
import PageHeader from 'components/PageHeader';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';

const WelcomePage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, info, workspace, isWorkspaceAdmin } = useUserContext();

  const { data } = useWorkspaceOnboardingQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext,
    skip: !activeWorkspaceId
  });

  const stripeCustomerId = workspace?.stripe_customer_id;

  const skip = !stripeCustomerId;
  const { data: stripeData } = useStripeCustomActiveSubscriptionQuery({
    variables: {
      customerId: stripeCustomerId as string
    },
    context: workspaceMemberContext,
    skip: skip
  });

  const facebook = data?.workspace?.apps?.facebook;
  const linkedin = data?.workspace?.apps?.linkedin;
  const youtube = data?.workspace?.apps?.youtube;
  const feedapi = data?.workspace?.apps?.feedapi;
  const gmb = data?.workspace?.apps?.gmb;
  const googleads = data?.workspace?.apps?.googleads;

  const branding = data?.workspace?.branding;
  const invites = data?.workspace?.invites_aggregate?.aggregate?.count ?? 0;

  const workspaceCreated = Boolean((info?.workspaces.length ?? 0) > 0);
  const fbConnected = Boolean(facebook?.ad_account_id) && Boolean(facebook?.page_id);
  const linkedinConnected = Boolean(linkedin?.id) && Boolean(linkedin?.organisation_id);
  const youtubeConnected = Boolean(youtube?.id) && Boolean(youtube?.channel_id);
  const gmbConnected = Boolean(gmb?.id) && gmb?.allowed_locations?.length;
  const googleAdsConnected = Boolean(googleads?.id) && Boolean(googleads?.account_id);

  const feedapiConnected = Boolean(feedapi?.id);
  const brandingSetup = Boolean(branding?.logo_url) && Boolean(branding?.primary_color);
  const hasInvitedUser = invites > 0;
  const profileSetup = Boolean(info?.avatar_url) && Boolean(info?.job_title);

  // Subscription Status
  const activeSubscriptions = stripeData?.stripe_customer_status.active_subscriptions;
  const subscriptionIsActive = activeSubscriptions;

  return (
    <div>
      <PageHeader
        title="Welcome"
        subtitle="In this section learn more about how to make the most of Properti for your business."
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <OnboardingChecklist
            title="Getting Started"
            hideHideButton
            items={[
              {
                title: 'Create a Workspace',
                subtitle:
                  'Workspaces allow you to separate and manage assets betweens franchises, accounts, etc.',
                complete: workspaceCreated,
                actionUrl: '/workspaces',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Choose your subscription',
                subtitle: 'Your workspace will need a subscription to use Properti.',
                complete: subscriptionIsActive,
                actionUrl: '/billing',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Complete your profile',
                subtitle:
                  'Set up your profile so you can add specific contact details in your creative.',
                complete: profileSetup,
                actionUrl: '/profile'
              },
              {
                title: 'Connect your CRM',
                subtitle:
                  'Connect relevant apps and turn your CRM into a real estate marketing machine!',
                complete: feedapiConnected,
                actionUrl: '/apps#crm',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Connect Facebook',
                subtitle: 'Connect your Facebook account to start posting and creating ads.',
                complete: fbConnected,
                actionUrl: '/apps/facebook',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Connect LinkedIn',
                subtitle: 'Connect your LinkedIn account to start posting.',
                complete: linkedinConnected,
                actionUrl: '/apps/linkedin',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Connect YouTube',
                subtitle: 'Connect your YouTube account to start posting videos.',
                complete: youtubeConnected,
                actionUrl: '/apps/youtube',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Connect Google Ads',
                subtitle:
                  "Connect your Google Ads account to run campaigns across Google's ad network.",
                complete: googleAdsConnected,
                actionUrl: '/apps/googleads',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Connect Google My Business',
                subtitle: 'Connect your Google My Business account to start posting images.',
                complete: gmbConnected,
                actionUrl: '/apps/gmb',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Setup your branding styles',
                subtitle: 'Add your agency branding to customise your videos.',
                complete: brandingSetup,
                actionUrl: '/branding#general',
                hide: !isWorkspaceAdmin
              },
              {
                title: 'Invite your team',
                subtitle: 'Add additional team members to make the most of Properti.',
                complete: hasInvitedUser,
                actionUrl: '/team',
                hide: !isWorkspaceAdmin
              }
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WelcomePage;
