import { NavLink } from 'react-router-dom';

import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';

import numbro from 'numbro';

export interface DashboardStatCardProps {
  title: string;
  value: number;
  subtitle?: string;
  buttonLink?: string;
  buttonText?: string;
}

const DashboardStatCard = ({
  title,
  subtitle,
  value,
  buttonLink,
  buttonText
}: DashboardStatCardProps) => {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '30%',
        height: '100%',
        minHeight: 200
      }}
      elevation={1}
    >
      <CardContent style={{ flexGrow: 1 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4">{numbro(value).format({ thousandSeparated: true })}</Typography>
        <Typography variant="caption" gutterBottom>
          {subtitle}
        </Typography>
      </CardContent>
      {buttonLink && buttonText && (
        <CardActions>
          <Button component={NavLink} to={buttonLink} color="primary">
            {buttonText}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default DashboardStatCard;
