import { useContext } from 'react';

import ListingImageSelectContext from './ListingImageSelectContext';

const useListingImageSelect = () => {
  const select = useContext(ListingImageSelectContext);
  return select;
};

export default useListingImageSelect;
