import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { logger } from 'lib/LoggerContext';

type CanvaDesignType =
  | 'A4Document'
  | 'Announcement'
  | 'BirthdayCard'
  | 'BirthdayInvitation'
  | 'BlogBanner'
  | 'BookCover'
  | 'Bookmark'
  | 'Brochure'
  | 'BusinessCard'
  | 'Calendar'
  | 'Card'
  | 'Certificate'
  | 'DesktopWallpaper'
  | 'EmailHeader'
  | 'EtsyShopCover'
  | 'EtsyShopIcon'
  | 'FacebookAd'
  | 'FacebookAppAd'
  | 'FacebookCover'
  | 'FacebookEventCover'
  | 'FacebookPost'
  | 'Flyer'
  | 'GiftCertificate'
  | 'Infographic'
  | 'InstagramPost'
  | 'InstagramStory'
  | 'Invitation'
  | 'Invoice'
  | 'Label'
  | 'LargeRectangleAd'
  | 'LeaderboardAd'
  | 'LessonPlan'
  | 'Letter'
  | 'LinkedInBanner'
  | 'Logo'
  | 'MagazineCover'
  | 'MediumRectangleAd'
  | 'Menu'
  | 'MindMap'
  | 'Newsletter'
  | 'PhotoCollage'
  | 'PinterestGraphic'
  | 'Postcard'
  | 'Poster'
  | 'Presentation'
  | 'Presentation43'
  | 'ProductLabel'
  | 'RecipeCard'
  | 'Resume'
  | 'SnapchatGeofilter'
  | 'SocialMedia'
  | 'Ticket'
  | 'TumblrGraphic'
  | 'TwitterHeader'
  | 'TwitterPost'
  | 'WattpadBookCover'
  | 'WeddingInvitation'
  | 'WideSkyscraperAd'
  | 'Worksheet'
  | 'Yearbook'
  | 'YouTubeChannelArt'
  | 'YouTubeThumbnail';

type CanvaButtonApi = {
  createDesign: (opts: {
    design: {
      type: CanvaDesignType;
      dimensions?: {
        width: number;
        height: number;
        units?: 'px' | 'cm' | 'mm' | 'in';
      };
    };
    editor?: {
      publishLabel?: string;
      fileType?: 'jpg' | 'jpeg' | 'pdf' | 'png';
    };
    onDesignOpen?: (opts: { designId: string }) => void;
    onDesignPublish?: (opts: { exportUrl: string; designId: string }) => void;
    onDesignClose?: () => void;
  }) => void;
  editDesign: (opts: {
    design: {
      id: string;
    };
    editor?: {
      publishLabel?: string;
      fileType?: 'jpg' | 'jpeg' | 'pdf' | 'png';
    };
    onDesignOpen?: (opts: { designId: string }) => void;
    onDesignPublish?: (opts: { exportUrl: string; designId: string }) => void;
    onDesignClose?: () => void;
  }) => void;
};

type CanvaContextType = {
  canvaApi?: CanvaButtonApi;
};

const defaultContext: CanvaContextType = {};

const CanvaContext = createContext(defaultContext);

const CanvaContextProvider = ({ children }: { children: ReactNode }) => {
  const [api, setApi] = useState<CanvaButtonApi>();

  useEffect(() => {
    (async function init() {
      if ((window as any).CanvaApi) {
        setApi((window as any).CanvaApi);
      } else if ((window as any).Canva && (window as any).Canva.DesignButton) {
        try {
          const newApi = await (window as any).Canva.DesignButton.initialize({
            apiKey: process.env.REACT_APP_CANVA_API_KEY
          });
          (window as any).CanvaApi = newApi;
          setApi(newApi);
        } catch (error: any) {
          console.error(error);
          logger.error(error);
        }
      }
    })();
  }, [setApi]);

  return <CanvaContext.Provider value={{ canvaApi: api }}>{children}</CanvaContext.Provider>;
};

export { CanvaContext, CanvaContextProvider };
