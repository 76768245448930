import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  DialogContentText,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';

import MaterialTable from '@material-table/core';

import { useConfirm } from 'material-ui-confirm';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  useWorkspaceLeadGenFormsQuery,
  useDeleteLeadGenFormMutation,
  Workspace_Lead_Forms_Bool_Exp,
  Workspace_Lead_Types_Enum,
  useInsertFacebookLeadFormMutation,
  useWorkspaceTeamMembersQuery,
  Workspace_Users_Bool_Exp,
  useUpdateLeadGenFormMutation,
  Workspace_Subscription_Model_Enum
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import Link from 'components/Link';
import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';
import LeadTabs from 'components/leads/LeadTabs';
import CreateLeadGenFormDialog from 'components/leads/forms/CreateLeadGenFormDialog';
import LeadGenListPreviewDialog from 'components/leads/forms/LeadGenListPreviewDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { createFilter, createFilterBySearch } from 'lib/hooks/useFiltersAndPagination';
import usePlatforms from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

const useSearchFilter = createFilterBySearch<Workspace_Lead_Forms_Bool_Exp>([{ name: {} }], {
  customReplacer: (str) => str.replace(/\s+/, '%')
});

const useFilterByType = createFilter<Workspace_Lead_Forms_Bool_Exp, Workspace_Lead_Types_Enum>({
  lead_type: {}
});

const useFilterByOwnerId = createFilter<Workspace_Lead_Forms_Bool_Exp, string>({
  owner_id: {}
});

const useFilterByPage = createFilter<Workspace_Lead_Forms_Bool_Exp, string>({
  facebook_page_id: {}
});

interface FacebookLeadFormResponse {
  follow_up_action_text: string;
  follow_up_action_url: string;
  formID: string;
  form_url: string; // 'https://fb.me/1GP6C5zUEHZIpv0';
  is_tcpa_compliant: boolean;
  name: string;
  pageID: number;
  privacy_policy_url: string;
  status: 'success' | 'error';
}

export default function LeadGenFormsPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const {
    userId,
    activeWorkspaceId,
    isWorkspaceAdmin,
    isWorkspaceCreator,
    isWorkspaceAnalyst,
    isWorkspaceAgent,
    workspace
  } = useUserContext();
  const agentPermissions = useAgentPermissions();
  const [, , meta] = usePlatforms();

  const confirm = useConfirm();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'menuPopover'
  });

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formId, setFormId] = useState<any>(undefined);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState(true);
  const [filterSearch, search, setSearch] = useSearchFilter();
  const [filterType, type, setType] = useFilterByType();
  const [filterOwnerId, ownerId, setOwnerId] = useFilterByOwnerId();
  const [filterPage, pageId, setPageId] = useFilterByPage();

  const where: Workspace_Lead_Forms_Bool_Exp = {
    workspace_id: { _eq: activeWorkspaceId },
    ...filterSearch,
    ...filterType,
    ...filterOwnerId,
    ...filterPage
  };

  const teamWhere: Workspace_Users_Bool_Exp = {
    _and: [{ workspace_id: { _eq: activeWorkspaceId! } }]
  };

  if (isWorkspaceAgent) {
    where._or = [
      {
        owner_id: { _eq: userId }
      },
      {
        created_by_id: { _eq: userId }
      }
    ];

    teamWhere._and!.push({
      _or: [
        {
          user: { display_name: { _in: agentPermissions.allowed_agents } }
        },
        {
          user_id: { _eq: userId! }
        }
      ]
    });
  }

  const { data: teamData } = useWorkspaceTeamMembersQuery({
    variables: {
      where: teamWhere,
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const { data, loading, refetch } = useWorkspaceLeadGenFormsQuery({
    variables: {
      where: where,
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext
  });
  const [insertFacebookForm] = useInsertFacebookLeadFormMutation({
    context: workspaceMemberContext
  });
  const [updateForm] = useUpdateLeadGenFormMutation({
    context: workspaceMemberContext
  });

  const handleOpenCreateDialog = () => {
    popupState.close();
    setOpen(true);
  };

  const handleCloseCreateDialog = () => {
    refetch();
    setOpen(false);
    setFormId(undefined);
  };

  const handleOpenBetaCreateDialog = async () => {
    popupState.close();

    let leadType: Workspace_Lead_Types_Enum = Workspace_Lead_Types_Enum.General;
    let selectedFacebookPage: string | undefined = meta?.facebook?.page_id;
    let facebookAdAccount = meta?.facebook?.ad_account_id;

    try {
      await confirm({
        title: 'Opening Facebook Popup',
        description: 'You will see a Facebook popup to create the leadgen form',
        content: (
          <>
            <DialogContentText>
              You will see a Facebook popup to create the leadgen form, but first select your
              Facebook page and lead type. <br />
              You need to have access to the page you select in Properti.
              <br />
              <br />
              Note this feature is still in beta, and may be a little clunky.
            </DialogContentText>
            <Select
              style={{ marginTop: 8 }}
              name="Facebook Page"
              label="Select Facebook Page"
              defaultValue={selectedFacebookPage}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                selectedFacebookPage = event.target.value as string;
              }}
            >
              {meta.facebook?.allowedPages?.map((page) => (
                <MenuItem key={page.id} value={page.id}>
                  {page.display_name}
                </MenuItem>
              ))}
            </Select>
            <Select
              style={{ marginTop: 8 }}
              name="Lead Type"
              label="Select Lead Type"
              defaultValue={leadType}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                leadType = event.target.value as Workspace_Lead_Types_Enum;
              }}
            >
              <MenuItem value={Workspace_Lead_Types_Enum.General}>General Lead</MenuItem>
              <MenuItem value={Workspace_Lead_Types_Enum.Appraisal}>Appraisal Lead</MenuItem>
              <MenuItem value={Workspace_Lead_Types_Enum.Listing}>Listing Lead</MenuItem>
            </Select>
          </>
        )
      });
    } catch (error) {
      return;
    }

    if (!selectedFacebookPage) {
      return enqueueSnackbar('Please choose a Facebook page', { variant: 'error' });
    }

    if (!facebookAdAccount) {
      return enqueueSnackbar('Cannot find correct Ad Account ID', { variant: 'error' });
    }

    try {
      // @ts-ignore
      FB.ui(
        {
          method: 'lead_gen',
          page_id: selectedFacebookPage,
          ad_account_id: facebookAdAccount.replace('act_', '')
        },
        function (response?: FacebookLeadFormResponse) {
          if (!response) {
            return enqueueSnackbar('No response from Facebook form', { variant: 'error' });
          }

          if (response.status !== 'success') {
            return enqueueSnackbar('Unable to create form', { variant: 'error' });
          }

          insertFacebookForm({
            variables: {
              object: {
                workspace_id: activeWorkspaceId,
                name: response.name,
                facebook_page_id: `${response.pageID}`,
                facebook_lead_gen_form_id: `${response.formID}`,
                call_to_action_text: 'APPLY_NOW' ?? response.follow_up_action_text,
                facebook_config: {},
                lead_type: leadType,
                facebook_form_url: response.form_url
              }
            }
          })
            .then(() => {
              refetch();
              enqueueSnackbar('Lead form created', { variant: 'success' });
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar('Unable to save form', { variant: 'error' });
            });
        }
      );
    } catch (error) {
      console.warn(error);
      enqueueSnackbar('Error dealing with Facebook Leadgen Form', { variant: 'error' });
    }
  };

  const [deleteLeadGenForm] = useDeleteLeadGenFormMutation({
    context: workspaceMemberContext
  });

  const handleOnRowDelete = async (row: any) => {
    const id = row.id;
    try {
      const resp = await deleteLeadGenForm({
        variables: {
          id,
          workspace_id: activeWorkspaceId!
        },
        context: workspaceMemberContext
      });

      if (resp.errors) {
        throw resp.errors[0];
      } else if (!resp.data?.deleteLeadGenForm?.id) {
        throw new Error('Unable to delete lead gen form');
      } else {
        enqueueSnackbar('Lead gen form deleted', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Unable to delete lead gen form', { variant: 'error' });
    } finally {
      refetch();
    }
  };

  const handleOnRowUpdate = async (row: any) => {
    try {
      await updateForm({
        variables: {
          form_id: row.id,
          set: {
            lead_type: row.lead_type ?? undefined,
            call_to_action_text: row.call_to_action_text ?? undefined,
            owner_id: row.owner_id
          }
        }
      });
      enqueueSnackbar('Form updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to update form', { variant: 'error' });
    } finally {
      refetch();
    }
  };

  const handleCloneLeadGenForm = (id: any) => {
    setFormId(id);
    setOpen(true);
  };

  // https://github.com/mbrn/material-table/issues/1979
  const forms = data?.lead_gen_forms?.map((f) => ({ ...f })) ?? [];
  const totalCount = data?.lead_gen_forms_aggregate?.aggregate?.count ?? 0;

  const handlePreviewLeadGenForm = (id: any) => {
    setFormId(id);
    setPreviewOpen(true);
  };

  const handleClosePreviewDialog = () => {
    refetch();
    setPreviewOpen(false);
    setFormId(undefined);
  };

  const teamMemberOptions =
    teamData?.workspace_users?.map((user) => ({
      value: user.user.id!,
      text: user.user.display_name!
    })) ?? [];

  const teamMemberLookup: { [key: string]: string } = {};
  teamMemberOptions.forEach((m) => (teamMemberLookup[m.value] = m.text));

  if (!workspace) {
    return <CircularProgress />;
  }

  if (workspace.subscription_model === Workspace_Subscription_Model_Enum.Basic) {
    return (
      <EmptyStatePage
        title="Upgrade account"
        text="Your subscription level does not include leadgen forms, upgrade your account to unlock"
        icon={<PublishIcon color="secondary" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            size="large"
            disabled={!isWorkspaceAdmin}
            component={NavLink}
            to="/billing"
          >
            Go to billing
          </Button>
        }
      />
    );
  }

  return (
    <>
      <div>
        <PageHeader
          title="Leadgen Forms"
          subtitle="Build leadgen campaigns"
          rightComponent={
            <Button
              {...bindTrigger(popupState)}
              style={{ minWidth: 160 }}
              color="secondary"
              variant="contained"
              size="large"
              endIcon={<ExpandMoreIcon />}
              disabled={isWorkspaceCreator || isWorkspaceAnalyst}
            >
              Create Form
            </Button>
          }
        />

        <LeadTabs currentTab={1} />
        <MaterialTable
          title="Leads"
          columns={[
            {
              title: 'Name',
              field: 'name',
              editable: 'never'
            },
            {
              title: 'Type',
              field: 'lead_type',
              editable: 'onUpdate',
              lookup: {
                [Workspace_Lead_Types_Enum.General]: 'General',
                [Workspace_Lead_Types_Enum.Appraisal]: 'Appraisal',
                [Workspace_Lead_Types_Enum.Listing]: 'Listing'
              }
            },
            {
              title: 'Lead Owner',
              field: 'owner_id',
              editable: 'onUpdate',
              lookup: teamMemberLookup,
              render: (data) => {
                if (data?.lead_owner?.display_name) {
                  return data?.lead_owner?.display_name;
                }
                return 'N/A';
              }
            },
            {
              title: 'Facebook page',
              field: 'facebook_page_id',
              editable: 'never',
              render: (data) => {
                const foundPage = meta.facebook?.allowedPages?.find(
                  (page) => page.id === data.facebook_page_id
                );

                if (foundPage && foundPage.display_name) {
                  return foundPage.display_name;
                }

                return data.facebook_page_id;
              }
            },
            {
              title: 'Created At',
              field: 'created_at',
              render: (data) => moment(data?.created_at).fromNow(),
              editable: 'never'
            },
            {
              title: 'Ad Call To Action',
              field: 'call_to_action_text',
              editable: 'onUpdate',
              lookup: {
                APPLY_NOW: 'APPLY_NOW',
                LEARN_MORE: 'LEARN_MORE',
                DOWNLOAD: 'DOWNLOAD',
                GET_QUOTE: 'GET_QUOTE',
                SIGN_UP: 'SIGN_UP',
                SUBSCRIBE: 'SUBSCRIBE'
              }
            },
            {
              title: 'Number of Leads',
              field: 'number_of_leads',
              editable: 'never',
              render: (data) => {
                if (data.number_of_leads) {
                  return <Link to={`${data.id}/leads`}>View {data.number_of_leads} leads</Link>;
                }

                return 'No leads yet';
              }
            }
          ]}
          actions={[
            (rowData) => ({
              icon: 'visibility',
              tooltip: 'Preview',
              onClick: () => {
                if (rowData.facebook_form_url) {
                  window.open(rowData.facebook_form_url, '_blank');
                } else {
                  handlePreviewLeadGenForm(rowData.id);
                }
              }
            }),
            (rowData) => ({
              icon: 'file_copy',
              tooltip: 'Clone Lead Gen Form',
              disabled: isWorkspaceCreator || isWorkspaceAnalyst,
              onClick: () => {
                handleCloneLeadGenForm(rowData.id);
              }
            })
          ]}
          data={forms}
          totalCount={totalCount}
          editable={{
            isDeletable: () => !isWorkspaceCreator && !isWorkspaceAnalyst,
            isEditable: () => !isWorkspaceCreator && !isWorkspaceAnalyst,
            onRowDelete: handleOnRowDelete,
            onRowUpdate: handleOnRowUpdate
          }}
          options={{
            search: true,
            debounceInterval: 500,
            toolbar: true,
            draggable: false,
            showTitle: false,
            filtering: false,
            actionsColumnIndex: -1,
            pageSize: limit,
            sorting: true
          }}
          isLoading={loading}
          onRowsPerPageChange={setLimit}
          onPageChange={setPage}
          page={page}
          components={{
            Container: TableContainer,
            Toolbar: (props) => (
              <Toolbar
                {...props}
                searchValue={search}
                setSearch={setSearch}
                filtersOpen={filtersOpen}
                onToggleFiltersOpen={() => {
                  setFiltersOpen(!filtersOpen);
                }}
                menuItems={[
                  {
                    name: 'Filter Type',
                    value: type,
                    setValue: setType,
                    options: [
                      { value: Workspace_Lead_Types_Enum.Appraisal, text: 'Appraisal' },
                      { value: Workspace_Lead_Types_Enum.General, text: 'General' },
                      { value: Workspace_Lead_Types_Enum.Listing, text: 'Listing' }
                    ],
                    hasAll: true
                  },
                  {
                    name: 'Filter Facebook Page',
                    value: pageId,
                    setValue: setPageId,
                    options:
                      meta.facebook?.allowedPages?.map((ap) => ({
                        value: ap.id,
                        text: ap.display_name
                      })) ?? [],
                    hasAll: true
                  },
                  {
                    name: 'Filter owner',
                    value: ownerId,
                    setValue: setOwnerId,
                    options: teamMemberOptions,
                    hasAll: true
                  }
                ]}
              />
            )
          }}
        />
      </div>
      <CreateLeadGenFormDialog open={open} onClose={handleCloseCreateDialog} formId={formId} />
      <LeadGenListPreviewDialog
        open={previewOpen}
        onClose={handleClosePreviewDialog}
        formId={formId}
      />
      <Menu
        {...bindPopover(popupState)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleOpenCreateDialog}>Open Form Builder</MenuItem>
        <MenuItem onClick={handleOpenBetaCreateDialog}>Open Beta Form Builder</MenuItem>
      </Menu>
    </>
  );
}
