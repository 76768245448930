export enum DatePresetOptions {
  LAST_7D = 'last_7d',
  LAST_28D = 'last_28',
  LAST_90D = 'last_90d',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  THIS_QUARTER = 'this_quarter',
  LAST_QUARTER = 'last_quarter',
  THIS_YEAR = 'this_year',
  LAST_YEAR = 'last_year'
}

export enum DateResolution {
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month'
}
