import { useContext } from 'react';

import { Bar } from 'react-chartjs-2';

import { Paper, Typography } from '@material-ui/core';

import numbro from 'numbro';

import { useBenchmarkCpmQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import { getStartDate, getEndDate } from '../utils';
import DatePresetSelect, { DashboardDatePresetContext } from './DatePresetSelect';
import EmptyChartMessage from './EmptyChartMessage';
import LoadingChartIndicator from './LoadingChartIndicator';

interface BenchmarkCPMBoxProps {
  workspaceId: string;
}

export default function BenchmarkCPMBox({ workspaceId }: BenchmarkCPMBoxProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { datePreset } = useContext(DashboardDatePresetContext);

  const startDate = getStartDate(datePreset).format('YYYY-MM-DD');
  const endDate = getEndDate(datePreset).format('YYYY-MM-DD');

  const { data, loading } = useBenchmarkCpmQuery({
    variables: {
      cpm_where: {
        _and: [
          { workspace_id: { _eq: workspaceId } },
          { date: { _gte: startDate } },
          { date: { _lte: endDate } }
        ]
      },
      benchmark_where: {
        _and: [{ date: { _gte: startDate } }, { date: { _lte: endDate } }]
      }
    },
    context: workspaceMemberContext
  });

  const benchmarkingCount = data?.benchmarking_workspaces_cpm_aggregate?.aggregate?.count ?? 0;

  return (
    <Paper style={{ height: '100%', minHeight: 200 }}>
      <div
        style={{
          padding: '16px 0px 8px 16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 30
        }}
      >
        <Typography variant="h6">Benchmark CPM</Typography>
        <DatePresetSelect />
      </div>
      {benchmarkingCount < 14 ? (
        loading ? (
          <LoadingChartIndicator />
        ) : (
          <EmptyChartMessage message="Benchmarking not available for selected period" />
        )
      ) : (
        <div
          style={{
            position: 'relative',
            maxHeight: 360,
            maxWidth: 600,
            paddingTop: 16,
            paddingBottom: 16,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Bar
            data={{
              labels: ['Top 20%', 'You', 'Bottom 20%'],
              datasets: [
                {
                  type: 'bar',
                  label: 'Spend',
                  backgroundColor: ['#D8DAE5', '#F47D6F', '#D8DAE5'],
                  data: [
                    data?.benchmarking_workspaces_cpm_aggregate?.aggregate?.avg?.top_20_percent,
                    data?.workspace_analytics_workspaces_aggregate?.aggregate?.avg?.cpm,
                    data?.benchmarking_workspaces_cpm_aggregate?.aggregate?.avg?.bottom_20_percent
                  ],
                  yAxisID: 'bar'
                }
              ]
            }}
            options={{
              // Horizontal Bar Chart
              indexAxis: 'y',

              // Hide Legend
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Cost per thousand Impressions',
                    color: '#696C80'
                  },
                  ticks: {
                    display: true,
                    mirror: false,
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    callback: (value) =>
                      numbro(value).formatCurrency({
                        thousandSeparated: true,
                        spaceSeparated: false,
                        mantissa: 0
                      })
                  }
                }
              },

              backgroundColor: 'rbga(0, 0, 0, 0)',
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 2
            }}
          />
        </div>
      )}
    </Paper>
  );
}
