import Uppy, { BasePlugin, DefaultPluginOptions } from '@uppy/core';

import heic2any from 'heic2any';

// PLUGIN DETAILS
const PLUGIN_NAME = 'HEIC Image Convertor';

// IGNORE FILE SOURCES
const IGNORE_SOURCES = ['Url', 'Canva'];

const FILE_EXT_REGEX = /\.[^/.]+$/;

interface UppyHEICImageConversionOpts extends DefaultPluginOptions {}

export class UppyHEICImageConversionPlugin extends BasePlugin {
  id: string;
  type: string;
  opts: UppyHEICImageConversionOpts;

  constructor(uppy: Uppy, opts: UppyHEICImageConversionOpts = {}) {
    super(uppy, opts);
    this.id = opts.id || 'HEICImageConversion';
    this.type = 'modifier';

    this.opts = opts;

    // we use those internally in `this.convert`, so they should not be overridden
    delete this.opts.success;
    delete this.opts.error;

    this.prepareUpload = this.prepareUpload.bind(this);
    this.convert = this.convert.bind(this);
  }

  setOptions(newOpts: UppyHEICImageConversionOpts) {
    super.setOptions(newOpts);
  }

  async convert(blob: Blob): Promise<Blob> {
    const newBlob = await heic2any({
      blob,
      toType: 'image/jpg'
    });
    return newBlob as Blob;
  }

  async void(): Promise<void> {
    return;
  }

  async prepareUpload(fileIds: string[]) {
    const promises = fileIds.map((fileId) => {
      const file = this.uppy.getFile(fileId);
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: 'Converting Image'
      });

      if (!file || !file.type || !file.size || !file.source) {
        return this.void();
      }

      // Don't edit images imported from Url
      if (IGNORE_SOURCES.includes(file.source)) {
        return this.void();
      }

      if (!file.type.toLowerCase().endsWith('heic')) {
        return this.void();
      }

      return this.convert(file.data)
        .then((convertedBlob) => {
          this.uppy.log(`[${PLUGIN_NAME}]: Heic Image ${file.id} converted to jpeg`);

          // Update filename/path/extension to be jpeg
          this.uppy.setFileState(fileId, {
            data: convertedBlob,
            extension: 'jpeg',
            type: 'image/jpeg',
            name: file.name.replace(FILE_EXT_REGEX, '.jpeg'),
            meta: {
              ...file.meta,
              filename: (file.meta.filename as string).replace(FILE_EXT_REGEX, '.jpeg'),
              name: file.meta.name.replace(FILE_EXT_REGEX, '.jpeg'),
              path: (file.meta.path as string).replace(FILE_EXT_REGEX, '.jpeg'),
              type: 'image/jpeg'
            }
          });
        })
        .catch((err) => {
          console.error(err);
          this.uppy.log(`[${PLUGIN_NAME}]: Failed to convert ${file.id}:`, 'warning');
          this.uppy.log(err, 'warning');
        });
    });

    const emitPreprocessCompleteForAll = () => {
      fileIds.forEach((fileId) => {
        const file = this.uppy.getFile(fileId);
        this.uppy.emit('preprocess-complete', file);
      });
    };

    // Why emit `preprocess-complete` for all files at once, instead of
    // above when each is processed?
    // Because it leads to StatusBar showing a weird “upload 6 files” button,
    // while waiting for all the files to complete pre-processing.
    await Promise.all(promises);
    return emitPreprocessCompleteForAll();
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}
