import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { useRocketiumWorkspaceLazyQuery, useSetVideoPendingMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useScript, { ScriptStatus } from 'lib/hooks/useScript';
import useUserContext from 'lib/hooks/useUserContext';

const CLIENT_ID = process.env.REACT_APP_ROCKETIUM_CLIENT_ID;
const SCRIPT_URL = 'https://rocketium.com/js/rfsdk.min.js';

type RocketiumContextType = {
  ready: boolean;
  enabled: boolean;
};

const defaultRocketiumContext: RocketiumContextType = {
  ready: false,
  enabled: false
};

const RocketiumContext = createContext(defaultRocketiumContext);

const RocketiumContextProvider = ({ children }: { children: ReactNode }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId, workspace, isAdminMode } = useUserContext();

  const [fetchWorkspace, { data, called }] = useRocketiumWorkspaceLazyQuery({
    context: workspaceMemberContext
  });
  const [updateVideo] = useSetVideoPendingMutation({ context: workspaceMemberContext });
  const status = useScript(SCRIPT_URL);
  const [ready, setReady] = useState<boolean>(false);

  const rocketiumTeamId = useMemo(
    () => data?.getRocketiumWorkspace?.team_id,
    [data?.getRocketiumWorkspace?.team_id]
  );
  const videoEditorEnabled = useMemo(() => {
    if (isAdminMode) {
      return true;
    }
    return workspace?.video_editor_enabled ?? false;
  }, [isAdminMode, workspace]);

  // On Init
  useEffect(() => {
    if (!called && activeWorkspaceId && videoEditorEnabled) {
      fetchWorkspace({
        variables: {
          workspace_id: activeWorkspaceId!
        }
      });
    }
  }, [fetchWorkspace, activeWorkspaceId, called, videoEditorEnabled]);

  // On Update Active Workspace
  useEffect(() => {
    if (videoEditorEnabled) {
      fetchWorkspace({
        variables: {
          workspace_id: activeWorkspaceId!
        }
      });
    }
  }, [fetchWorkspace, activeWorkspaceId, videoEditorEnabled]);

  useEffect(() => {
    if (status !== ScriptStatus.READY || !rocketiumTeamId || !videoEditorEnabled) {
      return;
    }

    if (ready) {
      // Update config
      (window as any).rfsdk('updateConfig', {
        workspaceId: rocketiumTeamId
      });
    } else {
      (window as any).rfsdk('init', {
        clientId: CLIENT_ID,
        workspaceId: rocketiumTeamId,
        'button-mode': 'advanced',
        appendTo: 'rfsdk-wrapper',
        'editor-button-text': 'Create Video',
        'show-Workspacelevel-Media': true,
        logo: true,
        music: true,
        layout: true,
        preview: 'full',
        'stock-library': true,
        'button-hidden': true,
        'voice-over': true,
        'entry-point': 'loader'
      });

      setTimeout(() => {
        setReady(true);
      }, 7500);

      (window as any).rfsdk('on', 'rfsdk-video-exporting', (data: any) => {
        const { videoData } = data;
        const videoId = videoData._id;
        updateVideo({ variables: { rocketium_id: videoId } });
        (window as any).rfsdk('export');
      });

      (window as any).rfsdk('on', 'rfsdk-editor-exit-clicked', () => {
        (window as any).rfsdk('save');
      });
    }
  }, [status, rocketiumTeamId, ready, setReady, updateVideo, videoEditorEnabled]);

  return (
    <RocketiumContext.Provider value={{ ready, enabled: videoEditorEnabled }}>
      {children}
      <div id="rfsdk-warpper" />
    </RocketiumContext.Provider>
  );
};

export { RocketiumContext, RocketiumContextProvider };
