import React from 'react';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// Material components
import MaterialLink from '@material-ui/core/Link';

const ForwardedLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink {...props} />
));

const Link = (props: any) => {
  return <MaterialLink component={ForwardedLink} {...props} />;
};

export default Link;
