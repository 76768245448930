import { useContext } from 'react';

import { Bar } from 'react-chartjs-2';

import { Paper, Typography } from '@material-ui/core';

import numbro from 'numbro';

import { useWorkspaceSpendCategoryBreakdownQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import { getStartDate, getEndDate } from '../utils';
import DatePresetSelect, { DashboardDatePresetContext } from './DatePresetSelect';
import EmptyChartMessage from './EmptyChartMessage';
import LoadingChartIndicator from './LoadingChartIndicator';

interface CategorySpendBoxProps {
  workspaceId: string;
}

export default function CategorySpendBox({ workspaceId }: CategorySpendBoxProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { datePreset } = useContext(DashboardDatePresetContext);
  const startDate = getStartDate(datePreset).format('YYYY-MM-DD');
  const endDate = getEndDate(datePreset).format('YYYY-MM-DD');

  const { data, loading } = useWorkspaceSpendCategoryBreakdownQuery({
    variables: {
      workspace_id: workspaceId!,
      start_date: startDate,
      end_date: endDate
    },
    context: workspaceMemberContext
  });

  const total = data?.total?.aggregate?.sum?.amount ?? 0;

  return (
    <Paper style={{ height: '100%', minHeight: 200 }}>
      <div
        style={{
          padding: '16px 0px 8px 16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 30
        }}
      >
        <Typography variant="h6">Your Categories</Typography>
        <DatePresetSelect />
        <Typography variant="caption">
          {numbro(total).formatCurrency({
            thousandSeparated: true,
            mantissa: 0
          })}
        </Typography>
      </div>
      {total === 0 ? (
        loading ? (
          <LoadingChartIndicator />
        ) : (
          <EmptyChartMessage />
        )
      ) : (
        <div
          style={{
            position: 'relative',
            maxHeight: 500,
            maxWidth: 800,
            paddingTop: 16,
            paddingBottom: 16,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Bar
            data={{
              labels: ['Agency', 'Agent', 'VPA', 'Other', 'Uncategorised'],
              datasets: [
                {
                  type: 'bar',
                  label: 'Spend',
                  backgroundColor: ['#D8DAE5', '#D8DAE5', '#F47D6F', '#D8DAE5', '#D8DAE5'],
                  data: [
                    data?.agency.aggregate?.sum?.amount,
                    data?.agent.aggregate?.sum?.amount,
                    data?.vpa.aggregate?.sum?.amount,
                    data?.other.aggregate?.sum?.amount,
                    data?.uncategorised.aggregate?.sum?.amount
                  ],
                  yAxisID: 'bar'
                }
              ]
            }}
            options={{
              // Horizontal Bar Chart
              indexAxis: 'y',

              // Hide Legend
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Budget',
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    }
                  },
                  ticks: {
                    display: true,
                    mirror: false,
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    callback: (value) =>
                      numbro(value).formatCurrency({
                        thousandSeparated: true,
                        spaceSeparated: false,
                        mantissa: 0
                      })
                  }
                }
              },

              backgroundColor: 'rbga(0, 0, 0, 0)',
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 2
            }}
          />
        </div>
      )}
    </Paper>
  );
}
