import React from 'react';

import { Form } from 'react-final-form';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';

import DialogTitle from 'components/DialogTitle';

interface AttachmentDialogProps {
  open: boolean;
  onClose: () => void;
  onRemoveAttachment: () => void;
  onSaveAttachment: (url?: string | null) => void;
  attachmentUrl?: string | null;
}

const schema = Yup.object({
  url: Yup.string().url()
}).required();

const validate = makeValidate(schema);

type AttachmentDialogFormSchema = Yup.InferType<typeof schema>;

const AttachmentDialog: React.FC<AttachmentDialogProps> = ({
  open,
  onClose,
  onRemoveAttachment,
  onSaveAttachment,
  attachmentUrl
}) => {
  const handleSubmit = (values: AttachmentDialogFormSchema) => {
    onSaveAttachment(values?.url);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="attachment-dialog-title">
      <DialogTitle onClose={onClose}>Attach Webpage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To attach an article or website to this post, enter the url below.
        </DialogContentText>
        <Form
          initialValues={{
            url: attachmentUrl
          }}
          validate={validate}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  name="url"
                  type="url"
                  placeholder="https://wwww.yourwebsite.com/article"
                />
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={onRemoveAttachment} color="primary">
                    Remove
                  </Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentDialog;
