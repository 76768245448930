import { Navigate, useLocation } from 'react-router';

import queryString from 'query-string';

export default function Callback() {
  const { search } = useLocation();
  const { status, id } = queryString.parse(search);

  return <Navigate to="/store/history" replace state={{ status, id }} />;
}
