export const RESPONSIVE_IMAGE_REQUIREMENTS = [
  {
    name: 'square',
    minWidth: 300,
    minHeight: 300
  },
  {
    name: 'landscape',
    minWidth: 600,
    minHeight: 314
  }
] as const;

export type ResponsiveImageSpec = typeof RESPONSIVE_IMAGE_REQUIREMENTS[number];

export function meetsResponsiveImageRequirements(
  image: HTMLImageElement,
  types: ('square' | 'landscape')[] = ['square', 'landscape']
) {
  const { width, height } = image;
  return RESPONSIVE_IMAGE_REQUIREMENTS.filter((type) => types.includes(type.name)).every(
    (spec) => width >= spec.minWidth && height >= spec.minHeight
  );
}
