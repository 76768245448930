import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useTemplatePreviewStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2)
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },

  content: {
    padding: theme.spacing(2)
  },

  media: {
    borderRadius: '7.5px',
    width: '100%'
  }
}));

export default useTemplatePreviewStyles;
