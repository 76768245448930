import React, { FormEvent } from 'react';

import { Form } from 'react-final-form';

import { CircularProgress } from '@material-ui/core';

import arrayMutators from 'final-form-arrays';

import { UpdateCarouselCreativeInput, useUpdateCarouselCreativeMutation } from 'generated/graphql';

import { Attachment } from 'components/types';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import CarouselAdDetails from './CarouselAdDetails';
import { validateDetails } from './schema';

export interface EditCarouselCreativeProps {
  initialValues: UpdateCarouselCreativeInput & { items: Array<any> };
  onSuccess: (values: UpdateCarouselCreativeInput) => void;
  onError: (error: unknown) => void;
  render: React.FC<{ submitting: boolean; submit: () => void }>;
}

export default function EditResponsiveCreative({
  initialValues,
  onSuccess,
  onError,
  render: Wrapper
}: EditCarouselCreativeProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const [updateCarouselCreative] = useUpdateCarouselCreativeMutation();
  async function handleSubmit(values: UpdateCarouselCreativeInput & { items?: Array<Attachment> }) {
    try {
      await updateCarouselCreative({
        variables: {
          creative: {
            // Not using spread because 'values' contains 'items' key which
            // doesn't need to get passed through the query. I didn't want to use
            // 'delete values.items' as I don't know whether it would mutate the
            // underlying React state in Final Form, which may cause bugs.
            caption: values.caption,
            carousel_id: values.carousel_id,
            end_card: values.end_card,
            link: values.link,
            id: values.id,
            message: values.message,
            order_optimised: values.order_optimised,
            tags: values.tags,
            workspace_id: values.workspace_id,
            creative_name: values.creative_name ?? values.message
          }
        },
        context: workspaceMemberContext
      });
      onSuccess(values);
    } catch (error) {
      onError(error);
    }
  }
  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
      validate={validateDetails}
      subscription={{ values: true, submitting: true }}
    >
      {({ submitting, values, handleSubmit }) => {
        if (submitting) {
          return <CircularProgress />;
        }

        async function submit(event?: FormEvent) {
          event?.preventDefault();
          await handleSubmit();
        }

        return (
          <Wrapper submit={submit} submitting={submitting}>
            <form onSubmit={submit}>
              <CarouselAdDetails />
            </form>
          </Wrapper>
        );
      }}
    </Form>
  );
}
