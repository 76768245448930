import React, { createContext, useContext, useState } from 'react';

import { createLogger, Logger } from 'logdna';

const LOGDNA_API_KEY = process.env.REACT_APP_LOGDNA_API_KEY as string;

type LoggerContextType = {
  logger: Logger;
};

interface LoggerContextProviderProps {
  children: React.ReactNode;
}

const logger = createLogger(LOGDNA_API_KEY, {
  app: 'dashboard',
  env: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
  index_meta: true
});

const defaultLoggerContext = {
  logger: logger
};

const LoggerContext = createContext(defaultLoggerContext);

const LoggerContextProvider: React.FC<LoggerContextProviderProps> = ({ children }) => {
  const [state] = useState<LoggerContextType>(defaultLoggerContext);

  return <LoggerContext.Provider value={{ ...state }}>{children}</LoggerContext.Provider>;
};

const useLogger = () => {
  const { logger } = useContext(LoggerContext);
  return logger;
};

export { useLogger, LoggerContextProvider, LoggerContext, logger };
