import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import ScrollBar from 'react-perfect-scrollbar';

type PerfectScrollbarContextType = {
  updateScroll: () => void;
};

const PerfectScrollbarContext = createContext<PerfectScrollbarContextType>(
  {} as PerfectScrollbarContextType
);

export function PerfectScrollProvider({ children }: { children: React.ReactNode }) {
  const scrollbarRef = useRef<ScrollBar>();

  const updateScroll = useCallback(() => {
    if (!scrollbarRef.current) {
      return;
    }

    scrollbarRef.current.updateScroll();
  }, []);

  const values = useMemo(() => ({ updateScroll }), [updateScroll]);

  return (
    <PerfectScrollbarContext.Provider value={values}>
      <PerfectScrollbar
        ref={(ref) => {
          scrollbarRef.current = ref ?? undefined;
        }}
      >
        {children}
      </PerfectScrollbar>
    </PerfectScrollbarContext.Provider>
  );
}

export const usePerfectScrollbar = () => useContext(PerfectScrollbarContext);
