import { makeStyles, Theme } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  header: {
    display: 'flex',
    transition: '0.3s',
    padding: theme.spacing(0, 0.5, 0, 0.5)
  },
  avatar: {
    transition: '0.3s',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  userinfo: {
    display: 'flex',
    placeContent: 'center',
    maxWidth: 240,
    textOverflow: 'ellipsis'
  },
  linkDivider: {
    margin: theme.spacing(1, 0)
  },
  content: {
    flexGrow: 1,
    flexBasis: 'auto'
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center'
  },
  logoImage: {
    height: 48,
    width: 48,
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  footer: {
    flex: 'none',
    margin: theme.spacing(2.5, 1)
  },
  underline: {
    borderBottom: 'none',
    '&:before': {
      borderBottom: 'none'
    }
  }
}));

export default useSidebarStyles;
