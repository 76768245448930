import React, { useState } from 'react';

import { Form } from 'react-final-form';

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Clear';

import { FORM_ERROR } from 'final-form';
import { useConfirm } from 'material-ui-confirm';
import { Autocomplete, makeValidate, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  UpdateResponsiveCreativeInput,
  UpdateCarouselCreativeInput,
  useCreativeByPkQuery,
  useDeleteCreativeMutation,
  useEditCreativeMutation,
  Workspace_Creative_Tags_Insert_Input,
  Workspace_Creative_Types_Enum
} from 'generated/graphql';

import FinalFormShareInput from 'components/forms/FinalFormShareInput';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useUserContext from 'lib/hooks/useUserContext';
import { track } from 'lib/utils/track';

import EditCarouselCreative from 'pages/workspace/Creatives/templates/carousel/EditCarousel';
import EditResponsiveCreative from 'pages/workspace/Creatives/templates/responsive/EditResponsive';

import CreativeVideoThumbnailEditor from '../CreativeVideoThumbnailEditor';

interface EditCreativeDrawerProps {
  open: boolean;
  creativeId: string;
  onClose: () => void;
  hideDelete?: boolean;
  isVideoCreative?: boolean; // Determines if we allow users to upload thumbnails
}

const schema = Yup.object()
  .shape({
    title: Yup.string().trim().required().label('Creative name'),
    url:
      process.env.NODE_ENV === 'production'
        ? Yup.string().url().nullable().label('Landing Page URL')
        : Yup.string().nullable().label('Landing Page URL'),
    ad_title: Yup.string().trim().nullable().label('Ad Title'),
    post: Yup.object()
      .shape({
        message: Yup.string().max(13000).trim().required().label('Post Message')
      })
      .required(),
    tags: Yup.array().of(Yup.string().trim())
  })
  .required();

const validate = makeValidate(schema);

type FormSchema = Yup.InferType<typeof schema>;

const EditCreativeDrawer: React.FC<EditCreativeDrawerProps> = ({
  open,
  creativeId,
  onClose,
  hideDelete = false,
  isVideoCreative = false
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { activeWorkspaceId } = useUserContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { data, loading, refetch } = useCreativeByPkQuery({
    variables: {
      id: creativeId
    },
    context: workspaceMemberContext,
    skip: !open
  });

  const [editCreative] = useEditCreativeMutation({ context: workspaceMemberContext });
  const [deleteCreative] = useDeleteCreativeMutation({ context: workspaceMemberContext });

  const creative = data?.creative;

  const handleSubmit = async (values: FormSchema) => {
    track('Edit Creative Metadata', values);
    setSubmitting(true);
    try {
      const newTags = values.tags ?? creative?.tags?.map((tag) => tag.tag);
      const tagsInput: Workspace_Creative_Tags_Insert_Input[] =
        newTags?.map((tag) => ({
          creative_id: creativeId,
          tag: tag
        })) ?? [];

      await editCreative({
        variables: {
          id: creativeId,
          title: values.title,
          tags: tagsInput,
          ad_title: values?.ad_title,
          message: values?.post?.message,
          url: values?.url
        }
      });
      setSubmitting(false);
      onClose();
    } catch (error: any) {
      setSubmitting(false);
      return { [FORM_ERROR]: error?.message };
    }
  };

  let submit: any;
  const handleSubmitBtn = () => {
    if (submit) {
      submit();
    }
  };

  const handleDeleteCreative = async () => {
    track('Creative Deleted', { creativeId });
    setSubmitting(true);
    try {
      await deleteCreative({
        variables: {
          id: creativeId
        }
      });
      setSubmitting(false);
      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleAskDeleteConfirmation = () => {
    confirm({
      title: `Delete ${creative?.title}?`,
      description: 'Are you sure you want to delete this creative?'
    }).then(handleDeleteCreative);
  };

  if (loading) {
    return null;
  }

  async function handleResponsiveUpdate() {
    await refetch();
    snackbar.enqueueSnackbar('Creative successfully updated', { variant: 'success' });
    onClose();
  }

  function handleResponsiveError() {
    snackbar.enqueueSnackbar('Unable to update creative', { variant: 'error' });
  }

  const ready = creative?.ready;

  const listingIds = creative?.listings?.map((l) => l.listing_id) ?? [];

  if (creative?.type === Workspace_Creative_Types_Enum.Responsive) {
    const initialValues: UpdateResponsiveCreativeInput = {
      id: creative.id,
      creative_name: creative.title,
      tags: creative.tags.map(({ tag }) => tag) ?? [],
      url: creative.default_url ?? '',
      videos: creative.responsive?.videos.map(({ value }) => value) ?? [],
      titles: creative.responsive?.titles.map(({ value }) => value) ?? [],
      long_title: creative.responsive?.long_title ?? '',
      cta: creative.responsive?.cta ?? '',
      descriptions: creative.responsive?.descriptions.map(({ value }) => value) ?? [],
      responsive_id: creative.responsive?.id!,
      workspace_id: creative.workspace_id
    };

    return (
      <EditResponsiveCreative
        initialValues={initialValues}
        onSuccess={handleResponsiveUpdate}
        onError={handleResponsiveError}
        render={({ submit, submitting, children }) => (
          <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            elevation={2}
            BackdropProps={{
              style: {
                backgroundColor: '#333333',
                opacity: 0.9
              }
            }}
          >
            <Container>
              <Top>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Updating {creative?.title}
                  </Typography>
                  <Typography variant="caption">
                    This information is used in campaigns and to pre-populate posts
                  </Typography>
                </div>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Top>
              <Middle style={{ textAlign: 'left' }}>{children}</Middle>
              <Bottom>
                <ButtonGroup>
                  {!hideDelete && (
                    <Button
                      variant="text"
                      size="large"
                      onClick={handleAskDeleteConfirmation}
                      disabled={submitting}
                    >
                      Delete Creative
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={submitting}
                    onClick={submit}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Bottom>
            </Container>
          </Drawer>
        )}
      />
    );
  }

  if (creative?.type === Workspace_Creative_Types_Enum.Carousel) {
    const initialValues: UpdateCarouselCreativeInput & { items: Array<any> } = {
      id: creative.id,
      creative_name: creative.title,
      tags: creative.tags.map(({ tag }) => tag) ?? [],
      link: creative.carousel?.link ?? '',
      items: creative.carousel?.items!,
      workspace_id: creative.workspace_id,
      carousel_id: creative.carousel?.id!,
      caption: creative.carousel?.caption ?? '',
      end_card: creative.carousel?.multi_share_end_card ?? false,
      message: creative.carousel?.message ?? '',
      order_optimised: creative.carousel?.multi_share_optimized ?? true
    };

    return (
      <EditCarouselCreative
        initialValues={initialValues}
        onSuccess={handleResponsiveUpdate}
        onError={handleResponsiveError}
        render={({ submit, submitting, children }) => (
          <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            elevation={2}
            BackdropProps={{
              style: {
                backgroundColor: '#333333',
                opacity: 0.9
              }
            }}
          >
            <Container>
              <Top>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Updating {creative?.title}
                  </Typography>
                  <Typography variant="caption">
                    This information is used in campaigns and to pre-populate posts
                  </Typography>
                </div>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Top>
              <Middle style={{ textAlign: 'left' }}>{children}</Middle>
              <Bottom>
                <ButtonGroup>
                  {!hideDelete && (
                    <Button
                      variant="text"
                      size="large"
                      onClick={handleAskDeleteConfirmation}
                      disabled={submitting}
                    >
                      Delete Creative
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={submitting}
                    onClick={submit}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Bottom>
            </Container>
          </Drawer>
        )}
      />
    );
  }

  if (loading || !creative) {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        elevation={2}
        BackdropProps={{
          style: {
            backgroundColor: '#333333',
            opacity: 0.9
          }
        }}
      >
        <Container>
          <Top />
          <Middle>
            <CircularProgress />
          </Middle>
          <Bottom />
        </Container>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={2}
      BackdropProps={{
        style: {
          backgroundColor: '#333333',
          opacity: 0.9
        }
      }}
    >
      <Container>
        <Top>
          <div>
            <Typography variant="h5" gutterBottom>
              Updating {creative?.title}
            </Typography>
            <Typography variant="caption">
              This information is used in campaigns and to pre-populate posts
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Top>
        <Middle>
          {open && (
            <>
              <Form
                validate={validate}
                initialValues={{
                  title: creative?.title,
                  tags: creative?.tags?.map((tag) => tag.tag) ?? [],
                  post: {
                    message: creative?.default_message
                  },
                  ad_title: creative?.ad_title,
                  url: creative?.default_url
                }}
                onSubmit={handleSubmit}
                render={({ handleSubmit, submitting, submitError }) => {
                  submit = handleSubmit;
                  if (submitting) {
                    return <CircularProgress />;
                  }

                  return (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <TextField name="title" label="Creative Name" />
                        <Autocomplete
                          name="tags"
                          label="Creative Tags"
                          placeholder="Start typing and press enter to select tags"
                          multiple
                          freeSolo
                          options={[]}
                          helperText="Start typing and press enter to select tags"
                        />
                        <TextField
                          name="ad_title"
                          label="Ad Title"
                          helperText="Title within ads, defaults to creative title if not set"
                        />
                        <FinalFormShareInput
                          workspaceId={activeWorkspaceId!}
                          baseFileUploadPath={`workspace/${activeWorkspaceId}/posts/uploads`}
                          name="post"
                          platform="generic"
                          label="Ad Copy"
                          placeholderText="Start typing your ad copy here ..."
                          hideAttachments
                          hideUrl
                          maxMessageLength={1300}
                          rows={8}
                          listingId={listingIds.length === 1 ? listingIds[0] : undefined}
                        />
                        <TextField name="url" label="Ad Landing Page URL" />
                      </div>
                      {submitError && (
                        <Typography variant="caption" color="error">
                          {submitError}
                        </Typography>
                      )}
                    </form>
                  );
                }}
              />
              {isVideoCreative && (
                <CreativeVideoThumbnailEditor
                  creative={creative}
                  onRefreshRequired={() => {
                    refetch();
                  }}
                />
              )}
            </>
          )}
        </Middle>
        <Bottom>
          <ButtonGroup>
            {!hideDelete && (
              <Button
                variant="text"
                size="large"
                onClick={handleAskDeleteConfirmation}
                disabled={submitting}
              >
                Delete Creative
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleSubmitBtn}
              disabled={submitting}
            >
              Save
            </Button>
          </ButtonGroup>
          {ready && (
            <Button component="a" href={`/creative/view/${creativeId}`} target="_blank">
              Open Permalink
            </Button>
          )}
        </Bottom>
      </Container>
    </Drawer>
  );
};

export default EditCreativeDrawer;

const Container = styled.div`
  min-width: 560px;
  width: 640px;
  max-width: 640px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const Middle = styled.div`
  padding: 40px 16px 8px 19px;
  text-align: center;
  flex-grow: 1;
  background-color: #f5f6f8;
  overflow-y: auto;
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
