import Desktop160x600ImageAd from './Desktop160x600ImageAd';
import Desktop350x250ImageAd from './Desktop350x250ImageAd';
import Desktop350x250TextAd from './Desktop350x250TextAd';
import Desktop480x120NativeAd from './Desktop480x120NativeAd';
import Mobile320x250ImageAd from './Mobile320x250ImageAd';
import Mobile320x250NativeAd from './Mobile320x250NativeAd';
import Mobile320x250TextAd from './Mobile320x250TextAd';
import Mobile320x568ImageAdEnhanced from './Mobile320x568ImageAdEnhanced';

export const previewTypes = [
  {
    label: 'Desktop Image Ad (160x600)',
    component: Desktop160x600ImageAd
  },
  {
    label: 'Desktop Image Ad (350x250)',
    component: Desktop350x250ImageAd
  },
  {
    label: 'Desktop Text Ad (350x250)',
    component: Desktop350x250TextAd
  },
  {
    label: 'Desktop Native Ad (480x120)',
    component: Desktop480x120NativeAd
  },
  {
    label: 'Mobile 320x568 Image Ad Enhanced',
    component: Mobile320x568ImageAdEnhanced
  },
  {
    label: 'Mobile 320x250 Image Ad',
    component: Mobile320x250ImageAd
  },
  {
    label: 'Mobile 320x250 Text Ad',
    component: Mobile320x250TextAd
  },
  {
    label: 'Mobile 320x250 Native Ad',
    component: Mobile320x250NativeAd
  }
];
