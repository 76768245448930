import React, { KeyboardEvent } from 'react';

import { Box, Typography } from '@material-ui/core';

import styled from 'styled-components';

export interface CategoryCheckboxProps {
  value: string;
  title: string | JSX.Element;
  description: React.ReactNode;
  checked?: boolean;
  onClick?: () => void;
  readonly?: boolean;
  styleOverride?: React.CSSProperties;
}

const Checkbox = styled.div<{ checked?: boolean; readonly?: boolean }>`
  height: 120px;
  width: 312px;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  border: ${({ checked, readonly }) =>
    checked && !readonly ? `1px solid #F47D6F ` : `1px solid rgba(0, 0, 0, 0.12)`};
  opacity: ${({ readonly }) => (readonly ? 0.5 : 1)};
  cursor: pointer;
`;

const CategoryCheckbox: React.FC<CategoryCheckboxProps> = ({
  title,
  description,
  checked = false,
  readonly = false,
  onClick,
  styleOverride = {}
}) => {
  const handleClick = () => {
    if (onClick && !readonly) {
      onClick();
    }
  };
  const triggerClick = (e: KeyboardEvent) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <Checkbox
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={triggerClick}
      checked={checked}
      readonly={readonly}
      style={styleOverride}
    >
      <Typography variant="h5" gutterBottom color={checked ? 'secondary' : 'inherit'}>
        {title}
      </Typography>
      {typeof description === 'string' ? (
        <Typography variant="body2" style={{ color: '#707683' }}>
          {description}
        </Typography>
      ) : (
        <Box color="#707683">{description}</Box>
      )}
    </Checkbox>
  );
};

export default CategoryCheckbox;
