import React, { useState } from 'react';

import { useFormState } from 'react-final-form';

import { AppBar, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

import FacebookPreview, { FacebookAdPreviewProps } from 'components/AdPreview/FacebookPreview';
import GoogleAdsPreview, { GoogleAdPreviewProps } from 'components/AdPreview/GoogleAdsPreview';

import { platformMap, useSelectedPlatforms } from 'lib/campaigns/hooks';

export interface AdPreviewProps {
  forceRefreshCount?: number;
}

export type SelectPreviewProps = {
  forceRefreshCount?: number;
  preview: GoogleAdPreviewProps | FacebookAdPreviewProps;
};

export function SelectPreview({ preview, forceRefreshCount }: SelectPreviewProps) {
  switch (preview.platform) {
    case 'facebook':
      return <FacebookPreview {...preview} forceRefreshCount={forceRefreshCount} />;
    case 'googleads':
      return <GoogleAdsPreview {...preview} forceRefreshCount={forceRefreshCount} />;
  }
}

export const AdPreview: React.FC<AdPreviewProps> = ({ forceRefreshCount }) => {
  const [platforms] = useSelectedPlatforms();
  const [selected, setSelected] = useState(platforms[0]);
  const state = useFormState({ subscription: { values: true } });
  const {
    objective,
    posts,
    creative_source: creativeSource,
    creative_ids: creativeIds,
    realshortz_video_ids: videoIds,
    facebook_page_id: facebookPageId,
    leadgen_form_id: leadFormId
  } = state.values;

  function handleChange(e: unknown, value: typeof platforms[number]) {
    setSelected(value);
  }

  const preview = {
    facebook: {
      platform: 'facebook' as const,
      objective,
      posts,
      creativeSource,
      creativeIds,
      videoIds,
      facebookPageId,
      leadFormId
    },
    googleads: {
      platform: 'googleads' as const,
      creativeIds
    }
  };

  return (
    <TabContext value={selected}>
      <AppBar position="static">
        <Tabs value={selected} onChange={handleChange}>
          {platforms.map((platform) => (
            <Tab key={platform} label={platformMap[platform]} value={platform} />
          ))}
        </Tabs>
      </AppBar>
      {platforms.map((platform) => (
        <TabPanel key={platform} value={platform}>
          <SelectPreview preview={preview[platform]} forceRefreshCount={forceRefreshCount} />
        </TabPanel>
      ))}
    </TabContext>
  );
};
