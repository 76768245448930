import React, { PropsWithChildren } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { classNames } from './utils';

const useTextButtonStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    height: '30px',
    padding: '0 36px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '30px',
    fontSize: '14px',
    lineHeight: '15px',
    fontWeight: 'bold',
    userSelect: 'none',
    textTransform: 'uppercase'
  },
  link: {
    textDecoration: 'none'
  }
}));

export default function TextButton({
  children,
  ...props
}: PropsWithChildren<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>) {
  const styles = useTextButtonStyles();
  return (
    <a {...props} className={classNames(props.className, styles.link)}>
      <div className={styles.button}>{children}</div>
    </a>
  );
}
