import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CalendarAddIcon(props: SvgIconProps) {
  const theme = useTheme();

  let defaultStroke = '#000000';
  if (props.color) {
    // @ts-ignore
    defaultStroke = theme.palette[props.color]?.main;
  }

  const stroke = props.stroke ?? defaultStroke;

  return (
    <SvgIcon viewBox="0 0 64 64" stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M46.667 62.6667C55.5035 62.6667 62.667 55.5032 62.667 46.6667C62.667 37.8301 55.5035 30.6667 46.667 30.6667C37.8304 30.6667 30.667 37.8301 30.667 46.6667C30.667 55.5032 37.8304 62.6667 46.667 62.6667Z"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M40.0005 46.6667H53.3338"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.667 53.3333V40"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.6667 26.6666V6.66663H44"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 6.66663H1.3335V49.3333H25.3335"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 1.33331H12V12H20V1.33331Z"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 1.33331H36V12H44V1.33331Z"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 6.66663H36"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 20H54.6668"
        stroke="#F47D6F"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
