import { Link } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

type PostsTabsProps = {
  value: number;
  showHeadOfficeContentSchedule?: boolean;
};

interface GetTabsOptions {
  showHeadOfficeContentSchedule: boolean;
}

function getTabs(value: number, { showHeadOfficeContentSchedule }: GetTabsOptions) {
  return [
    { label: 'Posts', to: '/posts', show: true },
    { label: 'Calendar', to: '/posts/calendar', show: true },
    { label: 'Posts Report', to: '/posts/report', show: true },
    { label: 'Analytics', to: '/posts/analytics', show: true },
    {
      label: 'Head Office Content Schedule',
      to: '/posts/head-office-schedule',
      show: showHeadOfficeContentSchedule
    }
  ]
    .filter((tab) => tab.show)
    .map((tab, idx) => {
      const props = value === idx ? {} : { component: Link, to: tab.to };

      return <Tab label={tab.label} {...props} key={idx} />;
    });
}

export default function PostTabs({ value, showHeadOfficeContentSchedule = false }: PostsTabsProps) {
  return (
    <Tabs textColor="secondary" value={value}>
      {getTabs(value, {
        showHeadOfficeContentSchedule
      })}
    </Tabs>
  );
}
