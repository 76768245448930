import React, { useContext } from 'react';

import { Doughnut } from 'react-chartjs-2';

import { Paper, Typography } from '@material-ui/core';

import numbro from 'numbro';

import { useWorkspacePlatformSpendQuery } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import { getStartDate, getEndDate } from '../utils';
import DatePresetSelect, { DashboardDatePresetContext } from './DatePresetSelect';
import EmptyChartMessage from './EmptyChartMessage';
import LoadingChartIndicator from './LoadingChartIndicator';

interface PlatformBoxProps {
  workspaceId: string;
}

export default function PlatformBox({ workspaceId }: PlatformBoxProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { datePreset } = useContext(DashboardDatePresetContext);

  const startDate = getStartDate(datePreset).format('YYYY-MM-DD');
  const endDate = getEndDate(datePreset).format('YYYY-MM-DD');

  const { data, loading } = useWorkspacePlatformSpendQuery({
    variables: {
      facebook_where: {
        _and: [
          {
            workspace_id: { _eq: workspaceId }
          },
          { date: { _gte: startDate } },
          { date: { _lte: endDate } }
        ]
      },
      googleads_where: {
        _and: [
          {
            workspace_id: { _eq: workspaceId }
          },
          { date: { _gte: startDate } },
          { date: { _lte: endDate } }
        ]
      }
    },
    context: workspaceMemberContext
  });

  const facebookSpend = data?.facebook?.aggregate?.sum?.spend ?? 0;
  const googleAdsSpend = data?.googleads?.aggregate?.sum?.spend ?? 0;
  const total = facebookSpend + googleAdsSpend;

  const formattedTotalSpend = numbro(total).formatCurrency({
    thousandSeparated: true,
    mantissa: 0
  });

  return (
    <Paper style={{ height: '100%' }}>
      <div
        style={{
          padding: '16px 0px 8px 16px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 30
        }}
      >
        <Typography variant="h6">Platform</Typography>
        <DatePresetSelect />
      </div>
      {total === 0 ? (
        loading ? (
          <LoadingChartIndicator />
        ) : (
          <EmptyChartMessage />
        )
      ) : (
        <div
          style={{
            position: 'relative',
            maxHeight: 400,
            maxWidth: 500,
            paddingTop: 4,
            paddingBottom: 12,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Doughnut
            data={{
              labels: [' Meta', ' Google Ads'],
              datasets: [
                {
                  type: 'doughnut',
                  label: 'Amount of Spend',
                  backgroundColor: ['#17457A', '#F47D6F'],
                  data: [facebookSpend, googleAdsSpend]
                }
              ]
            }}
            plugins={[
              {
                id: 'text',
                beforeDraw: function (chart) {
                  const width = chart.width;
                  const height = chart.height;
                  const ctx = chart.ctx;

                  ctx.restore();
                  const fontSize = (height / 140).toFixed(2);
                  // ctx.color = '#696C80';
                  ctx.font = fontSize + 'em DM Sans, sans-serif';
                  ctx.textBaseline = 'middle';

                  const textX = Math.round(
                    (width - ctx.measureText(formattedTotalSpend).width) / 2
                  );
                  const textY = height / 2 + 12;

                  ctx.fillStyle = '#696C80';
                  ctx.fillText(formattedTotalSpend, textX, textY);
                  ctx.save();
                }
              }
            ]}
            options={{
              cutout: '85%',
              plugins: {
                legend: {
                  display: true,
                  position: 'top',
                  align: 'center',
                  labels: {
                    boxWidth: 8,
                    color: '#696C80',
                    font: {
                      family: 'DM Sans, sans-serif',
                      size: 12
                    },
                    pointStyle: 'circle',
                    usePointStyle: true
                  }
                }
              },
              backgroundColor: 'rbga(0, 0, 0, 0)',
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 2.5
            }}
          />
        </div>
      )}
    </Paper>
  );
}
