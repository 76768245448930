import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function PenPaintBrush(props: SvgIconProps) {
  const theme = useTheme();

  let defaultStroke = '#000000';
  if (props.color) {
    // @ts-ignore
    defaultStroke = theme.palette[props.color]?.main;
  }

  const stroke = props.stroke ?? defaultStroke;

  return (
    <SvgIcon viewBox="0 0 64 64" stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.6721 49.3358L14.6738 57.3353L47.999 24.0007L39.9972 16.0012L6.6721 49.3358Z"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7812 40.2186L55.9999 62.6666C60.5306 62.6666 62.6666 63.1866 62.6666 56L36.6399 35.3626"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.9993 12C62.082 14.0827 62.082 17.92 59.9993 20L46.666 33.3333"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99967 58L1.33301 62.6667"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.6666 9.33449L54.666 1.33389L39.9998 16.0001L48.0004 24.0007L62.6666 9.33449Z"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.664 51.3333L4 55.9973L8 60L12.6667 55.3333"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99959 1.33334C7.50892 11.3093 16.5783 10.0827 20.4796 12.9893C24.6103 16.0693 25.4823 22.0533 22.3943 26.1813C12.4049 39.5787 -6.90175 15.7387 7.99959 1.33334Z"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7144 28.2825L23.2718 24.7599L23.1998 24.8399C22.9624 25.3039 22.7144 25.7572 22.3944 26.1812C21.5091 27.3652 20.5518 28.2452 19.5518 28.8825L23.4771 32.5172"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
