import {
  AnyBoolExp,
  AnyComparisonExp,
  BoolOrComparisonExp,
  ComparisonOperators,
  isComparisonExp
} from 'generated/filterTypes';

type ComparisonOperatorType = ComparisonOperators | ComparisonOperators[];
export type LogicalOperator = '_and' | '_or';
export type LogicalOperatorOption = {
  /**
   * How to group the array of filters. Choose between _and || _or.
   */
  logicalOperator?: LogicalOperator;
};

function findInObject(
  obj: BoolOrComparisonExp | BoolOrComparisonExp[],
  test: (args: any) => boolean
): BoolOrComparisonExp | BoolOrComparisonExp[] | null {
  let returnType: null | {} = null;

  Object.keys(obj).forEach((key) => {
    const value = obj[key as keyof typeof obj] as BoolOrComparisonExp | BoolOrComparisonExp[];

    if (test(value)) {
      if (returnType !== null) {
        returnType = [returnType, value];
      } else {
        returnType = value;
      }
    } else if (Array.isArray(value)) {
      returnType = value.flatMap((innerValue) => findInObject(innerValue, test));
    } else if (typeof value === 'object' && value !== null) {
      returnType = findInObject(value, test);
    }
  });

  return returnType;
}

function buildComparisonObj(
  operator: ComparisonOperatorType,
  searchTerm: unknown
): AnyComparisonExp {
  if (Array.isArray(operator)) {
    return operator.reduce((builtComparisonObj, currentOperator) => {
      builtComparisonObj[currentOperator] = searchTerm;

      return builtComparisonObj;
    }, {} as AnyComparisonExp);
  }

  return { [operator]: searchTerm } as AnyComparisonExp;
}

function applyComparison(
  comparator: AnyComparisonExp,
  comparisonObj: AnyComparisonExp,
  searchTerm: unknown
) {
  if (Object.keys(comparator).length === 0) {
    Object.entries(comparisonObj).forEach(([key, value]) => {
      comparator[key as keyof AnyComparisonExp] = value;
    });
  } else {
    // comparator already has keys in it. Let's use those keys
    Object.keys(comparator).forEach((key) => {
      comparator[key as keyof AnyComparisonExp] = searchTerm;
    });
  }
}

type Comparator = AnyComparisonExp | AnyComparisonExp[] | null;

function applyComparisonTerms(
  obj: Comparator,
  comparisonOperatorTerm: ComparisonOperatorType,
  searchTerm: unknown
) {
  if (obj === null) {
    return;
  }

  const comparisonObj = buildComparisonObj(comparisonOperatorTerm, searchTerm);
  toArray(obj).forEach((value) => applyComparison(value, comparisonObj, searchTerm));
}

const isComparisonExpFn = (value: any) => Object.keys(value).length === 0 || isComparisonExp(value);

function getComparator(obj: AnyBoolExp): AnyComparisonExp | null {
  const comparator = findInObject(obj, isComparisonExpFn);

  return comparator as AnyComparisonExp | null;
}

function objIsSingleType<T extends AnyBoolExp>(obj: T | T[]): obj is T {
  return !Array.isArray(obj);
}

function getComparatorsForBoolExp<T extends AnyBoolExp>(obj: T | T[]): Comparator {
  if (objIsSingleType(obj)) {
    return getComparator(obj) as AnyComparisonExp | null;
  }

  return obj.flatMap((value: T) => getComparator(value)).filter(Boolean) as AnyComparisonExp[];
}

function deepCopy<T extends BoolOrComparisonExp | BoolOrComparisonExp[]>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

function toArray<T extends AnyBoolExp>(obj: T | T[]): T[] {
  return Array.isArray(obj) ? obj : [obj];
}

/**
 * When mutating and returning the same object React's shallow compare and Apollo fail to
 * notice that a page rerender or refetch is required. This function create a new reference
 * for array/objects or simply returns the primitive if one was passed in
 */
function toNewValue<T extends BoolOrComparisonExp | BoolOrComparisonExp[]>(obj: T): T {
  if (typeof obj === 'object') {
    return deepCopy(obj) as T;
  }

  return obj;
}

export interface Setter<T extends AnyBoolExp> {
  (val: unknown): T;
}

export function buildSetter<T extends AnyBoolExp>(
  boolExp: T | T[],
  comparisonTerm: ComparisonOperatorType,
  logicalOperator?: LogicalOperator
): Setter<T> {
  const copied = deepCopy(boolExp);
  const comparators = getComparatorsForBoolExp(copied);

  return (val: unknown) => {
    applyComparisonTerms(comparators, comparisonTerm, val);

    return toNonArray(copied, logicalOperator ?? '_or');
  };
}

export function toNonArray<T extends AnyBoolExp>(
  obj: T | T[],
  logicalOperator: LogicalOperator
): T {
  if (Array.isArray(obj)) {
    return { [logicalOperator!]: toNewValue(obj) } as T;
  }

  return toNewValue(obj);
}

export function isDefined<T>(obj: T): obj is NonNullable<T> {
  return obj !== null && obj !== undefined;
}

/**
 * Create a nested object from a dot notation string
 *
 * @param key
 * @param value
 * @returns
 */
export function createNestedObject(key: string, value: any): Record<string, any> {
  const keys = key.split('.');
  const result: Record<string, any> = {};

  let current = result;
  for (let i = 0; i < keys.length; i++) {
    const keyPart = keys[i];
    if (i === keys.length - 1) {
      current[keyPart] = value;
    } else {
      current[keyPart] = {};
      current = current[keyPart];
    }
  }

  return result;
}

export type FilterKeys<T, P> = {
  [K in keyof T]: T[K] extends P ? T[K] : T[K] extends object ? FilterKeys<T[K], P> : never;
};

export type FlattenNestedMaxDepthTwo<T extends object, P> = {
  [K in keyof T]: T[K] extends P ? T[K] : FilterKeys<T[K], P>;
};

type RecursiveFlattenKeys<T> = T extends object
  ? {
      [K in keyof T]-?: K extends string ? K | `${K}.${RecursiveFlattenKeys<T[K]>}` : never;
    }[keyof T]
  : never;

export type FlattenKeys<T> = RecursiveFlattenKeys<T>;
