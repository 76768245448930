import React, { PropsWithChildren } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { classNames } from './utils';

interface SmallTextButtonStylesProps {
  height: string;
  width: string;
}

const useSmallTextButtonStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    flex: 1,
    height: (props: SmallTextButtonStylesProps) => props?.height,
    minWidth: (props: SmallTextButtonStylesProps) => props?.width,
    padding: '0 14px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '2px',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
    fontSize: '11px',
    lineHeight: '11px',
    fontWeight: theme.palette.primary.contrastText.toLowerCase() === '#ffffff' ? 700 : 500,
    userSelect: 'none'
  },
  link: {
    textDecoration: 'none'
  }
}));

export default function SmallTextButton({
  children,
  ...props
}: PropsWithChildren<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
    height?: string;
    width?: string;
  }
>) {
  const styles = useSmallTextButtonStyles({
    height: props.height ?? '24px',
    width: props.width ?? 'auto'
  });
  return (
    <a {...props} className={classNames(props.className, styles.link)}>
      <div className={styles.button}>{children}</div>
    </a>
  );
}
