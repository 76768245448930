import React from 'react';

import Preview from './facebook/Preview';

export interface FacebookAdPreviewProps {
  readonly platform: 'facebook';
  forceRefreshCount?: number;
  creativeIds: string[];
  videoIds: string[];
  creativeSource?: string;
  posts?: any[];
  facebookPageId?: string;
  objective?: string;
  leadFormId?: string;
}

const FacebookPreview: React.FC<FacebookAdPreviewProps> = ({
  forceRefreshCount,
  facebookPageId,
  creativeSource = 'custom',
  posts,
  creativeIds,
  videoIds,
  leadFormId,
  objective
}) => {
  return (
    <div>
      {creativeSource === 'existing_post' && (
        <Preview posts={posts} pageId={facebookPageId} leadFormId={leadFormId} />
      )}
      {creativeSource === 'custom' && (
        <Preview
          creative_ids={creativeIds}
          pageId={facebookPageId}
          objective={objective}
          forceRefreshCount={forceRefreshCount}
          leadFormId={leadFormId}
        />
      )}
      {creativeSource === 'realshortz_video' && (
        <Preview
          video_ids={videoIds}
          pageId={facebookPageId}
          objective={objective}
          forceRefreshCount={forceRefreshCount}
          leadFormId={leadFormId}
        />
      )}
    </div>
  );
};

export default FacebookPreview;
