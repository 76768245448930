import { useCallback } from 'react';

import { OperationVariables, ApolloQueryResult } from '@apollo/client';

import { useSnackbar } from 'notistack';

import {
  useSavedDraftCampaignLazyQuery,
  useSaveDraftCampaignMutation,
  Workspace_Campaign_Status_Enum
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import { useAgentPermissions } from './useAgentPermissions';
import useUserContext from './useUserContext';

export default function useCloneCampaign<TData = any, TVariables = OperationVariables>(
  refetch: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>
) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const { enqueueSnackbar } = useSnackbar();

  const [cloneCampaign] = useSaveDraftCampaignMutation({ context: workspaceMemberContext });
  const [fetchCampaignById] = useSavedDraftCampaignLazyQuery({
    context: workspaceMemberContext
  });

  const handleCloneCampaign = useCallback(
    async (id: string, listing_id?: string) => {
      try {
        const resp = await fetchCampaignById({ variables: { id } });
        const campaign = resp?.data?.workspace_campaigns_by_pk;
        const other = resp?.data?.other_data;

        if (!campaign || !other) {
          throw new Error('Unable to find campaign to clone');
        }

        let {
          id: _campaignId,
          __typename,
          name,
          listings: campaignListings,
          purchase_order_number,
          ad_credit_id,
          facebook_page_id,
          ...object
        } = campaign;

        const campaignStatus = other.status;

        const includePurchaseOrderNumber = [
          Workspace_Campaign_Status_Enum.Error,
          Workspace_Campaign_Status_Enum.Draft
        ].includes(campaignStatus);

        const listings: string[] = [];
        if (listing_id) {
          listings.push(listing_id);
        }
        for (const listing of campaignListings) {
          // de-dupe listing id
          if (!listings.includes(listing.listing_id)) {
            listings.push(listing.listing_id);
          }
        }

        // Ensure agent has permissions to page
        if (
          isWorkspaceAgent &&
          facebook_page_id &&
          !agentPermissions.allowed_facebook_pages.includes(facebook_page_id)
        ) {
          const defaultAgentPage = agentPermissions.allowed_facebook_pages[0];
          facebook_page_id = defaultAgentPage;
        }

        await cloneCampaign({
          variables: {
            object: {
              ...object,
              listings: {
                data: listings.map((id) => ({
                  listing_id: id
                }))
              },
              facebook_page_id: facebook_page_id,
              purchase_order_number: includePurchaseOrderNumber ? purchase_order_number : null,
              ad_credit_id: null,
              name: `${name} clone`,
              status: Workspace_Campaign_Status_Enum.Draft
            }
          }
        });
        await refetch();
        enqueueSnackbar('Campaign cloned!', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to clone campaign', { variant: 'error' });
      }
    },
    [
      cloneCampaign,
      enqueueSnackbar,
      fetchCampaignById,
      refetch,
      isWorkspaceAgent,
      agentPermissions.allowed_facebook_pages
    ]
  );

  return handleCloneCampaign;
}
