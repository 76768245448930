import { useState } from 'react';

import MaterialTable from '@material-table/core';

import moment from 'moment';

import {
  useWorkspaceAgentMappingsQuery,
  useWorkspacePostCountsByContentTypeQuery,
  Workspace_Post_Status_Enum,
  Workspace_Posts_Bool_Exp,
  WorkspacePostCountsByContentTypeQuery
} from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';
import Toolbar from 'components/Toolbar';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { createFilter } from 'lib/hooks/useFiltersAndPagination';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { getPlatformLabel } from 'lib/hooks/usePlatforms';
import useUserContext from 'lib/hooks/useUserContext';

import PostTabs from '../components/PostTabs';

const useStatusFilter = createFilter<Workspace_Posts_Bool_Exp>({ status: {} });

const usePlatformFilter = createFilter<Workspace_Posts_Bool_Exp, string>({
  args: { _has_key: null }
});

const PostsReportPage = () => {
  const { userId, workspace, activeWorkspaceId, isWorkspaceAgent, isWorkspaceAdmin } =
    useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const agentPermissions = useAgentPermissions();

  // Filters
  const [statusFilter, status, setStatus] = useStatusFilter();
  const [platformFilter, platform, setPlatform] = usePlatformFilter();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);
  const [filterAgentName, setFilterAgentName] = useLocalStorage(
    `properti.${activeWorkspaceId}.posts.filter.agent`
  );

  const { data: agentsData } = useWorkspaceAgentMappingsQuery({
    variables: {
      workspace_id: activeWorkspaceId!
    },
    context: workspaceMemberContext
  });

  const agents =
    agentsData?.workspace_agent_mapping?.filter(
      (map) =>
        !isWorkspaceAgent ||
        agentPermissions.allowed_agents.includes(map.listing_agent_name.trim()) ||
        map.user_id === userId
    ) ?? [];

  const agentNameOptions = agents
    ?.filter(Boolean)
    .map((agent) => ({ value: agent.user?.display_name!, text: agent.user?.display_name! }));

  // Date ranges for aggregating post counts
  const [startOfDay] = useState(moment().startOf('day')); // useState to avoid rerenders
  const [endOfDay] = useState(moment().endOf('day')); // useState to avoid rerenders

  const [startOfWeek] = useState(moment().startOf('week')); // useState to avoid rerenders
  const [endOfWeek] = useState(moment().endOf('week')); // useState to avoid rerenders

  const [startOfMonth] = useState(moment().startOf('month')); // useState to avoid rerenders
  const [endOfMonth] = useState(moment().endOf('month')); // useState to avoid rerenders

  const [startOfYear] = useState(moment().startOf('year')); // useState to avoid rerenders
  const [endOfYear] = useState(moment().endOf('year')); // useState to avoid rerenders

  const where: Workspace_Posts_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { ...statusFilter },
      { ...platformFilter }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        }
      ]
    });
  }

  const { data, loading } = useWorkspacePostCountsByContentTypeQuery({
    variables: {
      where: where,
      workspace_id: activeWorkspaceId!,
      start_of_day: startOfDay,
      end_of_day: endOfDay,
      start_of_week: startOfWeek,
      end_of_week: endOfWeek,
      start_of_month: startOfMonth,
      end_of_month: endOfMonth,
      start_of_year: startOfYear,
      end_of_year: endOfYear
    },
    context: workspaceMemberContext
  });

  const formatPostCounts = (data?: WorkspacePostCountsByContentTypeQuery) => {
    if (!data) return [];

    return [
      {
        contentType: 'Auction Clearance Rates',
        count_day: data.AuctionClearanceRates_day.aggregate?.count ?? 0,
        count_week: data.AuctionClearanceRates_week.aggregate?.count ?? 0,
        count_month: data.AuctionClearanceRates_month.aggregate?.count ?? 0,
        count_year: data.AuctionClearanceRates_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Authority Building',
        count_day: data.AuthorityBuilding_day.aggregate?.count ?? 0,
        count_week: data.AuthorityBuilding_week.aggregate?.count ?? 0,
        count_month: data.AuthorityBuilding_month.aggregate?.count ?? 0,
        count_year: data.AuthorityBuilding_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Listing',
        count_day: data.Listing_day.aggregate?.count ?? 0,
        count_week: data.Listing_week.aggregate?.count ?? 0,
        count_month: data.Listing_month.aggregate?.count ?? 0,
        count_year: data.Listing_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Market Data',
        count_day: data.MarketData_day.aggregate?.count ?? 0,
        count_week: data.MarketData_week.aggregate?.count ?? 0,
        count_month: data.MarketData_month.aggregate?.count ?? 0,
        count_year: data.MarketData_year.aggregate?.count ?? 0
      },
      {
        contentType: 'News Articles',
        count_day: data.NewsUpdate_day.aggregate?.count ?? 0,
        count_week: data.NewsUpdate_week.aggregate?.count ?? 0,
        count_month: data.NewsUpdate_month.aggregate?.count ?? 0,
        count_year: data.NewsUpdate_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Personal',
        count_day: data.Personal_day.aggregate?.count ?? 0,
        count_week: data.Personal_week.aggregate?.count ?? 0,
        count_month: data.Personal_month.aggregate?.count ?? 0,
        count_year: data.Personal_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Testimonials & Social Proof',
        count_day: data.SocialProof_day.aggregate?.count ?? 0,
        count_week: data.SocialProof_week.aggregate?.count ?? 0,
        count_month: data.SocialProof_month.aggregate?.count ?? 0,
        count_year: data.SocialProof_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Generic Post',
        count_day: data.GenericPost_day.aggregate?.count ?? 0,
        count_week: data.GenericPost_week.aggregate?.count ?? 0,
        count_month: data.GenericPost_month.aggregate?.count ?? 0,
        count_year: data.GenericPost_year.aggregate?.count ?? 0
      },
      {
        contentType: 'Other',
        count_day: data.Other_day.aggregate?.count ?? 0,
        count_week: data.Other_week.aggregate?.count ?? 0,
        count_month: data.Other_month.aggregate?.count ?? 0,
        count_year: data.Other_year.aggregate?.count ?? 0
      },
      {
        contentType: 'All',
        count_day: data.All_day.aggregate?.count ?? 0,
        count_week: data.All_week.aggregate?.count ?? 0,
        count_month: data.All_month.aggregate?.count ?? 0,
        count_year: data.All_year.aggregate?.count ?? 0
      }
    ];
  };

  const tableData = formatPostCounts(data);

  const hasHeadOffice = Boolean(workspace?.head_offices?.length);

  return (
    <div>
      <PageHeader title="Posts report" />
      <PostTabs value={2} showHeadOfficeContentSchedule={hasHeadOffice && isWorkspaceAdmin} />
      <MaterialTable
        columns={[
          { title: 'Content Type', field: 'contentType' },
          { title: 'Daily', field: 'count_day' },
          { title: 'Weekly', field: 'count_week' },
          { title: 'Monthly', field: 'count_month' },
          { title: 'Yearly', field: 'count_year' }
        ]}
        data={tableData}
        options={{
          search: false,
          showTitle: false,
          pageSize: 10,
          paging: false
        }}
        isLoading={loading}
        components={{
          Container: TableContainer,
          Toolbar: (props: any) => (
            <Toolbar
              {...props}
              onToggleFiltersOpen={() => setFiltersOpen((prev) => !prev)}
              filtersOpen={filtersOpen}
              menuItems={[
                {
                  name: 'Platform',
                  options: [
                    { value: 'facebook', text: getPlatformLabel('facebook') },
                    { value: 'instagram', text: getPlatformLabel('instagram') },
                    { value: 'linkedin', text: getPlatformLabel('linkedin') },
                    { value: 'linkedin_personal', text: getPlatformLabel('linkedin_personal') },
                    { value: 'youtube', text: getPlatformLabel('youtube') },
                    { value: 'gmb', text: getPlatformLabel('gmb') }
                  ],
                  value: platform,
                  setValue: setPlatform,
                  hasAll: true
                },
                {
                  name: 'Status',
                  options: [
                    { value: Workspace_Post_Status_Enum.Draft, text: 'Draft' },
                    { value: Workspace_Post_Status_Enum.Posted, text: 'Posted' },
                    { value: Workspace_Post_Status_Enum.Posting, text: 'Posting' },
                    { value: Workspace_Post_Status_Enum.Scheduled, text: 'Scheduled' },
                    { value: Workspace_Post_Status_Enum.Error, text: 'Error' }
                  ],
                  value: status,
                  setValue: setStatus,
                  hasAll: true
                },
                {
                  name: 'Agent',
                  value: filterAgentName,
                  setValue: setFilterAgentName,
                  options: agentNameOptions,
                  hasAll: true
                }
              ]}
            />
          )
        }}
      />
    </div>
  );
};

export default PostsReportPage;
