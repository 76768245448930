import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { ADMIN_MODE_SESSION_STORAGE_KEY, useAdminModeContext } from 'lib/AdminModeContext';
import { useHeadOfficeModeContext } from 'lib/HeadOfficeModeContext';

export type HasuraContext = {
  headers: Record<string, string>;
};

export type HasuraRoleContextType = {
  meContext: HasuraContext;
  workspaceMemberContext: HasuraContext;
  propertiStaffContext: HasuraContext | null;
  headOfficeUserContext: HasuraContext | null;
};

export const workspaceMemberHasuraContext: HasuraContext = {
  headers: {
    'x-hasura-role': 'workspace-member'
  }
};

export const meHasuraContext: HasuraContext = {
  headers: {
    'x-hasura-role': 'me'
  }
};

export const propertiStaffHasuraContext: HasuraContext = {
  headers: {
    'x-hasura-role': 'properti-staff'
  }
};

export const headOfficeModeContext: HasuraContext = {
  headers: {
    'x-hasura-role': 'head-office-user'
  }
};

const defaultContext: HasuraRoleContextType = {} as HasuraRoleContextType;

const HasuraRoleContext = createContext(defaultContext);

function HasuraRoleContextProvider({ children }: { children: ReactNode }) {
  const { isAdminMode } = useAdminModeContext();
  const { isHeadOfficeMode } = useHeadOfficeModeContext();

  const meContext = useMemo(() => {
    return meHasuraContext;
  }, []);

  const workspaceMemberContext = useMemo(() => {
    if (isAdminMode) {
      return propertiStaffHasuraContext;
    }

    return workspaceMemberHasuraContext;
  }, [isAdminMode]);

  const propertiStaffContext = useMemo(() => {
    return isAdminMode ? propertiStaffHasuraContext : null;
  }, [isAdminMode]);

  const headOfficeUserContext = useMemo(() => {
    return isHeadOfficeMode ? headOfficeModeContext : null;
  }, [isHeadOfficeMode]);

  const value = useMemo(
    () => ({ meContext, workspaceMemberContext, propertiStaffContext, headOfficeUserContext }),
    [meContext, workspaceMemberContext, propertiStaffContext, headOfficeUserContext]
  );

  return <HasuraRoleContext.Provider value={value}>{children}</HasuraRoleContext.Provider>;
}

const useHasuraRoleContext = () => useContext(HasuraRoleContext);

const getHasuraContextFromSessionStorage = () => {
  if (window.sessionStorage) {
    const adminModeStatus = window.sessionStorage.getItem(ADMIN_MODE_SESSION_STORAGE_KEY);

    if (adminModeStatus && adminModeStatus === 'true') {
      return propertiStaffHasuraContext;
    }
  }

  return workspaceMemberHasuraContext;
};

export { HasuraRoleContextProvider, useHasuraRoleContext, getHasuraContextFromSessionStorage };
