import React, { FormEvent } from 'react';

import { Form } from 'react-final-form';

import { CircularProgress } from '@material-ui/core';

import arrayMutators from 'final-form-arrays';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

import {
  UpdateResponsiveCreativeInput,
  useUpdateResponsiveCreativeMutation
} from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import ResponsiveAdDetails from './ResponsiveAdDetails';

export interface EditResponsiveCreativeProps {
  initialValues: UpdateResponsiveCreativeInput;
  onSuccess: (values: UpdateResponsiveCreativeInput) => void;
  onError: (error: unknown) => void;
  render: React.FC<{ submitting: boolean; submit: () => void }>;
}

const urlSchema =
  process.env.NODE_ENV === 'development'
    ? Yup.string().required().label('Landing Page URL')
    : Yup.string().url().required().label('Landing Page URL');

const schema = Yup.object().shape({
  creative_name: Yup.string().label('Creative Name'),
  cta: Yup.string().required().label('Call to Action'),
  descriptions: Yup.array()
    .required()
    .min(1)
    .max(5)
    .of(Yup.string().min(10).max(90).trim())
    .label('Descriptions'),
  // id: Yup.string().required(),
  long_title: Yup.string().required().min(10).max(90).trim().label('Long Title'),
  responsive_id: Yup.string().required(),
  tags: Yup.array().of(Yup.string().trim()).required().label('Tags'),
  titles: Yup.array()
    .required()
    .min(1)
    .max(5)
    .of(Yup.string().min(5).max(30).trim())
    .label('Titles'),
  url: urlSchema,
  videos: Yup.array().of(Yup.string().required()).label('Videos'),
  workspace_id: Yup.string().required().label('Workspace')
});

const validate = makeValidate(schema);

export default function EditResponsiveCreative({
  initialValues,
  onSuccess,
  onError,
  render: Wrapper
}: EditResponsiveCreativeProps) {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const [updateResponsiveCreative] = useUpdateResponsiveCreativeMutation();
  async function handleSubmit(values: UpdateResponsiveCreativeInput) {
    try {
      await updateResponsiveCreative({
        variables: {
          creative: {
            ...values,
            creative_name: values.creative_name ?? values.titles[0]
          }
        },
        context: workspaceMemberContext
      });
      onSuccess(values);
    } catch (error) {
      onError(error);
    }
  }
  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ values: true, submitting: true }}
    >
      {({ submitting, values, handleSubmit }) => {
        if (submitting) {
          return <CircularProgress />;
        }

        async function submit(event?: FormEvent) {
          event?.preventDefault();
          await handleSubmit();
        }

        return (
          <Wrapper submit={submit} submitting={submitting}>
            <form onSubmit={submit}>
              <ResponsiveAdDetails />
            </form>
          </Wrapper>
        );
      }}
    </Form>
  );
}
