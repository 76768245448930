import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MobileFrameSkeleton from '../MobileFrameSkeleton';
import TextButton from '../TextButton';
import { GoogleAdsResponsivePreviewProps } from './types';

const useMobile320x250TextAdStyles = makeStyles({
  host: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    height: '250px',
    border: '1px solid #E5E5E5'
  },
  logo: {
    width: '64px',
    height: '32px',
    objectFit: 'contain',
    objectPosition: 'center'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '23px',
    fontWeight: 500,
    lineHeight: '29px',
    margin: '0 0 4px'
  },
  description: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '19px'
  },
  brandName: {
    display: 'block',
    color: '#777777',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '19px',
    margin: '0 0 10px'
  }
});

export default function Mobile320x250TextAd({
  title,
  description,
  brandName,
  logoUrl,
  cta,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useMobile320x250TextAdStyles();
  return (
    <MobileFrameSkeleton>
      <div className={styles.host}>
        <Box display="flex" paddingTop={1}>
          <Box display="flex" justifyContent="center" width={128} height={128} pt={4}>
            <img className={styles.logo} src={logoUrl!} alt="" />
          </Box>
          <Box pt={4} pr={2} height={96}>
            <span className={styles.title}>{title}</span>
            <span className={styles.brandName}>{brandName}</span>
          </Box>
        </Box>
        <Box height={64} px={2}>
          <span className={styles.description}>{description}</span>
        </Box>
        <Box display="flex" justifyContent="center">
          <TextButton href={landingPageUrl} target="_blank" hidden={!showButton}>
            {cta}
          </TextButton>
        </Box>
      </div>
    </MobileFrameSkeleton>
  );
}
