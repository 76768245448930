import { useCallback, useEffect, useMemo, useState } from 'react';

import { AnyBoolExp } from 'generated/filterTypes';

import { getContext, Context, setContext } from './context';
import { buildSetter, isDefined, LogicalOperatorOption, Setter, toNonArray } from './utils';

export type FilteredOptions<T, State> = {
  initialState?: State;
  customizeFilter?: <CTX extends unknown | undefined = undefined>(
    boolExp: T | T[],
    state?: State,
    ctx?: CTX
  ) => T | T[];
  subscribe?: Context | Context[];
};

type HookReturn<T, S> = [T | undefined, S, React.Dispatch<React.SetStateAction<S>>];

export interface UseFilteredHook<T, State> {
  (options?: Omit<FilteredOptions<T, State>, 'customizeFilter'>): HookReturn<T, State | undefined>;
}

export default function createFilter<T extends AnyBoolExp, State = any>(
  boolExp: T | T[],
  options?: FilteredOptions<T, State> & LogicalOperatorOption
): Context & UseFilteredHook<T, State> {
  const { initialState, logicalOperator = '_or', customizeFilter, subscribe } = options ?? {};

  const emptyReturn = Array.isArray(boolExp) ? ({ [logicalOperator]: undefined } as T) : undefined;

  let setter: Setter<T>;

  if (Array.isArray(boolExp)) {
    setter = buildSetter(boolExp, '_eq', logicalOperator);
  } else {
    setter = buildSetter(boolExp, '_eq');
  }

  let subscribedList = subscribe ? (Array.isArray(subscribe) ? subscribe : [subscribe]) : undefined;

  const getSubscribedList = () => subscribedList;

  let CONTEXT_KEY: null | Object = null;

  let evaluateFunction: undefined | (() => void);

  let shouldDefer = false;

  const hook = function useFiltered(options?: FilteredOptions<T, State>) {
    const [state, __setState] = useState<State | undefined>(
      (options?.initialState as State) ?? (initialState as State)
    );

    const [filter, setFilter] = useState<T | undefined>(emptyReturn);

    useEffect(() => {
      return () => {
        // By setting CONTEXT_KEY to null, the WeakMap automagically clears the key/value pair
        CONTEXT_KEY = null;
      };
    }, []);

    useEffect(() => {
      if (!!customizeFilter) {
        evaluateFunction = function () {
          const subscribed = getSubscribedList();
          const ctx = subscribed ? getContext(subscribed) : undefined;
          const filter = toNonArray(customizeFilter(boolExp, state, ctx), logicalOperator);

          const isEmpty =
            !isDefined(filter) ||
            (Array.isArray(filter) && filter.length === 0) ||
            Object.keys(filter).length === 0;

          setFilter(isEmpty ? emptyReturn : filter);
        };
      }
    }, [state]);

    useEffect(() => {
      let filter: T;
      let isEmpty = true;

      if (!!customizeFilter && !shouldDefer) {
        const subscribed = getSubscribedList();
        const ctx = subscribed ? getContext(subscribed) : undefined;
        filter = toNonArray(customizeFilter(boolExp, state, ctx), logicalOperator);
        isEmpty =
          !isDefined(filter) ||
          (Array.isArray(filter) && filter.length === 0) ||
          Object.keys(filter).length === 0;
      } else {
        filter = setter(state);
        isEmpty = !isDefined(state);
      }

      setFilter(isEmpty ? emptyReturn : filter);
    }, [state]);

    const setState = useCallback<React.Dispatch<React.SetStateAction<State | undefined>>>(
      (state) => {
        if (typeof state === 'function') {
          __setState((prev) => {
            const newValue = (state as (prev: State | undefined) => State | undefined)(prev);

            setContext(CONTEXT_KEY ?? (CONTEXT_KEY = {}), newValue);

            return newValue;
          });
        } else {
          setContext(CONTEXT_KEY ?? (CONTEXT_KEY = {}), state);
          __setState(state);
        }
      },
      []
    );

    const filtered = useMemo<HookReturn<T, State | undefined>>(() => {
      return [filter, state, setState];
    }, [filter, state, setState]);

    return filtered;
  };
  hook.___getContextKey = () => CONTEXT_KEY;
  hook.___subscribe = (hooks: Context | Context[]) => {
    const subbedList = getSubscribedList();

    if (!subbedList) {
      subscribedList = Array.isArray(hooks) ? hooks : [hooks];
    } else {
      subscribedList = subbedList.concat(hooks);
    }
  };

  hook.___evaluate = () => {
    evaluateFunction?.();
    shouldDefer = false;
  };
  hook.___enableDefer = () => {
    shouldDefer = true;
  };
  return hook;
}
