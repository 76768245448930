import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TextButton from '../TextButton';
import { GoogleAdsResponsivePreviewProps } from './types';

const useDesktop350x250TextAdStyles = makeStyles({
  host: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    height: '250px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.12)'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '27px',
    fontWeight: 700,
    lineHeight: '33px',
    margin: '31px 18px 14px'
  },
  brandName: {
    display: 'block',
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '19px',
    margin: '14px 18px 8px'
  },
  description: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '19px',
    margin: '0 18px',
    letterSpacing: '1%'
  },
  container: {
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '62px',
    background: '#eeeeee'
  },
  button: {
    display: 'flex',
    fontSize: '36px',
    margin: '0 auto',
    '&[hidden]': {
      display: 'none'
    }
  }
});

export default function Desktop350x250TextAd({
  title,
  description,
  brandName,
  cta,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useDesktop350x250TextAdStyles();

  return (
    <div className={styles.host}>
      <div className={styles.container}>
        <span className={styles.title}>{title}</span>
        <span className={styles.brandName}>{brandName}</span>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.buttonContainer}>
        <TextButton
          className={styles.button}
          href={landingPageUrl}
          target="_blank"
          hidden={!showButton}
        >
          {cta}
        </TextButton>
      </div>
    </div>
  );
}
