export enum Objective {
  REACH = 'reach',
  ENGAGEMENT = 'engagement',
  VIDEO_VIEWS = 'video_views',
  WEBSITE_VISITS = 'website_visits',
  MESSAGES = 'messages',
  CONVERSIONS = 'conversions',
  LEADGEN = 'leadgen'
}

export enum ObjectiveCategory {
  AWARENESS,
  CONSIDERATION,
  CONVERSION
}

export interface ObjectiveContainer {
  title: string;
  category: ObjectiveCategory;
  description: string;
}

export interface Objectives {
  [key: string]: ObjectiveContainer;
}

export const OBJECTIVES: Objectives = {
  reach: {
    title: 'Reach',
    description: 'Maximise the number of people who see your ads and how often they see them.',
    category: ObjectiveCategory.AWARENESS
  },
  engagement: {
    title: 'Engagement',
    description: 'Get more people to engage with your campaign',
    category: ObjectiveCategory.CONSIDERATION
  },
  video_views: {
    title: 'Video Views',
    description: 'Focus primarily on getting your video viewed by as many people as possible',
    category: ObjectiveCategory.CONSIDERATION
  },
  website_visits: {
    title: 'Website Visits',
    description: 'Drive traffic to your website with this option',
    category: ObjectiveCategory.CONSIDERATION
  },
  conversions: {
    title: 'Conversions (pixel required)',
    description: 'Focus on converting potential leads',
    category: ObjectiveCategory.CONVERSION
  },
  messages: {
    title: 'Messages',
    description: 'Generate leads via Messenger',
    category: ObjectiveCategory.CONVERSION
  },
  leadgen: {
    title: 'Leadgen',
    description: 'Gather leads from leadgen forms',
    category: ObjectiveCategory.CONVERSION
  }
} as const;
