import { makeStyles, Theme } from '@material-ui/core/styles';

const useTagHolderStyles = makeStyles((theme: Theme) => ({
  tagHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.25)
    }
  },
  tags: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
  }
}));

export default useTagHolderStyles;
