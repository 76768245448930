import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MobileFrameSkeleton from '../MobileFrameSkeleton';
import SmallChevronTextButton from '../SmallChevronTextButton';
import { GoogleAdsResponsivePreviewProps } from './types';

const useMobile320x250NativeAdStyles = makeStyles({
  image: {
    width: '300px',
    height: '157px',
    objectFit: 'cover'
  },
  container: {
    height: '93px',
    padding: '8px 12px'
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '16px',
    margin: '0 0 10px',
    letterSpacing: '1%'
  },
  description: {
    display: 'block',
    color: '#555555',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: '19px'
  },
  brandName: {
    display: 'block',
    color: '#777777',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '19px',
    margin: '14px 0'
  },
  logo: {
    maxWidth: '64px',
    maxHeight: '32px',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
    objectPosition: 'top center'
  },
  button: {
    float: 'right',
    fontSize: '36px'
  }
});

export default function Mobile320x250NativeAd({
  title,
  brandName,
  imageUrl,
  logoUrl,
  cta,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useMobile320x250NativeAdStyles();
  return (
    <MobileFrameSkeleton>
      <img className={styles.image} src={imageUrl!} alt="" />
      <div className={styles.container}>
        <Box display="flex">
          <Box textAlign="center">
            <img className={styles.logo} src={logoUrl!} alt="" />
            <span className={styles.brandName}>{brandName}</span>
          </Box>
          <Box flex={1} ml={1.5}>
            <span className={styles.title}>{title}</span>
            <SmallChevronTextButton
              className={styles.button}
              href={landingPageUrl}
              target="_blank"
              hidden={!showButton}
            >
              {cta}
            </SmallChevronTextButton>
          </Box>
        </Box>
      </div>
    </MobileFrameSkeleton>
  );
}
