import React from 'react';

import { useLinkListingsQuery, useUpdateLinkListingsMutation } from 'generated/graphql';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

import AssociatePropertyBaseDialog, { FormValues } from '../Base';

interface AssociatePropertyLinkDialogProps {
  open: boolean;
  onClose: () => void;
  linkId: string;
}

const AssociatePropertyLinkDialog: React.FC<AssociatePropertyLinkDialogProps> = ({
  open,
  onClose,
  linkId
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const { data, loading, refetch } = useLinkListingsQuery({
    variables: {
      link_id: linkId
    },
    context: workspaceMemberContext
  });
  const [updateListings] = useUpdateLinkListingsMutation({
    context: workspaceMemberContext
  });

  const handleSave = async (values: FormValues) => {
    await updateListings({
      variables: {
        link_id: linkId,
        objects: values.property_ids?.map((id) => ({
          link_id: linkId,
          listing_id: id
        }))
      }
    });
  };

  const initialValues = data?.listings?.map((l) => l.listing_id) ?? [];

  return (
    <AssociatePropertyBaseDialog
      open={open}
      onClose={onClose}
      loading={loading}
      initialValues={initialValues}
      onSave={handleSave}
      refetch={refetch}
    />
  );
};

export default AssociatePropertyLinkDialog;
