import React from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

interface LoadingStatePageProps {}

const LoadingStatePage: React.FC<LoadingStatePageProps> = () => {
  return (
    <div
      style={{
        minHeight: '85vh',
        backgroundColor: '#fafafa',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingIndicator />
    </div>
  );
};

export default LoadingStatePage;
