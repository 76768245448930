import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiButton: {
    root: {
      textTransform: 'none'
    }
  },
  MuiTypography: {
    root: {
      whiteSpace: 'pre-wrap'
    }
  },
  MuiTabs: {
    indicator: {
      height: 4,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    }
  },
  MuiTab: {
    root: {
      fontWeight: 'bold'
    }
  },
  MuiTableHead: {
    root: {
      color: '#334D6E',
      opacity: 0.5
    }
  },
  MuiStepLabel: {
    active: {
      color: '#F47D6F !important',
      // @ts-ignore
      fontWeight: 'bold !important'
    }
  }
};

export default overrides;
