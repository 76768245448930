import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Tab,
  Tabs
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useDebounce } from 'use-debounce';

import { useCreativesQuery, Workspace_Creatives_Bool_Exp } from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import PaginationContainer from 'components/PaginationContainer';
import CreativeCard from 'components/creative/CreativeCard';
import SearchInput from 'components/forms/SearchInput';
import PenPaintBrush from 'components/icons/PenPaintBrush';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

const CreativePage = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [search, setSearch] = useState<string>();
  const [searchValue] = useDebounce(search ?? '', 650);
  const [page, setPage] = useState(1);
  const [limit] = useState(8);
  const popupState = usePopupState({ variant: 'popover', popupId: 'filterMenu' });
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [tab, setTab] = useState(0);

  const filterSearch = searchValue.length > 0 ? `%${searchValue}%` : undefined;
  const filterTags = filteredTags.length > 0 ? filteredTags : undefined;
  const where: Workspace_Creatives_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { title: filterSearch ? { _ilike: filterSearch } : undefined },
      { _or: [{ tags: { tag: { _in: filterTags } } }, { _not: { tags: {} } }] }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        }
      ]
    });
  }

  const { data, loading, refetch } = useCreativesQuery({
    variables: {
      workspace_id: activeWorkspaceId!,
      offset: (page - 1) * limit,
      limit: limit,
      where: where
    },
    context: workspaceMemberContext,
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true
  });

  const tags = data?.workspace_creative_tags?.map((tag) => tag.tag);
  const pageCount = Math.max(
    1,
    Math.ceil((data?.workspace_creatives_aggregate?.aggregate?.count ?? 0) / limit)
  );

  const handleFilterTag = (tag: string) => () => {
    if (filteredTags.includes(tag)) {
      // remove
      const newTags = filteredTags.filter((t) => t !== tag);
      setFilteredTags(newTags);
    } else {
      // add
      setFilteredTags([...filteredTags, tag]);
    }
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    if (tab !== newTab) {
      setTab(newTab);
    }
  };

  useEffect(() => {
    if (tab === 0) {
      setFilteredTags([]);
    }
    if (tab === 1) {
      setFilteredTags(['image']);
    }
    if (tab === 2) {
      setFilteredTags(['video']);
    }
    if (tab === 3) {
      setFilteredTags(['ofi']);
    }
    if (tab === 4) {
      setFilteredTags(['head office']);
    }
  }, [tab]);

  if (
    !loading &&
    data?.creatives?.length === 0 &&
    searchValue.length === 0 &&
    filteredTags.length === 0
  ) {
    return (
      <EmptyStatePage
        title="Creative"
        text="Easily generate and automate the creation for you marketing efforts here."
        icon={<PenPaintBrush color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            component={Link}
            to="create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceAnalyst}
            fullWidth
          >
            New Creative
          </Button>
        }
      />
    );
  }

  return (
    <div>
      <PageHeader
        title="Creative"
        rightComponent={
          <Button
            component={Link}
            to="create"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isWorkspaceAnalyst}
            style={{ minWidth: 160 }}
          >
            New Creative
          </Button>
        }
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="All" value={0} />
          <Tab label="Image" value={1} />
          <Tab label="Video" value={2} />
          <Tab label="OFI" value={3} />
          <Tab label="Head Office" value={4} />
        </Tabs>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'middle',
            alignItems: 'center'
          }}
        >
          <SearchInput
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            placeholder="Search Creatives"
            variant="outlined"
            onClickClear={() => {
              setSearch('');
              setPage(1);
            }}
            onClickSearch={() => {
              refetch();
            }}
          />
          <Tooltip title="Filter Tags">
            <IconButton {...bindTrigger(popupState)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh Creative">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box mb={0.5} height={2}>
        {loading && Boolean(data) && <LinearProgress />}
      </Box>

      {loading && !data && (
        <Grid container spacing={2}>
          {Array.from(Array(8).keys()).map((key) => (
            <Grid key={key} item xs={12} sm={6} md={4} lg={3} xl={3}>
              <CreativeCard key={key} />
            </Grid>
          ))}
        </Grid>
      )}

      <Grid container spacing={2}>
        {data?.creatives?.map((creative) => (
          <Grid
            key={creative.id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            style={{
              paddingTop: 2
            }}
          >
            <CreativeCard id={creative.id} onRefetchRequired={refetch} />
          </Grid>
        ))}
      </Grid>

      <PaginationContainer
        style={{
          marginTop: 16
        }}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={(_, page) => {
            setPage(page);
          }}
          showFirstButton={pageCount >= 5}
          showLastButton={pageCount >= 5}
        />
      </PaginationContainer>
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          style: {
            maxHeight: 300
          }
        }}
        elevation={0}
      >
        {tags?.map((tag) => (
          <MenuItem key={tag} onClick={handleFilterTag(tag)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={filteredTags.includes(tag)}
                onClick={handleFilterTag(tag)}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={tag} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CreativePage;
