import { getFilePath } from 'lib/auth/hbp';

import { FileAttachment } from './uploadFile';

export default function getImageSrc(src: string | Blob | FileAttachment) {
  if (typeof src === 'string') {
    return src;
  } else if (src instanceof Blob) {
    return URL.createObjectURL(src);
  } else {
    // File Attachment
    return getFilePath(src.path, src.token) as string;
  }
}
