import { useState } from 'react';

type SetBoolFunction = () => void;

type UseBooleanResponse = [boolean, SetBoolFunction, SetBoolFunction];

export default function useBool(defaultValue: boolean = false): UseBooleanResponse {
  const [state, setState] = useState<boolean>(defaultValue);

  const setTrue: SetBoolFunction = () => {
    setState(true);
  };

  const setFalse: SetBoolFunction = () => {
    setState(false);
  };

  return [state, setTrue, setFalse];
}
