import { useCallback, useMemo, useState } from 'react';

import { InputMaybe, Order_By } from 'generated/graphql';

import { FlattenKeys, FlattenNestedMaxDepthTwo, createNestedObject, isDefined } from './utils';

type OrderDirection = 'asc' | 'desc';

type OnOrderChangeHandler = (columnIndex: number, orderDirection: OrderDirection) => void;

type HookReturn<T> = [T[] | undefined, OnOrderChangeHandler];

type MaybeInputMaybe = InputMaybe<Order_By> | undefined;

type ValidOrderByColumns<T extends object> = FlattenKeys<
  FlattenNestedMaxDepthTwo<T, MaybeInputMaybe>
>;

export default function createOrderBy<T extends object>(
  initialState: T[],
  columnMapping: ValidOrderByColumns<T>[],
  options?: {
    orderByOverwrites?: {
      [K in ValidOrderByColumns<T>]?: {
        [KP in OrderDirection]?: Order_By;
      };
    };
  }
) {
  const hook = function useOrderBy(): HookReturn<T> {
    const [orderBy, setOrderBy] = useState<T[] | undefined>(initialState);

    const handleOnOrderChange: OnOrderChangeHandler = useCallback(
      (columnIndex: number, orderDirection: OrderDirection) => {
        const columnName = columnMapping[columnIndex];
        if (isDefined(columnName)) {
          // Get sort direction from options if set
          const overwriteDefaultOrderDirection =
            options?.orderByOverwrites?.[columnName]?.[orderDirection];
          const setOrderDirection = overwriteDefaultOrderDirection ?? orderDirection;

          // Create object from dot notation keys.
          const orderByObject = createNestedObject(columnName, setOrderDirection) as T;
          setOrderBy([orderByObject]);
        } else {
          setOrderBy(undefined);
        }
      },
      [setOrderBy]
    );

    const ordered = useMemo<HookReturn<T>>(() => {
      return [orderBy, handleOnOrderChange];
    }, [orderBy, handleOnOrderChange]);

    return ordered;
  };

  return hook;
}
