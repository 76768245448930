import React, { useState } from 'react';

import { useToggle } from 'react-use';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ResponsiveImageSelectionType } from 'generated/graphql';

import getImageSrc from 'lib/utils/getImageSrc';

import ResponsiveCreativeDrawer from './ImagePickerCropDrawer';
import { ImageCropSelection, ImageSource } from './types';
import { meetsResponsiveImageRequirements } from './utils';

const useStyles = makeStyles<Theme, { checked: boolean }>((theme) => ({
  root: {
    position: 'relative',
    height: '128px',
    lineHeight: 0,
    '&:hover': {
      boxShadow: theme.shadows[4]
    },
    transition: 'box-shadow 0.1s'
  },
  selected: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    boxShadow: `0 0 0 2px currentColor inset`,
    color: (props) => (props.checked ? theme.palette.secondary.main : 'transparent'),
    borderRadius: '2px',
    zIndex: 1,
    pointerEvents: 'none',
    transition: 'color 0.1s'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    top: '8px',
    right: '8px',
    background: '#fff',
    borderRadius: '100%'
  },
  image: {
    display: 'block',
    minWidth: '128px',
    width: 'auto',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '2px'
  },
  imageButton: {
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    padding: '0',
    margin: '0',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    boxSizing: 'border-box'
  }
}));

export interface ImagePickerItemProps {
  image: ImageSource;
  value?: ImageCropSelection;
  disabled?: boolean;
  onChange: (selection: ImageCropSelection) => void;
}

async function loadImage(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
    if (img.complete) {
      resolve(img);
    }
  });
}

export function ImagePickerItem({
  image,
  value: initialValue,
  onChange,
  disabled = false
}: ImagePickerItemProps) {
  const [value, setValue] = useState<ImageCropSelection>(initialValue ?? { type: 'none' });
  const [checked, setChecked] = useToggle((value?.type ?? 'none') !== 'none');
  const [drawerOpen, setDrawerOpen] = useToggle(false);
  const [imageData, setImageData] = useState(new Image());
  const src = getImageSrc(image.src);
  const classes = useStyles({ checked });

  async function handleChange() {
    if (!checked) {
      const element = await loadImage(src);
      setImageData(element);
      if (meetsResponsiveImageRequirements(element)) {
        const nextValue: ImageCropSelection = {
          ...value,
          type: ResponsiveImageSelectionType.Smart
        };
        setChecked(true);
        setValue(nextValue);
        onChange(nextValue);
      } else {
        setDrawerOpen(true);
      }
    } else {
      const nextValue: ImageCropSelection = { ...value, type: 'none' };
      setChecked(false);
      setValue(nextValue);
      onChange(nextValue);
    }
  }

  async function handleClick() {
    if (disabled) return;
    const element = await loadImage(src);
    setImageData(element);
    setDrawerOpen(true);
  }

  function handleDrawerClose() {
    setDrawerOpen(false);
  }

  function handleDrawerSave(selection: ImageCropSelection) {
    setDrawerOpen(false);
    setValue(selection);
    setChecked(selection.type !== 'none');
    onChange(selection);
  }

  return (
    <div className={classes.root}>
      <div className={classes.selected} />
      <ResponsiveCreativeDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        onSaved={handleDrawerSave}
        src={src}
        data={imageData}
        value={value}
      />
      <div className={classes.checkbox}>
        <Checkbox
          size="small"
          onChange={handleChange}
          checked={checked}
          value={value}
          disabled={disabled}
        />
      </div>
      <button type="button" className={classes.imageButton} onClick={handleClick}>
        <img className={classes.image} src={src} alt="" />
      </button>
    </div>
  );
}
