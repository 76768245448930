import moment from 'moment';

import { DatePresetOptions, DateResolution } from './types';

export function getDataResolution(datePreset: DatePresetOptions): DateResolution {
  switch (datePreset) {
    case DatePresetOptions.LAST_7D:
    case DatePresetOptions.LAST_28D:
    case DatePresetOptions.LAST_90D:
    case DatePresetOptions.LAST_MONTH:
    case DatePresetOptions.THIS_MONTH:
      return DateResolution.DAILY;
    case DatePresetOptions.THIS_QUARTER:
    case DatePresetOptions.LAST_QUARTER:
      return DateResolution.WEEKLY;
    case DatePresetOptions.THIS_YEAR:
    case DatePresetOptions.LAST_YEAR:
      return DateResolution.MONTHLY;
    default:
      return DateResolution.DAILY;
  }
}

export function getStartDate(datePreset: DatePresetOptions) {
  switch (datePreset) {
    case DatePresetOptions.LAST_7D:
      return moment().subtract(7, 'days');
    case DatePresetOptions.LAST_28D:
      return moment().subtract(28, 'days');
    case DatePresetOptions.LAST_90D:
      return moment().subtract(90, 'days');
    case DatePresetOptions.THIS_MONTH:
      return moment().startOf('month');
    case DatePresetOptions.LAST_MONTH:
      return moment().subtract(1, 'month').startOf('month');
    case DatePresetOptions.THIS_QUARTER:
      return moment().startOf('quarter');
    case DatePresetOptions.LAST_QUARTER:
      return moment().subtract(1, 'quarter').startOf('quarter');
    case DatePresetOptions.THIS_YEAR:
      return moment().startOf('year');
    case DatePresetOptions.LAST_YEAR:
      return moment().subtract(1, 'year').startOf('year');
    default:
      return moment().subtract(7, 'days');
  }
}

export function getEndDate(datePreset: DatePresetOptions) {
  switch (datePreset) {
    case DatePresetOptions.LAST_7D:
      return moment();
    case DatePresetOptions.LAST_28D:
      return moment();
    case DatePresetOptions.LAST_90D:
      return moment();
    case DatePresetOptions.THIS_MONTH:
      return moment();
    case DatePresetOptions.LAST_MONTH:
      return moment().subtract(1, 'month').endOf('month');
    case DatePresetOptions.THIS_QUARTER:
      return moment().endOf('quarter');
    case DatePresetOptions.LAST_QUARTER:
      return moment().subtract(1, 'quarter').endOf('quarter');
    case DatePresetOptions.THIS_YEAR:
      return moment().endOf('year');
    case DatePresetOptions.LAST_YEAR:
      return moment().subtract(1, 'year').endOf('year');
    default:
      return moment();
  }
}

export function getStartDatePreviousPeriod(datePreset: DatePresetOptions) {
  switch (datePreset) {
    case DatePresetOptions.LAST_7D:
      return moment().subtract(7 * 2, 'days');
    case DatePresetOptions.LAST_28D:
      return moment().subtract(28 * 2, 'days');
    case DatePresetOptions.LAST_90D:
      return moment().subtract(90 * 2, 'days');
    case DatePresetOptions.THIS_MONTH:
      return moment().subtract(1, 'month').startOf('month');
    case DatePresetOptions.LAST_MONTH:
      return moment().subtract(2, 'month').startOf('month');
    case DatePresetOptions.THIS_QUARTER:
      return moment().subtract(1, 'quarter').startOf('quarter');
    case DatePresetOptions.LAST_QUARTER:
      return moment().subtract(2, 'quarter').startOf('quarter');
    case DatePresetOptions.THIS_YEAR:
      return moment().subtract(1, 'year').startOf('year');
    case DatePresetOptions.LAST_YEAR:
      return moment().subtract(2, 'year').startOf('year');
    default:
      return moment().subtract(7 * 2, 'days');
  }
}

export function getEndDatePreviousPeriod(datePreset: DatePresetOptions) {
  switch (datePreset) {
    case DatePresetOptions.LAST_7D:
      return moment().subtract(7, 'days');
    case DatePresetOptions.LAST_28D:
      return moment().subtract(28, 'days');
    case DatePresetOptions.LAST_90D:
      return moment().subtract(90, 'days');
    case DatePresetOptions.THIS_MONTH:
      return moment().subtract(1, 'month').endOf('month');
    case DatePresetOptions.LAST_MONTH:
      return moment().subtract(2, 'month').endOf('month');
    case DatePresetOptions.THIS_QUARTER:
      return moment().subtract(1, 'quarter').endOf('quarter');
    case DatePresetOptions.LAST_QUARTER:
      return moment().subtract(2, 'quarter').endOf('quarter');
    case DatePresetOptions.THIS_YEAR:
      return moment().subtract(1, 'year').endOf('year');
    case DatePresetOptions.LAST_YEAR:
      return moment().subtract(2, 'year').endOf('year');
    default:
      return moment().subtract(7, 'days');
  }
}
