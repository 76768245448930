import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

import GoogleMyBusinessIcon from 'mdi-material-ui/GoogleMyBusiness';

interface GoogleMyBusinessAvatarProps extends AvatarProps {
  disabled?: boolean;
}
export default function GoogleMyBusinessAvatar(props: GoogleMyBusinessAvatarProps) {
  const { style, disabled, ...other } = props;

  const backgroundColor = disabled ? '#808080' : '#4285F4';

  return (
    <Avatar style={{ backgroundColor, ...style }} {...other}>
      <GoogleMyBusinessIcon />
    </Avatar>
  );
}
