import { useCallback, useEffect } from 'react';

import { Context, setContext } from './context';

/**
 * Allows you to add a value to the context that is then passed into every `customizeFilter` function that has subscribed to this hook
 */
type HookReturn<T> = (value: T) => void;

export interface UseFilteredContextHook<T> {
  (): HookReturn<T>;
}

export default function createFilterContext<T = unknown>(): Context & UseFilteredContextHook<T> {
  let CONTEXT_KEY: Object | null = {};

  let subscribedList: Context[] | undefined;

  const getSubscribedList = () => subscribedList;

  const hook = function useFilterContext() {
    useEffect(() => {
      return () => {
        // By setting CONTEXT_KEY to null, the WeakMap automagically clears the key/value pair
        CONTEXT_KEY = null;
      };
    }, []);
    return useCallback((value: T) => {
      setContext(CONTEXT_KEY ?? (CONTEXT_KEY = {}), value);
    }, []);
  };

  hook.___getContextKey = () => CONTEXT_KEY;
  hook.___subscribe = (hooks: Context | Context[]) => {
    const subbedList = getSubscribedList();

    if (!subbedList) {
      subscribedList = Array.isArray(hooks) ? hooks : [hooks];
    } else {
      subscribedList = subbedList.concat(hooks);
    }
  };

  return hook;
}
