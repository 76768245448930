import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CogCheckIcon(props: SvgIconProps) {
  const theme = useTheme();

  let defaultStroke = '#000000';
  if (props.color) {
    // @ts-ignore
    defaultStroke = theme.palette[props.color]?.main;
  }

  const stroke = props.stroke ?? defaultStroke;

  return (
    <SvgIcon viewBox="0 0 64 64" stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M43.7863 12.6667C41.813 11.4613 38.3117 10.5493 35.9997 9.99468V1.33334H27.9997V9.99468C25.6877 10.5493 22.1837 11.4613 20.2103 12.6667L14.085 6.54668L6.54101 14.088L12.6663 20.2107C11.461 22.184 10.549 25.688 9.98901 28H1.33301V36H9.98901C10.549 38.312 11.461 41.816 12.6663 43.7893L6.54101 49.9147L14.085 57.456L20.2077 51.3307C22.1837 52.5413 25.6877 53.4507 27.9997 54.0107V62.6667H35.9997V54.0107C38.3117 53.4507 41.8157 52.5413 43.7863 51.3333L49.9143 57.456L57.4557 49.9147L51.333 43.7893C52.5383 41.816 53.4503 38.312 54.0103 36H62.6663V28H54.0077C53.685 26.664 53.245 24.9307 52.6983 23.32"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.6663 1.33334L31.9997 38.6667L21.333 26.6667"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
