import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import AdPreviewButton from '../AdPreviewButton';
import { GoogleAdsResponsivePreviewProps, TextProps } from './types';

const useDesktop160x600ImageAdStyles = makeStyles({
  host: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '160px',
    height: '600px',
    textAlign: 'center',
    boxShadow: '0 2px 8px rgba(0,0,0,0.12)'
  },
  image: {
    display: 'block',
    width: '100%',
    height: '84px',
    objectFit: 'cover'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1
  },
  title: {
    display: 'block',
    color: '#111111',
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props: TextProps) => `${30 - props.title.length / 5}px`,
    fontWeight: 'bold',
    lineHeight: '1.48',
    margin: '32px 16px 24px'
  },
  logo: {
    display: 'block',
    maxWidth: '128px',
    width: 'auto',
    height: 'auto',
    maxHeight: '48px',
    margin: '24px auto'
  },
  logoImage: {
    display: 'block',
    height: '48px',
    width: 'auto',
    objectFit: 'contain'
  },
  description: {
    display: 'block',
    margin: '24px 16px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props: TextProps) => `${19 - props.description.length / 15}px`,
    fontWeight: 300,
    lineHeight: '1.9',
    letterSpacing: '0.03em'
  },
  button: {
    fontSize: '56px',
    margin: '26px auto'
  }
});

export default function Desktop160x600ImageAd({
  imageUrl,
  title,
  description,
  logoUrl,
  landingPageUrl,
  showButton
}: GoogleAdsResponsivePreviewProps) {
  const styles = useDesktop160x600ImageAdStyles({ title, description });

  return (
    <div className={styles.host}>
      <img className={styles.image} src={imageUrl!} alt="" />
      <div className={styles.container}>
        <div>
          <span className={styles.title}>{title}</span>
          <img className={styles.logo} src={logoUrl!} alt="" />
          <span className={styles.description}>{description}</span>
        </div>
        <AdPreviewButton
          className={styles.button}
          href={landingPageUrl}
          target="_blank"
          hidden={!showButton}
        />
      </div>
    </div>
  );
}
