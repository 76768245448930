import React from 'react';

// import { Button } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import LeadTabs from 'components/leads/LeadTabs';

// import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
// import useUserContext from 'lib/hooks/useUserContext';

export default function LeadAutoResponderPage() {
  // const { workspaceMemberContext } = useHasuraRoleContext();
  // const { activeWorkspaceId } = useUserContext();

  return (
    <div>
      <PageHeader
        title="Lead Auto Responder"
        subtitle="Automatically respond to leads with relevant information"
      />
      <LeadTabs currentTab={2} />
    </div>
  );
}
