import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

import { usePostInsightsLazyQuery } from 'generated/graphql';

import DialogTitle from 'components/DialogTitle';
import EmptyStatePage from 'components/EmptyStatePage';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';

interface PostInsightsModalProps {
  postId?: string;
  open: boolean;
  onClose: () => void;
}

const PostInsightsModal: React.FC<PostInsightsModalProps> = ({ postId, open, onClose }) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const [getData, { data, error }] = usePostInsightsLazyQuery({
    context: workspaceMemberContext
  });

  useEffect(() => {
    if (postId) {
      getData({
        variables: {
          post_id: postId
        }
      });
    }
  }, [getData, postId]);

  const showErrorMessage = Boolean(error);

  const post = data?.post;
  const insights = post?.overall_analytics;
  const facebook = post?.facebook_analytics;
  const linkedin = post?.linkedin_analytics;
  const linkedinPersonal = post?.linkedin_personal_analytics;
  const instagram = post?.instagram_analytics;
  const youtube = post?.youtube_analytics;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle onClose={onClose}>Lifetime Post Insights</DialogTitle>
      <DialogContent style={{ minHeight: 600 }}>
        {showErrorMessage && (
          <EmptyStatePage
            icon={<AssessmentOutlinedIcon color="secondary" style={{ width: 64, height: 64 }} />}
            title="Whoops"
            text="We could not load insights for this post"
            button={
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose}
                size="large"
                fullWidth
              >
                Close
              </Button>
            }
            error
          />
        )}

        {Boolean(post) && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Platform</TableCell>
                  <TableCell>Impressions</TableCell>
                  <TableCell>Engagement</TableCell>
                  <TableCell>Reactions</TableCell>
                  <TableCell>Clicks</TableCell>
                  <TableCell>Video Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facebook && (
                  <TableRow>
                    <TableCell>Facebook</TableCell>
                    <TableCell>{facebook.impressions}</TableCell>
                    <TableCell>{facebook.engagement}</TableCell>
                    <TableCell>{facebook.reactions}</TableCell>
                    <TableCell>{facebook.clicks}</TableCell>
                    <TableCell>{facebook.video_views}</TableCell>
                  </TableRow>
                )}
                {instagram && (
                  <TableRow>
                    <TableCell>Instagram</TableCell>
                    <TableCell>{instagram.impressions}</TableCell>
                    <TableCell>{instagram.engagement}</TableCell>
                    <TableCell>Not Available</TableCell>
                    <TableCell>Not Available</TableCell>
                    <TableCell>{instagram.video_views}</TableCell>
                  </TableRow>
                )}
                {linkedin && (
                  <TableRow>
                    <TableCell>LinkedIn</TableCell>
                    <TableCell>{linkedin.impressions}</TableCell>
                    <TableCell>{linkedin.engagement}</TableCell>
                    <TableCell>{linkedin.reactions}</TableCell>
                    <TableCell>{linkedin.clicks}</TableCell>
                    <TableCell>{linkedin.video_views}</TableCell>
                  </TableRow>
                )}
                {linkedinPersonal && (
                  <TableRow>
                    <TableCell>LinkedIn (personal profile)</TableCell>
                    <TableCell>Not Available</TableCell>
                    <TableCell>{linkedinPersonal.engagement}</TableCell>
                    <TableCell>{linkedinPersonal.reactions}</TableCell>
                    <TableCell>Not Available</TableCell>
                    <TableCell>{linkedinPersonal.video_views}</TableCell>
                  </TableRow>
                )}
                {youtube && (
                  <TableRow>
                    <TableCell>Youtube</TableCell>
                    <TableCell>{youtube.video_views}</TableCell>
                    <TableCell>{youtube.engagement}</TableCell>
                    <TableCell>{youtube.reactions}</TableCell>
                    <TableCell>Not Available</TableCell>
                    <TableCell>{youtube.video_views}</TableCell>
                  </TableRow>
                )}
                <TableRow selected>
                  <TableCell>Combined</TableCell>
                  <TableCell>{insights?.total_impressions}</TableCell>
                  <TableCell>{insights?.total_engagement}</TableCell>
                  <TableCell>{insights?.total_reactions}</TableCell>
                  <TableCell>{insights?.total_clicks}</TableCell>
                  <TableCell>{insights?.total_video_views}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box mt={2}>
              <Typography variant="caption">
                Insights are delayed, and some platforms don't provide all metrics. <br />
                Google My Business does not provide any metrics on posts.
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PostInsightsModal;
