import React from 'react';

import { IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps
} from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    }
  })
);

const DialogTitle: React.FC<DialogTitleProps> = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
